import styled, { css } from 'styled-components';
import {
  AutoHorizontalMargins,
  BaseBorderRadiusStyle,
  SortIcon as BaseSortIcon,
  CenterFlexStyle,
  ColumnDiv,
  ColumnFlexStyle,
  EmptyTableHeight,
  EssGapStyle,
  ExtraSmallGapStyle,
  ExtraSmallText,
  MediumGapStyle,
  SmallAddIcon,
  SmallIconSize,
  StartAlignedMediumDarkExtraSmallText,
  SyncIcon,
  TinyText,
  UnderlineStyle,
} from '../../../styles/shared-styled-components';
import { chatDemoContextKey } from '../../../utils/constants';

export const CreateNewCampaignAddIcon = styled(SmallAddIcon)``;

export const FilterButtonHangingChip = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: ${({ theme }) => theme.color.brightGreen};
  height: 20px;
  width: 20px;
  border-radius: 50%;
  ${CenterFlexStyle}
`;

export const FilterButtonHangingChipText = styled(ExtraSmallText)`
  font-size: 11px;
  color: white;
`;

export const TableActionsContainer = styled.div`
  ${ColumnFlexStyle}
  width: ${(props) =>
    props.ctx === chatDemoContextKey
      ? `calc(100% - 20px)`
      : `calc(100% - 20px)`};
  padding-left: 20px;
  ${AutoHorizontalMargins};
  ${MediumGapStyle}
  margin-bottom: 10px;
`;

export const TableButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${EssGapStyle}
`;

export const CampaignButtonsContainer = styled(TableButtonsContainer)`
  ${ExtraSmallGapStyle}
`;

export const ResultSizeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionsText = styled(StartAlignedMediumDarkExtraSmallText)`
  font-size: 15px;
`;

export const SelectedText = styled.span`
  width: max-content;
`;

export const ClientActionText = styled.span`
  margin-left: ${(props) => (props.largeIndent ? '10px' : '4px')};
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
`;

export const NumResultsContainer = styled.div``;

export const FiltersContainer = styled.div`
  ${CenterFlexStyle}
  ${MediumGapStyle}
`;

export const ClientsContainer = styled.div`
  background-color: white;
  padding: 0px;
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  ${BaseBorderRadiusStyle}
  ${ColumnFlexStyle}
  margin-bottom: 20px;
  width: ${(props) => (props.ctx === chatDemoContextKey ? `100%` : `100%`)};
  ${AutoHorizontalMargins}
`;

export const ClientsTableHeaderRow = styled.div`
  display: flex;
  ${AutoHorizontalMargins}
`;

export const HeaderColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${(props) =>
    props.end
      ? css``
      : css`
          border-right: 1px dotted ${({ theme }) => theme.color.muiInput};
        `}
  padding: 10px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.color.muiInput};
`;

export const ClientRow = styled.div`
  display: flex;
`;

export const FieldColumn = styled(ColumnDiv)``;

export const FieldTitleCell = styled.div`
  ${CenterFlexStyle}
  ${(props) =>
    props.end
      ? css``
      : css`
          border-right: 1px dotted ${({ theme }) => theme.color.muiInput};
        `}
  padding: 10px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.color.muiInput};
`;

const EmptyContainerTopOffset = EmptyTableHeight / 1.6;
const EmptyContainerWidth = 300;

export const EmptySearchContainer = styled.div`
  ${CenterFlexStyle}
  ${AutoHorizontalMargins}
  position: absolute;
  top: ${EmptyContainerTopOffset}px;
  width: ${EmptyContainerWidth}px;
  left: calc(50% - ${EmptyContainerWidth / 2}px);
`;

export const ResyncLeadsIcon = styled(SyncIcon)`
  margin-left: 10px;
  color: ${({ theme }) => theme.color.primary};
  ${SmallIconSize}
  cursor: pointer;
`;

export const ResyncServicesIcon = styled(SyncIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${SmallIconSize}
  cursor: pointer;
`;

export const ResetTextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 8px;
`;

export const ResetText = styled(TinyText)`
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
  ${UnderlineStyle}
`;

export const SortFieldContainer = styled.div`
  position: absolute;
  top: -13px;
  background-color: white;
  border: 1px solid
    ${(props) =>
      props.selected
        ? props.theme.color.secondary
        : props.theme.color.disabledText};
  border-radius: 50%;
  padding: 3px;
  height: 12px;
  width: 12px;
  ${CenterFlexStyle}
  cursor: pointer;
  left: calc(50% - 9.5px);
`;

export const SortIcon = styled(BaseSortIcon)`
  color: ${(props) =>
    props.selected
      ? props.theme.color.secondary
      : props.theme.color.disabledText};
  height: 14px;
  width: 14px;
`;

export const DemoNameInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px;
`;
