import { Knob } from 'primereact/knob';
import styled from 'styled-components';
import { TextStyle } from '../../../styles/shared-styled-components';

export const KnobComponent = styled(Knob)`
  /* You can target specific child elements if needed */
  ${TextStyle}
  cursor: pointer;

  .p-knob-text {
    font-size: ${({ theme }) => theme.typography.extraSmallSize};
  }
  .p-knob-range {
    stroke: ${({ theme }) => theme.color.muiInputLightGray};
  }

  .p-knob-value {
    color: #ff5722; /* Orange color for the value text */
  }

  .p-knob-range {
    stroke-width: 10; /* Thickness of the range */
  }

  .p-knob-minor {
    stroke: red; /* Color for the unfilled part of the knob */
  }
`;
