import { gql } from '@apollo/client';

export const CHAT_MESSAGES_SUBSCRIPTION = gql`
  subscription newChatMessagesAdded($chatId: String!) {
    newChatMessagesAdded(chatId: $chatId) {
      chat {
        id
        status
        campaignId
        medium
        disengagement {
          disengagerUserId
          disengagementReason
        }
        interception {
          interceptorUserId
          interceptionReason
        }
        appointmentStatuses
      }
      newMessages {
        id
        sender
        content
        sentAt
      }
    }
  }
`;

export const MY_CHATS_SUBSCRIPTION = gql`
  subscription myChatUpdates {
    myChatUpdates {
      timestamp
    }
  }
`;
