import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  BoxListItemContainer,
  CapitalIInfoTextIcon,
  ColumnFlexStyle,
  EmptyGapColumnCenteredDiv,
  LargeGapStyle,
  MediumLiteGapStyle,
} from '../../../styles/shared-styled-components';

export const AppointmentItemContainer = styled(EmptyGapColumnCenteredDiv)`
  padding: 0px 20px;
`;

export const AppointmentItem = styled(BoxListItemContainer)`
  width: calc(100% - 10px);
  padding: 22px 10px;
  ${ColumnFlexStyle}
  ${AutoHorizontalMargins}
  ${MediumLiteGapStyle}
`;

export const TopAppointmentItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${LargeGapStyle}
  width: 100%;
`;

export const TopLeftSideAppointmentItemContainer = styled.div`
  ${ColumnFlexStyle}
  gap: 2px;
  flex: 1;
`;

export const TopRightSideAppointmentItemContainer = styled.div`
  display: flex;
  flex: 0;
  justify-content: flex-end;
  align-items: center;
  margin-right: -10px;
`;

export const BottomAppointmentItemContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const TimestampDisclaimerIcon = styled(CapitalIInfoTextIcon)`
  margin-left: 8px;
  color: ${({ theme }) => theme.color.lightPrimary};
  height: 19px;
  width: 19px;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;
