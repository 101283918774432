import React, { useContext } from 'react';
import { RangeSlider } from '../../../../styles/shared-styled-components';
import {
  availabilityAgentSettingKey,
  dropdownInputType,
  interactionStyleAgentSettingKey,
  languageAgentSettingKey,
  salesAgentSettingKey,
  unclassifiedKey,
} from '../../../../utils/constants';
import { valueIsEmpty } from '../../../../utils/data';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../LoadingIndicator';
import Knob from '../../../Micro/Knob';
import {
  PromptDialContainer,
  PromptDialElementContainer,
  PromptDialsContainer,
  PromptDialsSection,
  PromptDialsSectionContainer,
  PromptDialsSectionTitleText,
  PromptDialSubtitleText,
  PromptDialTitleText,
  PromptsSection,
  SmallPromptInput,
} from '../../shared-training-components';

const rangeValueLabelMap = {
  [0]: `None`,
  [20]: `Low`,
  [40]: `Slightly low`,
  [60]: `Slightly high`,
  [80]: `High`,
  [100]: `Very`,
};
const frequencyValueLabelMap = {
  [0]: `Never`,
  [25]: `Rarely`,
  [50]: `Sometimes`,
  [75]: `Frequently`,
  [100]: `Always`,
};
const amountValueLabelMap = {
  [0]: `None`,
  [50]: `Some`,
  [100]: `Lots`,
};

const professionalKey = 'professional';
const friendlyKey = 'friendly';
const casualKey = 'casual';
const formalKey = 'formal';

const toneOptions = [
  {
    key: professionalKey,
    label: 'Professional',
  },
  {
    key: friendlyKey,
    label: 'Friendly',
  },
  {
    key: casualKey,
    label: 'Casual',
  },
  {
    key: formalKey,
    label: 'Formal',
  },
];

const morningKey = 'morning';
const afternoonKey = 'afternoon';
const eveningKey = 'evening';

const timeOfDayPreferenceOptions = [
  {
    key: unclassifiedKey,
    label: 'Agnostic',
  },
  {
    key: morningKey,
    label: 'Morning',
  },
  {
    key: afternoonKey,
    label: 'Afternoon',
  },
  {
    key: eveningKey,
    label: 'Evening',
  },
];

const aiKey = 'ai';
const frontDeskTeamKey = 'front_desk';
const contractorKey = 'contractor';
const selfDescribeOptions = [
  {
    key: aiKey,
    label: 'AI agent',
  },
  {
    key: frontDeskTeamKey,
    label: 'Front-desk staff',
  },
  {
    key: contractorKey,
    label: 'Contractor',
  },
];

const TextSettings = ({ agentName, agentSettings, onChange, loading }) => {
  const { width } = useContext(BaseContext);

  const settings = agentSettings?.settings || {};
  const interactionSettings = settings?.[interactionStyleAgentSettingKey] || {};
  const tone = interactionSettings?.tone || '';
  const brevity = interactionSettings?.brevity || 0;
  const emojis = interactionSettings?.emojis || 0;

  const salesSettings = settings?.[salesAgentSettingKey] || {};
  const aggression = !valueIsEmpty(salesSettings?.aggression)
    ? salesSettings.aggression
    : 0;
  const barteringLimit = !valueIsEmpty(salesSettings?.barteringLimit)
    ? salesSettings.barteringLimit
    : 0;
  const upselling = !valueIsEmpty(salesSettings?.upselling)
    ? salesSettings.upselling
    : 0;

  const availabilitySettings = settings?.[availabilityAgentSettingKey];
  const maxAvailabilitiesToDisplay = availabilitySettings?.max || 5;
  const availabilityTimePreference =
    availabilitySettings?.timePreference || unclassifiedKey;

  const languageSettings = settings?.[languageAgentSettingKey];
  const selfDescription = languageSettings?.selfDescription || aiKey;

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  const fixedInputWidth = width > 1600 ? 180 : width > 1000 ? 150 : 130;
  const dialSize = width > 1300 ? 70 : width > 1000 ? 60 : 50;

  return (
    <PromptsSection>
      <PromptDialsSectionContainer>
        <PromptDialsSection
          borderBottom
          top
          rightPadding
        >
          <PromptDialsSectionTitleText>
            Interaction Style
          </PromptDialsSectionTitleText>
          <PromptDialsContainer>
            <PromptDialContainer gap>
              <PromptDialTitleText>Tone</PromptDialTitleText>
              <SmallPromptInput
                type={dropdownInputType}
                options={toneOptions}
                value={tone}
                fixedWidth={fixedInputWidth}
                onChange={(e) => {
                  onChange({
                    ...agentSettings,
                    settings: {
                      ...settings,
                      [interactionStyleAgentSettingKey]: {
                        ...interactionSettings,
                        tone: e.target.value,
                      },
                    },
                  });
                }}
              />
              <PromptDialSubtitleText align='center'>
                {tone
                  ? `Will engage in a ${tone} way`
                  : `How ${agentName} will engage`}
              </PromptDialSubtitleText>
            </PromptDialContainer>
            <PromptDialContainer>
              <PromptDialTitleText>Brevity</PromptDialTitleText>
              <Knob
                value={brevity}
                onChange={({ value }) => {
                  console.log('value', value);
                  onChange({
                    ...agentSettings,
                    settings: {
                      ...settings,
                      [interactionStyleAgentSettingKey]: {
                        ...interactionSettings,
                        brevity: value,
                      },
                    },
                  });
                }}
                showValue={false}
                step={50}
                size={dialSize}
                color='turquoise'
              />
              <PromptDialSubtitleText align='center'>
                {brevity === 0
                  ? `Brief`
                  : brevity === 50
                  ? `Moderate`
                  : brevity === 100
                  ? `Long-winded`
                  : ''}
              </PromptDialSubtitleText>
            </PromptDialContainer>
          </PromptDialsContainer>
        </PromptDialsSection>
        <PromptDialsSection
          borderBottom
          borderLeft
          top
          leftPadding
        >
          <PromptDialsSectionTitleText>
            Sales Strategy
          </PromptDialsSectionTitleText>
          <PromptDialsContainer>
            <PromptDialContainer>
              <PromptDialTitleText>Persistence</PromptDialTitleText>
              <Knob
                value={aggression}
                onChange={({ value }) => {
                  console.log('value', value);
                  onChange({
                    ...agentSettings,
                    settings: {
                      ...settings,
                      [salesAgentSettingKey]: {
                        ...salesSettings,
                        aggression: value,
                      },
                    },
                  });
                }}
                showValue={false}
                size={dialSize}
              />
              <PromptDialSubtitleText>
                {rangeValueLabelMap[aggression]}
              </PromptDialSubtitleText>
            </PromptDialContainer>
            <PromptDialContainer>
              <PromptDialTitleText>Bartering</PromptDialTitleText>
              <PromptDialElementContainer>
                <Knob
                  value={barteringLimit}
                  onChange={({ value }) => {
                    onChange({
                      ...agentSettings,
                      settings: {
                        ...settings,
                        [salesAgentSettingKey]: {
                          ...salesSettings,
                          barteringLimit: value,
                        },
                      },
                    });
                  }}
                  min={0}
                  max={50}
                  step={5}
                  size={dialSize}
                  color='blue'
                  valueTemplate='{value}%'
                  showValue={!!barteringLimit}
                />
              </PromptDialElementContainer>
              <PromptDialSubtitleText align='center'>
                {barteringLimit === 0
                  ? `Never barter`
                  : `Barter up to ${barteringLimit}% off original price`}
              </PromptDialSubtitleText>
            </PromptDialContainer>
            <PromptDialContainer>
              <PromptDialTitleText>Upselling</PromptDialTitleText>
              <Knob
                value={upselling}
                onChange={({ value }) => {
                  console.log('value', value);
                  onChange({
                    ...agentSettings,
                    settings: {
                      ...settings,
                      [salesAgentSettingKey]: {
                        ...salesSettings,
                        upselling: value,
                      },
                    },
                  });
                }}
                step={25}
                size={dialSize}
                color='orange'
                showValue={false}
              />
              <PromptDialSubtitleText>
                {frequencyValueLabelMap[upselling]} upsell
              </PromptDialSubtitleText>
            </PromptDialContainer>
          </PromptDialsContainer>
        </PromptDialsSection>
        <PromptDialsSection
          topPadding
          rightPadding
        >
          <PromptDialsSectionTitleText>
            Availabilities
          </PromptDialsSectionTitleText>
          <PromptDialsContainer>
            <PromptDialContainer gap>
              <PromptDialTitleText>
                Max. displayed per message
              </PromptDialTitleText>
              <PromptDialElementContainer>
                <RangeSlider
                  step={1}
                  min={1}
                  max={7}
                  aria-label='Temperature'
                  defaultValue={maxAvailabilitiesToDisplay}
                  valueLabelDisplay='auto'
                  shiftStep={30}
                  value={maxAvailabilitiesToDisplay}
                  onChange={(e) => {
                    onChange({
                      ...agentSettings,
                      settings: {
                        ...settings,
                        [availabilityAgentSettingKey]: {
                          ...availabilitySettings,
                          max: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </PromptDialElementContainer>
              <PromptDialSubtitleText>
                {'<='} {maxAvailabilitiesToDisplay} availabilities shown per
                message
              </PromptDialSubtitleText>
            </PromptDialContainer>
            <PromptDialContainer gap>
              <PromptDialTitleText>Time of day preference</PromptDialTitleText>
              <PromptDialElementContainer>
                <SmallPromptInput
                  type={dropdownInputType}
                  options={timeOfDayPreferenceOptions}
                  value={availabilityTimePreference}
                  fixedWidth={fixedInputWidth}
                  onChange={(e) => {
                    onChange({
                      ...agentSettings,
                      settings: {
                        ...settings,
                        [availabilityAgentSettingKey]: {
                          ...availabilitySettings,
                          timePreference: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </PromptDialElementContainer>
              <PromptDialSubtitleText align='center'>
                {availabilityTimePreference === unclassifiedKey
                  ? `No preference on time of day`
                  : `Preference for ${availabilityTimePreference} appointments`}
              </PromptDialSubtitleText>
            </PromptDialContainer>
          </PromptDialsContainer>
        </PromptDialsSection>
        <PromptDialsSection
          borderLeft
          topPadding
          leftPadding
        >
          <PromptDialsSectionTitleText>Language</PromptDialsSectionTitleText>
          <PromptDialsContainer>
            <PromptDialContainer gap>
              <PromptDialTitleText>Self-description</PromptDialTitleText>
              <PromptDialElementContainer>
                <SmallPromptInput
                  type={dropdownInputType}
                  options={selfDescribeOptions}
                  value={selfDescription}
                  fixedWidth={fixedInputWidth}
                  onChange={(e) => {
                    onChange({
                      ...agentSettings,
                      settings: {
                        ...settings,
                        [languageAgentSettingKey]: {
                          ...languageSettings,
                          selfDescription: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </PromptDialElementContainer>
              <PromptDialSubtitleText align='center'>
                {!selfDescription
                  ? `How ${agentName} will describe themselves`
                  : `${agentName} will identify as a${
                      selfDescription === aiKey
                        ? `n AI agent`
                        : selfDescription === frontDeskTeamKey
                        ? ` front desk member`
                        : selfDescription === contractorKey
                        ? ` contractor`
                        : ''
                    }`}
              </PromptDialSubtitleText>
            </PromptDialContainer>
            <PromptDialContainer>
              <PromptDialTitleText>Emojis</PromptDialTitleText>
              <Knob
                value={emojis}
                onChange={({ value }) => {
                  onChange({
                    ...agentSettings,
                    settings: {
                      ...settings,
                      [interactionStyleAgentSettingKey]: {
                        ...interactionSettings,
                        emojis: value,
                      },
                    },
                  });
                }}
                showValue={false}
                step={50}
                size={dialSize}
                color='peach'
              />
              <PromptDialSubtitleText>
                {amountValueLabelMap[emojis]}
              </PromptDialSubtitleText>
            </PromptDialContainer>
          </PromptDialsContainer>
        </PromptDialsSection>
      </PromptDialsSectionContainer>
    </PromptsSection>
  );
};

export default TextSettings;
