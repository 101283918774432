import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarGroup } from '@visx/shape';
import React from 'react';
import { mediumDarkText, VisxText } from '../shared-analytics-styles';

// Sample data
const data = [
  { range: 'Sep 23-29', A: 40, B: 55, C: 30 },
  { range: 'Sep 30-Oct 6', A: 60, B: 45, C: 50 },
  { range: 'Oct 7-13', A: 80, B: 70, C: 60 },
  { range: 'Oct 14-20', A: 30, B: 40, C: 35 },
  { range: 'Oct 21-Now', A: 70, B: 80, C: 75 },
];

// Dimensions
const width = 600;
const height = 300;
const margin = { top: 40, right: 30, bottom: 50, left: 50 };

const colorRange = ['#6c5efb', '#c998ff', '#a44afe'];

export default function GroupedBarChart({ data }) {
  const keys = Object.keys(data?.[0])?.filter((k) => k !== 'range');
  const max =
    Math.max.apply(
      Math,
      [
        ...data.map((d) => {
          return keys.map((k) => d[k]);
        }),
      ].flat(),
    ) * 1.1;

  // Scales
  const rangeScale = scaleBand({
    domain: data.map((d) => d.range),
    padding: 0.4,
    range: [0, width - margin.left - margin.right],
  });

  const innerScale = scaleBand({
    domain: keys,
    padding: 0.1,
    range: [0, rangeScale.bandwidth()],
  });

  const valueScale = scaleLinear({
    domain: [0, max],
    range: [height - margin.top - margin.bottom, 0],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: keys,
    range: ['#6c5efb', '#c998ff', '#a44afe'],
  });

  return (
    <div>
      <svg
        width={width}
        height={height}
      >
        <VisxText
          x={width / 2}
          y={40}
          fontSize={14}
          textAnchor='middle'
          fill={mediumDarkText}
        >
          Tasks Handled
        </VisxText>
        <Group
          top={margin.top}
          left={margin.left}
        >
          <BarGroup
            data={data}
            keys={keys}
            height={height - margin.top - margin.bottom}
            x0={(d) => d.range}
            x0Scale={rangeScale}
            x1Scale={innerScale}
            yScale={valueScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}`}
                  left={barGroup.x0}
                >
                  {barGroup.bars.map((bar) => (
                    <rect
                      key={`bar-group-bar-${barGroup.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                    />
                  ))}
                </Group>
              ))
            }
          </BarGroup>
          {/* Left Axis */}
          <AxisLeft
            scale={valueScale}
            stroke='#333'
            tickStroke='#333'
            numTicks={6}
            hideTicks
            tickLabelProps={() => ({
              fill: '#333',
              fontSize: 12,
              fontFamily: 'SeldonFont',
              textAnchor: 'end',
              dx: '-0.25em',
              dy: '0.25em',
            })}
          />
          {/* Bottom Axis */}
          <AxisBottom
            top={height - margin.bottom - margin.top}
            scale={rangeScale}
            hideTicks
            stroke='#333'
            tickStroke='#333'
            tickLabelProps={() => ({
              fill: '#333',
              fontSize: 12,
              textAnchor: 'middle',
              fontFamily: 'SeldonFont',
            })}
          />
        </Group>
      </svg>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 10,
          marginTop: -10,
        }}
      >
        {keys.map((key, i) => (
          <div
            key={key}
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '0 10px',
              fontFamily: 'SeldonFont',
              fontSize: 13,
              color: mediumDarkText,
            }}
          >
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: colorRange[i],
                marginRight: 5,
              }}
            />
            <span>{key}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
