import gql from 'graphql-tag';

export const GET_AGENT_BY_ID = gql`
  query getAgentById($id: String!) {
    getAgentById(id: $id) {
      id
      name
      productKey
      status
      createdAt
    }
  }
`;

export const GET_AGENT_VERSION = gql`
  query getAgentVersion($id: String!) {
    getAgentVersion(id: $id) {
      version
      name
      description
      updatedAt
    }
  }
`;

export const GET_MY_AGENTS = gql`
  query getMyAgents {
    getMyAgents {
      id
      name
      email
      productKey
      status
      agentVersion {
        version
        name
        description
        updatedAt
      }
      createdAt
    }
  }
`;

export const GET_AGENT_VERSIONS = gql`
  query getAgentVersions($global: Boolean!) {
    getAgentVersions(global: $global) {
      id
      agentId
      version
      name
      description
      isLocationSpecific
      deployedGlobally
      isCurrent
      createdAt
    }
  }
`;

export const GET_AGENTS = gql`
  query getAgents {
    getAgents {
      id
      name
      locationName
      email
      productKey
      status
      createdAt
    }
  }
`;
