import React, { useContext } from 'react';
import Paths from '../../Paths';
import { BaseContext } from '../../components/Auth/AuthRouter/AuthRouter';
import Header from '../../components/NavBar/Header';
import {
  ColumnCenteredDiv,
  ContentContainer,
  DarkLargeText,
  MediumText,
  PageContainer,
  SmallLinkText,
} from '../../styles/shared-styled-components';

const Terms = () => {
  const { drawerOpen, drawerExpanded } = useContext(BaseContext);

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <DarkLargeText>Terms of Use</DarkLargeText>
          <ColumnCenteredDiv>
            <MediumText>
              <SmallLinkText
                href={Paths.eula}
                target='_blank'
                useLargerOffset={true}
              >
                End-User License Agreement
              </SmallLinkText>
            </MediumText>
            <MediumText>
              <SmallLinkText
                href={Paths.privacyPolicy}
                target='_blank'
                useLargerOffset={true}
              >
                Privacy Policy
              </SmallLinkText>
            </MediumText>
          </ColumnCenteredDiv>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default Terms;
