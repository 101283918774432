import { useMutation } from '@apollo/client';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { EDIT_PACKAGE } from '../../../../api/mutations/training';
import {
  AddIcon,
  AutoScrollWrapper,
  CenteredDiv,
  CenteredDivWithExtraSmallGap,
  CenteredDivWithGap,
  ClearInputIcon,
  ColumnCenteredDiv,
  ContainerWithInputWidth,
  CopyIdIcon,
  ExpandViewIcon,
  FullSizeEmptyGapColumnCenteredDiv,
  LargeNoDataIcon,
  LightPrimaryEssText,
  MediumDarkEssText,
  MenuItem,
  MLGapColumnCenteredDiv,
  ModularWidthInput,
  RedCancelIcon,
  SmallCheckbox,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
  SmallPrimaryButton,
  SmallSecondaryButton,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkExtraSmallText,
  StartAlignedMediumDarkLargeTinyText,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  backToBackSessionBookingType,
  bookableThroughPackageSessionTypeStatus,
  bookingTypeOptions,
  collapsedPackageCategoryIdsCookieKey,
  contactTypesForServiceLabelMap,
  contactTypesForServices,
  discontinuedSessionTypeStatus,
  dropdownInputType,
  dropInKey,
  integerInputType,
  liveiqSourceKey,
  packageKey,
  parallelSessionBookingType,
  requiresConsultationBookableSessionTypeStatus,
  sessionTypeBookableStatusMap,
  sessionTypeTypesMap,
  sessionTypeUnitsLabelMap,
  stagingFilterConversionDelay,
  textSeparatorChar,
  unbookableSessionTypeStatus,
  uncategorizedKey,
  unclassifiedKey,
} from '../../../../utils/constants';
import { getCookieExpiryObject } from '../../../../utils/date';
import { formatNumber, safeParseInt } from '../../../../utils/numbers';
import {
  copyTextToClipboard,
  formatKeyToLabel,
  parseCookieValueString,
} from '../../../../utils/string';
import { checkIfUserIsSuperAdmin } from '../../../../utils/user';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../LoadingIndicator';
import { DownArrow, UpArrow } from '../../../Micro/ArrowIcon/styled';
import SwitchToggle from '../../../Micro/SwitchToggle/SwitchToggle';
import PopperMenu from '../../../PopperMenu';
import {
  getBookableIcon,
  ListInputSection,
  OptionActionsContainer,
  OptionIconContainer,
  OptionItemContainer,
  OptionParagraphText,
  OptionsSearchBoxContainer,
  OptionSubitemText,
  OptionSubitemTextContainer,
  PromptContainer,
  PromptInput,
  PromptsSection,
} from '../../shared-training-components';

const cookieExpiryObject = getCookieExpiryObject();

const basePackageMap = {
  custom: {
    categoryId: 'custom',
    categoryName: 'Custom LiveIQ Packages',
    categoryPackages: [],
  },
  source: {
    categoryId: 'source',
    categoryName: 'Source Packages',
    categoryPackages: [],
  },
};

const Packages = ({
  loading,
  sessionTypeCatalog,
  onSave,
  agentName,
  remainingHeight,
}) => {
  const { user, cookies, setCookie } = useContext(BaseContext);

  const isSuperAdmin = checkIfUserIsSuperAdmin(user);

  const [editPackageMutation, { loading: createLoading }] =
    useMutation(EDIT_PACKAGE);

  const collapsedCategoryIdsCookieValue =
    cookies[collapsedPackageCategoryIdsCookieKey] || [];

  const [packages, setPackages] = useState(basePackageMap);
  const [availableSessionTypes, setAvailableSessionTypes] = useState([]);
  const [mode, setMode] = useState(false);
  const [displayUnbookable, setDisplayUnbookable] = useState(false);
  const [stagingSearchFilter, setStagingSearchFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [collapsedCategoryIds, setCollapsedCategoryIds] = useState(
    parseCookieValueString(collapsedCategoryIdsCookieValue) || [],
  );
  const [editingPackage, setEditingPackage] = useState();
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState();
  const [consultationMenuAnchorEl, setConsultationMenuAnchorEl] = useState();

  const editMenuOpen = Boolean(editMenuAnchorEl);
  const consultationMenuOpen = Boolean(consultationMenuAnchorEl);

  const categoriesMap = sessionTypeCatalog?.categories || {};
  const allCategories = Object.values(categoriesMap);
  const uncategorizedMap = sessionTypeCatalog?.[uncategorizedKey];
  const consultationsMap = allCategories.reduce((acc, c) => {
    const consultationsForCategory = c?.consultations || [];

    // Add each consultation to the map using its ID as the key
    consultationsForCategory.forEach((consultation) => {
      acc[consultation.id] = consultation;
    });

    return acc;
  }, {});
  const allConsultations = sortBy(
    Object.values(consultationsMap),
    (c) => c.displayName,
  );

  useEffect(() => {
    if (sessionTypeCatalog) {
      const allPackages = Object.values(sessionTypeCatalog?.packages) || [];
      const packageMap = {
        custom: [],
        source: [],
      };
      allPackages.map((p) => {
        if (p.source === liveiqSourceKey) {
          packageMap['custom'] = [...packageMap['custom'], p];
        } else {
          packageMap['source'] = [...packageMap['source'], p];
        }
      });
      let allSessionTypes = [];
      allCategories.map((c) => {
        const allCategoryServices = [
          ...(c?.dropIns || []),
          ...(c?.consultations || []),
          ...(c?.addOns || []),
        ];
        allSessionTypes.push(...allCategoryServices);
      });
      allSessionTypes.push(
        ...[
          ...(uncategorizedMap?.dropIns || []),
          ...(uncategorizedMap?.consultations || []),
          ...(uncategorizedMap?.addOns || []),
        ],
      );
      setAvailableSessionTypes(allSessionTypes);
      setPackages(packageMap);
    }
  }, [sessionTypeCatalog]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        setSearchFilter(stagingSearchFilter);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  useEffect(() => {
    setCollapsedCategoryIds(
      parseCookieValueString(collapsedCategoryIdsCookieValue) || [],
    );
  }, [collapsedCategoryIdsCookieValue]);

  if (loading || createLoading) {
    return <LoadingIndicator fullScreen />;
  }

  const onChangeCollapsedCategories = (id, shouldAdd) => {
    let updatedCollapsedCategories;
    if (shouldAdd) {
      updatedCollapsedCategories = [...collapsedCategoryIds, id];
    } else {
      updatedCollapsedCategories = collapsedCategoryIds.filter((i) => i !== id);
    }

    const updatedCollapsedCategoriesString = parseCookieValueString(
      updatedCollapsedCategories,
    );

    setCookie(
      collapsedPackageCategoryIdsCookieKey,
      updatedCollapsedCategoriesString,
      cookieExpiryObject,
    );
  };

  const onReorder = (result) => {
    const { source, destination } = result;
    const newOrder = destination.index;
    const previousOrder = source.index;
    // If dropped outside the list or at the same position
    if (!destination || previousOrder === newOrder) {
      return;
    }

    const movingDown = newOrder > previousOrder;

    const updatedPackageOfferings = editingPackage?.packageOfferings.map(
      (o) => {
        const currentOrder = o.order;
        if (o.id === result.draggableId) {
          return {
            ...o,
            order: newOrder,
          };
        } else if (
          movingDown &&
          currentOrder > previousOrder &&
          currentOrder >= newOrder
        ) {
          return {
            ...o,
            order: currentOrder - 1,
          };
        } else if (
          !movingDown &&
          currentOrder < previousOrder &&
          currentOrder <= newOrder
        ) {
          return {
            ...o,
            order: currentOrder + 1,
          };
        }
        return o;
      },
    );

    // Reorder the list
    setEditingPackage({
      ...editingPackage,
      packageOfferings: updatedPackageOfferings,
    });
  };

  const onSavePackage = (inputPackage) => {
    const packageToUpdate = inputPackage ?? editingPackage;
    const isNew = !packageToUpdate?.id;
    setStagingSearchFilter();
    setSearchFilter();
    setEditingPackage();
    editPackageMutation({
      variables: {
        package: packageToUpdate,
      },
      onCompleted: async () => {
        setMode();
        await onSave(isNew);
      },
    });
  };

  const lowercaseSearchFilter = searchFilter?.toLowerCase();
  const customPackages = packages?.['custom'] || [];
  const numCustomPackages = customPackages.length;
  const customCollapsed = collapsedCategoryIds.includes('custom');
  const sourcePackages = packages?.['source'] || [];
  const numSourcePackages = sourcePackages.length;
  const sourceCollapsed = collapsedCategoryIds.includes('source');

  const editingServices = sortBy(editingPackage?.packageOfferings || [], (o) =>
    o.order === null || o.order === undefined ? 1000 : o.order,
  );
  let editingServicesTotalPrice = 0;
  let editingServicesTotalMinutes = 0;
  const servicesIncludedMap = {};
  editingServices.map((s) => {
    editingServicesTotalPrice += parseFloat(s.price);
    editingServicesTotalMinutes += parseInt(s.displayDurationMinutes);
    servicesIncludedMap[s.id] = true;
  });

  const filteredServices = sortBy(
    availableSessionTypes.filter(
      (s) =>
        (!searchFilter ||
          s.displayName?.toLowerCase().includes(lowercaseSearchFilter)) &&
        (displayUnbookable ||
          (s?.bookableStatus !== unbookableSessionTypeStatus &&
            s?.bookableStatus !== discontinuedSessionTypeStatus)),
    ),
    (a) => {
      // If no search filter, prioritize services present in servicesIncludedMap
      if (!searchFilter) {
        return servicesIncludedMap[a.id] ? 0 : 1;
      }
      // Otherwise, sort by displayName alphabetically
      return a.displayName.toLowerCase();
    },
  );

  const bookingType = editingPackage?.sessionBookingType;
  const shouldSum = bookingType !== parallelSessionBookingType;

  return (
    <>
      <PromptsSection
        hideOverflow
        unsetHeight
      >
        <PromptContainer overflow>
          {!mode ? (
            <>
              <FullSizeEmptyGapColumnCenteredDiv>
                <OptionsSearchBoxContainer fixedHeight={50}>
                  <ModularWidthInput
                    value={stagingSearchFilter}
                    onChange={(e) => setStagingSearchFilter(e.target.value)}
                    placeholder='Search existing packages'
                    InputProps={{
                      endAdornment: (
                        <ClearInputIcon
                          onClick={() => setStagingSearchFilter('')}
                        />
                      ),
                    }}
                    extraSmallHeight
                    extraSmallWidth
                    widthPercentage={45}
                  />
                  <CenteredDivWithExtraSmallGap>
                    <Tooltip
                      title={
                        <TooltipTitleText>Create new package</TooltipTitleText>
                      }
                      placement='bottom'
                    >
                      <AddIcon
                        lightPrimary
                        pointer
                        onClick={() => setMode('services')}
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        <TooltipTitleText>
                          Unbookable packages{' '}
                          {displayUnbookable ? `shown` : `hidden`}
                        </TooltipTitleText>
                      }
                      placement='bottom'
                    >
                      <span>
                        <SwitchToggle
                          value={displayUnbookable}
                          onChange={() =>
                            setDisplayUnbookable(!displayUnbookable)
                          }
                        />
                      </span>
                    </Tooltip>
                  </CenteredDivWithExtraSmallGap>
                </OptionsSearchBoxContainer>
              </FullSizeEmptyGapColumnCenteredDiv>
              <ListInputSection height={remainingHeight}>
                <AutoScrollWrapper disableMaxHeight>
                  {!numCustomPackages && !numSourcePackages ? (
                    <ColumnCenteredDiv topMargin={50}>
                      <LargeNoDataIcon />
                      <LightPrimaryEssText>
                        No {searchFilter ? 'matching' : ''} packages
                      </LightPrimaryEssText>
                    </ColumnCenteredDiv>
                  ) : (
                    <>
                      {numCustomPackages ? (
                        <>
                          <OptionItemContainer
                            top
                            widthPercentage={95}
                            bottomMargin={-10}
                          >
                            <StartAlignedFlexDiv>
                              <MediumDarkEssText>
                                Custom LiveIQ Packages (
                                {`${numCustomPackages}${
                                  searchFilter ? ` filtered packages` : ``
                                }`}
                                )
                              </MediumDarkEssText>
                              <CenteredDiv>
                                {customCollapsed ? (
                                  <DownArrow
                                    color='darkGray'
                                    pointer
                                    onClick={() => {
                                      onChangeCollapsedCategories(
                                        'custom',
                                        false,
                                      );
                                    }}
                                  />
                                ) : (
                                  <UpArrow
                                    color='darkGray'
                                    pointer
                                    onClick={() => {
                                      onChangeCollapsedCategories(
                                        'custom',
                                        true,
                                      );
                                    }}
                                  />
                                )}
                              </CenteredDiv>
                            </StartAlignedFlexDiv>
                          </OptionItemContainer>
                          {!customCollapsed && (
                            <>
                              {sortBy(customPackages, (p) => p.displayName).map(
                                (p, idx) => {
                                  const {
                                    id,
                                    price,
                                    displayName,
                                    displayDurationMinutes,
                                    bookableStatus,
                                    units,
                                    packageOfferings,
                                    consultationSessionTypeId,
                                    clientType,
                                  } = p;
                                  const offerings = packageOfferings
                                    .map((o) => o.displayName)
                                    .sort();

                                  const formattedType = packageKey;

                                  const requiredConsultationName =
                                    consultationsMap?.[
                                      consultationSessionTypeId
                                    ]?.displayName;

                                  return (
                                    <OptionItemContainer
                                      bottom={idx === numCustomPackages - 1}
                                      widthPercentage={95}
                                    >
                                      <OptionSubitemTextContainer>
                                        <OptionSubitemText start>
                                          {displayName}
                                          {formattedType !== dropInKey &&
                                            ` (${sessionTypeTypesMap[formattedType]})`}
                                          {isSuperAdmin && (
                                            <Tooltip
                                              title={
                                                <TooltipTitleText>
                                                  Copy ID {id?.slice(0, 4)}
                                                </TooltipTitleText>
                                              }
                                              placement='bottom'
                                            >
                                              <CopyIdIcon
                                                addLargeLeftMargin
                                                addTopMargin
                                                onClick={() =>
                                                  copyTextToClipboard(id)
                                                }
                                              />
                                            </Tooltip>
                                          )}
                                        </OptionSubitemText>
                                        <OptionParagraphText>
                                          Includes: {offerings.join(', ')}
                                          <br></br>${price} per {units}{' '}
                                          {textSeparatorChar}{' '}
                                          {displayDurationMinutes} minutes
                                          <br></br>
                                          For:{' '}
                                          {
                                            contactTypesForServiceLabelMap[
                                              clientType
                                            ]
                                          }
                                        </OptionParagraphText>
                                      </OptionSubitemTextContainer>
                                      <OptionActionsContainer>
                                        <OptionIconContainer
                                          status={
                                            bookableStatus || unclassifiedKey
                                          }
                                          onClick={(e) => {
                                            setEditingPackage(p);
                                            setEditMenuAnchorEl(
                                              e.currentTarget,
                                            );
                                          }}
                                        >
                                          {getBookableIcon(
                                            bookableStatus,
                                            agentName,
                                            requiredConsultationName,
                                          )}
                                        </OptionIconContainer>
                                        <OptionIconContainer
                                          onClick={() => {
                                            setEditingPackage(p);
                                            setMode('services');
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              <TooltipTitleText>
                                                Edit
                                              </TooltipTitleText>
                                            }
                                          >
                                            <CenteredDiv>
                                              <ExpandViewIcon />
                                            </CenteredDiv>
                                          </Tooltip>
                                        </OptionIconContainer>
                                        {formattedType !== packageKey && (
                                          <OptionIconContainer>
                                            <Tooltip
                                              title={
                                                <TooltipTitleText>
                                                  Expand
                                                </TooltipTitleText>
                                              }
                                            >
                                              <CenteredDiv
                                                onClick={() => {
                                                  setEditingPackage(p);
                                                }}
                                              >
                                                <ExpandViewIcon />
                                              </CenteredDiv>
                                            </Tooltip>
                                          </OptionIconContainer>
                                        )}
                                      </OptionActionsContainer>
                                    </OptionItemContainer>
                                  );
                                },
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {numSourcePackages ? (
                        <>
                          <OptionItemContainer
                            top
                            widthPercentage={95}
                            bottomMargin={-10}
                          >
                            <StartAlignedFlexDiv>
                              <MediumDarkEssText>
                                Source Booking System Packages (
                                {`${numCustomPackages}${
                                  searchFilter ? ` filtered packages` : ``
                                }`}
                                )
                              </MediumDarkEssText>
                              <CenteredDiv>
                                {sourceCollapsed ? (
                                  <DownArrow
                                    color='darkGray'
                                    pointer
                                    onClick={() => {
                                      onChangeCollapsedCategories(
                                        'source',
                                        false,
                                      );
                                    }}
                                  />
                                ) : (
                                  <UpArrow
                                    color='darkGray'
                                    pointer
                                    onClick={() => {
                                      onChangeCollapsedCategories(
                                        'source',
                                        true,
                                      );
                                    }}
                                  />
                                )}
                              </CenteredDiv>
                            </StartAlignedFlexDiv>
                          </OptionItemContainer>
                          {!sourceCollapsed && (
                            <>
                              {sortBy(sourcePackages, (p) => p.displayName).map(
                                (p, idx) => {
                                  const {
                                    id,
                                    category,
                                    displayName,
                                    displayDurationMinutes,
                                    durationMinutes,
                                    numberOfPeopleToBook,
                                    followUpDays,
                                    clientDepositAmount,
                                    bookableStatus,
                                    consultationSessionTypeId,
                                    priceSetting,
                                  } = p;

                                  const formattedType = packageKey;

                                  const requiredConsultationName =
                                    consultationsMap?.[
                                      consultationSessionTypeId
                                    ]?.displayName;

                                  const hasCustomDepositSettings =
                                    clientDepositAmount != null;

                                  return (
                                    <OptionItemContainer
                                      bottom={idx === numCustomPackages - 1}
                                      widthPercentage={95}
                                    >
                                      <OptionSubitemTextContainer>
                                        <OptionSubitemText start>
                                          {displayName}
                                          {formattedType !== dropInKey &&
                                            ` (${sessionTypeTypesMap[formattedType]})`}
                                          {isSuperAdmin && (
                                            <Tooltip
                                              title={
                                                <TooltipTitleText>
                                                  Copy ID {id?.slice(0, 4)}
                                                </TooltipTitleText>
                                              }
                                              placement='bottom'
                                            >
                                              <CopyIdIcon
                                                addLargeLeftMargin
                                                addTopMargin
                                                onClick={() =>
                                                  copyTextToClipboard(id)
                                                }
                                              />
                                            </Tooltip>
                                          )}
                                        </OptionSubitemText>
                                        <OptionParagraphText>
                                          {p.price === -1
                                            ? `Variable price`
                                            : p.price != null
                                            ? `$${formatNumber(p.price)}`
                                            : '(Price unknown)'}{' '}
                                          {p.units && `per ${p.units} `}
                                          {priceSetting === 'fixed_price'
                                            ? ''
                                            : '(Variable price) '}
                                          {hasCustomDepositSettings &&
                                            `${textSeparatorChar} Custom deposit `}
                                          {textSeparatorChar}{' '}
                                          {displayDurationMinutes != null &&
                                          durationMinutes != null
                                            ? displayDurationMinutes !==
                                              durationMinutes
                                              ? `${displayDurationMinutes} minutes (${durationMinutes} for staff)`
                                              : safeParseInt(
                                                  displayDurationMinutes,
                                                ) === 0
                                              ? `No time added on`
                                              : `${displayDurationMinutes} minutes`
                                            : 'Time length unknown'}
                                          {numberOfPeopleToBook !== 1
                                            ? ` ${textSeparatorChar} ${numberOfPeopleToBook} guests/booking`
                                            : ''}
                                          {followUpDays != null
                                            ? ` ${textSeparatorChar} ${followUpDays} day follow-up`
                                            : ''}
                                          <br></br>
                                          {category
                                            ? `Part of the '${formatKeyToLabel(
                                                category,
                                              )}' corpus category`
                                            : `Unassigned to a corpus category`}
                                        </OptionParagraphText>
                                      </OptionSubitemTextContainer>
                                      <OptionActionsContainer>
                                        <OptionIconContainer
                                          status={
                                            bookableStatus || unclassifiedKey
                                          }
                                          onClick={(e) => {
                                            setEditingPackage(p);
                                            setEditMenuAnchorEl(
                                              e.currentTarget,
                                            );
                                          }}
                                        >
                                          {getBookableIcon(
                                            bookableStatus,
                                            agentName,
                                            requiredConsultationName,
                                          )}
                                        </OptionIconContainer>
                                      </OptionActionsContainer>
                                    </OptionItemContainer>
                                  );
                                },
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <PopperMenu
                    open={editMenuOpen}
                    anchorElement={editMenuAnchorEl}
                    onClose={() => setEditMenuAnchorEl()}
                    variant='offset'
                  >
                    <SmallMenuItemTitleContainer disableRipple>
                      <SmallMenuItemTitleText>
                        Select booking status
                      </SmallMenuItemTitleText>
                    </SmallMenuItemTitleContainer>
                    {Object.keys(sessionTypeBookableStatusMap).map((key) => {
                      const label = sessionTypeBookableStatusMap[key];
                      const isCurrent = editingPackage?.bookableStatus === key;
                      return (
                        key !== bookableThroughPackageSessionTypeStatus && (
                          <MenuItem
                            onClick={() => {
                              if (!isCurrent) {
                                if (
                                  key ===
                                  requiresConsultationBookableSessionTypeStatus
                                ) {
                                  setConsultationMenuAnchorEl(editMenuAnchorEl);
                                  setEditMenuAnchorEl();
                                } else {
                                  setEditMenuAnchorEl();
                                  const updatedPackage = {
                                    ...editingPackage,
                                    bookableStatus: key,
                                  };
                                  setEditingPackage(updatedPackage);
                                  onSavePackage(updatedPackage);
                                }
                              }
                            }}
                            disabled={isCurrent}
                          >
                            <SmallMenuItemText>
                              {label}
                              {isCurrent && ` (Current)`}
                            </SmallMenuItemText>
                          </MenuItem>
                        )
                      );
                    })}
                  </PopperMenu>
                  <PopperMenu
                    open={consultationMenuOpen}
                    anchorElement={consultationMenuAnchorEl}
                    onClose={() => setConsultationMenuAnchorEl()}
                    variant='offset'
                  >
                    <SmallMenuItemTitleContainer disableRipple>
                      <SmallMenuItemTitleText>
                        Select consultation required
                      </SmallMenuItemTitleText>
                    </SmallMenuItemTitleContainer>
                    {allConsultations.map((c) => {
                      const label = c.displayName;
                      return (
                        <MenuItem
                          onClick={() => {
                            setConsultationMenuAnchorEl();
                            const updatedPackage = {
                              ...editingPackage,
                              bookableStatus:
                                requiresConsultationBookableSessionTypeStatus,
                              consultationSessionTypeId: c.id,
                            };
                            setEditingPackage(updatedPackage);
                            onSavePackage(updatedPackage);
                          }}
                        >
                          <SmallMenuItemText>{label}</SmallMenuItemText>
                        </MenuItem>
                      );
                    })}
                  </PopperMenu>
                </AutoScrollWrapper>
              </ListInputSection>
            </>
          ) : mode === 'services' ? (
            <>
              <FullSizeEmptyGapColumnCenteredDiv>
                <MediumDarkEssText>
                  Select services to add to{' '}
                  {editingPackage?.displayName
                    ? `the '${editingPackage.displayName}'`
                    : 'new'}{' '}
                  package ({editingServices?.length || 0} selected,{' '}
                  {formatNumber(filteredServices.length)}{' '}
                  {searchFilter ? 'matches' : 'available'})
                </MediumDarkEssText>
                <OptionsSearchBoxContainer fixedHeight={50}>
                  <ModularWidthInput
                    value={stagingSearchFilter}
                    onChange={(e) => setStagingSearchFilter(e.target.value)}
                    placeholder='Search services to add to package'
                    InputProps={{
                      endAdornment: (
                        <ClearInputIcon
                          onClick={() => setStagingSearchFilter('')}
                        />
                      ),
                    }}
                    extraSmallHeight
                    extraSmallWidth
                    widthPercentage={45}
                  />
                  <CenteredDivWithExtraSmallGap>
                    <Tooltip
                      title={
                        <TooltipTitleText>Clear selection</TooltipTitleText>
                      }
                      placement='bottom'
                    >
                      <span>
                        <RedCancelIcon
                          onClick={() =>
                            setEditingPackage({
                              ...editingPackage,
                              packageOfferings: [],
                            })
                          }
                          smm
                        />
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={
                        <TooltipTitleText>
                          Unbookable services{' '}
                          {displayUnbookable ? `shown` : `hidden`}
                        </TooltipTitleText>
                      }
                      placement='bottom'
                    >
                      <span>
                        <SwitchToggle
                          value={displayUnbookable}
                          onChange={() =>
                            setDisplayUnbookable(!displayUnbookable)
                          }
                        />
                      </span>
                    </Tooltip>
                  </CenteredDivWithExtraSmallGap>
                </OptionsSearchBoxContainer>
              </FullSizeEmptyGapColumnCenteredDiv>
              <ListInputSection height={remainingHeight}>
                <AutoScrollWrapper
                  disableMaxHeight
                  minHeight={remainingHeight - 350}
                >
                  {filteredServices.length ? (
                    <>
                      {filteredServices.map((s, idx) => {
                        const { id, displayName, type } = s;

                        const checked = !!editingServices?.find(
                          (s) => s.id === id,
                        );

                        return (
                          <OptionItemContainer
                            bottom={idx === filteredServices.length - 1}
                            widthPercentage={95}
                          >
                            <OptionSubitemTextContainer>
                              <OptionSubitemText start>
                                {displayName}
                                {s.type !== dropInKey &&
                                  ` (${sessionTypeTypesMap[s.type]})`}
                                {isSuperAdmin && (
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>
                                        Copy ID {id?.slice(0, 4)}
                                      </TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <CopyIdIcon
                                      addLargeLeftMargin
                                      addTopMargin
                                      onClick={() => copyTextToClipboard(id)}
                                    />
                                  </Tooltip>
                                )}
                              </OptionSubitemText>
                            </OptionSubitemTextContainer>
                            <OptionActionsContainer>
                              <SmallCheckbox
                                checked={checked}
                                onClick={() => {
                                  const currentOfferings =
                                    editingPackage?.packageOfferings || [];
                                  if (checked) {
                                    setEditingPackage({
                                      ...editingPackage,
                                      packageOfferings: currentOfferings.filter(
                                        (s) => s.id !== id,
                                      ),
                                    });
                                  } else {
                                    const maxOrder = Math.max(
                                      ...currentOfferings.map((o) => o.order),
                                    );
                                    setEditingPackage({
                                      ...editingPackage,
                                      packageOfferings: [
                                        ...currentOfferings,
                                        {
                                          id,
                                          displayName,
                                          type,
                                          price: s.price,
                                          units: s.units,
                                          durationMinutes: s.durationMinutes,
                                          displayDurationMinutes:
                                            s.displayDurationMinutes,
                                          order:
                                            maxOrder < 0 ? 0 : maxOrder + 1,
                                        },
                                      ],
                                    });
                                  }
                                }}
                              />
                            </OptionActionsContainer>
                          </OptionItemContainer>
                        );
                      })}
                    </>
                  ) : (
                    <ColumnCenteredDiv topMargin={50}>
                      <LargeNoDataIcon />
                      <LightPrimaryEssText>
                        No matching results
                      </LightPrimaryEssText>
                    </ColumnCenteredDiv>
                  )}
                </AutoScrollWrapper>
                <CenteredDivWithGap>
                  <SmallSecondaryButton
                    largePadding
                    onClick={() => {
                      setEditingPackage();
                      setMode(false);
                      setStagingSearchFilter('');
                      setSearchFilter('');
                    }}
                  >
                    Cancel
                  </SmallSecondaryButton>
                  <SmallPrimaryButton
                    largePadding
                    disabled={!editingServices?.length}
                    onClick={() => {
                      if (editingServices?.length) {
                        setMode('params');
                      }
                    }}
                  >
                    Continue
                  </SmallPrimaryButton>
                </CenteredDivWithGap>
              </ListInputSection>
            </>
          ) : mode === 'params' ? (
            <>
              <FullSizeEmptyGapColumnCenteredDiv>
                <MediumDarkEssText>
                  Edit package configuration
                </MediumDarkEssText>
              </FullSizeEmptyGapColumnCenteredDiv>
              <ListInputSection
                height={remainingHeight}
                bottomPadding={20}
              >
                <AutoScrollWrapper
                  disableMaxHeight
                  minHeight={remainingHeight - 350}
                  topPadding={10}
                >
                  <MLGapColumnCenteredDiv>
                    <PromptInput
                      value={editingPackage?.displayName}
                      label='Name'
                      onChange={(e) => {
                        setEditingPackage({
                          ...editingPackage,
                          displayName: e.target.value,
                        });
                      }}
                      useSmallText
                    />
                    <PromptInput
                      value={bookingType}
                      label='Booking type'
                      onChange={(e) => {
                        setEditingPackage({
                          ...editingPackage,
                          sessionBookingType: e.target.value,
                        });
                      }}
                      useSmallText
                      type={dropdownInputType}
                      useSmallGap
                      options={bookingTypeOptions}
                    />
                    <ColumnCenteredDiv topMargin={-10}>
                      <ContainerWithInputWidth useSmallWidth>
                        <StartAlignedMediumDarkExtraSmallText>
                          Booking services
                          {bookingType === backToBackSessionBookingType &&
                            ' in the following order'}
                          :
                        </StartAlignedMediumDarkExtraSmallText>
                        {bookingType === backToBackSessionBookingType ? (
                          <DragDropContext onDragEnd={onReorder}>
                            <Droppable droppableId='services'>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '3px',
                                    marginTop: '6px',
                                  }}
                                >
                                  {editingServices.map((s, index) => (
                                    <Draggable
                                      key={s.id}
                                      draggableId={s.id}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <StartAlignedMediumDarkLargeTinyText
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          {index + 1}. {s.displayName}
                                        </StartAlignedMediumDarkLargeTinyText>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        ) : (
                          <StartAlignedMediumDarkLargeTinyText>
                            {editingServices?.map((s, idx) => (
                              <>
                                {textSeparatorChar} {s.displayName}
                                <br></br>
                              </>
                            ))}
                          </StartAlignedMediumDarkLargeTinyText>
                        )}
                      </ContainerWithInputWidth>
                    </ColumnCenteredDiv>
                    <PromptInput
                      value={editingPackage?.price}
                      label={
                        !shouldSum
                          ? `Price (per item: $${
                              editingServicesTotalPrice / editingServices.length
                            })`
                          : `Price (sum total: $${editingServicesTotalPrice})`
                      }
                      onChange={(e) => {
                        setEditingPackage({
                          ...editingPackage,
                          price: parseInt(e.target.value),
                        });
                      }}
                      useSmallText
                      type={integerInputType}
                      useSmallGap
                    />
                    <PromptInput
                      value={editingPackage?.units}
                      label={`Pricing units`}
                      onChange={(e) => {
                        setEditingPackage({
                          ...editingPackage,
                          units: e.target.value,
                        });
                      }}
                      useSmallText
                      type={dropdownInputType}
                      options={sessionTypeUnitsLabelMap}
                      removeGap
                    />
                    <PromptInput
                      value={editingPackage?.durationMinutes}
                      label={
                        !shouldSum
                          ? `Duration minutes (per item: ${
                              editingServicesTotalMinutes /
                              editingServices.length
                            })`
                          : `Duration minutes (sum total: ${editingServicesTotalMinutes})`
                      }
                      onChange={(e) => {
                        setEditingPackage({
                          ...editingPackage,
                          durationMinutes: e.target.value,
                        });
                      }}
                      useSmallText
                      type={integerInputType}
                      useSmallGap
                    />
                    <PromptInput
                      value={editingPackage?.clientType}
                      label='Client type'
                      onChange={(e) => {
                        setEditingPackage({
                          ...editingPackage,
                          clientType: e.target.value,
                        });
                      }}
                      useSmallText
                      type={dropdownInputType}
                      useSmallGap
                      options={contactTypesForServices}
                    />
                  </MLGapColumnCenteredDiv>
                </AutoScrollWrapper>
                <CenteredDivWithGap>
                  <SmallSecondaryButton
                    largePadding
                    onClick={() => setMode('services')}
                  >
                    Back
                  </SmallSecondaryButton>
                  <SmallPrimaryButton
                    largePadding
                    disabled={
                      !editingServices?.length ||
                      !editingPackage?.displayName ||
                      !editingPackage?.sessionBookingType
                    }
                    onClick={() => {
                      if (
                        !(
                          !editingServices?.length ||
                          !editingPackage?.displayName ||
                          !editingPackage?.sessionBookingType
                        )
                      ) {
                        onSavePackage();
                      }
                    }}
                  >
                    {editingPackage?.id ? 'Save' : 'Create'}
                  </SmallPrimaryButton>
                </CenteredDivWithGap>
              </ListInputSection>
            </>
          ) : (
            <></>
          )}
        </PromptContainer>
      </PromptsSection>
    </>
  );
};

export default Packages;
