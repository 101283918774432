import styled, { css } from 'styled-components';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../styles/shared-styled-components';

export const AuthButtonStyle = css`
  padding: 0px;
  min-width: 150px;
  font-size: 19px;
`;

export const LargeAuthButtonStyle = css`
  min-width: 230px;
  font-size: ${({ theme }) => theme.typography.mlSize};
  padding: 0px;
`;

export const PrimaryLoginButtonComponent = styled(PrimaryButton)`
  ${(props) =>
    props.buttonSize === 'large'
      ? css`
          ${LargeAuthButtonStyle}
        `
      : props.buttonSize === 'medium'
      ? css``
      : css`
          ${AuthButtonStyle}
        `};
`;

export const SecondaryLoginButtonComponent = styled(SecondaryButton)`
  ${(props) =>
    props.buttonSize === 'large'
      ? css`
          ${LargeAuthButtonStyle}
        `
      : props.buttonSize === 'medium'
      ? css``
      : css`
          ${AuthButtonStyle}
        `};
`;
