import React from 'react';
import { ClearInputIcon } from '../../../styles/shared-styled-components';
import { ClientSearchBoxComponent } from './styled';

const ClientSearchBox = ({
  value,
  onChange,
  onReset,
  entityLabel = 'contact',
}) => {
  return (
    <ClientSearchBoxComponent
      placeholder={`${`Search by ${entityLabel} name`}`}
      onChange={onChange}
      value={value}
      InputProps={{
        endAdornment: <ClearInputIcon onClick={onReset} />,
      }}
    />
  );
};

export default ClientSearchBox;
