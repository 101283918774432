import React from 'react';
import { SwitchToggleComponent } from './styled';

const SwitchToggle = ({ value, onChange, disabled = false }) => {
  return (
    <SwitchToggleComponent
      checked={value}
      onChange={onChange}
      color='primary'
      size='small'
      disabled={disabled}
    />
  );
};

export default SwitchToggle;
