import styled from 'styled-components';
import {
  ColumnCenteredDiv,
  TextInputWidthStyle,
} from '../../../styles/shared-styled-components';

export const DateRangeFieldContainer = styled(ColumnCenteredDiv)``;

export const DateRangeFieldTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${TextInputWidthStyle}
`;
