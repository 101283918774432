import React from 'react';
import {
  DarkGoldText,
  GoldText,
} from '../../../styles/shared-styled-components';
import {
  LogoCarouselContainer,
  LogoSlide,
  LogoSlideTrack,
  Slider,
} from '../styles/style-Homepage';
import { PartnersContainer } from '../styles/style-Integrations';
import {
  AvaTrustedText,
  BeautiqueLogo,
  BellatoxLogo,
  FoxRiverLogo,
  LiquividaLogo,
  OvumHealthLogo,
  VioLogo,
} from '../styles/style-Partners';

const LogoSlides = (
  <>
    <LogoSlide>
      <VioLogo />
    </LogoSlide>
    <LogoSlide>
      <LiquividaLogo />
    </LogoSlide>
    <LogoSlide>
      <FoxRiverLogo />
    </LogoSlide>
    <LogoSlide>
      <BeautiqueLogo />
    </LogoSlide>
    <LogoSlide>
      <OvumHealthLogo />
    </LogoSlide>
    <LogoSlide>
      <BellatoxLogo />
    </LogoSlide>
  </>
);

const Partners = ({ darkMode }) => {
  return (
    <>
      <PartnersContainer>
        <AvaTrustedText dark={darkMode}>
          Proudly serving{' '}
          {darkMode ? (
            <GoldText>industry leaders</GoldText>
          ) : (
            <DarkGoldText>industry leaders</DarkGoldText>
          )}
        </AvaTrustedText>
        <p />
        <LogoCarouselContainer>
          <Slider>
            <LogoSlideTrack>
              {LogoSlides}
              {LogoSlides}
            </LogoSlideTrack>
          </Slider>
        </LogoCarouselContainer>
      </PartnersContainer>
    </>
  );
};

export default Partners;
