import sortBy from 'lodash/sortBy';
import { default as React, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMyChatEvents } from '../../../../api/hooks/chat';
import OptionsFilterDropdown from '../../../../components/Analytics/OptionsFilterDropdown/OptionsFilterDropdown';
import { FilterSectionContainer } from '../../../../components/Analytics/shared-analytics-styles';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import EmptyCard from '../../../../components/Micro/EmptyCard';
import Header from '../../../../components/NavBar/Header';
import ClientSearchBox from '../../../../components/Search/ClientSearchBox/ClientSearchBox';
import Snackbar from '../../../../components/Snackbar';
import Paths from '../../../../Paths';
import {
  BaseCard,
  CardActionsContainer,
  CardHeaderContainer,
  CardPageSection,
  ColumnCenteredDiv,
  ColumnDivWithGap,
  ContentContainer,
  DarkEssText,
  DarkLargeText,
  LightDarkTextSpan,
  MediumGapColumnCenteredDiv,
  PageContainer,
  StartAlignedLargeTinyText,
  StartAlignedLightDarkLargeTinyText,
  TinyPrimaryButton,
} from '../../../../styles/shared-styled-components';
import { textSeparatorChar } from '../../../../utils/constants';
import { getTimestamp } from '../../../../utils/date';
import { formatPhoneNumber } from '../../../../utils/numbers';

const eventLabelMap = {
  follow_up: <>📲&nbsp;&nbsp;Follow-up requests</>,
  appointment: <>🗓️&nbsp;&nbsp;Appointment bookings</>,
  bookmark: <>🔖 &nbsp;&nbsp;Bookmarks</>,
};

const getEventLabel = (key, count) => {
  return (
    <>
      {eventLabelMap[key]} ({count})
    </>
  );
};

const eventTypes = [
  {
    key: 'appointment',
    label: 'Appointment',
  },
  {
    key: 'follow_up',
    label: 'Callback Request',
  },
  {
    key: 'bookmark',
    label: 'Bookmark',
  },
];

const ChatEvents = () => {
  const navigate = useNavigate();
  const { drawerOpen, drawerExpanded } = useContext(BaseContext);

  const [cursor, setCursor] = useState();
  const [stagingContactNameSearch, setStagingContactNameSearch] = useState('');
  const [contactNameSearch, setContactNameSearch] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const updateSearchFilter = (stagingSearchFilterValue) => {
    setStagingContactNameSearch(stagingSearchFilterValue);
  };

  const onResetSearchFilter = () => {
    setStagingContactNameSearch('');
  };

  const {
    events: interactionsMap,
    loading,
    refetch,
  } = useMyChatEvents({ cursor, contactName: contactNameSearch });
  const interactions = Object.values(interactionsMap);

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ColumnCenteredDiv>
            <DarkLargeText>Chat Events</DarkLargeText>
          </ColumnCenteredDiv>
          {loading ? (
            <LoadingIndicator fullScreen />
          ) : (
            <CardPageSection>
              {interactions?.length === 0 ? (
                <EmptyCard text='No chat events' />
              ) : (
                <>
                  <FilterSectionContainer spaced>
                    <ClientSearchBox />
                    <div></div>
                    <OptionsFilterDropdown
                      label='Event type'
                      options={eventTypes}
                      filtered={eventTypes.map((t) => t.key)}
                    />
                  </FilterSectionContainer>
                  <MediumGapColumnCenteredDiv>
                    {interactions.map((interaction) => {
                      const {
                        events: baseEvents,
                        countMap,
                        name,
                        contactType,
                        type,
                        updatedAt,
                      } = interaction;
                      const interactionLabel =
                        type === 'call' ? `Call from` : `Text chat with`;
                      const eventCounts = sortBy(
                        Object.values(countMap),
                        (c) => c.timestamp,
                      );
                      const events = baseEvents || [];
                      const callEvent = events.find(
                        (e) => e.event_type === 'call',
                      );
                      const phoneNumber = events?.[0]?.phone_number;
                      const contactLabel =
                        contactType === 'phone'
                          ? formatPhoneNumber(name)
                          : `${name}${
                              phoneNumber
                                ? ` - ${formatPhoneNumber(phoneNumber)}`
                                : ''
                            }`;
                      const chatId = events?.[0]?.chat_id;
                      return (
                        <BaseCard>
                          <ColumnDivWithGap>
                            <CardHeaderContainer>
                              <DarkEssText>
                                {interactionLabel} {contactLabel}
                                <LightDarkTextSpan>
                                  {' '}
                                  {textSeparatorChar} {getTimestamp(updatedAt)}
                                </LightDarkTextSpan>
                              </DarkEssText>
                            </CardHeaderContainer>
                            {eventCounts.length ? (
                              <StartAlignedLargeTinyText>
                                {eventCounts.map((e, idx) => (
                                  <>
                                    {getEventLabel(e.key, e.count)}
                                    {idx !== eventCounts.length - 1 && (
                                      <>,&nbsp;&nbsp;&nbsp;</>
                                    )}
                                  </>
                                ))}
                              </StartAlignedLargeTinyText>
                            ) : (
                              <></>
                            )}
                          </ColumnDivWithGap>
                          {callEvent && (
                            <div>
                              <StartAlignedLightDarkLargeTinyText>
                                {callEvent.metadata?.analysis?.call_summary ||
                                  'No summary generated'}
                              </StartAlignedLightDarkLargeTinyText>
                            </div>
                          )}
                          {chatId && (
                            <CardActionsContainer>
                              <TinyPrimaryButton
                                largePadding
                                onClick={() => {
                                  const chatPath = `${Paths.chats}?chat-id=${chatId}`;
                                  navigate(chatPath);
                                }}
                              >
                                View chat
                              </TinyPrimaryButton>
                            </CardActionsContainer>
                          )}
                        </BaseCard>
                      );
                    })}
                  </MediumGapColumnCenteredDiv>
                </>
              )}
            </CardPageSection>
          )}
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default ChatEvents;
