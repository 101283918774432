export const Paths = {
  // ** API Endpoints **
  prodHttpsBackend: 'https://api.liveiq.ai/graphql',
  testHttpsBackend: 'https://demoapi.liveiq.ai/graphql',
  localHttpBackend: 'http://localhost:4002/graphql',
  prodWsBackend: 'api.liveiq.ai/graphql',
  testWsBackend: 'demoapi.liveiq.ai/graphql',
  localWsBackend: 'localhost:4002/graphql',

  // Homepage
  homepage: '/',
  newHomepage: '/new-homepage',
  chat: '/chat',

  // Onboarding
  middleRouter: '/middle-router',
  personalOnboarding: `/onboarding/personal`,
  businessOnboarding: `/onboarding/business`,
  systemOnboarding: `/location-onboarding/system`,
  integrationOnboarding: `/location-onboarding/integration`,
  surveyOnboarding: `/location-onboarding/survey`,

  // Setup
  agentSelection: '/agent-selection',
  agentAppConnection: '/agent-app-connection/:agentId',
  ghlSyncStatus: '/ghl-sync-status',
  initializationPurgatory: '/initialization',

  // Enterprise
  headOffice: '/head-office',
  createBusiness: '/create-business',

  // Overview
  dashboard: '/app/dashboard',
  appointments: '/app/appointments',
  // Chats
  chats: '/app/chats',
  events: '/app/events',
  pipeline: '/app/pipeline',
  // Training
  training: '/app/training',
  requests: '/app/requests',
  // Contacts
  contacts: '/app/contacts',
  // Campaigns
  campaigns: '/app/campaigns',
  editScript: '/app/campaigns/edit-script/:scriptId',
  editPromo: '/app/campaigns/edit-promo/:promoId',
  audience: '/app/audience',
  // Demo
  agentDemo: '/app/agent-demo',
  // Sequences
  sequences: '/app/sequences',
  // Users
  users: '/app/users',
  // Templates
  templates: '/app/templates',

  // Admin
  connections: '/connections',
  terms: '/terms',
  settings: '/settings',

  // Public
  appointment: '/app/appointment/:appointmentId',

  // Auth
  callback: '/callback',
  logout: '/logout',

  // Assets
  calendlyLink: 'https://calendly.com/liveiq/demo/',

  // Legal
  privacyPolicy:
    'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/files/legal-docs/LiveIQ%20Privacy%20Policy.pdf',
  eula: 'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/files/legal-docs/LiveIQ%20EULA.pdf',
};

export default Paths;
