import React, { useRef, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { valueIsEmpty } from '../../../utils/data';
import PopperMenu from '../../PopperMenu';
import {
  EndInputAdornment,
  IconButton,
  LocationSearchInput,
  LocationSuggestion,
  SearchIcon,
} from './styled';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

let locationSuggestions = [];

const LocationSearchBox = ({
  ismobilescreen,
  value,
  onChange,
  onEnter,
  containsError,
  errorMessage,
  enterOnBlur = true,
  transparentBackground = true,
  customLabel = null,
  CustomEndAdornment = null,
  CustomInputComponent = null,
  customPopperMenuStyle = null,
  customPopperMenuWidth = null,
  useSmallWidth = false,
  elementId = 'location-search',
  ref = null,
}) => {
  const searchBoxRef = useRef(null);

  const [anchorElement, setAnchorElement] = useState(null);
  const [hoveredSuggestionIndex, setHoveredSuggestionIndex] = useState(-1);

  const getAddressData = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const result = results[0];

      const latLng = await getLatLng(result);
      const lat = latLng.lat;
      const lng = latLng.lng;
      const timestamp = Math.floor(Date.now() / 1000);
      const timezoneResponse = await fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${googleMapsApiKey}`,
      );
      const timezoneData = await timezoneResponse.json();
      const timezone = timezoneData?.timeZoneId;

      const postalCodeComponent = result.address_components.find((component) =>
        component.types.includes('postal_code'),
      );
      const postalCode = postalCodeComponent
        ? postalCodeComponent.long_name
        : '';

      return { address, lat, lng, timezone, postalCode };
    } catch (error) {
      console.error('Error: ', error);
      return null;
    }
  };

  const handleSelect = async (selectedAddress) => {
    const data = await getAddressData(selectedAddress);

    if (!data) {
      onChange('');
      return;
    }

    const { address, lat, lng, timezone, postalCode } = data;
    const addressInfo = { address, lat, lng, timezone, postalCode };

    onEnter(address, lat, lng, timezone, postalCode);
    searchBoxRef.current.blur();

    return addressInfo;
  };

  const onKeyPress = (e) => {
    if (e.key === 'ArrowUp') {
      setHoveredSuggestionIndex(hoveredSuggestionIndex - 1);
    } else if (e.key === 'ArrowDown') {
      setHoveredSuggestionIndex(hoveredSuggestionIndex + 1);
    }
  };

  const onChangeSearch = (search) => {
    setHoveredSuggestionIndex(-1);
    onChange(search);
  };

  const handleOnBlur = () => {
    if (enterOnBlur && !valueIsEmpty(value)) {
      const numSuggestions = locationSuggestions?.length;

      const modulo = getModuloOfHoveredIndex(numSuggestions);

      const indexToAccessAt =
        modulo >= 0 && modulo < numSuggestions ? modulo : 0;

      const suggestionToSave = locationSuggestions[indexToAccessAt];

      handleSelect(suggestionToSave?.description);
    }
  };

  const getModuloOfHoveredIndex = (numSuggestions) => {
    // All suggestions
    const numberOfOptionsToHoverUpon = numSuggestions + 1;

    let divisor;

    if (hoveredSuggestionIndex >= 0) {
      // Positive hover index - divisor is just the hover index
      divisor = hoveredSuggestionIndex;
    } else {
      // Negative hover index - need to inverse the hoveredSuggestionIndex
      // The actual value will be the total number of suggestions subtract the absolute value of hoveredSuggestionIndex
      // We can then do the modulo based off that divisor
      divisor = numberOfOptionsToHoverUpon - Math.abs(hoveredSuggestionIndex);
    }

    const modulo = divisor % numberOfOptionsToHoverUpon;

    return modulo;
  };

  const checkIfSuggestionIndexIsHovered = (index, numSuggestions) => {
    const modulo = getModuloOfHoveredIndex(numSuggestions);

    return modulo === index;
  };

  const defaultLabel = 'Enter your address';
  const label = customLabel || defaultLabel;

  const valueToDisplay = value || '';

  const endAdornment = CustomEndAdornment ? (
    CustomEndAdornment
  ) : (
    <EndInputAdornment onClick={() => handleSelect(value)}>
      <IconButton>
        <SearchIcon />
      </IconButton>
    </EndInputAdornment>
  );

  const isOpen = Boolean(anchorElement);

  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChangeSearch}
      onSelect={handleSelect}
      googleCallbackName='initAutocomplete'
      googleMapsApiKey={googleMapsApiKey}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <>
            {CustomInputComponent ? (
              <CustomInputComponent
                id={elementId}
                inputRef={searchBoxRef}
                label={label}
                ismobilescreen={ismobilescreen}
                transparentBackground={transparentBackground}
                value={valueToDisplay}
                onClick={(event) => setAnchorElement(event.currentTarget)}
                error={containsError}
                helperText={containsError && errorMessage}
                InputProps={{
                  onKeyPress,
                  onBlur: () => handleOnBlur(suggestions),
                  endAdornment,
                }}
                {...getInputProps({
                  onKeyDown: onKeyPress,
                })}
              />
            ) : (
              <LocationSearchInput
                id={elementId}
                inputRef={searchBoxRef}
                label={label}
                ismobilescreen={ismobilescreen}
                useSmallWidth={useSmallWidth}
                transparentBackground={transparentBackground}
                value={valueToDisplay}
                onClick={(event) => setAnchorElement(event.currentTarget)}
                error={containsError}
                helperText={containsError && errorMessage}
                ref={ref}
                InputProps={{
                  onKeyPress,
                  onBlur: () => handleOnBlur(suggestions),
                  endAdornment: (
                    <EndInputAdornment onClick={() => handleSelect(value)}>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </EndInputAdornment>
                  ),
                }}
                {...getInputProps({
                  onKeyDown: onKeyPress,
                })}
              />
            )}
            {suggestions?.length > 0 && (
              <PopperMenu
                open={isOpen}
                anchorElement={anchorElement}
                onClose={() => setAnchorElement(null)}
                disablebottompadding
                customStyle={customPopperMenuStyle}
                fixedWidth={customPopperMenuWidth}
                ismobilescreen={ismobilescreen}
                useThinScrollBar={true}
              >
                {suggestions.map((suggestion, idx) => {
                  const { description, placeId } = suggestion;
                  const numSuggestions = suggestions?.length;

                  // Have to store in global var, as the suggestions can disappear if the user presses escape on the dropdown
                  locationSuggestions = suggestions;

                  return (
                    <LocationSuggestion
                      {...getSuggestionItemProps(suggestion)}
                      key={placeId}
                      onMouseEnter={() => setHoveredSuggestionIndex(idx)}
                      hovered={checkIfSuggestionIndexIsHovered(
                        idx,
                        numSuggestions,
                      )}
                    >
                      <span>{description}</span>
                    </LocationSuggestion>
                  );
                })}
              </PopperMenu>
            )}
          </>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default LocationSearchBox;
