import { useMutation } from '@apollo/client';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { TEST_CATALOG_BOOKABILITY } from '../../../../api/mutations/enterprise';
import {
  AddIcon,
  AutoScrollWrapper,
  CenteredDiv,
  CenteredDivWithExtraSmallGap,
  ClearInputIcon,
  ColumnCenteredDiv,
  CompatibilityIcon,
  CopyIdIcon,
  ExpandViewIcon,
  FullSizeEmptyGapColumnCenteredDiv,
  LargeNoDataIcon,
  LightPrimaryEssText,
  MediumDarkEssText,
  MediumEditIcon,
  MenuItem,
  ModularWidthInput,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
  StartAlignedFlexDiv,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  allServiceCategories,
  collapsedCategoryIdsCookieKey,
  discontinuedSessionTypeStatus,
  dropInKey,
  ghlAppKey,
  packageKey,
  packagesLabel,
  requiresConsultationBookableSessionTypeStatus,
  sessionTypeBookableStatusMap,
  sessionTypeTypesMap,
  stagingFilterConversionDelay,
  textSeparatorChar,
  unbookableSessionTypeStatus,
  uncategorizedKey,
  unclassifiedKey,
  zenotiKey,
} from '../../../../utils/constants';
import { getCookieExpiryObject } from '../../../../utils/date';
import { formatNumber, safeParseInt } from '../../../../utils/numbers';
import {
  copyTextToClipboard,
  formatKeyToLabel,
  parseCookieValueString,
} from '../../../../utils/string';
import { checkIfUserIsSuperAdmin } from '../../../../utils/user';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../LoadingIndicator';
import { DownArrow, UpArrow } from '../../../Micro/ArrowIcon/styled';
import SwitchToggle from '../../../Micro/SwitchToggle/SwitchToggle';
import CatalogDiagnosticsModal from '../../../Modals/CatalogDiagnosticsModal';
import EditServiceModal from '../../../Modals/EditServiceModal/EditServiceModal';
import PopperMenu from '../../../PopperMenu';
import {
  getBookableIcon,
  ListInputSection,
  OptionActionsContainer,
  OptionIconContainer,
  OptionItemContainer,
  OptionParagraphText,
  OptionsSearchBoxContainer,
  OptionSubitemText,
  OptionSubitemTextContainer,
  PromptContainer,
  PromptsSection,
} from '../../shared-training-components';

const cookieExpiryObject = getCookieExpiryObject();

const Services = ({
  loading,
  sessionTypeCatalog,
  onCreate,
  onUpdate,
  onSave,
  onUpdateCategoryAssignment,
  agentName,
  calendarPlatform,
  remainingHeight,
}) => {
  const { user, cookies, setCookie } = useContext(BaseContext);

  const isSuperAdmin = checkIfUserIsSuperAdmin(user);

  const collapsedCategoryIdsCookieValue =
    cookies[collapsedCategoryIdsCookieKey] || [];

  const [availableSessionTypes, setAvailableSessionTypes] = useState([]);
  const [displayUnbookable, setDisplayUnbookable] = useState(false);
  const [stagingSearchFilter, setStagingSearchFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [collapsedCategoryIds, setCollapsedCategoryIds] = useState(
    parseCookieValueString(collapsedCategoryIdsCookieValue) || [],
  );
  const [editingCategory, setEditingCategory] = useState();
  const [
    editCategoryAssignmentMenuAnchorEl,
    setEditCategoryAssignmentMenuAnchorEl,
  ] = useState();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editingService, setEditingService] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState();
  const [consultationMenuAnchorEl, setConsultationMenuAnchorEl] = useState();
  const [diagnosticsModalIsOpen, setDiagnosticsModalIsOpen] = useState(false);

  const [testCatalogBookabilityMutation] = useMutation(
    TEST_CATALOG_BOOKABILITY,
    {
      onCompleted: async (data) => {
        setDiagnosticsModalIsOpen(false);
      },
    },
  );

  const editCategoryAssignmentMenuOpen = Boolean(
    editCategoryAssignmentMenuAnchorEl,
  );
  const editMenuOpen = Boolean(editMenuAnchorEl);
  const consultationMenuOpen = Boolean(consultationMenuAnchorEl);
  const categoriesMap = sessionTypeCatalog?.categories || {};
  const allCategories = Object.values(categoriesMap);
  const uncategorizedMap = sessionTypeCatalog?.[uncategorizedKey];

  const sortedAvailableSessionTypes = sortBy(availableSessionTypes, (s) =>
    s.categoryName === packagesLabel
      ? 'zzzz'
      : s.categoryName === unclassifiedKey || !s.categoryName
      ? 'zzz'
      : s.categoryName,
  );

  const consultationsMap = allCategories.reduce((acc, c) => {
    const consultationsForCategory = c?.consultations || [];

    // Add each consultation to the map using its ID as the key
    consultationsForCategory.forEach((consultation) => {
      acc[consultation.id] = consultation;
    });

    return acc;
  }, {});
  const allConsultations = sortBy(
    Object.values(consultationsMap),
    (c) => c.displayName,
  );

  const calendars = sessionTypeCatalog?.calendars || [];

  useEffect(() => {
    if (sessionTypeCatalog) {
      let allSessionTypes = [];
      allCategories.map((c) => {
        const allCategoryServices = [
          ...(c?.dropIns || []),
          ...(c?.consultations || []),
          ...(c?.addOns || []),
        ];
        allSessionTypes.push({
          categoryId: c.id,
          categoryName: c.name,
          services: allCategoryServices,
        });
      });
      allSessionTypes.push({
        categoryId: uncategorizedKey,
        categoryName: 'Uncategorized',
        services: [
          ...(uncategorizedMap?.dropIns || []),
          ...(uncategorizedMap?.consultations || []),
          ...(uncategorizedMap?.addOns || []),
        ],
      });
      allSessionTypes.push({
        categoryId: packageKey,
        categoryName: packagesLabel,
        services: Object.values(sessionTypeCatalog?.packages) || [],
      });
      setAvailableSessionTypes(allSessionTypes);
    }
  }, [sessionTypeCatalog]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        setSearchFilter(stagingSearchFilter);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  useEffect(() => {
    setCollapsedCategoryIds(
      parseCookieValueString(collapsedCategoryIdsCookieValue) || [],
    );
  }, [collapsedCategoryIdsCookieValue]);

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  const onChangeCollapsedCategories = (id, shouldAdd) => {
    let updatedCollapsedCategories;
    if (shouldAdd) {
      updatedCollapsedCategories = [...collapsedCategoryIds, id];
    } else {
      updatedCollapsedCategories = collapsedCategoryIds.filter((i) => i !== id);
    }

    const updatedCollapsedCategoriesString = parseCookieValueString(
      updatedCollapsedCategories,
    );

    setCookie(
      collapsedCategoryIdsCookieKey,
      updatedCollapsedCategoriesString,
      cookieExpiryObject,
    );
  };

  const updateCategoryAssignment = (editingCategory, assignedCategory) => {
    console.log('editingCategory', editingCategory);
    console.log('assignedCategory', assignedCategory);
    const categoryId = editingCategory?.id;
    onUpdateCategoryAssignment(categoryId, assignedCategory);
  };

  const onUpdateService = (
    originalService,
    updatedService,
    showSnackbarMessage = false,
  ) => {
    onUpdate(originalService, updatedService, showSnackbarMessage);
  };

  const onTestCatalogBookability = () => {
    testCatalogBookabilityMutation();
  };

  const lowercaseSearchFilter = searchFilter?.toLowerCase();
  let hasMatchingServices = false;

  return (
    <>
      <PromptsSection hideOverflow>
        <PromptContainer overflow>
          <FullSizeEmptyGapColumnCenteredDiv>
            <OptionsSearchBoxContainer fixedHeight={50}>
              <ModularWidthInput
                value={stagingSearchFilter}
                onChange={(e) => setStagingSearchFilter(e.target.value)}
                placeholder='Search'
                InputProps={{
                  endAdornment: (
                    <ClearInputIcon
                      onClick={() => setStagingSearchFilter('')}
                    />
                  ),
                }}
                extraSmallHeight
                extraSmallWidth
                widthPercentage={45}
              />
              <CenteredDivWithExtraSmallGap>
                {(!calendarPlatform || calendarPlatform === ghlAppKey) && (
                  <Tooltip
                    title={<TooltipTitleText>Add new</TooltipTitleText>}
                    placement='bottom'
                  >
                    <AddIcon
                      lightPrimary
                      pointer
                      onClick={() => setCreateModalOpen(true)}
                    />
                  </Tooltip>
                )}
                <Tooltip
                  title={
                    <TooltipTitleText>
                      Unbookable items {displayUnbookable ? `shown` : `hidden`}
                    </TooltipTitleText>
                  }
                  placement='bottom'
                >
                  <span>
                    <SwitchToggle
                      value={displayUnbookable}
                      onChange={() => setDisplayUnbookable(!displayUnbookable)}
                    />
                  </span>
                </Tooltip>
                {calendarPlatform === zenotiKey && (
                  <Tooltip
                    title={
                      <TooltipTitleText>
                        Check service bookability against EMR
                      </TooltipTitleText>
                    }
                    placement='bottom'
                  >
                    <CompatibilityIcon
                      onClick={() => setDiagnosticsModalIsOpen(true)}
                    />
                  </Tooltip>
                )}
              </CenteredDivWithExtraSmallGap>
            </OptionsSearchBoxContainer>
          </FullSizeEmptyGapColumnCenteredDiv>
          <ListInputSection height={remainingHeight}>
            <AutoScrollWrapper disableMaxHeight>
              {sortedAvailableSessionTypes?.map((sessionTypeCategory, idx) => {
                const { categoryId, categoryName, services } =
                  sessionTypeCategory;
                const categoryLabel = `${categoryName || 'Uncategorized'}`;

                const collapsed = collapsedCategoryIds.includes(categoryId);

                const filteredServices = services.filter(
                  (s) =>
                    (!searchFilter ||
                      s.displayName
                        ?.toLowerCase()
                        .includes(lowercaseSearchFilter)) &&
                    (displayUnbookable ||
                      (s?.bookableStatus !== unbookableSessionTypeStatus &&
                        s?.bookableStatus !== discontinuedSessionTypeStatus)),
                );

                const numServices = filteredServices.length;
                if (!hasMatchingServices && numServices) {
                  hasMatchingServices = true;
                }

                return numServices ? (
                  <>
                    <OptionItemContainer
                      top
                      widthPercentage={95}
                      bottomMargin={-10}
                    >
                      <StartAlignedFlexDiv>
                        <MediumDarkEssText>
                          {categoryLabel} (
                          {`${numServices}${
                            searchFilter ? ` filtered services` : ``
                          }`}
                          )
                        </MediumDarkEssText>
                        <CenteredDiv>
                          {collapsed ? (
                            <DownArrow
                              color='darkGray'
                              pointer
                              onClick={() => {
                                onChangeCollapsedCategories(categoryId, false);
                              }}
                            />
                          ) : (
                            <UpArrow
                              color='darkGray'
                              pointer
                              onClick={() => {
                                onChangeCollapsedCategories(categoryId, true);
                              }}
                            />
                          )}
                          <MediumEditIcon
                            color='darkGray'
                            onClick={(e) => {
                              setEditingCategory({
                                id: categoryId,
                                name: categoryName,
                                isPackage: false,
                              });
                              setEditCategoryAssignmentMenuAnchorEl(
                                e.currentTarget,
                              );
                            }}
                          />
                        </CenteredDiv>
                      </StartAlignedFlexDiv>
                    </OptionItemContainer>
                    {!collapsed && (
                      <>
                        {sortBy(filteredServices, (s) => s.displayName).map(
                          (s, idx) => {
                            const {
                              id,
                              category,
                              displayName,
                              displayDurationMinutes,
                              durationMinutes,
                              numberOfPeopleToBook,
                              followUpDays,
                              clientDepositAmount,
                              bookableStatus,
                              type,
                              consultationSessionTypeId,
                              priceSetting,
                            } = s;

                            const isPackage = categoryName === packagesLabel;
                            const formattedType = isPackage ? packageKey : type;

                            const formattedService = {
                              ...s,
                              type: formattedType,
                            };

                            const requiredConsultationName =
                              consultationsMap?.[consultationSessionTypeId]
                                ?.displayName;

                            const hasCustomDepositSettings =
                              clientDepositAmount != null;

                            return (
                              <OptionItemContainer
                                bottom={idx === numServices - 1}
                                widthPercentage={95}
                              >
                                <OptionSubitemTextContainer>
                                  <OptionSubitemText start>
                                    {displayName}
                                    {formattedType !== dropInKey &&
                                      ` (${sessionTypeTypesMap[formattedType]})`}
                                    {isSuperAdmin && (
                                      <Tooltip
                                        title={
                                          <TooltipTitleText>
                                            Copy ID {id?.slice(0, 4)}
                                          </TooltipTitleText>
                                        }
                                        placement='bottom'
                                      >
                                        <CopyIdIcon
                                          addLargeLeftMargin
                                          addTopMargin
                                          onClick={() =>
                                            copyTextToClipboard(id)
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                  </OptionSubitemText>
                                  <OptionParagraphText>
                                    {s.price === -1
                                      ? `Variable price`
                                      : s.price != null
                                      ? `$${formatNumber(s.price)}`
                                      : '(Price unknown)'}{' '}
                                    {s.units && `per ${s.units} `}
                                    {priceSetting === 'fixed_price'
                                      ? ''
                                      : '(Variable price) '}
                                    {hasCustomDepositSettings &&
                                      `${textSeparatorChar} Custom deposit `}
                                    {categoryName !==
                                      sessionTypeTypesMap[packageKey] && (
                                      <>
                                        {textSeparatorChar}{' '}
                                        {displayDurationMinutes != null &&
                                        durationMinutes != null
                                          ? displayDurationMinutes !==
                                            durationMinutes
                                            ? `${displayDurationMinutes} minutes (${durationMinutes} for staff)`
                                            : safeParseInt(
                                                displayDurationMinutes,
                                              ) === 0
                                            ? `No time added on`
                                            : `${displayDurationMinutes} minutes`
                                          : 'Time length unknown'}
                                      </>
                                    )}
                                    {numberOfPeopleToBook !== 1
                                      ? ` ${textSeparatorChar} ${numberOfPeopleToBook} guests/booking`
                                      : ''}
                                    {followUpDays != null
                                      ? ` ${textSeparatorChar} ${followUpDays} day follow-up`
                                      : ''}
                                    <br></br>
                                    {category
                                      ? `Part of the '${formatKeyToLabel(
                                          category,
                                        )}' corpus category`
                                      : `Unassigned to a corpus category`}
                                  </OptionParagraphText>
                                </OptionSubitemTextContainer>
                                <OptionActionsContainer>
                                  <OptionIconContainer
                                    status={bookableStatus || unclassifiedKey}
                                    onClick={(e) => {
                                      setEditingService(formattedService);
                                      setEditMenuAnchorEl(e.currentTarget);
                                    }}
                                  >
                                    {getBookableIcon(
                                      bookableStatus,
                                      agentName,
                                      requiredConsultationName,
                                    )}
                                  </OptionIconContainer>
                                  {formattedType !== packageKey && (
                                    <OptionIconContainer>
                                      <Tooltip
                                        title={
                                          <TooltipTitleText>
                                            Edit
                                          </TooltipTitleText>
                                        }
                                      >
                                        <CenteredDiv
                                          onClick={() => {
                                            setEditingService(s);
                                            setEditModalOpen(true);
                                          }}
                                        >
                                          <ExpandViewIcon />
                                        </CenteredDiv>
                                      </Tooltip>
                                    </OptionIconContainer>
                                  )}
                                </OptionActionsContainer>
                              </OptionItemContainer>
                            );
                          },
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {!hasMatchingServices &&
                    searchFilter &&
                    idx === sortedAvailableSessionTypes?.length - 1 ? (
                      <ColumnCenteredDiv topMargin={50}>
                        <LargeNoDataIcon />
                        <LightPrimaryEssText>
                          No matching results
                        </LightPrimaryEssText>
                      </ColumnCenteredDiv>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
              <PopperMenu
                open={editCategoryAssignmentMenuOpen}
                anchorElement={editCategoryAssignmentMenuAnchorEl}
                onClose={() => {
                  setEditingCategory();
                  setEditCategoryAssignmentMenuAnchorEl();
                }}
                variant='offset'
                placement='bottom'
              >
                <SmallMenuItemTitleContainer disableRipple>
                  <SmallMenuItemTitleText>
                    Select corpus category to assign all '
                    {editingCategory?.name}' services to
                  </SmallMenuItemTitleText>
                </SmallMenuItemTitleContainer>
                {sortBy(allServiceCategories, (c) => c.toLowerCase()).map(
                  (c) => {
                    const label = formatKeyToLabel(c);
                    return (
                      <MenuItem
                        onClick={() => {
                          updateCategoryAssignment(editingCategory, c);
                          setEditingCategory();
                          setEditCategoryAssignmentMenuAnchorEl();
                        }}
                      >
                        <SmallMenuItemText>{label}</SmallMenuItemText>
                      </MenuItem>
                    );
                  },
                )}
              </PopperMenu>
              <PopperMenu
                open={editMenuOpen}
                anchorElement={editMenuAnchorEl}
                onClose={() => setEditMenuAnchorEl()}
                variant='offset'
              >
                <SmallMenuItemTitleContainer disableRipple>
                  <SmallMenuItemTitleText>
                    Select booking status
                  </SmallMenuItemTitleText>
                </SmallMenuItemTitleContainer>
                {Object.keys(sessionTypeBookableStatusMap).map((key) => {
                  const label = sessionTypeBookableStatusMap[key];
                  const isCurrent = editingService?.bookableStatus === key;
                  return (
                    <MenuItem
                      onClick={() => {
                        console.log('editingService', editingService);
                        if (!isCurrent) {
                          if (
                            key ===
                            requiresConsultationBookableSessionTypeStatus
                          ) {
                            setConsultationMenuAnchorEl(editMenuAnchorEl);
                            setEditMenuAnchorEl();
                          } else {
                            onUpdateService(editingService, {
                              ...editingService,
                              bookableStatus: key,
                              consultationSessionTypeId: null,
                            });
                            setEditMenuAnchorEl();
                          }
                        }
                      }}
                      disabled={isCurrent}
                    >
                      <SmallMenuItemText>
                        {label}
                        {isCurrent && ` (Current)`}
                      </SmallMenuItemText>
                    </MenuItem>
                  );
                })}
              </PopperMenu>
              <PopperMenu
                open={consultationMenuOpen}
                anchorElement={consultationMenuAnchorEl}
                onClose={() => setConsultationMenuAnchorEl()}
                variant='offset'
              >
                <SmallMenuItemTitleContainer disableRipple>
                  <SmallMenuItemTitleText>
                    Select consultation required
                  </SmallMenuItemTitleText>
                </SmallMenuItemTitleContainer>
                {allConsultations.map((c) => {
                  const label = c.displayName;
                  return (
                    <MenuItem
                      onClick={() => {
                        onUpdateService(editingService, {
                          ...editingService,
                          bookableStatus:
                            requiresConsultationBookableSessionTypeStatus,
                          consultationSessionTypeId: c.id,
                        });
                        setConsultationMenuAnchorEl();
                      }}
                    >
                      <SmallMenuItemText>{label}</SmallMenuItemText>
                    </MenuItem>
                  );
                })}
              </PopperMenu>
            </AutoScrollWrapper>
          </ListInputSection>
        </PromptContainer>
      </PromptsSection>
      <EditServiceModal
        isOpen={createModalOpen || editModalOpen}
        onClose={() => {
          if (createModalOpen) {
            setCreateModalOpen(false);
          } else {
            setEditingService();
            setEditModalOpen(false);
          }
        }}
        onSave={(updatedService) => {
          onUpdateService(editingService, updatedService, true);
          setEditModalOpen(false);
          setEditingService();
        }}
        onCreate={async (createdService) => {
          onCreate(createdService);
          setCreateModalOpen(false);
        }}
        onSaveUpdates={(updatedService) => {
          onSave(updatedService);
          setEditModalOpen(false);
        }}
        sessionType={editingService}
        categories={categoriesMap}
        calendars={calendars}
        consultations={allConsultations}
        isEditing={editModalOpen}
      />
      <CatalogDiagnosticsModal
        isOpen={diagnosticsModalIsOpen}
        onClose={() => setDiagnosticsModalIsOpen(false)}
        onTest={onTestCatalogBookability}
      />
    </>
  );
};

export default Services;
