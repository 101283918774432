import styled from 'styled-components';
import {
  LargeIconSize,
  SyncIcon,
} from '../../../styles/shared-styled-components';

export const StyledSyncIcon = styled(SyncIcon)`
  ${LargeIconSize}
  color: ${({ theme }) => theme.color.primary}
`;
