import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { BOOKMARK_CALL } from '../../../api/mutations/call';
import {
  DarkMLText,
  FixedWidthCheckboxContainer,
  InputContainerForMediumGap,
  InputWidthDiv,
  MediumDarkEssText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SMCheckbox,
} from '../../../styles/shared-styled-components';
import Input from '../../Form/Input';

const BookmarkCallModal = ({ isOpen, onClose, callId, onCreate }) => {
  const [bookmarkCallMutation, { loading }] = useMutation(BOOKMARK_CALL);

  const [notes, setNotes] = useState();
  const [createFeedback, setCreateFeedback] = useState(false);
  const [feedback, setFeedback] = useState();

  const handleClose = () => {
    setNotes();
    setCreateFeedback();
    setFeedback();
    onClose();
  };

  const handleCreate = () => {
    bookmarkCallMutation({
      variables: {
        id: callId,
        notes,
        feedback: {
          notes: feedback,
        },
      },
      onCompleted: async () => {
        handleClose();
        await onCreate();
      },
    });
  };

  const createEnabled = !!notes && (!createFeedback || !!feedback);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <ModalContainer>
          <DarkMLText>Bookmark Call</DarkMLText>
          <ModalInputsContainer>
            <ModalInputsContainerScrollWrapper topPadding>
              <Input
                id='notes'
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                label={'Notes'}
                numRows={3}
                multiline
                useSmallGap
              />
              <InputContainerForMediumGap topMargin={20}>
                <InputWidthDiv>
                  <FixedWidthCheckboxContainer fullWidth>
                    <MediumDarkEssText>
                      Attach feedback request
                    </MediumDarkEssText>
                    <SMCheckbox
                      checked={createFeedback}
                      onClick={() => {
                        setCreateFeedback(!createFeedback);
                      }}
                    />
                  </FixedWidthCheckboxContainer>
                </InputWidthDiv>
              </InputContainerForMediumGap>
              {createFeedback && (
                <Input
                  id='feedback'
                  value={feedback}
                  onChange={(e) => {
                    setFeedback(e.target.value);
                  }}
                  label={'Feedback request'}
                  isRequired={false}
                  multiline
                  numRows={6}
                  useExtraSmallGap
                />
              )}
            </ModalInputsContainerScrollWrapper>
          </ModalInputsContainer>
          <ModalActions>
            <MediumPrimaryButton
              disabled={!createEnabled}
              onClick={handleCreate}
            >
              Create
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={handleClose}>
              Close
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default BookmarkCallModal;
