import MuiSearchIcon from '@mui/icons-material/Search';
import MuiIconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import styled, { css } from 'styled-components';
import { MenuItem, TextInput } from '../../../styles/shared-styled-components';

export const LocationSearchInput = styled(TextInput)``;

export const EndInputAdornment = styled(InputAdornment).attrs({
  position: 'end',
})`
  padding: 0px;
  margin: 0px;
`;

export const IconButton = styled(MuiIconButton)`
  padding: 0px 2px;
`;

export const SearchIcon = styled(MuiSearchIcon)`
  color: ${({ theme }) => theme.color.primary};
`;

export const LocationSuggestionWidthStyle = css`
  width: ${(props) => (props.fixedWidth ? props.fixedWidth : 330)}px;
  ${(props) =>
    props.ismobilescreen &&
    !props.fixedWidth &&
    css`
      left: 10px !important;
    `}
`;

export const LocationSuggestion = styled(MenuItem)`
  width: max-content;
  min-width: 100%;
  background-color: ${(props) =>
    props.hovered && props.theme.color.superLightPrimary};
  &:hover {
    background-color: ${(props) => props.theme.color.superLightPrimary};
    cursor: pointer;
  }
`;
