import MuiSwitch from '@mui/material/Switch';
import styled from 'styled-components';

export const SwitchToggleComponent = styled(MuiSwitch)`
  .Mui-checked {
    color: ${({ theme }) => theme.color.lightPrimary} !important;
  }
  .MuiSwitch-switchBase {
    color: ${({ theme }) => theme.color.muiInput};
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.color.primary} !important;
  }
`;
