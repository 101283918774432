import React from 'react';
import { useLaunchStatus } from '../../../api/hooks/training';
import {
  CompleteModuleIcon,
  IncompleteModuleIcon,
  ModuleStatusIconContainer,
} from '../../../pages-app/Tabs/Training/Training/styled';
import {
  CenteredDivWithGap,
  EmptyGapColumnCenteredDiv,
  MLText,
  MediumGapColumnCenteredDiv,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  StartAlignedMediumDarkLargeTinyText,
  StartAlignedMediumDarkSmallText,
} from '../../../styles/shared-styled-components';
import { formatKeyToLabel } from '../../../utils/string';
import LoadingIndicator from '../../LoadingIndicator';

const LaunchChecklistModal = ({ isOpen, onClose, inputData }) => {
  // Launch status
  const launchStatusRes = useLaunchStatus({
    skipCondition: inputData || !isOpen,
  });
  const launchStatusLoading = launchStatusRes?.loading;
  const data = inputData ? inputData : launchStatusRes?.data || {};

  if (launchStatusLoading) {
    return <LoadingIndicator fullScreen />;
  }

  const sectionKeys = Object.keys(data);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer useSmallGap={true}>
        <MLText>Launch Checklist</MLText>
        <ModalInputsContainer>
          <ModalInputsContainerScrollWrapper
            columnFlex
            largeGap
          >
            <MediumGapColumnCenteredDiv>
              {sectionKeys?.map((key) => {
                const subsections = Object.keys(data[key]);
                return (
                  <EmptyGapColumnCenteredDiv>
                    <StartAlignedMediumDarkSmallText>
                      {formatKeyToLabel(key)}
                    </StartAlignedMediumDarkSmallText>
                    {subsections.map((subsectionKey) => (
                      <CenteredDivWithGap>
                        <StartAlignedMediumDarkLargeTinyText>
                          {formatKeyToLabel(subsectionKey)}
                        </StartAlignedMediumDarkLargeTinyText>
                        <ModuleStatusIconContainer>
                          {data[key][subsectionKey] ? (
                            <CompleteModuleIcon />
                          ) : (
                            <IncompleteModuleIcon />
                          )}
                        </ModuleStatusIconContainer>
                      </CenteredDivWithGap>
                    ))}
                  </EmptyGapColumnCenteredDiv>
                );
              })}
            </MediumGapColumnCenteredDiv>
          </ModalInputsContainerScrollWrapper>
        </ModalInputsContainer>
        <ModalActions addTopMargin={true}>
          <MediumSecondaryButton onClick={onClose}>Close</MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default LaunchChecklistModal;
