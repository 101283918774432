import { gql } from '@apollo/client';

export const BOOKMARK_CALL = gql`
  mutation bookmarkCall($id: String!, $notes: String!, $feedback: JSON) {
    bookmarkCall(id: $id, notes: $notes, feedback: $feedback) {
      success
    }
  }
`;

export const REMOVE_CALL_BOOKMARK = gql`
  mutation removeCallBookmark($id: String!) {
    removeCallBookmark(id: $id) {
      success
    }
  }
`;
