import { gql } from '@apollo/client';

export const CREATE_AGENT = gql`
  mutation createAgent(
    $locationId: String!
    $productKey: String
    $userFirstName: String
    $userLastName: String
    $userEmail: String
  ) {
    createAgent(
      locationId: $locationId
      productKey: $productKey
      userFirstName: $userFirstName
      userLastName: $userLastName
      userEmail: $userEmail
    ) {
      id
      name
      productKey
      status
      createdAt
    }
  }
`;

export const EDIT_AGENT_NAME = gql`
  mutation editAgentName($agent: JSON!, $name: String!) {
    editAgentName(agent: $agent, name: $name) {
      id
      name
      productKey
      status
      createdAt
    }
  }
`;

export const RUN_REFRESH_EMBEDDINGS_JOB = gql`
  mutation runRefreshEmbeddingsJob {
    runRefreshEmbeddingsJob {
      success
    }
  }
`;

export const CREATE_AGENT_VERSION = gql`
  mutation createAgentVersion(
    $version: String!
    $name: String!
    $description: String!
    $agentId: String
  ) {
    createAgentVersion(
      version: $version
      name: $name
      description: $description
      agentId: $agentId
    ) {
      success
    }
  }
`;

export const DEPLOY_GLOBAL_AGENT_VERSION = gql`
  mutation deployGlobalAgentVersion($globalAgentVersionId: String!) {
    deployGlobalAgentVersion(globalAgentVersionId: $globalAgentVersionId) {
      success
    }
  }
`;

export const DEPLOY_AGENT_SPECIFIC_VERSION = gql`
  mutation deployAgentSpecificVersion(
    $agentId: String!
    $agentVersionId: String!
  ) {
    deployAgentSpecificVersion(
      agentId: $agentId
      agentVersionId: $agentVersionId
    ) {
      success
    }
  }
`;
