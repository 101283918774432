import React, { useState } from 'react';
import {
  MLText,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalParagraphText,
} from '../../../styles/shared-styled-components';
import { dropdownInputType } from '../../../utils/constants';
import Input from '../../Form/Input';

const SelectCampaignForChatDemoModal = ({
  isOpen,
  onClose,
  campaigns,
  onSelect,
}) => {
  const [selectedCampaignIdx, setSelectedCampaignIdx] = useState();

  const handleClose = () => {
    setSelectedCampaignIdx();
    onClose();
  };

  const formattedCampaigns = campaigns.map((c, idx) => {
    return {
      key: idx,
      label: c.name,
    };
  });

  return (
    formattedCampaigns?.length > 0 && (
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <ModalContainer>
          <MLText>Select Campaign to Demo</MLText>
          <ModalParagraphText>
            This client belongs to multiple campaigns. Select which one you want
            to demo with your agent.
          </ModalParagraphText>
          <MediumGapColumnCenteredDiv>
            <Input
              id='campaign'
              value={selectedCampaignIdx}
              onChange={(e) => {
                setSelectedCampaignIdx(e.target.value);
              }}
              label='Campaign to demo'
              isRequired={false}
              options={formattedCampaigns}
              type={dropdownInputType}
            />
          </MediumGapColumnCenteredDiv>
          <ModalActions>
            <MediumPrimaryButton
              onClick={() => onSelect(campaigns[selectedCampaignIdx])}
              disabled={selectedCampaignIdx == null}
            >
              Select
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={handleClose}>
              Cancel
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    )
  );
};

export default SelectCampaignForChatDemoModal;
