import React, { useState } from 'react';
import {
  FilterDownArrow,
  FilterOptionText,
  FilterUpArrow,
  MenuItem,
} from '../../../styles/shared-styled-components';
import PopperMenu from '../../PopperMenu';
import { FilterSectionContainer } from '../shared-analytics-styles';
import { FilterContainer, FilterText } from './styled';

const FilterDropdown = ({ value, onChange, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const dateRangeFilterOpen = Boolean(anchorEl);

  const labelsMap = {};
  options.map((o) => (labelsMap[o.key] = o.label));

  const onCloseFilter = () => {
    setAnchorEl(null);
    setFilterOpen(false);
  };

  const updateValue = (key) => {
    onChange(key);
    onCloseFilter();
  };

  return (
    <FilterSectionContainer>
      <FilterContainer
        clickable={true}
        onClick={(event) => {
          setFilterOpen(!filterOpen);
          setAnchorEl(event.currentTarget);
        }}
      >
        <FilterText>
          {filterOpen ? <FilterUpArrow /> : <FilterDownArrow />}
          {labelsMap[value]}
        </FilterText>
      </FilterContainer>
      <PopperMenu
        open={dateRangeFilterOpen}
        anchorElement={anchorEl}
        onClose={onCloseFilter}
        variant='offset'
      >
        {options.map((o) => (
          <MenuItem
            disableRipple
            onClick={() => updateValue(o.key)}
          >
            <FilterOptionText selected={value === o.key}>
              {o.label}
            </FilterOptionText>
          </MenuItem>
        ))}
      </PopperMenu>
    </FilterSectionContainer>
  );
};

export default FilterDropdown;
