import styled from 'styled-components';
import {
  CenterFlexStyle,
  ExtraSmallGapStyle,
  LightTinyText,
} from '../../../styles/shared-styled-components';

export const FilterContainer = styled.div`
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  border: 1.5px dotted ${({ theme }) => theme.color.muiInput};
  min-width: fit-content;
  height: 30px;
  padding: 3px 30px;
  ${CenterFlexStyle}
  cursor: ${(props) => props.clickable && 'pointer'};
`;

export const FilterText = styled(LightTinyText)`
  ${CenterFlexStyle}
  ${ExtraSmallGapStyle}
`;
