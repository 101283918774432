import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import {
  useMessageTemplateContent,
  useMyFiles,
} from '../../../api/hooks/content';
import { useAvailableSessionTypes } from '../../../api/hooks/enterprise';
import {
  CREATE_MESSAGE_TEMPLATE,
  EDIT_MESSAGE_TEMPLATE,
} from '../../../api/mutations/content';
import {
  CenteredDiv,
  DarkMLText,
  EmptyGapColumnCenteredDiv,
  FullSizeColumnCenteredDivWithGap,
  InputWidthDiv,
  ItalicTextSpan,
  MediumDarkEssText,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  MenuItem,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
  StartAlignedInputTextContainer,
  StartAlignedMediumDarkLargeTinyText,
  Tab,
  Tabs,
} from '../../../styles/shared-styled-components';
import {
  contactNameMessageTemplateVariables,
  customMessageTemplateVariables,
  firstUnresponsiveFollowUpDripKey,
  secondUnresponsiveFollowUpDripKey,
  sequenceStartMessageTemplateTypeKey,
  thirdUnresponsiveFollowUpDripKey,
} from '../../../utils/constants';
import { VariablesInputLabel } from '../../Form/CustomVariablesMenu/styled';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';
import SwitchToggle from '../../Micro/SwitchToggle/SwitchToggle';
import PopperMenu from '../../PopperMenu';
import {
  InputToggleContainer,
  InputToggleSection,
} from '../EditServiceModal/styled';

const openingMessageTabKey = 0;
const firstFollowUpMessageTabKey = 1;
const secondFollowUpMessageTabKey = 2;
const thirdFollowUpMessageTabKey = 3;

const tabContentTypeMap = {
  [openingMessageTabKey]: sequenceStartMessageTemplateTypeKey,
  [firstFollowUpMessageTabKey]: firstUnresponsiveFollowUpDripKey,
  [secondFollowUpMessageTabKey]: secondUnresponsiveFollowUpDripKey,
  [thirdFollowUpMessageTabKey]: thirdUnresponsiveFollowUpDripKey,
};

const defaultContentObject = {
  [sequenceStartMessageTemplateTypeKey]: {
    text: '',
    autoGenerateText: false,
  },
  [firstUnresponsiveFollowUpDripKey]: {
    autoGenerateText: true,
    type: firstUnresponsiveFollowUpDripKey,
  },
  [secondUnresponsiveFollowUpDripKey]: {
    autoGenerateText: true,
    type: secondUnresponsiveFollowUpDripKey,
  },
  [thirdUnresponsiveFollowUpDripKey]: {
    autoGenerateText: true,
    type: thirdUnresponsiveFollowUpDripKey,
  },
};

const formatContent = (content) => {
  if (!content) {
    return defaultContentObject;
  }

  const contentMap = {};

  content?.map((c) => {
    contentMap[c.type] = c;
  });

  return contentMap;
};

const fieldsContainerId = 'fields-container';

const SingleNodeTemplateModal = ({
  id,
  campaignId,
  isOpen,
  onClose,
  onSave,
}) => {
  const { data, loading, refetch } = useMessageTemplateContent({
    id,
    skipCondition: !isOpen,
  });
  const template = data?.template || {};
  const content = formatContent(data?.content);

  const [
    createMessageTemplateMutation,
    { loading: createMessageTemplateLoading },
  ] = useMutation(CREATE_MESSAGE_TEMPLATE);
  const [editMessageTemplateMutation, { loading: editMessageTemplateLoading }] =
    useMutation(EDIT_MESSAGE_TEMPLATE);
  const { sessionTypes, loading: sessionTypesLoading } =
    useAvailableSessionTypes({ asCatalog: true });
  const { files, loading: filesLoading } = useMyFiles();

  const inputRef = useRef();

  const [attachFileMenuAnchorEl, setAttachFileMenuAnchorEl] = useState();
  const [contentToEdit, setContentToEdit] = useState(content);
  const [tab, setTab] = useState(openingMessageTabKey);

  const attachFileMenuOpen = Boolean(attachFileMenuAnchorEl);

  useEffect(() => {
    if (data) {
      const content = formatContent(data?.content);
      setContentToEdit(content);
    }
  }, [data]);

  const handleClose = () => {
    setContentToEdit(defaultContentObject);
    setTab(openingMessageTabKey);
    onClose();
  };

  const onUpdateContent = (field, value) => {
    const updatedContent = { ...contentToEdit };

    updatedContent[typeToDisplay] = {
      ...updatedContent[typeToDisplay],
      [field]: value,
      type: typeToDisplay,
    };
    if (field === 'files' && !value?.length) {
      updatedContent[typeToDisplay] = {
        ...updatedContent[typeToDisplay],
        filesDescription: '',
      };
    }

    setContentToEdit(updatedContent);
  };

  const onSuccess = async () => {
    handleClose();
    await onSave();
  };

  const handleSave = () => {
    const contentObjects = Object.values(contentToEdit);

    const formattedContentObjects = contentObjects.map((c) => {
      const fileIds = c?.files?.map((f) => f.id) || [];

      return {
        ...c,
        fileIds,
        files: undefined,
      };
    });

    if (id) {
      editMessageTemplateMutation({
        variables: {
          id: template.id,
          name: 'Template',
          content: formattedContentObjects,
        },
        onCompleted: async (data) => {
          const success = data.editMessageTemplate;

          if (success) {
            await onSuccess();
          }
        },
      });
    } else {
      createMessageTemplateMutation({
        variables: {
          name: 'Template',
          content: formattedContentObjects,
          campaignId,
        },
        onCompleted: async (data) => {
          const success = data.createMessageTemplate;

          if (success) {
            await onSuccess();
          }
        },
      });
    }
  };

  if (
    editMessageTemplateLoading ||
    loading ||
    sessionTypesLoading ||
    filesLoading
  ) {
    return <LoadingIndicator fullScreen />;
  }

  const typeToDisplay = tabContentTypeMap[tab];
  const contentToDisplay = contentToEdit[typeToDisplay];

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer>
        <DarkMLText>{id ? 'Edit' : 'Create'} Message Template</DarkMLText>
        <ModalInputsContainer>
          <ModalInputsContainerScrollWrapper
            columnFlex
            largeGap
          >
            <MediumGapColumnCenteredDiv
              topMargin={10}
              id={fieldsContainerId}
            >
              <EmptyGapColumnCenteredDiv>
                <CenteredDiv>
                  <Tabs
                    value={tab}
                    onChange={(_, key) => setTab(key)}
                    addBottomMargin
                  >
                    <Tab
                      value={openingMessageTabKey}
                      label='Opener'
                    />
                    <Tab
                      value={firstFollowUpMessageTabKey}
                      label='Follow-Ups'
                    />
                  </Tabs>
                </CenteredDiv>
                {typeToDisplay === sequenceStartMessageTemplateTypeKey ? (
                  <>
                    <Input
                      id='text'
                      value={contentToDisplay?.text}
                      onChange={(e) => {
                        onUpdateContent('text', e.target.value);
                      }}
                      label={<VariablesInputLabel>Content</VariablesInputLabel>}
                      isRequired={false}
                      multiline
                      numRows={7}
                      variables={customMessageTemplateVariables}
                      removeGap
                      inputRef={inputRef}
                    />
                    <Input
                      id='text'
                      value={contentToDisplay?.followUpText}
                      onChange={(e) => {
                        onUpdateContent('followUpText', e.target.value);
                      }}
                      label={
                        'Follow-up text, if you want to split it up into multiple messages'
                      }
                      isRequired={false}
                      multiline
                      numRows={2}
                      useSmallGap
                      inputRef={inputRef}
                    />
                    <InputToggleSection>
                      <InputToggleContainer>
                        <MediumDarkEssText>
                          Use default stopword
                        </MediumDarkEssText>
                        <SwitchToggle
                          value={contentToDisplay?.customStopMessage == null}
                          onChange={(e) => {
                            onUpdateContent(
                              'customStopMessage',
                              contentToDisplay?.customStopMessage != null
                                ? null
                                : '',
                            );
                          }}
                        />
                      </InputToggleContainer>
                      {contentToDisplay?.customStopMessage == null ? (
                        <StartAlignedMediumDarkLargeTinyText>
                          <ItalicTextSpan>
                            "To stop receiving our messages, just reply
                            __stopword__"
                          </ItalicTextSpan>{' '}
                          <br></br>
                          Your stopword can be edited via the Sequences tab *
                        </StartAlignedMediumDarkLargeTinyText>
                      ) : (
                        <Input
                          id='text'
                          value={contentToDisplay?.customStopMessage}
                          onChange={(e) => {
                            onUpdateContent(
                              'customStopMessage',
                              e.target.value,
                            );
                          }}
                          label={'Custom stopword used in template'}
                          isRequired={false}
                          useExtraSmallGap
                          inputRef={inputRef}
                        />
                      )}
                    </InputToggleSection>
                    <InputWidthDiv>
                      <FullSizeColumnCenteredDivWithGap topMargin={16}>
                        <StartAlignedInputTextContainer>
                          <StartAlignedMediumDarkLargeTinyText smallLineHeight>
                            Does your copy describe a specific service you're
                            advertising using a different name than it appears
                            in your service list? If so, enter the name as it
                            appears in your copy. For example, if advertisting
                            the 'Youthful Glow Package', but your promo is
                            actually booking 'Botox Consult', you'd enter
                            'Youthful Glow Package' below.
                          </StartAlignedMediumDarkLargeTinyText>
                        </StartAlignedInputTextContainer>
                        <Input
                          id='copy'
                          value={contentToDisplay?.copyServiceName}
                          onChange={(e) => {
                            onUpdateContent('copyServiceName', e.target.value);
                          }}
                          label='Service name in copy'
                          removeGap
                        />
                      </FullSizeColumnCenteredDivWithGap>
                    </InputWidthDiv>
                  </>
                ) : (
                  <Input
                    id='text'
                    value={contentToDisplay?.text}
                    onChange={(e) => {
                      onUpdateContent('text', e.target.value);
                    }}
                    label={<VariablesInputLabel>Content</VariablesInputLabel>}
                    isRequired={false}
                    multiline
                    numRows={7}
                    variables={contactNameMessageTemplateVariables}
                    showAutoGenerateToggle
                    autoGenerate={!!contentToDisplay?.autoGenerateText}
                    onChangeAutoGenerate={(updatedAutoGenerate) => {
                      onUpdateContent('autoGenerateText', updatedAutoGenerate);
                    }}
                    removeGap
                    inputRef={inputRef}
                  />
                )}
              </EmptyGapColumnCenteredDiv>
              <PopperMenu
                open={attachFileMenuOpen}
                anchorElement={attachFileMenuAnchorEl}
                onClose={() => setAttachFileMenuAnchorEl()}
                variant='offset'
              >
                <SmallMenuItemTitleContainer disableRipple>
                  <SmallMenuItemTitleText>
                    Select file to attach
                  </SmallMenuItemTitleText>
                </SmallMenuItemTitleContainer>
                {files.map((file) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        onUpdateContent('files', [
                          ...(contentToDisplay?.files || []),
                          file,
                        ]);
                        setAttachFileMenuAnchorEl();
                      }}
                    >
                      <SmallMenuItemText>{file.name}</SmallMenuItemText>
                    </MenuItem>
                  );
                })}
              </PopperMenu>
            </MediumGapColumnCenteredDiv>
          </ModalInputsContainerScrollWrapper>
        </ModalInputsContainer>
        <ModalActions>
          <MediumPrimaryButton onClick={handleSave}>Save</MediumPrimaryButton>
          <MediumSecondaryButton onClick={handleClose}>
            Close
          </MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default SingleNodeTemplateModal;
