import styled, { css } from 'styled-components';
import { ModalInputsContainerScrollWrapper } from '../../../styles/shared-styled-components';

export const OfferingsContainer = styled(ModalInputsContainerScrollWrapper)``;

export const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: ${(props) =>
    props.bottomBorder && `1px dotted ${props.theme.color.muiInput}`};
  padding: 5px 3px;
  ${(props) =>
    props.bottomBorder &&
    css`
      border-bottom: ${({ theme }) => `1px dotted ${theme.color.muiInput}`};
      margin-bottom: 20px;
    `}
`;
