import React, { useContext } from 'react';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Header from '../../../components/NavBar/Header';
import {
  BoldPrimaryText,
  ColumnCenteredDiv,
  ContentContainer,
  DarkLargeText,
  PageContainer,
} from '../../../styles/shared-styled-components';
import { getWordWithCapitalizedFirstLetter } from '../../../utils/name';
import { getUserLocationName } from '../../../utils/user';
import { ContactContainer, ContactText } from './styled';

const InitializationPurgatory = () => {
  const { user, userLoading } = useContext(BaseContext);

  const locationName = getUserLocationName(user);

  if (userLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    user && (
      <>
        <Header />
        <PageContainer>
          <ContentContainer>
            <ColumnCenteredDiv>
              <DarkLargeText>Syncing Your Business Data ⏳</DarkLargeText>
            </ColumnCenteredDiv>
            <ColumnCenteredDiv>
              <ContactContainer>
                <ContactText>
                  Hi {getWordWithCapitalizedFirstLetter(user?.firstName)}!
                  <br></br>
                  <br></br>
                  While our agent learns about your business, a LiveIQ team
                  member will reach out to finish onboarding you onto our
                  platform.
                  <br></br>
                  <br></br>
                  We can't wait to supercharge{' '}
                  {locationName ? locationName : 'your business'} with the power
                  of AI.
                  <br></br>
                  <br></br>
                  <BoldPrimaryText>
                    Talk soon, <br></br>- The LiveIQ Team
                  </BoldPrimaryText>
                </ContactText>
              </ContactContainer>
            </ColumnCenteredDiv>
          </ContentContainer>
        </PageContainer>
      </>
    )
  );
};

export default InitializationPurgatory;
