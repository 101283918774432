import Slide from '@mui/material/Slide';
import React from 'react';

const UpTransition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

export default UpTransition;
