import gql from 'graphql-tag';

export const GET_MY_CAMPAIGNS = gql`
  query getMyCampaigns($shallow: Boolean!) {
    getMyCampaigns(shallow: $shallow) {
      id
      name
      type
      status
      startDate
      endDate
      memberIds
      numMembers
      numContacted
      agent {
        id
        name
      }
      promoId
      targetClients
      targetLeads
      template {
        id
        type
      }
      messageNewLeads
      lastContactDelayDays
      tagsToExclude
      smartList {
        enabled
        filter
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONTACT_TAGS = gql`
  query getContactTags {
    getContactTags
  }
`;
