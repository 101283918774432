import BarChartIcon from '@mui/icons-material/BarChart';
import ConstructionIcon from '@mui/icons-material/Construction';
import GroupIcon from '@mui/icons-material/Group';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SyncIcon from '@mui/icons-material/Sync';
import WidgetsIcon from '@mui/icons-material/Widgets';
import WorkIcon from '@mui/icons-material/Work';
import styled, { css } from 'styled-components';
import {
  AgentIcon,
  AnalyticsIcon,
  AutoHorizontalMargins,
  BaseDrawerContainer,
  BrainIcon,
  CenterFlexStyle,
  CenteredDiv,
  ChatIcon,
  ClientIcon,
  CollapseArrowIcon,
  CollapsedDrawerWidthStyle,
  EmptyGapColumnCenteredDiv,
  ExpandArrowIcon,
  ExpandedDrawerWidthValue,
  ExtraSmallText,
  FlexDiv,
  LargeTinyText,
  MarketingIcon,
  MediumLiteGapStyle,
  StartAlignedMediumDarkExtraSmallText,
  WorkflowIcon,
} from '../../../styles/shared-styled-components';

export const BottomDrawerSectionHeight = 50;

export const DrawerContainer = styled(BaseDrawerContainer)`
  border-right: 2px solid ${({ theme }) => theme.color.muiInputLightGray};
  left: 0px;
  overflow: hidden;
  z-index: 1000;
`;

export const LocationViewTextContainer = styled(CenteredDiv)`
  border-bottom: 1px dotted ${({ theme }) => theme.color.muiInputDarkGray};
  padding: 10px 0px;
  justify-content: flex-end;
`;

export const LocationViewText = styled(StartAlignedMediumDarkExtraSmallText)`
  text-align: center;
  margin: 0px 12px;
  font-size: 16px;
`;

export const TabsContainer = styled(EmptyGapColumnCenteredDiv)`
  margin-top: 1px;
  padding-bottom: 20px;
`;

export const TabSection = styled(FlexDiv)`
  display: flex;
  justify-content: space-between;
  height: ${(props) => !props.noMaxHeight && '100%'};
  padding: 10px 20px;
  ${AutoHorizontalMargins}
  ${(props) =>
    props.expanded
      ? css`
          width: 100%;
          border-bottom: ${(props) =>
            `${props.hq ? `3px` : `0.5px`} dotted ${
              props.theme.color.muiInputLightGray
            }`};
        `
      : css`
          align-items: center;
          border-bottom: ${(props) =>
            `${props.hq ? `6px` : `0px`} dotted ${
              props.theme.color.muiInputLightGray
            }`};
        `}
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  cursor: ${(props) => (!props.unclickable ? 'pointer' : 'default')};
  ${(props) =>
    props.expanded
      ? css`
          ${MediumLiteGapStyle}
          align-items: flex-start;
          justify-content: space-between;
        `
      : css`
          ${CenterFlexStyle}
        `};
`;

export const HeadOfficeTabContainer = styled(TabContainer)`
  ${(props) =>
    props.expanded && props.businessSelected
      ? css`
          border-bottom: 3px dotted
            ${({ theme }) => theme.color.muiInputLightGray};
        `
      : css`
          border-bottom: 6px dotted
            ${({ theme }) => theme.color.muiInputLightGray};
        `}
`;

export const TabTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 20px;
  cursor: pointer;
  gap: 2px;
  margin-top: 5px;
`;

export const TabText = styled(ExtraSmallText)`
  color: ${(props) =>
    props.selected ? props.theme.color.primary : props.theme.color.charcoal};
  font-size: 15.5px;
  margin-bottom: ${(props) => !props.noBottomMargin && `10px`};
  margin-top: ${(props) => props.topMargin && '4px'};
  margin-left: ${(props) => props.leftMargin && `${props.leftMargin}px`};
`;

export const TabSubtext = styled(LargeTinyText)`
  color: ${(props) =>
    props.selected
      ? props.theme.color.primary
      : props.theme.color.muiInputDarkGray};
  font-size: 14.5px;
`;

const TabIconStyle = css`
  color: ${(props) =>
    props.selected ? props.theme.color.primary : props.theme.color.defaultGray};
  height: 35px;
  width: 35px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    opacity: 0.8;
  }
`;

export const HeadOfficeTabIcon = styled(BarChartIcon)`
  ${TabIconStyle}
  height: 38px;
  width: 38px;
  margin-top: -3px;
  margin-left: ${(props) => (props.expanded ? `0px` : `0px`)};
`;

export const ConnectionsTabIcon = styled(SyncIcon)`
  ${TabIconStyle}
`;

export const CampaignTabIcon = styled(MarketingIcon)`
  ${TabIconStyle}
  height: 28px;
  width: 28px;
  margin-top: 3px;
`;

export const ClientsTabIcon = styled(ClientIcon)`
  ${TabIconStyle}
  height: 32px;
  width: 32px;
  margin-left: -4px;
`;

export const AgentsTabIcon = styled(AgentIcon)`
  ${TabIconStyle}
  margin-left: -5.5px;
`;

export const DemoTabIcon = styled(ConstructionIcon)`
  ${TabIconStyle}
  margin-left: -6px;
`;

export const ChatTabIcon = styled(ChatIcon)`
  ${TabIconStyle}
  height: 29px;
  width: 29px;
  margin-top: ${(props) => (props.expanded ? `4px` : '-1px')};
  margin-left: ${(props) => props.expanded && `3px`};
`;

export const TrainingTabIcon = styled(BrainIcon)`
  ${TabIconStyle}
  height: 31px;
  width: 31px;
  margin-top: 2px;
`;

export const AnalyticsTabIcon = styled(AnalyticsIcon)`
  ${TabIconStyle}
  height: 32px;
  width: 32px;
`;

export const SequenceTabIcon = styled(WorkflowIcon)`
  ${TabIconStyle}
  height: 32px;
  width: 32px;
  margin-left: -5px;
`;

export const TemplatesTabIcon = styled(WidgetsIcon)`
  ${TabIconStyle}
  height: 30px;
  width: 30px;
  margin-left: -5px;
`;

export const UsersTabIcon = styled(GroupIcon)`
  ${TabIconStyle}
  height: 32px;
  width: 32px;
  margin-left: -5px;
`;

export const AgentVersionsIcon = styled(SmartToyIcon)`
  ${TabIconStyle}
  height: 32px;
  width: 32px;
`;

export const AIIcon = styled(SmartToyIcon)`
  ${TabIconStyle}
  height: 32px;
  width: 32px;
`;

export const DataRoomIcon = styled(WorkIcon)`
  ${TabIconStyle}
  height: 32px;
  width: 32px;
`;

export const BottomSection = styled(TabContainer)`
  position: fixed;
  height: ${BottomDrawerSectionHeight}px;
  padding: 0px;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.color.muiInputLightGray};
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.expanded
      ? css`
          width: ${ExpandedDrawerWidthValue}px;
          padding-left: 1px;
          padding-right: 1px;
          border-bottom: 0px;
          left: -2px;
        `
      : css`
          ${CollapsedDrawerWidthStyle}
          left: 0px;
        `}
  bottom: 0px;
  z-index: 10000;
  margin-top: 5px;
  background-color: white;
  border-right: 2px solid ${({ theme }) => theme.color.muiInputLightGray};
`;

export const ExpandDrawerArrowIcon = styled(ExpandArrowIcon)`
  ${TabIconStyle}
`;

export const CollapseDrawerArrowIcon = styled(CollapseArrowIcon)`
  ${TabIconStyle}
`;
