import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { EDIT_AGENT_NAME } from '../../../api/mutations/agent';
import {
  MLText,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  PrimaryButton,
} from '../../../styles/shared-styled-components';
import {
  updateAgentInCache,
  updateAgentInCampaigns,
} from '../../../utils/cache';
import { valueIsEmpty } from '../../../utils/data';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';

const EditAgentModal = ({ isOpen, onClose, agent, onSave }) => {
  const { cache } = useApolloClient();

  const { user } = useContext(BaseContext);

  const email = user?.email;

  const [editAgentNameMutation, { loading: editAgentNameLoading }] =
    useMutation(EDIT_AGENT_NAME);

  const [name, setName] = useState(agent?.name);

  useEffect(() => {
    if (agent) {
      setName(agent.name);
    }
  }, [agent]);

  const onClickSave = () => {
    const changesMade = name !== agent?.name;

    if (changesMade) {
      editAgentNameMutation({
        variables: {
          agent,
          name,
        },
        onCompleted: async (data) => {
          const updatedAgent = data.editAgentName;

          await updateAgentInCache(updatedAgent, email, cache);
          await updateAgentInCampaigns(updatedAgent, cache);

          onSave(true);
          onClose();
        },
      });
    } else {
      onSave(false);
    }
  };

  if (editAgentNameLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer useSmallGap={true}>
        <MLText>Edit Agent Name</MLText>
        <ModalInputsContainer>
          <Input
            id='name'
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            label='Name'
            isRequired={false}
            useSmallWidth={true}
          />
        </ModalInputsContainer>
        <ModalActions addTopMargin={true}>
          <PrimaryButton
            onClick={onClickSave}
            disabled={valueIsEmpty(name)}
          >
            Save
          </PrimaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default EditAgentModal;
