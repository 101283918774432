import React, { useEffect, useState } from 'react';
import {
  DarkMLText,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
} from '../../../styles/shared-styled-components';
import Input from '../../Form/Input';

const EditTrainingResolutionExplanationModal = ({
  isOpen,
  onClose,
  onSave,
  explanation,
}) => {
  const resolutionKey = explanation?.resolutionKey;
  const value = explanation?.value || '';
  const [explanationText, setExplanationText] = useState(value);

  useEffect(() => {
    setExplanationText(value);
  }, [value]);

  const handleClose = () => {
    setExplanationText();
    onClose();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <ModalContainer>
          <DarkMLText>Edit Explanation</DarkMLText>
          <MediumGapColumnCenteredDiv>
            <Input
              id='answer'
              value={explanationText}
              onChange={(e) => {
                setExplanationText(e.target.value);
              }}
              label={'Explanation'}
              isRequired={false}
              multiline
              numRows={6}
              removeGap
            />
          </MediumGapColumnCenteredDiv>
          <ModalActions>
            <MediumPrimaryButton
              onClick={() => {
                onSave(resolutionKey, explanationText);
                handleClose();
              }}
            >
              Save
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={handleClose}>
              Close
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default EditTrainingResolutionExplanationModal;
