import React, { useState } from 'react';
import { LargeTinyText } from '../../../styles/shared-styled-components';
import {
  ChartContainer,
  ChartTitleContainer,
  StyledChart,
} from '../shared-dashboard-styles';

const basePalette = {
  fills: ['#9c90bd', 'rgb(37, 179, 77)', '#d8504d'],
  strokes: ['gray'],
};

const StackedBarChart = ({ data, series, fills, title }) => {
  const [options, setOptions] = useState({
    theme: {
      palette: {
        ...basePalette,
        fills: fills || basePalette.fills,
      },
    },
    chart: {
      borderRadius: 10,
      height: '100%',
    },
    axes: [
      {
        type: 'category',
        position: 'bottom',
        label: {
          fontSize: 12,
          fontFamily: 'SeldonFont',
          // rotation: 0, // Keep the labels horizontal
          formatter: (params) => {
            // Truncate to the first 5 characters
            const value = params.value;
            return value.length > 7 ? `${value.slice(0, 7)}...` : value; // Return a plain string
          },
        },
        gridLine: {
          enabled: false,
        },
      },
      {
        type: 'number',
        position: 'left',
        label: {
          fontSize: 12,
          fontFamily: 'SeldonFont',
        },
        gridLine: {
          enabled: false,
        },
      },
    ],
    data,
    series,
    legend: {
      position: 'right',
      item: {
        label: {
          fontSize: 14,
          fontFamily: 'SeldonFont',
        },
        marker: {
          size: 10,
          shape: 'circle',
        },
      },
    },
    tooltip: {
      position: {
        type: 'pointer',
        xOffset: 70,
        yOffset: 140,
      },
    },
  });

  return (
    <ChartContainer>
      <StyledChart options={options} />
      <ChartTitleContainer left>
        <LargeTinyText>{title}</LargeTinyText>
      </ChartTitleContainer>
    </ChartContainer>
  );
};

export default StackedBarChart;
