import styled, { css } from 'styled-components';
import { SecondaryButton } from '../../../styles/shared-styled-components';
import { AuthButtonStyle, LargeAuthButtonStyle } from '../LoginButton/styled';

export const SignupButtonComponent = styled(SecondaryButton)`
  ${(props) =>
    props.buttonSize === 'large'
      ? css`
          ${LargeAuthButtonStyle}
        `
      : props.buttonSize === 'medium'
      ? css``
      : css`
          ${AuthButtonStyle}
        `};
`;
