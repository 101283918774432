import styled from 'styled-components';
import {
  PrimaryButton,
  SuccessfulPrimaryButton,
} from '../../../styles/shared-styled-components';

export const SystemIntegrationButton = styled(PrimaryButton).attrs({
  fixedMinWidth: 350,
})`
  margin-bottom: ${(props) => props.removeBottomMargin && '-20px'};
`;

export const CompleteSystemIntegrationButton = styled(
  SuccessfulPrimaryButton,
).attrs({
  fixedMinWidth: 350,
})`
  margin-bottom: ${(props) => props.removeBottomMargin && '-20px'};
`;
