import React, { useEffect, useState } from 'react';
import {
  CenteredDiv,
  DarkMLText,
  EmptyGapColumnCenteredDiv,
  EssText,
  LightDarkLargeTinyText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  RadioButton,
} from '../../../styles/shared-styled-components';
import { stagingFilterConversionDelay } from '../../../utils/constants';
import Input from '../../Form/Input';
import { ListContainer } from './styled';

const SelectOfferingModal = ({
  isOpen,
  onClose,
  onSave,
  offerings,
  inputSelected,
}) => {
  const [selectedOffering, setSelectedOffering] = useState(
    inputSelected || null,
  );
  const [stagingSearchFilter, setStagingSearchFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  useEffect(() => {
    setSelectedOffering(inputSelected);
  }, [inputSelected]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        setSearchFilter(stagingSearchFilter);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  const handleSave = () => {
    onSave(selectedOffering);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const saveEnabled = !!selectedOffering;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <ModalContainer>
          <DarkMLText>Select Offering to Book</DarkMLText>
          <CenteredDiv>
            <Input
              removeGap
              fixedHeight={45}
              useExtraSmallWidth
              useSmallText
              label='Search'
              value={stagingSearchFilter}
              onChange={(e) => setStagingSearchFilter(e.target.value)}
            />
          </CenteredDiv>
          <ModalInputsContainer removeTopMargin>
            <ModalInputsContainerScrollWrapper columnFlex>
              <EmptyGapColumnCenteredDiv>
                {offerings.map((category) => {
                  const name = category?.categoryName || 'Uncategorized';
                  const services = category?.services;
                  const filteredServices = !searchFilter
                    ? services
                    : services.filter((s) =>
                        s.displayName?.toLowerCase()?.includes(searchFilter),
                      );
                  return filteredServices.length ? (
                    <>
                      <ListContainer>
                        <EssText>{name}</EssText>
                      </ListContainer>
                      {filteredServices.map((s, idx) => {
                        const { id, type, displayName } = s;
                        const checked = selectedOffering?.id === id;
                        return (
                          <ListContainer
                            bottomBorder={idx === filteredServices.length - 1}
                          >
                            <LightDarkLargeTinyText>
                              {s.displayName}
                            </LightDarkLargeTinyText>
                            <RadioButton
                              small
                              checked={checked}
                              onClick={() => {
                                if (!checked) {
                                  setSelectedOffering({
                                    id,
                                    type,
                                    displayName,
                                  });
                                } else {
                                  setSelectedOffering();
                                }
                              }}
                            />
                          </ListContainer>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  );
                })}
              </EmptyGapColumnCenteredDiv>
            </ModalInputsContainerScrollWrapper>
          </ModalInputsContainer>
          <ModalActions>
            <MediumPrimaryButton
              disabled={!saveEnabled}
              onClick={() => handleSave()}
            >
              Save
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={handleClose}>
              Close
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default SelectOfferingModal;
