import Paths from '../Paths';
import {
  campaignForChatDemoCookieKey,
  campaignForPipelineCookieKey,
  chatDemoCookieKey,
  chatDemoTabCookieKey,
  chatLogsCookieKey,
  chatsSearchParamsCookieKey,
  clientIdForChatDemoCookieKey,
  clientSearchParamsCookieKey,
  collapsedModulesCookieKey,
  collapsedSubmodulesCookieKey,
  contactNameForPipelineCookieKey,
  contactTypeForPipelineCookieKey,
  selectedPromptCookieKey,
  selectedSubmoduleCookieKey,
} from './constants';
import { valueIsEmpty } from './data';

export const defaultHomePath = Paths.overview;

export const checkIfOnPage = (pagePath, pathname) => {
  return pathname?.includes(pagePath) ? 'true' : 'false';
};

export const getResolvedPage = (pathname) => {
  if (checkIfOnPage(Paths.headOffice, pathname) === 'true') {
    return Paths.headOffice;
  }
  if ([checkIfOnPage(Paths.dashboard, pathname)].includes('true')) {
    return Paths.dashboard;
  }
  if (
    [
      checkIfOnPage(Paths.chats, pathname),
      checkIfOnPage(Paths.events, pathname),
      checkIfOnPage(Paths.pipeline, pathname),
    ].includes('true')
  ) {
    return Paths.chats;
  }
  if (
    [
      checkIfOnPage(Paths.training, pathname),
      checkIfOnPage(Paths.requests, pathname),
    ].includes('true')
  ) {
    return Paths.training;
  }
  if (checkIfOnPage(Paths.dashboard, pathname) === 'true') {
    return Paths.dashboard;
  }
  if (checkIfOnPage(Paths.contacts, pathname) === 'true') {
    return Paths.contacts;
  }
  if (
    [
      checkIfOnPage(Paths.campaigns, pathname),
      checkIfOnPage(Paths.audience, pathname),
      checkIfOnPage(Paths.editScript.replace(':scriptId', ''), pathname),
      checkIfOnPage(Paths.editPromo.replace(':promoId', ''), pathname),
    ].includes('true')
  ) {
    return Paths.campaigns;
  }
  if (checkIfOnPage(Paths.sequences, pathname) === 'true') {
    return Paths.sequences;
  }
  if (checkIfOnPage(Paths.templates, pathname) === 'true') {
    return Paths.templates;
  }
  if (checkIfOnPage(Paths.agents, pathname) === 'true') {
    return Paths.agents;
  }
  if (checkIfOnPage(Paths.agentDemo, pathname) === 'true') {
    return Paths.agentDemo;
  }
  if (checkIfOnPage(Paths.users, pathname) === 'true') {
    return Paths.users;
  }

  return null;
};

export const getPathnameToReturnTo = (pathname) => {
  const isOnboardingPath = checkIfPathIsUserOnboarding(pathname);
  const isAnalyticsPath = checkIfOnPage(Paths.overview, pathname);
  const pathnameToReturnTo =
    isAnalyticsPath === 'true' || isOnboardingPath ? Paths.overview : pathname;
  return pathnameToReturnTo;
};

export const userOnboardingPathPrefix = '/onboarding';
export const locationOnboardingPathPrefix = '/location-onboarding';

export const checkIfPathIsUserOnboarding = (pathname) => {
  return pathname?.includes(userOnboardingPathPrefix);
};

export const checkIfPathIsLocationOnboarding = (pathname) => {
  return pathname?.includes(locationOnboardingPathPrefix);
};

export const checkIfPathIsAgentSetupPage = (pathname, baseUrl) => {
  return (
    pathname === Paths.callback ||
    pathname === Paths.agentSelection ||
    Paths.agentAppConnection.includes(baseUrl) ||
    pathname === Paths.initializationPurgatory
  );
};

export const checkIfPathIsPrivilegedPage = (pathname) => {
  return [Paths.users, Paths.createBusiness, Paths.settings].includes(pathname);
};

const appPathPrefix = '/app';

export const checkIfPathIsAppPage = (pathname) => {
  return pathname?.includes(appPathPrefix);
};

export const justLoggedInParam = 'li';
export const justCompletedOnboardingParam = 'co';

export const getPathToRedirectToBasedOnRole = (role) => {
  return Paths.agentAppConnection;
};

export const getAgentAppConnectionUrl = (agentId) => {
  return Paths.agentAppConnection.replace(':agentId', agentId);
};

export const getEditPromoUrl = (id) => {
  return Paths.editPromo.replace(':promoId', id);
};

export const getEditScriptUrl = (id) => {
  return Paths.editScript.replace(':scriptId', id);
};

export const getOnboardingPathToRedirectTo = (user) => {
  if (!user.phone) {
    return Paths.personalOnboarding;
  }

  return Paths.backgroundOnboarding;
};

export const updateSearchParams = (
  newParamsObject,
  currentParams,
  setParamsCallback,
) => {
  const newSearchParams = new URLSearchParams(currentParams.toString());
  for (const key in newParamsObject) {
    const searchValue = newParamsObject[key];
    // Want to make sure we allow 'false' or 0 as valid values, even though they're falsey
    if (!valueIsEmpty(searchValue)) {
      newSearchParams.set(key, searchValue);
    } else {
      newSearchParams.delete(key);
    }
  }
  setParamsCallback(newSearchParams);
};

export const getTabFromSearchParams = (searchParams) => {
  const tabFromParams = searchParams?.get('tab');
  return tabFromParams != null ? parseInt(tabFromParams) : null;
};

export const pmsSystemTypeKey = 'pms';
export const marketingSystemTypeKey = 'marketing';
export const accountingSystemTypeKey = 'accounting';

export const getUrlWithoutParams = (pathname, params) => {
  return Object.values(params).reduce(
    (path, param) => path.replace('/' + param, ''),
    pathname,
  );
};

export const tabSearchParamKey = 'tab';
export const clientIdSearchParamKey = 'client-id';
export const chatIdSearchParamKey = 'chat-id';

export const shouldReloadKey = 'should-reload';

export const getChatPath = (window, chatId) => {
  return `https://liveiq.ai${window.location.pathname}?${chatIdSearchParamKey}=${chatId}`;
};

export const removeAllLocationSpecificCookies = (removerFcn) => {
  removerFcn(chatDemoTabCookieKey);
  removerFcn(clientSearchParamsCookieKey);
  removerFcn(chatsSearchParamsCookieKey);
  removerFcn(chatDemoCookieKey);
  removerFcn(clientIdForChatDemoCookieKey);
  removerFcn(campaignForChatDemoCookieKey);
  removerFcn(chatLogsCookieKey);
  removerFcn(collapsedModulesCookieKey);
  removerFcn(collapsedSubmodulesCookieKey);
  removerFcn(selectedSubmoduleCookieKey);
  removerFcn(selectedPromptCookieKey);
  removerFcn(contactNameForPipelineCookieKey);
  removerFcn(campaignForPipelineCookieKey);
  removerFcn(contactTypeForPipelineCookieKey);
};

export default {
  defaultHomePath,
  checkIfOnPage,
  getPathnameToReturnTo,
  checkIfPathIsUserOnboarding,
  justLoggedInParam,
  getOnboardingPathToRedirectTo,
  userOnboardingPathPrefix,
  justCompletedOnboardingParam,
  updateSearchParams,
  getTabFromSearchParams,
};
