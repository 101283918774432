import React from 'react';
import {
  CarouselContainer,
  DotContainer,
  FilledDot,
  PauseIcon,
  PlayIcon,
  UnfilledDot,
} from './styled';

const DotCarousel = ({ options, selected, topOffset, paused, onToggle }) => {
  return (
    <CarouselContainer topOffset={topOffset}>
      <DotContainer>
        {options.map((o, idx) => (
          <>{idx === selected ? <FilledDot /> : <UnfilledDot />}</>
        ))}
      </DotContainer>
      {paused ? (
        <PlayIcon onClick={() => onToggle()} />
      ) : (
        <PauseIcon onClick={() => onToggle()} />
      )}
    </CarouselContainer>
  );
};

export default DotCarousel;
