import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useEffect } from 'react';
import {
  DefaultPopper,
  HeaderPopper,
  MenuList,
  OffsetPopper,
  Paper,
} from './styled';

const PopperMenu = ({
  fixedWidth = null,
  variant = 'offset',
  disableTopPadding,
  ismobilescreen,
  // Have to spell it in lowercase so React doesn't confuse it for a built-in prop
  disablebottompadding,
  open,
  anchorElement,
  onClose,
  children,
  customStyle,
  useThinScrollBar = false,
  placement = 'bottom',
  modifiers = null,
  largeMaxHeight = false,
  removeScroll = false,
  largePaperHeight = false,
}) => {
  // Add scroll event listener to disappear when scroll outside
  useEffect(() => {
    const handleScroll = (event) => {
      // Close the Popover when scrolling outside of it
      if (anchorElement && !anchorElement.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [anchorElement]);

  const modifiersToUse = modifiers ? [modifiers] : {};

  return variant === 'header' ? (
    <HeaderPopper
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      fixedWidth={fixedWidth}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper largePaperHeight={largePaperHeight}>
          <MenuList
            disableTopPadding={disableTopPadding}
            disablebottompadding={disablebottompadding}
          >
            {children}
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </HeaderPopper>
  ) : variant === 'default' ? (
    <DefaultPopper
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      fixedWidth={fixedWidth}
      customStyle={customStyle}
      ismobilescreen={ismobilescreen}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper largePaperHeight={largePaperHeight}>
          <MenuList
            disableTopPadding={disableTopPadding}
            disablebottompadding={disablebottompadding}
            useThinScrollBar={useThinScrollBar}
          >
            {children}
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </DefaultPopper>
  ) : (
    <OffsetPopper
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      fixedWidth={fixedWidth}
      placement={placement}
      modifiers={modifiersToUse}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper largePaperHeight={largePaperHeight}>
          <MenuList
            disableTopPadding={disableTopPadding}
            disablebottompadding={disablebottompadding}
            largeMaxHeight={largeMaxHeight}
            removeScroll={removeScroll}
          >
            {children}
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </OffsetPopper>
  );
};

export default PopperMenu;
