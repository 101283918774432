import React from 'react';
import {
  LogoCarouselContainer,
  LogoSlide,
  LogoSlideTrack,
  Slider,
} from '../styles/style-Homepage';

import {
  HomepageBoulevardLogo,
  HomepageFacebookAdsLogo,
  HomepageGoHighLevelLogo,
  HomepageGoogleAdsLogo,
  HomepageHubspotLogo,
  HomepageMeevoLogo,
  HomepageMindBodyLogo,
  HomepageNextechLogo,
  HomepageSnowflakeLogo,
  HomepageSquareLogo,
  HomepageZenotiLogo,
  PartnersContainer,
} from '../styles/style-Integrations';

const Integrations = () => {
  return (
    <>
      <p>
        &nbsp;
        <PartnersContainer>
          <LogoCarouselContainer>
            <Slider>
              <LogoSlideTrack>
                <LogoSlide fixedWidth>
                  <HomepageZenotiLogo />
                  Zenoti
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageMindBodyLogo />
                  MindBody
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageBoulevardLogo />
                  Boulevard
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageNextechLogo />
                  Nextech
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageMeevoLogo />
                  Meevo
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageGoHighLevelLogo />
                  GoHighLevel
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageHubspotLogo />
                  Hubspot
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageGoogleAdsLogo />
                  Google Ads
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageFacebookAdsLogo />
                  FB Ads
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageSnowflakeLogo />
                  Snowflake
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageSquareLogo />
                  Square
                </LogoSlide>
                {/* repeat */}
                <LogoSlide fixedWidth>
                  <HomepageZenotiLogo />
                  Zenoti
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageMindBodyLogo />
                  MindBody
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageBoulevardLogo />
                  Boulevard
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageNextechLogo />
                  Nextech
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageMeevoLogo />
                  Meevo
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageGoHighLevelLogo />
                  GoHighLevel
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageHubspotLogo />
                  Hubspot
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageSnowflakeLogo />
                  Snowflake
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageGoogleAdsLogo />
                  Google Ads
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageFacebookAdsLogo />
                  FB Ads
                </LogoSlide>
                <LogoSlide fixedWidth>
                  <HomepageSquareLogo />
                  Square
                </LogoSlide>
              </LogoSlideTrack>
            </Slider>
          </LogoCarouselContainer>
        </PartnersContainer>
      </p>
    </>
  );
};

export default Integrations;
