import { Text } from '@visx/text';
import { defaultStyles as defaultTooltipStyles } from '@visx/tooltip';
import styled, { css } from 'styled-components';
import { ToolbarDivider } from '../../components/NavBar/Header/styled';
import {
  AutoHorizontalMargins,
  BoxShadowStyle,
  CapitalIInfoTextIcon,
  CardStyle,
  CenterFlexStyle,
  ColumnCenteredDiv,
  ColumnDiv,
  ColumnFlexStyle,
  EssGapStyle,
  ExtraSmallGapStyle,
  FullWidthCenteredDiv as FWCD,
  LightTinyText,
  MediumDarkExtraSmallText,
  MediumGapStyle,
  PrimaryButton,
  SMGapStyle,
  SmallText,
  TextStyle,
  UnderlineStyle,
} from '../../styles/shared-styled-components';
import {
  cancelledStatusKey,
  completeStatusKey,
  scheduledStatusKey,
} from '../../utils/constants';

export const DataDivBottomMargin = 40;

export const DataDiv = styled(ColumnDiv)`
  margin-bottom: ${DataDivBottomMargin}px;
`;

export const TopSectionContainer = styled.div`
  ${CardStyle}
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => !props.useDynamicHeight && '100px'};
  flex-wrap: wrap;
  padding: ${(props) =>
    props.useLargePadding
      ? '40px 0px'
      : `${props.theme.spacing.smallContainerPadding}px 0px`};
  margin-bottom: 30px;
  margin-top: 10px;
  border: ${(props) => props.hideBorder && 'none'};
  width: 100%;
  ${AutoHorizontalMargins}
  position: relative;
`;

export const PanelDivider = styled(ToolbarDivider)`
  display: flex;
  height: 40%;
  width: 0px;
  margin: 0px 5px;
`;

export const TopSectionPanel = styled.div`
  ${ColumnFlexStyle}
  align-items: ${(props) =>
    props.position === 'start'
      ? 'flex-start'
      : props.position === 'end'
      ? 'flex-end'
      : 'center'};
  justify-content: flex-end;
  gap: 2px;
  flex: ${(props) =>
    props.position === 'start' || props.position === 'end' ? 6 : 7};
  position: relative;
  padding: 20px 30px;
`;

export const ButtonContainer = styled(ColumnCenteredDiv)`
  margin-top: 15px;
  ${MediumGapStyle}
`;

export const SingleButtonContainer = styled(ButtonContainer)`
  margin-top: 5px;
`;

export const IncompleteButton = styled(PrimaryButton)`
  min-width: min(410px, 87%);
`;

export const CompleteButton = styled(PrimaryButton)`
  min-width: min(410px, 87%);
  background-color: ${({ theme }) => theme.color.brightGreen};
  &:hover {
    background-color: ${(props) => props.theme.color.lightGreen};
  }
`;

export const FieldTitleText = styled(MediumDarkExtraSmallText)`
  text-align: ${(props) =>
    props.position === 'start'
      ? 'start'
      : props.position === 'end'
      ? 'end'
      : 'center'};
  line-height: 1;
  margin-top: 3px;
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
      &:hover {
        ${UnderlineStyle}
      }
    `}
`;

export const FieldValueText = styled(SmallText)`
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
      &:hover {
        ${UnderlineStyle}
      }
    `}
`;

export const InfoIcon = styled(CapitalIInfoTextIcon)`
  margin-left: ${(props) => (props.largeLeftMargin ? `10px` : `5px`)};
  color: ${({ theme }) => theme.color.lightPrimary};
  height: 18px;
  width: ${(props) => (props.small ? `16px` : `20px`)};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const MinChartSectionHeight = 300;

export const ChartSectionLoadingIndicatorContainer = styled(FWCD)`
  min-height: ${MinChartSectionHeight}px;
`;

export const ChartSectionContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing.smallContainerPadding}px`};
  border: 0.5px dotted ${({ theme }) => theme.color.muiInput};
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  min-height: ${MinChartSectionHeight}px;
  margin-top: 15px;
  ${BoxShadowStyle}
  height: 100%;
  width: 90%;
  ${AutoHorizontalMargins}
`;

export const ChartContainer = styled(ColumnDiv)`
  ${SMGapStyle}
`;

export const mediumDarkText = '#645a56';
export const primary = '#7161a0';
export const teal = '#83b0b6';
export const royalBlue = '#6c5efb';
export const lavender = '#a482c2';
export const skyBlue = '#6fb6d6';
export const mauve = '#b984a0';
export const amethyst = '#7e6ca8';
const lightBlue = '#83d1e2';
const violet = '#8b77a7';
const steelBlue = '#5e83b0';
export const purple1 = '#7161a0';
export const purple2 = '#6c5efb';
export const purple3 = '#a482c2';
const secondary = '#b984a0';
const dodgerBlue = '#3498db';
export const alizarinCrimson = '#e74c3c';
export const emerald = '#2ecc71';
export const orange = '#f39c12';
export const turquoise = '#1abc9c';
export const carrot = '#e67e22';
export const wetAsphalt = '#34495e';
const pumpkin = '#d35400';
export const nephritis = '#27ae60';

export const colorScheme = [
  skyBlue,
  lightBlue,
  dodgerBlue,
  purple2,
  teal,
  primary,
  lavender,
  nephritis,
  turquoise,
  steelBlue,
  emerald,
  mauve,
];

export const statusColorMap = {
  [completeStatusKey]: emerald,
  [scheduledStatusKey]: royalBlue,
  [cancelledStatusKey]: alizarinCrimson,
};

export const tooltipStyles = {
  ...defaultTooltipStyles,
  width: 180,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 7,
  padding: 10,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
  fontFamily: 'SeldonFont',
  zIndex: 10,
};

export const DataCardMaxWidth = 1100;

export const widerTooltipStyle = {
  ...tooltipStyles,
  width: 240,
};

export const dynamicWidthTooltipStyle = {
  ...tooltipStyles,
  width: 'fit-content',
};

export const widerBenchmarkTooltipStyle = {
  ...widerTooltipStyle,
  backgroundColor: '#7161a0',
};

export const leftAxisTickLabelProps = {
  dx: '-0.25em',
  dy: '0.25em',
  fontFamily: 'Arial',
  fontSize: 10,
  textAnchor: 'end',
  fill: primary,
};

export const FilterSectionContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.spaced ? `space-between` : 'flex-end')};
  margin-right: 7px;
  ${EssGapStyle}
  position: relative;
`;

export const FilterContainer = styled.div`
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  border: 1.5px dotted ${({ theme }) => theme.color.muiInput};
  min-width: fit-content;
  height: 30px;
  padding: 3px 30px;
  ${CenterFlexStyle}
  cursor: ${(props) => props.clickable && 'pointer'};
  ${(props) =>
    props.fixedWidth &&
    css`
      min-width: ${(props) => props.fixedWidth && `${props.fixedWidth}px`};
      max-width: ${(props) => props.fixedWidth && `${props.fixedWidth}px`};
    `}
`;

export const FilterContainerWidth = 160;

export const DateFilterContainer = styled.div`
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  border: 1.5px dotted ${({ theme }) => theme.color.muiInput};
  width: ${FilterContainerWidth}px;
  height: 30px;
  padding: 3px 3px;
  ${CenterFlexStyle}
  cursor: ${(props) => props.clickable && 'pointer'};
`;

export const DateFilterText = styled(LightTinyText)`
  ${CenterFlexStyle}
`;

export const FilterText = styled(LightTinyText)`
  ${CenterFlexStyle}
  ${ExtraSmallGapStyle}
`;

export const VisxText = styled(Text)`
  ${TextStyle}
`;

export const ChartTitleContainer = styled.div`
  margin-top: 30px;
`;

export const ChartTitleText = styled(Text)`
  ${TextStyle}
`;
