import { NumberFormat } from './styled';

function NumberFieldFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      // Needs to send in this format for some reason - can't just send value
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator
    />
  );
}

export default NumberFieldFormat;
