import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { MODIFY_LOCATION_PAUSED_STATUS } from '../../../api/mutations/enterprise';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import {
  ColumnCenteredDiv,
  ContentContainer,
  DarkLargeText,
  PageContainer,
} from '../../../styles/shared-styled-components';
import { getUserLocation } from '../../../utils/user';
import { EnableButton, PauseButton } from './styled';

const Settings = () => {
  const { drawerOpen, drawerExpanded, user, refetchUser } =
    useContext(BaseContext);

  const location = getUserLocation(user);
  const isPaused = location.isPaused;

  const [modifyLocationPausedStatusMutation, { loading }] = useMutation(
    MODIFY_LOCATION_PAUSED_STATUS,
  );

  const [snackbarMessage, setSnackbarMessage] = useState();

  const onModifyPausedStatus = () => {
    modifyLocationPausedStatusMutation({
      variables: {
        isPaused: !isPaused,
      },
      onCompleted: async (data) => {
        const success = data.modifyLocationPausedStatus;

        if (success) {
          await refetchUser();
          setSnackbarMessage(`Status updated`);
        } else {
          setSnackbarMessage(`Error updating status`);
        }
      },
    });
  };

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <DarkLargeText>Settings</DarkLargeText>
          <ColumnCenteredDiv>
            {isPaused ? (
              <EnableButton onClick={() => onModifyPausedStatus()}>
                Re-launch agent
              </EnableButton>
            ) : (
              <PauseButton onClick={() => onModifyPausedStatus()}>
                Pause agent
              </PauseButton>
            )}
          </ColumnCenteredDiv>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        quick
      />
    </>
  );
};

export default Settings;
