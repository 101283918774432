import React, { useContext } from 'react';
import {
  QuickSnackbar,
  StyledSnackbar,
  StyledSuccessAlert,
} from '../../styles/shared-styled-components';
import { BaseContext } from '../Auth/AuthRouter/AuthRouter';

const Snackbar = ({
  isOpen,
  onClose,
  message,
  onClick = () => {},
  quick = true,
}) => {
  const { drawerOpen, drawerExpanded } = useContext(BaseContext);

  return (
    <div onClick={onClick}>
      {quick ? (
        <QuickSnackbar
          open={isOpen}
          onClose={onClose}
          drawerExpanded={drawerExpanded}
          drawerOpen={drawerOpen}
        >
          <StyledSuccessAlert onClose={onClose}>{message}</StyledSuccessAlert>
        </QuickSnackbar>
      ) : (
        <StyledSnackbar
          open={isOpen}
          onClose={onClose}
          drawerExpanded={drawerExpanded}
          drawerOpen={drawerOpen}
        >
          <StyledSuccessAlert onClose={onClose}>{message}</StyledSuccessAlert>
        </StyledSnackbar>
      )}
    </div>
  );
};

export default Snackbar;
