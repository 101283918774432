import MuiMenuList from '@mui/material/MenuList';
import BasePaper from '@mui/material/Paper';
import MuiPopper from '@mui/material/Popper';
import styled, { css } from 'styled-components';
import {
  ScrollStyle,
  ThinScrollBarStyle,
} from '../../styles/shared-styled-components';

export const DefaultPopper = styled(MuiPopper)`
  color: white;
  font-family: ${({ theme }) => theme.typography.typeFont};
  z-index: ${({ theme }) => theme.spacing.headerZIndex + 1};
  position: fixed !important;
  bottom: 0;
  right: 0;
  top: unset;
  left: unset;
  width: ${(props) => props.fixedWidth && `${props.fixedWidth}px`};
  .MuiMenu-list {
    border: 1px solid ${({ theme }) => theme.color.lightGrey};
  }
  .MuiPaper-root {
    border-radius: 4px;
  }
  ${(props) =>
    props.customStyle &&
    css`
      ${props.customStyle}
    `}
`;

export const OffsetPopper = styled(MuiPopper)`
  color: ${({ theme }) => theme.color.secondary};
  width: ${(props) => props.fixedWidth && `${props.fixedWidth}px`};
  font-family: ${({ theme }) => theme.typography.typeFont};
  z-index: ${({ theme }) => theme.spacing.headerZIndex + 1000};
  top: 0px !important;
  .MuiMenu-list {
    border: 1px solid ${({ theme }) => theme.color.lightGrey};
    border-radius: 0px 0px 10px 10px !important;
  }
  // Popper menu glitch happening here
`;

export const HeaderPopper = styled(MuiPopper)`
  width: 100%;
  top: 25px !important;
  left: 0px;
  font-family: ${({ theme }) => theme.typography.typeFont};
  z-index: ${({ theme }) => theme.spacing.headerZIndex + 1};
  .MuiList-root {
    display: flex;
    padding: 30px 20px;
    gap: 20px;
  }
`;

export const Paper = styled(BasePaper).attrs({
  elevation: 8,
})`
  height: ${(props) => props.largePaperHeight && '440px'};
`;

export const MenuList = styled(MuiMenuList)`
  padding-top: ${(props) => props.disableTopPadding && '0px'};
  padding-bottom: ${(props) => props.disablebottompadding && '0px'};
  max-height: ${(props) => (props.largeMaxHeight ? '390px' : '365px')};
  ${(props) =>
    props.removeScroll
      ? css``
      : !props.useThinScrollBar
      ? css`
          ${ScrollStyle}
        `
      : css`
          ${ThinScrollBarStyle}
        `};
`;
