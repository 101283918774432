import styled, { css } from 'styled-components';
import { BaseStatusChip } from '../../../../styles/shared-styled-components';
import {
  completeStatusKey,
  inProgressStatusKey,
  scheduledStatusKey,
} from '../../../../utils/constants';

export const CountContainer = styled.div`
  position: absolute;
  top: -30px;
  left: 3px;
`;

export const StatusChip = styled(BaseStatusChip)`
  ${(props) =>
    props.status === inProgressStatusKey
      ? css`
          color: ${({ theme }) => theme.color.disabledText};
          background-color: ${({ theme }) => theme.color.disabled};
        `
      : props.status === completeStatusKey
      ? css`
          color: white;
          background-color: ${({ theme }) => theme.color.brightGreen};
        `
      : props.status === scheduledStatusKey
      ? css`
          color: white;
          background-color: ${({ theme }) => theme.color.blue};
        `
      : css`
          color: white;
          background-color: ${({ theme }) => theme.color.lightError};
        `}
  min-width: ${(props) => props.fixedMinWidth && `${props.fixedMinWidth}px`};
  margin-left: 10px;
  margin-top: 3px;
`;
