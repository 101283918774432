import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { CREATE_LOCATION } from '../../../api/mutations/enterprise';
import {
  AddLocationIcon,
  DarkMLText,
  FixedWidthCheckboxContainer,
  InputContainerForMediumGap,
  InputWidthDiv,
  MediumDarkEssText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  SMCheckbox,
  SMGapColumnCenteredDiv,
  TextInputWidthStyle,
} from '../../../styles/shared-styled-components';
import { valueIsEmpty } from '../../../utils/data';
import Input from '../../Form/Input';
import LocationSearchBox from '../../Search/LocationSearchBox/LocationSearchBox';

const AddLocationModal = ({ isOpen, onClose, onAdd }) => {
  const [createLocationMutation, { loading: createLocationMutationLoading }] =
    useMutation(CREATE_LOCATION);

  const [locationName, setLocationName] = useState('');
  const [website, setWebsite] = useState(null);
  const [stagingAddress, setStagingAddress] = useState('');
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [timezone, setTimezone] = useState('');
  const [postalCode, setPostalCode] = useState('');

  const onCloseModal = () => {
    setLocationName();
    setWebsite();
    setStagingAddress();
    setAddress();
    setLat();
    setLng();
    setTimezone();
    setPostalCode();
    onClose();
  };

  const onClickAdd = () => {
    createLocationMutation({
      variables: {
        name: locationName,
        website,
        address,
        lat: lat?.toString(),
        lng: lng?.toString(),
        timezone,
        postalCode,
      },
      onCompleted: async () => {
        await onAdd(false);
        onCloseModal();
      },
    });
  };

  const createEnabled =
    !valueIsEmpty(locationName) &&
    (website === null || !valueIsEmpty(website)) &&
    !valueIsEmpty(address) &&
    !valueIsEmpty(lat) &&
    !valueIsEmpty(lng) &&
    !valueIsEmpty(timezone) &&
    !valueIsEmpty(postalCode);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth={830}
    >
      <ModalContainer>
        <SMGapColumnCenteredDiv>
          <AddLocationIcon />
          <DarkMLText>Add Location</DarkMLText>
        </SMGapColumnCenteredDiv>
        <Input
          id='location-name'
          value={locationName}
          onChange={(e) => {
            setLocationName(e.target.value);
          }}
          label='Name'
          removeGap
        />
        <LocationSearchBox
          customLabel={'Address'}
          value={stagingAddress}
          onChange={(newStagingAddress) => {
            setStagingAddress(newStagingAddress);
            if (!newStagingAddress) {
              setAddress('');
              setLat('');
              setLng('');
              setTimezone('');
              setPostalCode('');
            }
          }}
          onEnter={(newAddress, newLat, newLng, newTimezone, newPostalCode) => {
            setStagingAddress(newAddress);
            setAddress(newAddress);
            setLat(newLat);
            setLng(newLng);
            setTimezone(newTimezone);
            setPostalCode(newPostalCode);
          }}
          customPopperMenuStyle={TextInputWidthStyle}
        />
        <InputContainerForMediumGap removeMargin>
          <InputWidthDiv>
            <FixedWidthCheckboxContainer fullWidth>
              <MediumDarkEssText>
                Use same website as core business
              </MediumDarkEssText>
              <SMCheckbox
                checked={website === null}
                onClick={() => {
                  if (website === null) {
                    setWebsite('');
                  } else {
                    setWebsite(null);
                  }
                }}
              />
            </FixedWidthCheckboxContainer>
          </InputWidthDiv>
          {website !== null && (
            <Input
              id='website'
              value={website}
              onChange={(e) => {
                setWebsite(e.target.value);
              }}
              label='Website'
              useExtraSmallGap
            />
          )}
        </InputContainerForMediumGap>
        <ModalActions>
          <MediumPrimaryButton
            onClick={onClickAdd}
            disabled={!createEnabled}
          >
            Add
          </MediumPrimaryButton>
          <MediumSecondaryButton onClick={onCloseModal}>
            Cancel
          </MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default AddLocationModal;
