import styled from 'styled-components';
import { BackgroundContainer } from '../../../pages-website/Homepage/styles/style-Homepage';
import {
  ColumnCenteredDiv,
  LargeGapStyle,
} from '../../../styles/shared-styled-components';

export const StyledBackgroundContainer = styled(BackgroundContainer)`
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.color.charcoal},
    ${({ theme }) => theme.color.primary}
  );
`;

export const AuthSection = styled(ColumnCenteredDiv)`
  ${LargeGapStyle}
  margin-top: 100px;
`;
