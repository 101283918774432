import { RiGitBranchFill } from 'react-icons/ri';
import ReactFlow, { Handle } from 'reactflow';
import styled, { css } from 'styled-components';
import {
  SaveIcon as BaseSaveIcon,
  CalendarIcon,
  CenterFlexStyle,
  ChatIcon,
  ContentContainer,
  EditIcon,
  EmptyGapColumnCenteredDiv,
  LightDarkEssText,
  TextStyle,
  TinyText,
} from '../../../../styles/shared-styled-components';
import { bookingScriptNodeType } from '../../../../utils/constants';

export const ScriptContentContainer = styled(ContentContainer).attrs({
  topPadding: 10,
})`
  --right-offset: -16px;
  width: calc(100% - var(--right-offset));
  align-items: center;
  position: relative;
`;

export const SaveIcon = styled(BaseSaveIcon)`
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const StyledReactFlow = styled(ReactFlow)`
  .react-flow__node {
    ${TextStyle}
    border-color: ${({ theme }) => theme.color.muiInput};
    box-shadow: none !important;
  }
`;

export const NodeContainer = styled(EmptyGapColumnCenteredDiv)`
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
  ${(props) =>
    props.type === bookingScriptNodeType
      ? css`
          border: ${(props) => `1px solid ${props.theme.color.brightGreen}`};
          border-right: ${(props) =>
            `1px dotted ${props.theme.color.brightGreen}`};
        `
      : css`
          border: ${(props) => `1px solid ${props.theme.color.muiInput}`};
          border-right: ${(props) =>
            `1px dotted ${props.theme.color.muiInput}`};
        `}
  background-color: white;
  color: ${({ theme }) => theme.color.primary};
  min-width: 150px;
  min-height: 40px;
  position: relative;
`;

export const NodeButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 5px;
  gap: 3px;
  cursor: pointer;
`;

export const ButtonIconContainer = styled.div`
  ${CenterFlexStyle}
  padding: 2px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.muiInput};
`;

const IconSizeStyle = css`
  height: 12px;
  width: 12px;
`;

export const TypeIconContainer = styled(ButtonIconContainer)`
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: default;
`;

export const MessageTypeIcon = styled(ChatIcon)`
  color: ${({ theme }) => theme.color.turquoise};
  ${IconSizeStyle}
`;

export const ConditionalIcon = styled(RiGitBranchFill)`
  color: ${({ theme }) => theme.color.orange};
  ${IconSizeStyle}
`;

export const BookingIcon = styled(CalendarIcon)`
  color: ${({ theme }) => theme.color.brightGreen};
  ${IconSizeStyle}
  margin-top: unset;
  cursor: default;
  &:hover {
    color: ${({ theme }) => theme.color.brightGreen};
  }
`;

export const EditIconContainer = styled(ButtonIconContainer)`
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    background-color: ${({ theme }) => theme.color.lightPrimary};
    color: white;
  }
`;

export const EditNodeIcon = styled(EditIcon)`
  ${IconSizeStyle}
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

export const StyledHandle = styled(Handle)`
  background-color: ${({ theme }) => theme.color.superLightPrimary};
  height: 1px;
  width: 1px;
`;

export const NodeText = styled(TinyText)`
  color: ${({ theme }) => theme.color.muiInputDarkGray};
`;

export const ContentPreviewContainer = styled(NodeContainer)`
  border-radius: 0px 10px 10px 0px;
  background-color: white;
  color: ${({ theme }) => theme.color.primary};
  min-height: 40px;
  position: relative;
  ${(props) =>
    props.type === bookingScriptNodeType
      ? css`
          border: ${(props) => `1px solid ${props.theme.color.brightGreen}`};
        `
      : css`
          border: ${(props) => `1px solid ${props.theme.color.muiInput}`};
        `}
  border-left: 0px;
  width: 100px;
  min-width: unset;
`;

export const ContentPreviewText = styled(LightDarkEssText)`
  font-size: 8px;
  line-height: 1.2;
`;
