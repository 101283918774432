import { sortBy } from 'lodash';
import React, { useState } from 'react';
import {
  AutoScrollWrapper,
  CenteredDiv,
  CenteredDivWithGap,
  ExpandViewIcon,
  LightDarkEssText,
  StartAlignedMediumDarkEssText,
  TinyGapColumnCenteredDiv,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  getFormattedFullNameFromUser,
  getFormattedGender,
} from '../../../../utils/name';
import LoadingIndicator from '../../../LoadingIndicator';
import SwitchToggle from '../../../Micro/SwitchToggle/SwitchToggle';
import EditStaffModal from '../../../Modals/EditStaffModal/EditStaffModal';
import {
  ListInputSection,
  ListInputTitleContainer,
  OptionActionsContainer,
  OptionIconContainer,
  OptionItemContainer,
  OptionParagraphText,
  OptionSubitemTextContainer,
  PromptContainer,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const displayExpandIcon = true;

const Staff = ({
  staff,
  onUpdate,
  onSave,
  loading,
  agentName,
  remainingHeight,
}) => {
  const [hideUnbookable, setHideUnbookable] = useState(true);
  const [editingStaff, setEditingStaff] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  const sortedStaff = sortBy(staff, (s) => s.firstName);
  const filteredStaff = hideUnbookable
    ? sortedStaff.filter((s) => s.isBookable)
    : sortedStaff;
  const numStaff = filteredStaff?.length;

  return (
    <>
      <PromptsSection hideOverflow>
        <PromptContainer overflow>
          <ListInputTitleContainer addBottomMargin>
            <TinyGapColumnCenteredDiv>
              <PromptTitleText>
                Select which staff {agentName} can book appointments for
              </PromptTitleText>
              <CenteredDivWithGap>
                <LightDarkEssText>
                  {' '}
                  {hideUnbookable
                    ? `Only bookable staff displayed`
                    : `All staff displayed`}{' '}
                  ({numStaff})
                </LightDarkEssText>
                <SwitchToggle
                  value={hideUnbookable}
                  onChange={() => setHideUnbookable(!hideUnbookable)}
                />
              </CenteredDivWithGap>
            </TinyGapColumnCenteredDiv>
          </ListInputTitleContainer>
          <ListInputSection height={remainingHeight}>
            <AutoScrollWrapper disableMaxHeight>
              {filteredStaff.map((staffObject, idx) => {
                const { isBookable } = staffObject;
                return (
                  <OptionItemContainer
                    bottom={idx === numStaff - 1}
                    widthPercentage={95}
                  >
                    <OptionSubitemTextContainer>
                      <StartAlignedMediumDarkEssText start>
                        {getFormattedFullNameFromUser(staffObject)} (
                        {getFormattedGender(staffObject?.gender)})
                      </StartAlignedMediumDarkEssText>
                      <OptionParagraphText
                        success={isBookable}
                        fail={!isBookable}
                      >
                        {isBookable ? `Bookable` : `Unbookable`}
                      </OptionParagraphText>
                    </OptionSubitemTextContainer>
                    <OptionActionsContainer>
                      <SwitchToggle
                        value={isBookable}
                        onChange={() =>
                          onUpdate({
                            ...staffObject,
                            isBookable: !isBookable,
                          })
                        }
                      />
                      {displayExpandIcon && (
                        <OptionIconContainer>
                          <Tooltip
                            title={<TooltipTitleText>Expand</TooltipTitleText>}
                          >
                            <CenteredDiv
                              onClick={() => {
                                setEditingStaff(staffObject);
                                setEditModalOpen(true);
                              }}
                            >
                              <ExpandViewIcon />
                            </CenteredDiv>
                          </Tooltip>
                        </OptionIconContainer>
                      )}
                    </OptionActionsContainer>
                  </OptionItemContainer>
                );
              })}
            </AutoScrollWrapper>
          </ListInputSection>
        </PromptContainer>
      </PromptsSection>
      <EditStaffModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        staff={editingStaff}
        onSave={onSave}
      />
    </>
  );
};

export default Staff;
