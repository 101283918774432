import styled from 'styled-components';
import {
  BaseLogo,
  BoulevardLogo,
  HubspotLogo,
  MindBodyLogo,
  NextechLogo,
  SnowflakeLogo,
  ZenotiLogo,
} from '../../../styles/shared-styled-components';

export const PartnersContainer = styled.div`
  letter-spacing: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
  margin-top: 30px;

  @media (max-width: 1024px) {
    font-size: 22px;
    margin-top: 80px;
  }

  @media (max-width: 576px) {
    font-size: 18px;
    margin-top: 60px;
  }
`;

export const HomepageZenotiLogo = styled(ZenotiLogo)`
  height: 27px;
  width: auto;
`;

export const HomepageMindBodyLogo = styled(MindBodyLogo)`
  height: 40px;
  width: auto;
`;

export const HomepageBoulevardLogo = styled(BoulevardLogo)`
  width: auto;
  height: 34px;
`;

export const HomepageNextechLogo = styled(NextechLogo)`
  width: auto;
  height: 40px;
`;

export const HomepageHubspotLogo = styled(HubspotLogo)`
  height: 40px;
  width: auto;
`;

export const HomepageSnowflakeLogo = styled(SnowflakeLogo)`
  height: 40px;
  width: auto;
`;

export const HomepageSquareLogo = styled(BaseLogo).attrs({
  src: 'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/logos/homepage-diagram/square-logo.png',
})`
  height: 40px;
  width: auto;
`;

export const HomepageGoogleAdsLogo = styled(BaseLogo).attrs({
  src: 'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/logos/homepage-diagram/google-ads-icon.png',
})`
  height: 40px;
  width: auto;
`;

export const HomepageFacebookAdsLogo = styled(BaseLogo).attrs({
  src: 'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/logos/homepage-diagram/facebook-ads-icon.png',
})`
  height: 40px;
  width: auto;
`;

export const HomepageGoHighLevelLogo = styled(BaseLogo).attrs({
  src: 'https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/logos/homepage-diagram/gohighlevel-icon.png',
})`
  height: 40px;
  width: auto;
`;

export const HomepageMeevoLogo = styled.img.attrs({
  src: 'https://i.ibb.co/YQ1r3Hs/meevo-logo.jpg',
})`
  border-radius: 50%;
  height: 40px;
  width: auto;
`;
