import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ImageIcon from '@mui/icons-material/Image';
import styled from 'styled-components';
import {
  CenterFlexStyle,
  ChatIcon,
  ColumnFlexStyle,
  EssGapStyle,
  MediumDarkSMText,
  MoreMenuIcon,
  PrimaryButton,
  PrimaryTextButton,
  ScrollStyle,
  SmallGapStyle,
} from '../../../styles/shared-styled-components';

const chartHorizontalPadding = 30;
const ghostChartLeftMargin = 20;
const leftMargin = chartHorizontalPadding - ghostChartLeftMargin;

export const ChartHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChartTitleText = styled(MediumDarkSMText)`
  text-align: start;
  margin-left: ${leftMargin}px;
`;

export const ChartActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${EssGapStyle}
`;

export const ChartCommentsContainer = styled.div`
  position: relative;
  cursor: default;
  margin-top: 7px;
`;

export const ChartCommentsIcon = styled(ChatIcon)`
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
  height: 22px;
  width: auto;
`;

export const ChartNumCommentsContainer = styled.div`
  position: absolute;
  top: -9px;
  right: -6px;
  font-family: ${({ theme }) => theme.typography.baseFont};
  font-size: 10px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  ${CenterFlexStyle}
  border: 1px solid ${(props) =>
    props.numComments > 0
      ? props.theme.color.turquoise
      : props.theme.color.defaultGray};
  z-index: 10;
  background-color: ${(props) =>
    props.numComments > 0
      ? props.theme.color.turquoise
      : props.theme.color.defaultGray};
  cursor: pointer;
`;

export const ChartNumCommentsText = styled.span`
  color: white;
  font-family: ${({ theme }) => theme.typography.baseFont};
  font-size: 10.5px;
  width: 100%;
  ${CenterFlexStyle}
`;

export const ChartMoreMenuIcon = styled(MoreMenuIcon)`
  color: white;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.lightPrimary};
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin-top: -1px;
`;

export const ExportChartIcon = styled(ImageIcon)`
  color: ${({ theme }) => theme.color.lightText};
`;

export const ExportCSVIcon = styled(FileDownloadIcon)`
  color: ${({ theme }) => theme.color.lightText};
`;

export const DataMagicIcon = styled(AutoFixNormalIcon)`
  color: ${({ theme }) => theme.color.lightText};
`;

export const UndoDataMagicIcon = styled(AutoFixOffIcon)`
  color: ${({ theme }) => theme.color.lightText};
`;

export const CommentsContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  width: 280px;
  position: absolute;
  right: 0px;
  z-index: 1000;
  top: 20px;
  background-color: white;
`;

export const CommentsListContainer = styled.div`
  height: fit-content;
  overflow-y: scroll;
  ${ScrollStyle}
  z-index: 100;
  max-height: 200px;
`;

export const CommentCardContainer = styled.div`
  ${ColumnFlexStyle}
  ${SmallGapStyle}
  padding: 15px;
  font-family: ${({ theme }) => theme.typography.baseFont};
  color: ${({ theme }) => theme.color.mediumDarkText};
  font-size: 15px;
`;

export const CommentCardTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${SmallGapStyle}
`;

export const CommentCardAvatar = styled.div`
  height: 33px;
  width: 33px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  color: white;
  ${CenterFlexStyle}
`;

export const CommentCardHeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CommentDateText = styled.div`
  color: ${({ theme }) => theme.color.lightDarkText};
  font-size: 12px;
`;

export const CommentCardContent = styled.div`
  width: 100%;
  font-size: 14px;
`;

export const EmptyCommentsContainer = styled.div`
  display: flex;
  height: fit-content;
  justify-content: flex-start;
  align-items: center;
`;

export const EmptyCommentsText = styled.p`
  font-family: ${({ theme }) => theme.typography.baseFont};
  color: ${({ theme }) => theme.color.mediumDarkText};
  font-size: 15px;
  padding-left: 10px;
`;

export const CommentsActionContainer = styled.div`
  ${ColumnFlexStyle}
  border-top: 1px solid ${({ theme }) => theme.color.muiInput};
`;

export const CommentTypeContainer = styled.input.attrs({ type: 'text' })`
  margin: 10px 5px;
  padding: 5px;
  font-family: ${({ theme }) => theme.typography.baseFont};
  color: ${({ theme }) => theme.color.mediumDarkText};
  cursor: text;
  outline-color: transparent;
  border: 0px;
`;

export const CommentsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  padding: 4px 10px;
  gap: 2px;
`;

export const PostCommentButton = styled(PrimaryButton)`
  min-width: unset;
  font-size: 15px;
  padding: 1px 10px;
  margin: 0px;
`;

export const CancelButton = styled(PrimaryTextButton)`
  min-width: unset;
  font-size: 15px;
  padding: 1px 10px;
  margin: 0px;
`;
