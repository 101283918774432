import React from 'react';
import {
  AutoScrollWrapper,
  StartAlignedMediumDarkEssText,
} from '../../../../styles/shared-styled-components';
import {
  cancelAppointmentTaskKey,
  cancelMembershipTaskKey,
  createAppointmentTaskKey,
  createMembershipTaskKey,
  dropdownInputType,
  faqTaskKey,
  membershipInquiryTaskKey,
  phoneDeploymentSettingOptions,
  phoneTaskOptions,
  rescheduleAppointmentTaskKey,
  updatePatientCharts,
} from '../../../../utils/constants';
import LoadingIndicator from '../../../LoadingIndicator';
import SwitchToggle from '../../../Micro/SwitchToggle/SwitchToggle';
import {
  ListInputContainer,
  ListInputSection,
  ListInputTitleContainer,
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const Deployment = ({ agentName, agentSettings, onChange, loading }) => {
  const openingMessage = agentSettings?.phoneOpeningMessage || '';
  const deploymentMode = agentSettings?.phoneDeploymentMode || '';

  const enabledTasks = {
    [faqTaskKey]: true,
    [createAppointmentTaskKey]: agentSettings?.createAppts,
    [rescheduleAppointmentTaskKey]: agentSettings?.rescheduleAppts,
    [cancelAppointmentTaskKey]: agentSettings?.cancelAppts,
    [updatePatientCharts]: agentSettings?.chartNotes,
    [membershipInquiryTaskKey]: agentSettings?.explainMemberships,
    [createMembershipTaskKey]: agentSettings?.createMemberships,
    [cancelMembershipTaskKey]: agentSettings?.cancelMemberships,
  };

  const onChangeTaskEnabled = (key, enabled) => {
    const updatedEnabledTasks = { ...enabledTasks };
    if (enabled) {
      updatedEnabledTasks[key] = true;
    } else {
      updatedEnabledTasks[key] = false;
    }
    console.log('updatedEnabledTasks', updatedEnabledTasks);

    onChange({
      ...agentSettings,
      ...updatedEnabledTasks,
    });
  };

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer>
        <PromptInput
          value={deploymentMode}
          onChange={(e) => {
            onChange({
              ...agentSettings,
              phoneDeploymentMode: e.target.value,
            });
          }}
          type={dropdownInputType}
          options={phoneDeploymentSettingOptions}
          label='Deployment mode'
        />
      </PromptContainer>
      <PromptContainer overflow>
        <ListInputTitleContainer>
          <PromptTitleText>
            Select what {agentName} can do over the phone
          </PromptTitleText>
        </ListInputTitleContainer>
        <ListInputSection widthPercentage={60}>
          <AutoScrollWrapper>
            {phoneTaskOptions.map((type) => {
              const { key, label } = type;
              const included = !!enabledTasks[key];
              return (
                <ListInputContainer>
                  <StartAlignedMediumDarkEssText>
                    {label}
                  </StartAlignedMediumDarkEssText>
                  <SwitchToggle
                    value={included}
                    onChange={() => onChangeTaskEnabled(key, !included)}
                    disabled={key === faqTaskKey}
                  />
                </ListInputContainer>
              );
            })}
          </AutoScrollWrapper>
        </ListInputSection>
      </PromptContainer>
    </PromptsSection>
  );
};

export default Deployment;
