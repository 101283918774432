import React from 'react';
import {
  dropdownInputType,
  percentInputType,
} from '../../../../utils/constants';
import { safeParseInt } from '../../../../utils/numbers';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const slotTypes = [
  {
    key: 'cancellations',
    label: 'Only cancellations',
  },
  {
    key: 'all',
    label: 'All available slots',
  },
];

const LastMinute = ({ policy, onChange, loading }) => {
  const lastMinutePolicy = policy?.lastMinute || {};
  const maxDiscount = lastMinutePolicy?.maxDiscount || '';
  const slotType = lastMinutePolicy?.slotType;
  const bufferHoursRequired = lastMinutePolicy?.bufferHoursRequired || '';

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer>
        <PromptInput
          value={maxDiscount}
          onChange={(e) => {
            onChange({
              ...lastMinutePolicy,
              maxDiscount: safeParseInt(e.target.value),
            });
          }}
          label='Max discount offered'
          type={percentInputType}
        />
      </PromptContainer>
      <PromptContainer>
        <PromptInput
          value={slotType}
          onChange={(e) => {
            onChange({
              ...lastMinutePolicy,
              slotType: e.target.value,
            });
          }}
          type={dropdownInputType}
          options={slotTypes}
          label='Slots to replace'
        />
      </PromptContainer>
      <PromptContainer>
        <PromptTitleText>Notice hours required for booking</PromptTitleText>
        <PromptInput
          value={bufferHoursRequired}
          onChange={(e) => {
            onChange({
              ...policy,
              bufferHoursRequired: safeParseInt(e.target.value),
            });
          }}
        />
      </PromptContainer>
    </PromptsSection>
  );
};

export default LastMinute;
