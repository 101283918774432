import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import { sortChatMessages } from '../../utils/chat';
import { emptyStringValue } from '../../utils/constants';
import {
  GET_CHAT,
  GET_CHAT_EVENTS,
  GET_MY_LOCATION_CHATS,
} from '../queries/chat';

export const useChat = ({ chatId }) => {
  const { data, error, loading, refetch } = useQuery(GET_CHAT, {
    fetchPolicy: 'network-only',
    skip: !chatId || chatId?.toString() === emptyStringValue,
    variables: {
      chatId,
    },
  });

  const chat = get(data, 'getChat', null);

  let formattedChat;
  if (chat) {
    const sortedMessages = sortChatMessages(chat);
    formattedChat = {
      ...chat,
      messages: sortedMessages,
    };
  } else {
    formattedChat = null;
  }

  return {
    error,
    loading,
    refetch,
    chat: formattedChat,
  };
};

export const useMyChats = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_LOCATION_CHATS, {
    fetchPolicy: 'network-only',
    skip: skipCondition,
  });

  const chats = get(data, 'getMyLocationChats', []);

  return {
    error,
    loading,
    refetch,
    chats,
  };
};

export const useMyChatEvents = ({ skipCondition, cursor, contactName }) => {
  const { data, error, loading, refetch } = useQuery(GET_CHAT_EVENTS, {
    fetchPolicy: 'network-only',
    skip: skipCondition,
    variables: {
      cursor,
      contactName,
    },
  });

  const events = get(data, 'getChatEvents', []);

  return {
    error,
    loading,
    refetch,
    events,
  };
};
