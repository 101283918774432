import styled from 'styled-components';
import {
  CenterFlexStyle,
  SMGapColumnCenteredDiv,
  SecondaryButton,
} from '../../../styles/shared-styled-components';

export const ConnectAppsContainer = styled(SMGapColumnCenteredDiv)`
  margin-top: 40px;
`;

export const ProceedToSiteButtonContainer = styled.div`
  ${CenterFlexStyle}
  margin-top: 40px;
  border-top: 2px dotted ${({ theme }) => theme.color.muiInput};
  padding: 30px 60px;
`;

export const ProceedToSiteButton = styled(SecondaryButton)`
  cursor: pointer;
`;
