import { useMutation } from '@apollo/client';
import React from 'react';
import { EDIT_CAMPAIGN } from '../../../api/mutations/campaign';
import {
  CenteredDiv,
  ColumnDivWithGap,
  ExclamationIcon,
  MediumDarkMediumText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalContainer,
} from '../../../styles/shared-styled-components';
import {
  liveStatusKey,
  pausedStatusKey,
  stagingStatusKey,
} from '../../../utils/constants';
import LoadingIndicator from '../../LoadingIndicator';

const EditCampaignStatusModal = ({ isOpen, onClose, campaign, onEdit }) => {
  const [editCampaignMutation, { loading: editCampaignLoading }] =
    useMutation(EDIT_CAMPAIGN);
  const status = campaign?.status;

  const handleEdit = (campaign, currentStatus) => {
    if (campaign) {
      const statusToChangeTo =
        currentStatus === liveStatusKey ? pausedStatusKey : liveStatusKey;
      editCampaignMutation({
        variables: {
          ...campaign,
          status: statusToChangeTo,
        },
        onCompleted: async (data) => {
          const success = data.editCampaign.success;
          if (success) {
            onEdit();
          }
        },
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth={830}
    >
      <ModalContainer>
        <CenteredDiv>
          <ExclamationIcon />
        </CenteredDiv>
        <MediumDarkMediumText>
          Are you sure you want to{' '}
          {status === liveStatusKey
            ? 'pause'
            : status === pausedStatusKey
            ? 'relaunch'
            : status === stagingStatusKey
            ? 'launch'
            : 'edit'}{' '}
          the "{campaign?.name?.trim()}" campaign?
        </MediumDarkMediumText>
        {editCampaignLoading ? (
          <LoadingIndicator />
        ) : (
          <ColumnDivWithGap>
            <MediumPrimaryButton onClick={() => handleEdit(campaign, status)}>
              {status === liveStatusKey
                ? 'Pause'
                : status === pausedStatusKey
                ? 'Relaunch'
                : status === stagingStatusKey
                ? 'Launch'
                : 'Edit'}
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={onClose}>
              Cancel
            </MediumSecondaryButton>
          </ColumnDivWithGap>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default EditCampaignStatusModal;
