import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Checkbox,
  Chip,
  ChipCancelIcon,
  ContainerWithInputWidth,
  Dropdown,
  DropdownLabel,
  ExtraSmallAddIcon,
  ExtraSmallSecondaryButton,
  Form,
  FormControlLabel,
  FormGroup,
  MediumGapColumnCenteredDiv,
  Stack,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkExtraSmallText,
} from '../../../../styles/shared-styled-components';
import {
  appointmentBookedContactEventKey,
  appointmentCancelledContactEventKey,
  appointmentRescheduledContactEventKey,
  callbackRequestedContactEventKey,
  phoneInputType,
} from '../../../../utils/constants';
import Input from '../../../Form/Input';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  PromptContainer,
  PromptInput,
  PromptsSection,
} from '../../shared-training-components';

const contactEventOptions = [
  {
    key: appointmentBookedContactEventKey,
    label: 'Appointment created',
  },
  {
    key: appointmentRescheduledContactEventKey,
    label: 'Appointment rescheduled',
  },
  {
    key: appointmentCancelledContactEventKey,
    label: 'Appointment cancelled',
  },
  {
    key: callbackRequestedContactEventKey,
    label: 'Callback requested',
  },
];
const contactEventOptionsLabelMap = {};
contactEventOptions.map((e) => {
  contactEventOptionsLabelMap[e.key] = e.label;
});

const Contact = ({ agentName, location, onChange, loading }) => {
  const businessPhoneNumberForClient =
    location?.businessPhoneNumberForClient || '';
  const contactPhoneNumbers = location?.contactPhoneNumbers || [];
  const numNumbers = contactPhoneNumbers.length;
  const notificationPreference = location?.notificationPreference || {};
  const contactEvents = notificationPreference?.contactEvents || [];

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer addMediumGap>
        <PromptInput
          value={businessPhoneNumberForClient}
          onChange={(updatedPhone) => {
            onChange({
              ...location,
              businessPhoneNumberForClient: updatedPhone,
            });
          }}
          label='Main business phone number for clients'
          type={phoneInputType}
        />
      </PromptContainer>
      <PromptContainer>
        <Input
          id='contact-events'
          value={contactEvents}
          removeGap
          useSmallWidth
          CustomInputComponent={
            <Form
              fitContentHeight
              useSmallWidth
            >
              <DropdownLabel>When should {agentName} text you?</DropdownLabel>
              <Dropdown
                multiple
                useSmallWidth
                value={contactEvents}
                label={`When should ${agentName} text you?`}
                renderValue={(selected) => {
                  return (
                    <Stack>
                      {Object.values(selected)?.map((selectedEvent) => {
                        return (
                          <Chip
                            key={selectedEvent}
                            label={contactEventOptionsLabelMap[selectedEvent]}
                            onDelete={() => {
                              onChange({
                                ...location,
                                notificationPreference: {
                                  ...notificationPreference,
                                  contactEvents: contactEvents.filter(
                                    (e) => e !== selectedEvent,
                                  ),
                                },
                              });
                            }}
                            deleteIcon={
                              <ChipCancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        );
                      })}
                    </Stack>
                  );
                }}
              >
                {contactEventOptions.map((o) => {
                  const { key, label } = o;
                  const checked = contactEvents.includes(key);
                  return (
                    <>
                      <FormGroup key={key}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onClick={() => {
                                if (!checked) {
                                  onChange({
                                    ...location,
                                    notificationPreference: {
                                      ...notificationPreference,
                                      contactEvents: [...contactEvents, key],
                                    },
                                  });
                                } else {
                                  onChange({
                                    ...location,
                                    notificationPreference: {
                                      ...notificationPreference,
                                      contactEvents: contactEvents.filter(
                                        (e) => e !== key,
                                      ),
                                    },
                                  });
                                }
                              }}
                            />
                          }
                          label={label}
                        />
                      </FormGroup>
                    </>
                  );
                })}
              </Dropdown>
            </Form>
          }
        />
      </PromptContainer>
      <PromptContainer addMediumGap>
        <ContainerWithInputWidth useSmallWidth>
          <StartAlignedMediumDarkExtraSmallText>
            Where should {agentName} text you?
          </StartAlignedMediumDarkExtraSmallText>
          <MediumGapColumnCenteredDiv
            topMargin={25}
            bottomMargin={25}
          >
            {contactPhoneNumbers?.map((contactPhoneNumber, idx) => (
              <div>
                <PromptInput
                  useFullWidth
                  value={contactPhoneNumber.value}
                  onChange={(value) => {
                    onChange({
                      ...location,
                      contactPhoneNumbers: contactPhoneNumbers.map((n) => {
                        if (n.id === contactPhoneNumber.id) {
                          return {
                            ...n,
                            value,
                          };
                        }
                        return n;
                      }),
                    });
                  }}
                  label={`Phone number${
                    numNumbers === 1 ? '' : ` #${idx + 1}`
                  }`}
                  type={phoneInputType}
                  includeDeleteButton
                  onDelete={() => {
                    onChange({
                      ...location,
                      contactPhoneNumbers: contactPhoneNumbers.filter(
                        (n) => n.id !== contactPhoneNumber.id,
                      ),
                    });
                  }}
                />
              </div>
            ))}
          </MediumGapColumnCenteredDiv>
          <StartAlignedFlexDiv
            topMargin={contactPhoneNumbers?.length ? 10 : -30}
          >
            <ExtraSmallSecondaryButton
              disabled={numNumbers === 3}
              onClick={() => {
                onChange({
                  ...location,
                  contactPhoneNumbers: [
                    ...contactPhoneNumbers,
                    { id: uuidv4() },
                  ],
                });
              }}
            >
              Add number <ExtraSmallAddIcon />
            </ExtraSmallSecondaryButton>
          </StartAlignedFlexDiv>
        </ContainerWithInputWidth>
      </PromptContainer>
    </PromptsSection>
  );
};

export default Contact;
