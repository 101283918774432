import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveMonotoneX } from '@visx/curve';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { schemeCategory10 } from 'd3-scale-chromatic';
import React from 'react';
import {
  colorScheme,
  mediumDarkText,
  primary,
  VisxText,
} from '../shared-analytics-styles';

// Sample Data
const data = [
  { time: 'Sep 23-29', series1: 20, series2: 35, series3: 40, series4: 50 },
  { time: 'Sep 30-Oct 6', series1: 40, series2: 30, series3: 50, series4: 70 },
  { time: 'Oct 7-13', series1: 50, series2: 45, series3: 35, series4: 60 },
  { time: 'Oct 14-20', series1: 60, series2: 55, series3: 45, series4: 80 },
  { time: 'Oct 21-Now', series1: 80, series2: 60, series3: 55, series4: 90 },
];

// Colors for the 4 series
const colors = schemeCategory10;

const LineGraph = ({
  data,
  yAxisLabel,
  maxY = 100,
  width = 550,
  height = 300,
  margin = { top: 50, right: 30, bottom: 50, left: 70 },
}) => {
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // Define scales
  const xScale = scaleBand({
    range: [0, xMax],
    domain: data.map((d) => d.time),
    padding: 0.2,
  });

  const yScale = scaleLinear({
    range: [yMax, 0],
    domain: [0, maxY],
  });

  const keys = Object.keys(data?.[0])?.filter((d) => d !== 'time');
  const numColors = colorScheme?.length;
  const lines = keys.map((k, idx) => {
    return {
      key: k,
      color: colorScheme[numColors - idx - 1],
    };
  });

  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={width}
        height={height}
      >
        <VisxText
          x={width / 2}
          y={50}
          fontSize={14}
          textAnchor='middle'
          fill={mediumDarkText}
        >
          {yAxisLabel}
        </VisxText>
        <Group
          top={margin.top}
          left={margin.left}
        >
          <AxisLeft
            scale={yScale}
            tickFormat={(v) => `${v}%`}
            top={-1}
            left={0}
            labelProps={{
              fontSize: 12,
              textAnchor: 'middle',
              fontFamily: 'SeldonFont',
            }}
            tickLabelProps={() => ({
              fontSize: 11,
              textAnchor: 'end',
              fontFamily: 'SeldonFont',
              left: 10,
            })}
            stroke={primary}
            tickStroke={primary}
            hideTicks
          />
          <AxisBottom
            top={yMax}
            scale={xScale}
            hideTicks
            tickLabelProps={() => ({
              fontSize: 11,
              textAnchor: 'middle',
              fontFamily: 'SeldonFont',
            })}
          />
          {lines.map((line) => (
            <LinePath
              key={line.key}
              data={data}
              x={(d) => xScale(d.time) + xScale.bandwidth() / 2}
              y={(d) => yScale(d[line.key])}
              stroke={line.color}
              strokeWidth={2}
              curve={curveMonotoneX}
            />
          ))}
        </Group>
      </svg>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}
      >
        {lines.map((line) => (
          <div
            key={line.key}
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '0 10px',
              fontFamily: 'SeldonFont',
              fontSize: 13,
              color: mediumDarkText,
            }}
          >
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: line.color,
                marginRight: 5,
              }}
            />
            <span>{line.key}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LineGraph;
