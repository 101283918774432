import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Paths from '../../../../Paths';
import { useChat } from '../../../../api/hooks/chat';
import { useAllEnterprises } from '../../../../api/hooks/enterprise';
import { useMyLocationUsers } from '../../../../api/hooks/users';
import {
  END_CHAT,
  INTERCEPT_CHAT,
  RESEND_LAST_MESSAGE,
  RESET_CHAT,
  RESUME_CHAT,
  SEND_MESSAGE,
  TEST_CHAT,
} from '../../../../api/mutations/chat';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import ChatQueryManager from '../../../../components/Chats/ChatQueryManager/ChatQueryManager';
import ClientProfileDrawer from '../../../../components/Clients/ClientProfileDrawer/ClientProfileDrawer';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import MessageQueryManager from '../../../../components/Messages/MessageQueryManager';
import EditChatAppointmentsModal from '../../../../components/Modals/EditChatAppointmentsModal/EditChatAppointmentsModal';
import EndChatModal from '../../../../components/Modals/EndChatModal';
import InterceptChatModal from '../../../../components/Modals/InterceptChatModal/InterceptChatModal';
import ViewChatAppointmentsModal from '../../../../components/Modals/ViewChatAppointmentsModal';
import ViewDepositModal from '../../../../components/Modals/ViewDepositModal/ViewDepositModal';
import Header from '../../../../components/NavBar/Header';
import Snackbar from '../../../../components/Snackbar';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import {
  AppointmentBookingMonitoringIcon,
  ChatActionButton,
  ClientIconButton,
  CollapseIcon,
  ExpandIcon,
  PageContainer,
  PageSubtitleText,
  PageTitleText,
  ShareLinkIcon,
  TextInput,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import { updateChatMessages } from '../../../../utils/cache';
import {
  getChatAppointmentStatus,
  sortChatMessages,
} from '../../../../utils/chat';
import {
  activeChatStatusKey,
  appointmentBookedStatus,
  appointmentDepositRequiredStatus,
  appointmentMonitoringStatus,
  assistantKey,
  chatDemoCookieKey,
  chatDemoTabCookieKey,
  chatLogsCookieKey,
  clientProfileOpenIdCookieKey,
  disengagedChatStatusKey,
  disengagedThenInterceptedChatStatusKey,
  humanAgentKey,
  interceptedChatStatusKey,
  internalContactType,
  singleLocationBusinessType,
  superAdminRole,
  userKey,
} from '../../../../utils/constants';
import { getCookieExpiryObject } from '../../../../utils/date';
import { getFormattedFullNameFromUser } from '../../../../utils/name';
import { chatIdSearchParamKey, getChatPath } from '../../../../utils/routing';
import { copyTextToClipboard } from '../../../../utils/string';
import { chatTab } from '../../ChatDemo/ChatDemo';
import {
  AppointmentBookedIcon,
  BookAppointmentIcon,
  ChatBox,
  ChatClientContainer,
  ChatClientIconContainer,
  ChatContentContainer,
  ChatHeaderContainer,
  ChatSection,
  ChatSectionActionsContainer,
  ContinueChatIcon,
  ContinueChatIconContainer,
  DNDIcon,
  ExpandMessageBoxContainer,
  InternalIconButton,
  ResendMessageIcon,
  ResetChatIcon,
  ResetChatIconContainer,
  TestChatIcon,
} from './styled';

const cookieExpiryObject = getCookieExpiryObject();

const chatLinkCopiedSnackbarMessageText = 'Chat link copied';

const displayChatTest = false;

const Chats = () => {
  const navigate = useNavigate();
  const { cache } = useApolloClient();

  const contentContainerRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    user,
    inDemoMode,
    inMonitoringMode,
    width,
    drawerOpen,
    drawerExpanded,
    cookies,
    setCookie,
    removeCookie,
  } = useContext(BaseContext);

  const { users: locationUsers } = useMyLocationUsers({
    skipCondition: inMonitoringMode,
  });
  const userMap = {};
  locationUsers?.map((u) => {
    userMap[u.id] = getFormattedFullNameFromUser(u);
  });

  const { enterprises } = useAllEnterprises({
    skipCondition: !inMonitoringMode,
  });
  const locationMap = {};
  enterprises?.map((e) => {
    const { business, locations } = e;
    const type = business.type;
    locations.map((l) => {
      const { name, nickname } = l;
      const locationName = nickname || name;
      if (type === singleLocationBusinessType) {
        locationMap[l.id] = locationName;
      } else {
        const businessName = business.name;
        locationMap[l.id] = `${businessName} - ${locationName}`;
      }
    });
  });

  const [hasLoaded, setHasLoaded] = useState(false);

  let chatIdCookie = cookies[chatLogsCookieKey];
  const profileOpenCookie = cookies[clientProfileOpenIdCookieKey];

  const chatIdParam = searchParams?.get(chatIdSearchParamKey);

  const [selectedChatId, setSelectedChatId] = useState(chatIdParam);
  const [chatClientProfileOpenId, setChatClientProfileOpenId] =
    useState(profileOpenCookie);
  const [newMessage, setNewMessage] = useState();
  const [newMessageBoxExpanded, setNewMessageBoxExpanded] = useState(false);
  const [viewChatAppointmentsModalOpen, setViewChatAppointmentsModalOpen] =
    useState(false);
  const [editChatAppointmentsModalOpen, setEditChatAppointmentsModalOpen] =
    useState(false);
  const [depositIdToView, setDepositIdToView] = useState();
  const [interceptChatModalOpen, setInterceptChatModalOpen] = useState(false);
  const [endChatModalOpen, setEndChatModalOpen] = useState(false);
  const [chatIdForBookedAppointment, setChatIdForBookedAppointment] =
    useState();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [remainingHeight, setRemainingHeight] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const {
    chat,
    loading: chatLoading,
    refetch: refetchChat,
  } = useChat({
    chatId: selectedChatId,
  });
  const [sendMessageMutation, { loading: sendMessageMutationLoading }] =
    useMutation(SEND_MESSAGE);
  const [interceptChatMutation, { loading: interceptChatMutationLoading }] =
    useMutation(INTERCEPT_CHAT);
  const [endChatMutation, { loading: endChatMutationLoading }] =
    useMutation(END_CHAT);
  const [resetChatMutation, { loading: resetChatMutationLoading }] =
    useMutation(RESET_CHAT);
  const [resumeChatMutation, { loading: resumeChatMutationLoading }] =
    useMutation(RESUME_CHAT);
  const [
    resendLastMessageMutation,
    { loading: resendLastMessageMutationLoading },
  ] = useMutation(RESEND_LAST_MESSAGE);
  const [testChatMutation, { loading: testChatMutationLoading }] =
    useMutation(TEST_CHAT);

  const onSelectChat = (chatId) => {
    if (!chatLoading) {
      setCookie(chatLogsCookieKey, chatId, cookieExpiryObject);

      // In case it gets stuck, manually set it
      if (!chatLoading && chatIdCookie === chatId && chatIdParam !== chatId) {
        updateChatIdSearchParam(chatId);
      }
    }
  };

  // In case param doesnt match cookie, reconcile the two
  if (!hasLoaded && chatIdParam && chatIdParam !== chatIdCookie) {
    onSelectChat(chatIdParam);
  }

  const chatIntercepted =
    chat?.status === interceptedChatStatusKey ||
    chat?.status === disengagedThenInterceptedChatStatusKey;
  const clientFirstName = chat?.client?.firstName;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoadingIndicator(true);
    }, 750);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const divHeight =
        contentContainerRef?.current?.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const heightDifference = windowHeight - divHeight;
      setRemainingHeight(heightDifference);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    setHasLoaded(true);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width, hasLoaded, selectedChatId]);

  // Update cookie
  useEffect(() => {
    // If we've loaded already, and there's not a new chat being loaded (ie were switching)
    // Or we havent yet loaded but theres no chatIdParam
    if ((hasLoaded && !chatLoading) || (!hasLoaded && !chatIdParam)) {
      updateChatIdSearchParam(chatIdCookie);
    }
  }, [chatIdCookie]);

  // Update param
  useEffect(() => {
    if (chatIdParam && selectedChatId !== chatIdParam) {
      setNewMessageBoxExpanded(false);
      setSelectedChatId(chatIdParam);
    }
  }, [chatIdParam]);

  useEffect(() => {
    // If the request comes thru with no cookie & no param, tell the downstream component to select the first chat
    if (hasLoaded && !chatIdCookie && !chatIdParam) {
      setSelectedChatId(-1);
    }
  }, [hasLoaded, chatIdCookie, chatIdParam]);

  useEffect(() => {
    if (profileOpenCookie && profileOpenCookie === chat?.client?.id) {
      setChatClientProfileOpenId(profileOpenCookie);
    } else {
      setChatClientProfileOpenId();
    }
  }, [profileOpenCookie]);

  useEffect(() => {
    const chatClientId = chat?.client?.id;
    if (
      chatClientId &&
      chatClientProfileOpenId &&
      chatClientProfileOpenId !== chatClientId
    ) {
      onOpenClientProfile(chatClientId);
    }
  }, [chat]);

  const updateChatIdSearchParam = (chatIdParamValue) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (!chatIdParamValue) {
      newSearchParams.delete(chatIdSearchParamKey);
    } else {
      newSearchParams.set(chatIdSearchParamKey, chatIdParamValue);
    }

    setSearchParams(newSearchParams);
  };

  const onCloseClientProfile = () => {
    removeCookie(clientProfileOpenIdCookieKey);
  };

  const onOpenClientProfile = (clientId) => {
    if (clientId !== profileOpenCookie) {
      setCookie(clientProfileOpenIdCookieKey, clientId, cookieExpiryObject);
    } else if (!chatClientProfileOpenId) {
      // In case state gets improperly set even though cookie is set
      setChatClientProfileOpenId(clientId);
    }
  };

  const onToggleClientProfile = (clientId) => {
    if (!chatClientProfileOpenId) {
      onOpenClientProfile(clientId);
    } else {
      onCloseClientProfile();
    }
  };

  const onEnterMessage = () => {
    const messageToSend = newMessage;

    setNewMessage('');

    sendMessageMutation({
      variables: {
        chatId: selectedChatId,
        messageId: uuidv4(),
        message: messageToSend,
        isHumanAgent: true,
      },
    });
  };

  const onInterceptChat = (reason, notes) => {
    interceptChatMutation({
      variables: {
        chatId: selectedChatId,
        reason,
        notes,
      },
      onCompleted: async (data) => {
        setInterceptChatModalOpen(false);

        const updatedChat = data.interceptChat;

        if (updatedChat) {
          const returnedMessages = sortChatMessages(updatedChat);

          await updateChatMessages(updatedChat, returnedMessages, cache);
          refetchChat();

          setNewMessage('');
          setSnackbarMessage(
            `Chat intercepted, you're now live${
              clientFirstName ? ` with ${clientFirstName}` : ''
            }`,
          );
        } else {
          console.log('Failed to intercept chat');
        }
      },
    });
  };

  const onEndChat = () => {
    endChatMutation({
      variables: {
        chatId: selectedChatId,
      },
      onCompleted: async (data) => {
        setEndChatModalOpen(false);

        const updatedChat = data.endChat;

        if (updatedChat) {
          const returnedMessages = sortChatMessages(updatedChat);

          await updateChatMessages(updatedChat, returnedMessages, cache);
          refetchChat();

          setNewMessage('');
          setSnackbarMessage(`Chat ended`);
        } else {
          console.log('Failed to end chat');
        }
      },
    });
  };

  const onClickEdit = () => {
    setViewChatAppointmentsModalOpen(false);
    setEditChatAppointmentsModalOpen(true);
  };

  const onSaveAppointmentAssignments = () => {
    refetchChat();
    setSnackbarMessage('Chat appointment assignments saved');
  };

  const onResumeDemoChat = () => {
    if (selectedChatId) {
      setCookie(chatDemoCookieKey, selectedChatId, cookieExpiryObject);
      setCookie(chatDemoTabCookieKey, chatTab, {});
      navigate(Paths.agentDemo);
    }
  };

  const onResendLastMessage = () => {
    resendLastMessageMutation({
      variables: {
        chatId: selectedChatId,
      },
      onCompleted: async (data) => {
        const success = data.resendLastMessage.success;

        if (success) {
          setSnackbarMessage(`Last message resent`);
        } else {
          setSnackbarMessage(`Failed to resend last message`);
        }
      },
    });
  };

  const onResetChat = () => {
    setNewMessage('');

    if (selectedChatId) {
      resetChatMutation({
        variables: {
          chatId: selectedChatId,
        },
        onCompleted: async (data) => {
          const success = data.resetChat;
          if (success) {
            const demoChatId = cookies[chatDemoCookieKey];
            if (demoChatId === selectedChatId) {
              removeCookie(chatDemoCookieKey);
            }
            onSelectChat('');
          }

          navigate(`${Paths.chats}?${chatIdSearchParamKey}=-1`);
        },
      });
    }
  };

  const onNewAppointment = async (
    clientName,
    chatId,
    status,
    refetchMessages,
  ) => {
    await refetchChat();
    setSnackbarMessage(
      status === appointmentBookedStatus
        ? `New appointment booked with ${clientName} 🎉`
        : `Monitoring potential appointment with ${clientName}`,
    );
    setChatIdForBookedAppointment(chatId);
    await refetchMessages();
  };

  const clientProfileDrawerDisplayed = !!chatClientProfileOpenId;
  const isDemoChat = chat?.medium?.includes('demo');
  const isInternal = chat?.type === internalContactType;
  const clientName = isInternal
    ? 'Internal Communication'
    : getFormattedFullNameFromUser(
        chat?.client,
        inDemoMode,
        null,
        chat?.phoneNumber,
      );
  const { appointmentStatus } = getChatAppointmentStatus(
    chat?.appointmentStatuses,
  );
  const chatLocationId = chat?.locationId;

  const lastHumanMessage = chat?.messages
    ?.slice()
    ?.reverse()
    ?.find(
      (m) =>
        m.sender === humanAgentKey ||
        m.sender === assistantKey ||
        m.sender === userKey,
    );
  const isSuperAdmin = user?.role === superAdminRole;
  const chatStatus = chat?.status;
  const canBeResent =
    (isSuperAdmin &&
      chatStatus !== disengagedChatStatusKey &&
      lastHumanMessage?.sender === assistantKey &&
      ![appointmentDepositRequiredStatus, appointmentBookedStatus].includes(
        appointmentStatus,
      )) ||
    true;

  return (
    hasLoaded &&
    selectedChatId && (
      <>
        <MetaSetter
          title={!!chat && false ? `Chat with ${clientName}` : `Chats`}
          description={`Chat with your AI assistant`}
        />
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          chatDrawerDisplayed
          clientProfileDrawerDisplayed={clientProfileDrawerDisplayed}
        >
          <ChatContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
            clientProfileDrawerDisplayed={clientProfileDrawerDisplayed}
            ref={contentContainerRef}
            chatDrawerDisplayed
            removeTopPadding
            hideOverflow
            unbounded
          >
            <ChatHeaderContainer>
              {chatLoading ? (
                <>
                  {showLoadingIndicator && (
                    <ChatClientContainer>
                      <PageTitleText>
                        <br></br>
                      </PageTitleText>
                    </ChatClientContainer>
                  )}
                </>
              ) : !!chat ? (
                <ChatClientContainer>
                  <PageSubtitleText capitalize>
                    {isDemoChat && 'Demo Chat - '}
                    {isInternal
                      ? clientName
                      : inDemoMode
                      ? `${clientFirstName?.[0]}`
                      : clientName.length > 30
                      ? `${clientName.slice(0, 30).trim()}...`
                      : clientName}
                    {inMonitoringMode
                      ? ` (${locationMap[chatLocationId]})`
                      : ''}
                  </PageSubtitleText>
                  <ChatClientIconContainer>
                    {isInternal ? (
                      <>
                        <Tooltip
                          title={
                            <TooltipTitleText>
                              {chatClientProfileOpenId ? 'Hide' : 'Show'}{' '}
                              internal chat info
                            </TooltipTitleText>
                          }
                        >
                          <InternalIconButton
                            onClick={() =>
                              onToggleClientProfile(internalContactType)
                            }
                            selected={clientProfileDrawerDisplayed}
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            <TooltipTitleText>
                              Copy link to chat
                            </TooltipTitleText>
                          }
                        >
                          <ShareLinkIcon
                            onClick={() => {
                              copyTextToClipboard(
                                getChatPath(window, chat?.id),
                              );
                              setSnackbarMessage(
                                chatLinkCopiedSnackbarMessageText,
                              );
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Tooltip
                          title={
                            <TooltipTitleText>
                              {chatClientProfileOpenId ? 'Hide' : 'Show'} client
                              profile
                            </TooltipTitleText>
                          }
                        >
                          <ClientIconButton
                            onClick={() =>
                              onToggleClientProfile(chat?.client?.id)
                            }
                            selected={clientProfileDrawerDisplayed}
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            <TooltipTitleText>
                              Copy link to chat
                            </TooltipTitleText>
                          }
                        >
                          <ShareLinkIcon
                            onClick={() => {
                              copyTextToClipboard(
                                getChatPath(window, chat?.id),
                              );
                              setSnackbarMessage(
                                chatLinkCopiedSnackbarMessageText,
                              );
                            }}
                          />
                        </Tooltip>
                        {appointmentStatus === appointmentBookedStatus ? (
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                Show booked appointments
                              </TooltipTitleText>
                            }
                          >
                            <AppointmentBookedIcon
                              small
                              onClick={() =>
                                setViewChatAppointmentsModalOpen(true)
                              }
                            />
                          </Tooltip>
                        ) : appointmentStatus ===
                          appointmentMonitoringStatus ? (
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                Edit potential booking
                              </TooltipTitleText>
                            }
                          >
                            <span>
                              <AppointmentBookingMonitoringIcon
                                small
                                topMargin={5}
                                onClick={() =>
                                  setEditChatAppointmentsModalOpen(true)
                                }
                              />
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                Assign appointment booking to chat
                              </TooltipTitleText>
                            }
                          >
                            <BookAppointmentIcon
                              onClick={() =>
                                setEditChatAppointmentsModalOpen(true)
                              }
                            />
                          </Tooltip>
                        )}
                        {isDemoChat ? (
                          <>
                            <Tooltip
                              title={
                                <TooltipTitleText>
                                  Resume demo chat
                                </TooltipTitleText>
                              }
                            >
                              <ContinueChatIconContainer>
                                <ContinueChatIcon
                                  onClick={() => onResumeDemoChat(true)}
                                />
                              </ContinueChatIconContainer>
                            </Tooltip>
                            <Tooltip
                              title={
                                <TooltipTitleText>
                                  Reset demo chat
                                </TooltipTitleText>
                              }
                            >
                              <ResetChatIconContainer>
                                <ResetChatIcon onClick={onResetChat} />
                              </ResetChatIconContainer>
                            </Tooltip>
                          </>
                        ) : (
                          <></>
                        )}
                        {canBeResent && (
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                Resend last assistant message
                              </TooltipTitleText>
                            }
                          >
                            <ResendMessageIcon
                              onClick={() => onResendLastMessage()}
                            />
                          </Tooltip>
                        )}
                        {!isDemoChat && (
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                {chatStatus === activeChatStatusKey
                                  ? `Disengage and DND contact`
                                  : `DND contact`}
                              </TooltipTitleText>
                            }
                          >
                            <DNDIcon
                              onClick={() => setEndChatModalOpen(true)}
                            />
                          </Tooltip>
                        )}
                        {isSuperAdmin && displayChatTest && (
                          <Tooltip
                            title={<TooltipTitleText>Test</TooltipTitleText>}
                          >
                            <TestChatIcon onClick={() => testChatMutation()} />
                          </Tooltip>
                        )}
                      </>
                    )}
                  </ChatClientIconContainer>
                </ChatClientContainer>
              ) : (
                <></>
              )}
            </ChatHeaderContainer>
            {remainingHeight > 0 && (
              <ChatSection
                remainingHeight={remainingHeight}
                clientProfileDrawerOpen={!!clientProfileDrawerDisplayed}
              >
                {chatLoading ? (
                  <ChatBox>
                    <LoadingIndicator
                      size={1}
                      fullScreen
                    />
                    <ChatSectionActionsContainer>
                      <ExpandMessageBoxContainer>
                        <ExpandIcon />
                      </ExpandMessageBoxContainer>
                      <TextInput
                        disabled
                        removeGap
                        useSmallWidth
                        style={{ width: '80%' }}
                        removeAutoMargins={true}
                      />
                      <div>
                        <ChatActionButton useLargeHeight>
                          Intercept
                        </ChatActionButton>
                      </div>
                    </ChatSectionActionsContainer>
                  </ChatBox>
                ) : !!chat ? (
                  <ChatBox>
                    <MessageQueryManager
                      chat={chat}
                      context='interception'
                      onNewAppointment={onNewAppointment}
                      onClickAppointment={() =>
                        setViewChatAppointmentsModalOpen(true)
                      }
                      onClickDeposit={(id) =>
                        setDepositIdToView(id ?? selectedChatId)
                      }
                      userMap={userMap}
                      snackbarSetter={setSnackbarMessage}
                    />
                    <ChatSectionActionsContainer
                      expanded={newMessageBoxExpanded}
                    >
                      <ExpandMessageBoxContainer
                        onClick={() =>
                          setNewMessageBoxExpanded(!newMessageBoxExpanded)
                        }
                        type={newMessageBoxExpanded ? 'collapse' : 'expanded'}
                      >
                        {newMessageBoxExpanded ? (
                          <CollapseIcon />
                        ) : (
                          <ExpandIcon />
                        )}
                      </ExpandMessageBoxContainer>
                      {newMessageBoxExpanded ? (
                        <TextInput
                          disabled={!chatIntercepted}
                          value={
                            !chatIntercepted
                              ? 'Must intercept chat to send message'
                              : newMessage
                          }
                          onChange={(e) => {
                            setNewMessage(e.target.value);
                          }}
                          removeGap
                          useSmallWidth
                          useMediumText
                          style={{
                            width: '100%',
                          }}
                          removeAutoMargins={false}
                          fixedHeight={160}
                          multiline
                          rows={4}
                          expanded
                        />
                      ) : (
                        <TextInput
                          disabled={!chatIntercepted}
                          value={newMessage}
                          onChange={(e) => {
                            setNewMessage(e.target.value);
                          }}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              onEnterMessage();
                            }
                          }}
                          removeGap
                          useMediumText
                          useSmallWidth
                          style={{ width: '80%' }}
                          removeAutoMargins={true}
                        />
                      )}
                      <div>
                        {chatIntercepted ? (
                          <ChatActionButton
                            onClick={onEnterMessage}
                            disabled={!newMessage}
                            useLargeHeight={!newMessageBoxExpanded}
                          >
                            Send
                          </ChatActionButton>
                        ) : (
                          <ChatActionButton
                            onClick={() => setInterceptChatModalOpen(true)}
                            useLargeHeight={!newMessageBoxExpanded}
                          >
                            Intercept
                          </ChatActionButton>
                        )}
                      </div>
                    </ChatSectionActionsContainer>
                  </ChatBox>
                ) : (
                  <></>
                )}
              </ChatSection>
            )}
          </ChatContentContainer>
        </PageContainer>
        <ChatQueryManager
          selectedChatId={selectedChatId}
          onSelectChat={onSelectChat}
        />
        <ClientProfileDrawer
          isOpen={!!chatClientProfileOpenId}
          onClose={onCloseClientProfile}
          clientId={chatClientProfileOpenId}
        />
        <ViewChatAppointmentsModal
          isOpen={viewChatAppointmentsModalOpen}
          onClose={() => setViewChatAppointmentsModalOpen(false)}
          chatId={selectedChatId}
          clientName={clientName}
          onClickEdit={onClickEdit}
          onSave={onSaveAppointmentAssignments}
        />
        <EditChatAppointmentsModal
          isOpen={editChatAppointmentsModalOpen}
          onClose={() => setEditChatAppointmentsModalOpen(false)}
          chatId={selectedChatId}
          clientName={clientName}
          agentName={chat?.agentName}
          userMap={userMap}
          onSave={onSaveAppointmentAssignments}
        />
        <ViewDepositModal
          isOpen={!!depositIdToView}
          onClose={() => setDepositIdToView()}
          id={depositIdToView}
          clientName={clientName}
        />
        <InterceptChatModal
          isOpen={interceptChatModalOpen}
          onClose={() => setInterceptChatModalOpen(false)}
          onIntercept={onInterceptChat}
          clientName={clientName}
          loading={interceptChatMutationLoading}
        />
        <EndChatModal
          isOpen={endChatModalOpen}
          onClose={() => setEndChatModalOpen(false)}
          onEnd={onEndChat}
          loading={endChatMutationLoading}
        />
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => {
            setSnackbarMessage('');
            setChatIdForBookedAppointment();
          }}
          message={snackbarMessage}
          onClick={() => {
            if (chatIdForBookedAppointment) {
              onSelectChat(chatIdForBookedAppointment);
            }
          }}
          quick={snackbarMessage === chatLinkCopiedSnackbarMessageText}
        />
      </>
    )
  );
};

export default Chats;
