import { useApolloClient, useMutation } from '@apollo/client';
import React from 'react';
import { DELETE_MEMBERS_FROM_ALL_CAMPAIGNS } from '../../../api/mutations/campaign';
import {
  FlexibleMediumPrimaryButton,
  FlexibleMediumSecondaryButton,
  MLText,
  MediumDarkSMText,
  Modal,
  ModalActions,
  ModalContainer,
} from '../../../styles/shared-styled-components';
import { updateAllMyCampaignsMembersInCache } from '../../../utils/cache';
import LoadingIndicator from '../../LoadingIndicator';

const PermanentlyDeleteCampaignMembersModal = ({
  isOpen,
  onClose,
  clientsToDelete,
  onDelete,
}) => {
  const { cache } = useApolloClient();

  const [
    deleteMembersFromAllCampaignsMutation,
    { loading: deleteMembersFromAllCampaignsMutationLoading },
  ] = useMutation(DELETE_MEMBERS_FROM_ALL_CAMPAIGNS);

  const onDeleteMembersFromCampaigns = (permanentlyDelete) => {
    deleteMembersFromAllCampaignsMutation({
      variables: {
        contactIds: clientIdsToDelete,
        permanentlyDelete,
      },
      onCompleted: async (data) => {
        const updatedCampaigns = data.deleteMembersFromAllCampaigns;

        if (updatedCampaigns) {
          await updateAllMyCampaignsMembersInCache(updatedCampaigns, cache);
        }

        onClose();
        onDelete(clientIdsToDelete.length);
      },
    });
  };

  const clientIdsToDelete = Object.keys(clientsToDelete);

  if (deleteMembersFromAllCampaignsMutationLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    clientIdsToDelete && (
      <Modal
        open={isOpen}
        onClose={onClose}
        maxWidth={900}
      >
        <ModalContainer>
          <MLText>Delete Campaign Members</MLText>
          <MediumDarkSMText>
            Do you want to only delete these members from the campaigns they're
            currently in, or do you want to permanently exclude them from all
            future campaigns as well?
            <br></br>
            <br></br>
            Note: if any of these clients have an active chat, the agent will
            immediately disengage and cease responding.
          </MediumDarkSMText>
          <ModalActions>
            <FlexibleMediumPrimaryButton
              onClick={() => onDeleteMembersFromCampaigns(true)}
              fixedMinWidth={440}
            >
              Delete from all future campaigns
            </FlexibleMediumPrimaryButton>
            <FlexibleMediumSecondaryButton
              onClick={() => onDeleteMembersFromCampaigns(false)}
              fixedMinWidth={440}
            >
              Delete only from current campaigns
            </FlexibleMediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    )
  );
};

export default PermanentlyDeleteCampaignMembersModal;
