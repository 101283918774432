import React, { useState } from 'react';
import {
  MediumDarkSmallText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  MLText,
  Modal,
  ModalActions,
  ModalContainer,
} from '../../../styles/shared-styled-components';
import LoadingIndicator from '../../LoadingIndicator';

const EndChatModal = ({ isOpen, onClose, onEnd, loading }) => {
  const [reason, setReason] = useState();
  const [notes, setNotes] = useState();

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <MLText>Are you sure you want to end the chat?</MLText>
        <MediumDarkSmallText>
          This will disengage the agent and DND the contact
        </MediumDarkSmallText>
        <ModalActions>
          <MediumPrimaryButton
            onClick={() => {
              onEnd();
            }}
          >
            End Chat
          </MediumPrimaryButton>
          <MediumSecondaryButton onClick={onClose}>Close</MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default EndChatModal;
