import orderBy from 'lodash/orderBy';
import React, { useContext, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useSearchParams } from 'react-router-dom';
import {
  useAppointmentClients,
  useMyAnalytics,
} from '../../../../api/hooks/analytics';
import { useMyCampaigns } from '../../../../api/hooks/campaigns';
import { useMyLocations } from '../../../../api/hooks/enterprise';
import DoubleBarChart from '../../../../components/Analytics/BarChart/DoubleBarChart';
import LineGraph from '../../../../components/Analytics/LineGraph';
import { InfoIcon } from '../../../../components/Analytics/shared-analytics-styles';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import PieChart from '../../../../components/Dashboard/PieChart';
import {
  carrot,
  emerald,
  royalBlue,
  TooltipText,
  TooltipWrapper,
} from '../../../../components/Dashboard/shared-dashboard-styles';
import StackedBarChart from '../../../../components/Dashboard/StackedBarChart';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import AddLocationModal from '../../../../components/Modals/AddLocationModal';
import Header from '../../../../components/NavBar/Header';
import PopperMenu from '../../../../components/PopperMenu';
import RangePopperMenuCalendar from '../../../../components/Schedule/Calendar/RangePopperMenuCalendar';
import Snackbar from '../../../../components/Snackbar';
import {
  AddIcon,
  AgentAvatarContainer,
  AnalystAvatar,
  CenteredDiv,
  ColumnCenteredDiv,
  ContentContainer,
  ExtraSmallPrimaryAddIcon,
  FullWidthCenteredDiv,
  MediumDarkMediumText,
  MediumPrimaryButton,
  MenuItem,
  MenuItemCheckmark,
  PageContainer,
  PageTitleText,
  SmallMenuItemText,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkExtraSmallText,
  StartAlignedMediumDarkLargeTinyText,
  StartAlignedSMText,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  appointmentContactTypeLabelMap,
  cancelledStatusKey,
  completeStatusKey,
  inboundKey,
  nameKey,
  percentDataType,
  scheduledStatusKey,
} from '../../../../utils/constants';
import { getFormattedDateString } from '../../../../utils/date';
import {
  formatNumber,
  getFormattedPercentageString,
} from '../../../../utils/numbers';
import {
  getUserBusiness,
  getUserLiveIQAgentName,
} from '../../../../utils/user';
import Appointments from '../Appointments/Appointments';
import {
  AvatarSection,
  Container,
  NoLocationsCreatedContainer,
  QueryFieldText,
  QueryPanel,
  QueryPanelContainer,
  SectionComponentDiv,
  SectionDiv,
  SectionTitleContainer,
  TopPanel,
  TopPanelSection,
  TopSection,
} from './styled';

const assumedHourlyRate = 20;

const viewParamKey = 'view';
const appointmentsKey = 'appointments';

const topPanelFields = [
  {
    hardcodedKey: 'revenue',
    key: 'revenue',
    label: 'Revenue Received',
    prefix: '$',
    view: appointmentsKey,
  },
  {
    hardcodedKey: 'appointments',
    key: 'numDirectAppointments',
    subkey: 'numIndirectAppointments',
    label: 'Appointments Booked (Indirect)',
    explanation: `Indirect appointments are those where the AI agent prompted the client to book, and a human completed the booking`,
    view: appointmentsKey,
  },
  {
    hardcodedKey: 'talkMinutes',
    key: 'talkSeconds',
    label: 'Talk Time',
    divisor: 60,
    suffix: ' mins',
  },
  {
    hardcodedKey: 'humanCostDiverted',
    key: 'talkSeconds',
    label: 'Human Cost Diverted',
    coefficient: assumedHourlyRate / 60,
    prefix: '$',
    explanation: `Assuming a $${assumedHourlyRate}/hr human cost`,
  },
];

const hardcodedData = {
  revenue: 12397,
  appointments: 83,
  talkMinutes: 2611,
  humanCostDiverted: 2400,
};

// Overview
const appointmentCategoryData = [
  {
    label: 'Massages',
    value: 53,
  },
  {
    label: 'Facials',
    value: 18,
  },
  {
    label: 'Stretch',
    value: 29,
  },
];
const total = 52 + 22 + 9;
const appointmentStatusData = [
  {
    label: 'Scheduled',
    status: scheduledStatusKey,
    value: (22 / total).toFixed(1) * 100,
  },
  {
    label: 'Completed',
    status: completeStatusKey,
    value: (52 / total).toFixed(1) * 100,
  },
  {
    label: 'Cancel',
    status: cancelledStatusKey,
    value: (9 / total).toFixed(1) * 100,
  },
];
const campaignData = [
  {
    key: 'a',
    label: 'September Stretch',
    value: 50,
  },
  {
    key: 'b',
    label: 'Membership Upsell',
    value: 30,
  },
  {
    key: 'c',
    label: 'Facials Promo',
    value: 30,
  },
];

// Phone calls
const phoneCallResolutionData = [
  {
    label: 'Handled',
    value: 55,
  },
  {
    label: 'Callback',
    value: 18,
  },
  {
    label: 'Transferred',
    value: 27,
  },
];
const callTopicsData = [
  {
    range: 'Sep 23-29',
    Create: 3,
    Reschedule: 2,
    Cancel: 3,
  },
  {
    range: 'Sep 30-Oct 6',
    Create: 9,
    Reschedule: 2,
    Cancel: 5,
  },
  {
    range: 'Oct 7-13',
    Create: 12,
    Reschedule: 3,
    Cancel: 2,
  },
  {
    range: 'Oct 14-21',
    Create: 11,
    Reschedule: 3,
    Cancel: 5,
  },
  {
    range: 'Oct 21-Now',
    Create: 4,
    Reschedule: 1,
    Cancel: 1,
  },
];
const callContactTypes = [
  {
    label: 'Clients',
    value: 68,
  },
  {
    label: 'Leads',
    value: 12,
  },
  {
    label: 'Unknown',
    value: 20,
  },
];

// Campaigns
const campaignLeadConversionData = [
  {
    time: 'Sep 23-29',
    'September Stretch': 20,
    'Membership Upsell': 35,
    'Facials Promo': 40,
  },
  {
    time: 'Sep 30-Oct 6',
    'September Stretch': 24,
    'Membership Upsell': 33,
    'Facials Promo': 48,
  },
  {
    time: 'Oct 7-13',
    'Membership Upsell': 37,
    'Facials Promo': 34,
  },
  {
    time: 'Oct 14-21',
    'Membership Upsell': 31,
    'Facials Promo': 46,
  },
  {
    time: 'Oct 21-Now',
    'Membership Upsell': 40,
    'Facials Promo': 29,
  },
];
const campaignRatesData = [
  {
    time: 'Sep 23-29',
    Response: 8,
    Interception: 1,
    Unsubscribe: 3,
  },
  {
    time: 'Sep 30-Oct 6',
    Response: 9,
    Interception: 1.5,
    Unsubscribe: 2.7,
  },
  {
    time: 'Oct 7-13',
    Response: 7,
    Interception: 0.8,
    Unsubscribe: 4,
  },
  {
    time: 'Oct 14-21',
    Response: 11,
    Interception: 2.5,
    Unsubscribe: 5,
  },
  {
    time: 'Oct 21-Now',
    Response: 7,
    Interception: 1,
    Unsubscribe: 3,
  },
];
const campaignSourceData = [
  {
    key: 'a',
    label: 'Meta',
    value: 62,
  },
  {
    key: 'a',
    label: 'Google',
    value: 21,
  },
  {
    key: 'a',
    label: 'Web',
    value: 17,
  },
];

function serviceBookingTooltipRenderer(params) {
  console.log('params', params);
  const content = (
    <TooltipWrapper color={params.fill}>
      <TooltipText>
        Service: {params.datum[params.xKey]}
        <br></br>
        {params.title}: {params.datum[params.yKey].toFixed(0)}
        {params.yKey === completeStatusKey && (
          <>
            <br></br>
            Revenue: ${formatNumber(params.datum['revenue'])}
          </>
        )}
      </TooltipText>
    </TooltipWrapper>
  );

  // Return the tooltip content as a string.
  // This step converts the React element to a string that can be used.
  return renderToString(content);
}

const clientTypeTooltipRenderer = (params) => {
  console.log('params', params);
  const content = (
    <TooltipWrapper color={params.fill}>
      <TooltipText>
        {params.datum['type']}s: {params.datum['amount']} bookings
      </TooltipText>
    </TooltipWrapper>
  );

  return renderToString(content);
};

const serviceBookingSeries = [
  {
    type: 'bar',
    xKey: nameKey,
    yKey: scheduledStatusKey,
    yName: 'Scheduled',
    stacked: true,
    tooltip: { renderer: serviceBookingTooltipRenderer },
  },
  {
    type: 'bar',
    xKey: nameKey,
    yKey: completeStatusKey,
    yName: 'Completed',
    stacked: true,
    tooltip: { renderer: serviceBookingTooltipRenderer },
  },
  {
    type: 'bar',
    xKey: nameKey,
    yKey: cancelledStatusKey,
    yName: 'Cancelled',
    stacked: true,
    tooltip: { renderer: serviceBookingTooltipRenderer },
  },
];

const clientTypeSeries = [
  {
    type: 'pie',
    angleKey: 'amount',
    legendItemKey: 'type',
    tooltip: { renderer: clientTypeTooltipRenderer },
  },
];

const maxElements = 15;

const appointmentStatusFills = ['#9c90bd', 'rgb(37, 179, 77)', '#d8504d'];
const clientTypeFills = [royalBlue, emerald, carrot];

const Dashboard = () => {
  const {
    user,
    drawerOpen,
    drawerExpanded,
    cookies,
    removeCookie,
    inDemoMode,
  } = useContext(BaseContext);

  const agentName = getUserLiveIQAgentName(user);

  const [searchParams, setSearchParams] = useSearchParams();

  const onAppointmentsView = searchParams.get(viewParamKey) === appointmentsKey;

  const business = getUserBusiness(user);
  const businessName = business?.name;
  const inHardcodedMode = inDemoMode;

  const {
    locations,
    refetch: refetchLocations,
    loading: locationsLoading,
  } = useMyLocations();
  const {
    campaigns,
    loading: campaignsLoading,
    refetch: refetchCampaigns,
  } = useMyCampaigns({ shallow: true, skipCondition: inHardcodedMode });

  const allLocationIds = [];
  const locationOptions = (locations || []).map((l) => {
    allLocationIds.push(l.id);
    return { key: l.id, label: l.nickname };
  });
  const [filteredLocations, setFilteredLocations] = useState(
    locationOptions.map((c) => c.key),
  );
  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();
  const [addedLocation, setAddedLocation] = useState(false);
  const [campaignsHaveLoaded, setCampaignsHaveLoaded] = useState(false);

  let allCampaignIds = ['inbound'];
  let campaignOptions = [{ key: 'inbound', label: 'Inbound' }];
  let campaignsToUse = inHardcodedMode ? campaignData : campaigns || [];
  let isEmpty = false;
  if (campaignsToUse.length) {
    campaignsToUse.map((c, idx) => {
      allCampaignIds.push(c.id);
      campaignOptions.push({ key: c.id || c.key, label: c.name || c.label });
    });
  }
  const [filteredCampaigns, setFilteredCampaigns] = useState(
    campaignOptions.map((c) => c.key),
  );

  useEffect(() => {
    if (!filteredLocations?.length || addedLocation) {
      setFilteredLocations(locationOptions.map((c) => c.key));
      setAddedLocation(false);
    }
  }, [addedLocation, locationOptions]);

  useEffect(() => {
    console.log('campaigns', campaigns);
    if (campaigns && !campaignsHaveLoaded) {
      allCampaignIds = [];
      campaignOptions = [];
      if (campaigns.length) {
        campaigns.map((c, idx) => {
          if (idx === 0) {
            allCampaignIds.push(inboundKey);
            campaignOptions.push({ key: inboundKey, label: 'Inbound' });
          }
          allCampaignIds.push(c.id);
          campaignOptions.push({
            key: c.id || c.key,
            label: c.name || c.label,
          });
          console.log('c', c);
        });
        console.log('campaignOptions', campaignOptions);
        setFilteredCampaigns(campaignOptions.map((c) => c.key));
      } else {
        setFilteredCampaigns([inboundKey]);
      }
      setCampaignsHaveLoaded(true);
    }
  }, [campaigns]);

  if (campaignsHaveLoaded && !campaigns?.length) {
    campaignsToUse = [{ key: 'inbound', label: 'Inbound' }];
    isEmpty = true;
  }

  const filteredCampaignIds = filteredCampaigns?.length
    ? filteredCampaigns
    : allCampaignIds;
  const {
    analytics,
    loading: analyticsLoading,
    refetch,
  } = useMyAnalytics({
    filteredCampaignIds,
    start: startDateRange,
    end: endDateRange,
  });

  const appointments = analytics?.appointments || [];
  const { clients, loading: clientsLoading } = useAppointmentClients({
    clientIds: appointments.map((a) => a.clientId),
    skipCondition: !onAppointmentsView,
  });

  const [menuField, setMenuField] = useState();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const menuOpen = Boolean(menuAnchorEl);

  const locationsMenuOpen = menuOpen && menuField === 'locations';
  const campaignsMenuOpen = menuOpen && menuField === 'campaigns';
  const datesMenuOpen = menuOpen && menuField === 'dates';

  const menuOptions =
    menuField === 'locations'
      ? locationOptions
      : menuField === 'campaigns'
      ? campaignOptions
      : [];
  const filteredOptions =
    menuField === 'locations'
      ? filteredLocations
      : menuField === 'campaigns'
      ? filteredCampaigns
      : [];

  const startLabel = startDateRange
    ? getFormattedDateString(startDateRange, false)
    : 'Start';
  const endLabel = endDateRange
    ? getFormattedDateString(endDateRange, false)
    : 'Present';
  const dateLabel =
    !startDateRange && !endDateRange
      ? `All-Time`
      : `${startLabel} - ${endLabel}`;

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);

  const onChangeFilter = (id, add) => {
    if (menuField === 'locations') {
      if (add) {
        setFilteredLocations([...filteredLocations, id]);
      } else {
        setFilteredLocations([
          ...filteredLocations.filter((key) => key !== id),
        ]);
      }
    } else if (menuField === 'campaigns') {
      if (add) {
        setFilteredCampaigns([...filteredCampaigns, id]);
      } else {
        setFilteredCampaigns([
          ...filteredCampaigns.filter((key) => key !== id),
        ]);
      }
    }
    setMenuAnchorEl();
  };

  const onUpdateStart = (updatedValue) => {
    const updatedStartDate = new Date(updatedValue).toISOString();
    const endDate = endDateRange ? new Date(endDateRange).toISOString() : null;
    if (endDate && updatedStartDate > endDate) {
      const updatedEndDate = new Date(updatedStartDate);
      updatedEndDate.setDate(updatedEndDate.getDate() + 7);
      setEndDateRange(updatedEndDate.toISOString());
    }
    setStartDateRange(updatedValue);
    setMenuAnchorEl();
  };

  const onUpdateEnd = (updatedValue) => {
    const updatedEndDate = new Date(updatedValue).toISOString();
    const startDate = startDateRange
      ? new Date(startDateRange).toISOString()
      : null;
    if (startDate && updatedEndDate < startDate) {
      const updatedStartDate = new Date(updatedEndDate);
      updatedStartDate.setDate(updatedStartDate.getDate() - 7);
      setStartDateRange(updatedStartDate.toISOString());
    }
    setEndDateRange(updatedValue);
    setMenuAnchorEl();
  };

  const onNavigateToView = (view) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (!view) {
      newSearchParams.delete(viewParamKey);
    } else {
      newSearchParams.set(viewParamKey, view);
    }

    setSearchParams(newSearchParams);
  };

  if (
    locationsLoading ||
    campaignsLoading ||
    analyticsLoading ||
    (!campaignsHaveLoaded && !inHardcodedMode)
  ) {
    return <LoadingIndicator fullScreen />;
  }

  console.log('analytics', analytics);

  const serviceBookings = [];
  let serviceBookingsTitle = `Bookings by service`;
  let values = Object.keys(analytics?.serviceMap || {});
  if (values.length > maxElements) {
    serviceBookingsTitle += ` (top ${maxElements} displayed)`;
    values = orderBy(values, ['revenue', 'completed', 'scheduled']).slice(
      0,
      maxElements,
    );
  }
  values.map((serviceName) => {
    const value = analytics.serviceMap[serviceName];
    serviceBookings.push({
      name: serviceName,
      complete: value.complete || 0,
      scheduled: value.scheduled || 0,
      cancelled: value.cancelled || 0,
      revenue: value.revenue,
    });
  });

  const clientTypeMap = {};
  appointments.map((a) => {
    const clientType = a.clientType;
    const numForType = clientTypeMap[clientType] || 0;
    clientTypeMap[clientType] = numForType + 1;
  });
  console.log('clientTypeMap', clientTypeMap);
  const clientTypes = Object.keys(clientTypeMap).map((k) => {
    return {
      type: appointmentContactTypeLabelMap[k],
      amount: clientTypeMap[k],
    };
  });

  if (onAppointmentsView) {
    return (
      <Appointments
        appointments={appointments}
        clients={clients}
        agentName={agentName}
        campaigns={campaignOptions}
        loading={clientsLoading}
      />
    );
  }

  return (
    locations && (
      <>
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            <ColumnCenteredDiv bottomMargin={10}>
              <PageTitleText>{businessName} - Dashboard</PageTitleText>
            </ColumnCenteredDiv>
            <Container>
              {locationsLoading ? (
                <LoadingIndicator />
              ) : locations?.length ? (
                <>
                  <TopSection>
                    <TopPanelSection>
                      {topPanelFields.map((p) => {
                        const key = inHardcodedMode ? p.hardcodedKey : p.key;
                        const subkey = p.subkey;
                        let value = inHardcodedMode
                          ? hardcodedData[key]
                          : analytics?.[key];

                        if (!inHardcodedMode) {
                          if (p.divisor && value) {
                            value = (value / p.divisor).toFixed(0);
                          } else if (p.coefficient && value) {
                            value = value * p.coefficient;
                          }
                        }

                        value =
                          p.type === percentDataType
                            ? getFormattedPercentageString(value)
                            : formatNumber(value);

                        if (p.subkey) {
                          value += ` (${analytics?.[subkey]})`;
                        }
                        return (
                          <TopPanel
                            clickable={true}
                            onClick={() => {
                              if (p.view) {
                                onNavigateToView(p.view);
                              }
                            }}
                          >
                            <StartAlignedMediumDarkLargeTinyText
                              smallLineHeight
                            >
                              {p.label}
                              {p.explanation && (
                                <Tooltip
                                  placement='bottom'
                                  title={
                                    <TooltipTitleText>
                                      {p.explanation}
                                    </TooltipTitleText>
                                  }
                                >
                                  <InfoIcon />
                                </Tooltip>
                              )}
                            </StartAlignedMediumDarkLargeTinyText>
                            <StartAlignedSMText smallLineHeight>
                              {p.prefix || ''}
                              {value}
                              {p.suffix || ''}
                            </StartAlignedSMText>
                          </TopPanel>
                        );
                      })}
                    </TopPanelSection>
                    <QueryPanelContainer>
                      <QueryPanel center>
                        <AvatarSection>
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                Let's explore your data
                              </TooltipTitleText>
                            }
                          >
                            <AgentAvatarContainer analyst>
                              <AnalystAvatar />
                            </AgentAvatarContainer>
                          </Tooltip>
                        </AvatarSection>
                        <StartAlignedFlexDiv lowerGap>
                          <QueryFieldText
                            selected={locationsMenuOpen}
                            onClick={(e) => {
                              setMenuField('locations');
                              setMenuAnchorEl(e.currentTarget);
                            }}
                          >
                            {filteredLocations?.length === locations?.length
                              ? 'All Locations'
                              : `Locations`}{' '}
                            ({filteredLocations?.length})
                          </QueryFieldText>
                          <Tooltip
                            title={
                              <TooltipTitleText>Add location</TooltipTitleText>
                            }
                          >
                            <ExtraSmallPrimaryAddIcon
                              topMargin={-4}
                              onClick={() => setAddLocationModalOpen(true)}
                            />
                          </Tooltip>
                        </StartAlignedFlexDiv>
                        <StartAlignedFlexDiv>
                          <QueryFieldText
                            selected={campaignsMenuOpen}
                            onClick={(e) => {
                              setMenuField('campaigns');
                              setMenuAnchorEl(e.currentTarget);
                            }}
                          >
                            {isEmpty
                              ? filteredCampaigns?.length ===
                                campaignsToUse?.length
                                ? `All Campaigns`
                                : `Campaigns`
                              : filteredCampaigns?.length ===
                                campaignsToUse?.length + 1
                              ? 'All Campaigns'
                              : `Campaigns`}{' '}
                            ({filteredCampaigns?.length})
                          </QueryFieldText>
                        </StartAlignedFlexDiv>
                        <StartAlignedFlexDiv>
                          <QueryFieldText
                            selected={datesMenuOpen}
                            onClick={(e) => {
                              setMenuField('dates');
                              setMenuAnchorEl(e.currentTarget);
                            }}
                          >
                            {dateLabel}{' '}
                          </QueryFieldText>
                        </StartAlignedFlexDiv>
                      </QueryPanel>
                    </QueryPanelContainer>
                    <PopperMenu
                      open={menuOpen && !datesMenuOpen}
                      anchorElement={menuAnchorEl}
                      onClose={() => setMenuAnchorEl()}
                      variant='offset'
                    >
                      {menuOptions.map((o) => {
                        const { key, label } = o;
                        const selected = !!filteredOptions.find(
                          (filteredValue) => filteredValue === key,
                        );
                        return (
                          <MenuItem
                            disableRipple
                            onClick={() => {
                              if (!(selected && filteredOptions.length === 1)) {
                                console.log('key', key);
                                console.log('selected', selected);
                                onChangeFilter(key, !selected);
                              }
                            }}
                          >
                            <SmallMenuItemText>{label}</SmallMenuItemText>
                            {selected && <MenuItemCheckmark />}
                          </MenuItem>
                        );
                      })}
                    </PopperMenu>
                    <RangePopperMenuCalendar
                      start={startDateRange}
                      end={endDateRange}
                      onChangeStart={onUpdateStart}
                      onChangeEnd={onUpdateEnd}
                      onReset={() => {
                        setStartDateRange();
                        setEndDateRange();
                      }}
                      open={datesMenuOpen}
                      anchorElement={menuAnchorEl}
                      onClose={() => setMenuAnchorEl()}
                    />
                  </TopSection>
                  <SectionDiv>
                    <SectionTitleContainer>
                      <StartAlignedMediumDarkExtraSmallText>
                        Appointments
                      </StartAlignedMediumDarkExtraSmallText>
                    </SectionTitleContainer>
                    <SectionComponentDiv>
                      <FullWidthCenteredDiv>
                        <StackedBarChart
                          data={serviceBookings}
                          series={serviceBookingSeries}
                          title={serviceBookingsTitle}
                          fills={appointmentStatusFills}
                        />
                      </FullWidthCenteredDiv>
                      <FullWidthCenteredDiv>
                        <PieChart
                          data={clientTypes}
                          series={clientTypeSeries}
                          title={'Bookings by client type'}
                          fills={clientTypeFills}
                        />
                      </FullWidthCenteredDiv>
                    </SectionComponentDiv>
                  </SectionDiv>
                  <SectionDiv>
                    <SectionTitleContainer>
                      <StartAlignedMediumDarkExtraSmallText>
                        Phone Calls
                      </StartAlignedMediumDarkExtraSmallText>
                    </SectionTitleContainer>
                    <PieChart
                      data={inHardcodedMode ? phoneCallResolutionData : []}
                      size='medium'
                      title={'Resolution'}
                      ternary
                    />
                    <CenteredDiv>
                      <DoubleBarChart
                        data={callTopicsData}
                        yAxisLabel='Tasks Handled'
                        maxY={20}
                      />
                    </CenteredDiv>
                    <PieChart
                      data={inHardcodedMode ? callContactTypes : []}
                      size='medium'
                      title={'Contact Types'}
                      alt
                    />
                  </SectionDiv>
                  <SectionDiv>
                    <SectionTitleContainer>
                      <StartAlignedMediumDarkExtraSmallText>
                        Campaigns
                      </StartAlignedMediumDarkExtraSmallText>
                    </SectionTitleContainer>
                    <CenteredDiv>
                      <LineGraph
                        data={campaignLeadConversionData}
                        yAxisLabel='Lead Conversion %'
                      />
                    </CenteredDiv>
                    <CenteredDiv>
                      <LineGraph
                        data={campaignRatesData}
                        yAxisLabel='Interaction Rates'
                        maxY={20}
                      />
                    </CenteredDiv>
                    <PieChart
                      data={inHardcodedMode ? campaignSourceData : []}
                      size='medium'
                      title={'Source'}
                      secondary
                    />
                  </SectionDiv>
                </>
              ) : (
                <NoLocationsCreatedContainer>
                  <MediumDarkMediumText>
                    Add a location to begin tracking metrics
                  </MediumDarkMediumText>
                  <MediumPrimaryButton
                    onClick={() => setAddLocationModalOpen(true)}
                  >
                    <AddIcon /> Add location
                  </MediumPrimaryButton>
                </NoLocationsCreatedContainer>
              )}
            </Container>
          </ContentContainer>
        </PageContainer>
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
        />
        <AddLocationModal
          isOpen={addLocationModalOpen}
          onClose={() => setAddLocationModalOpen(false)}
          onAdd={async (sentInviteToOwner) => {
            await refetchLocations();
            const snackbarText = `Added location${
              sentInviteToOwner ? ' and sent invite to location admin' : ''
            }`;
            setSnackbarMessage(snackbarText);
            setAddedLocation(true);
          }}
        />
      </>
    )
  );
};

export default Dashboard;
