import React from 'react';
import {
  CenteredDiv,
  FlexibleMediumPrimaryButton,
  FlexibleMediumSecondaryButton,
  MLText,
  MediumDarkSMText,
  Modal,
  ModalActions,
  ModalContainer,
  SmartListIcon,
} from '../../../styles/shared-styled-components';

const SmartListModal = ({ isOpen, onClose, campaign, onSelect }) => {
  const campaignId = campaign?.id;

  const handleSelect = (value) => {
    onClose();
    onSelect(campaignId, value);
  };

  return (
    campaign && (
      <Modal
        open={isOpen}
        maxWidth={830}
        onClose={onClose}
      >
        <ModalContainer>
          <CenteredDiv>
            <SmartListIcon />
          </CenteredDiv>
          <MLText>Create Smart List</MLText>
          <MediumDarkSMText>
            Do you want to create a smart list for this campaign? This way
            whenever a client becomes qualified for your current filter, they
            would be automatically added to the '{campaign.name}' campaign.
            <br></br>
            <br></br>Smart Lists allow you to not have to constantly monitor and
            update your targeted client list.
          </MediumDarkSMText>
          <ModalActions>
            <FlexibleMediumPrimaryButton
              onClick={() => handleSelect(true)}
              largePadding
              fixedMinWidth={240}
            >
              Create smart list
            </FlexibleMediumPrimaryButton>
            <FlexibleMediumSecondaryButton
              onClick={() => handleSelect(false)}
              largePadding
              fixedMinWidth={240}
            >
              Ignore
            </FlexibleMediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    )
  );
};

export default SmartListModal;
