import React from 'react';
import { DarkGoldText } from '../../../styles/shared-styled-components';
import {
  TestimonialContainer,
  TestimonialHeaderSubText,
  TestimonialHeaderText,
  TestimonialMedia,
  TestimonialMediaContainer,
  TestimonialTextContainer,
} from '../styles/style-Testimonials';

const Testimonials = ({ darkMode }) => {
  return (
    <div id='testimonials'>
      <TestimonialContainer>
        <TestimonialTextContainer>
          <p>
            <TestimonialHeaderText>
              Hear how Julie <DarkGoldText>transformed</DarkGoldText> her
              business with LiveIQ
            </TestimonialHeaderText>
          </p>
          <TestimonialHeaderSubText>
            Fox River MedSpa achieved <DarkGoldText>{'>'}4.2x ROI</DarkGoldText>
            , booking appointments across over <DarkGoldText>300+</DarkGoldText>{' '}
            services!
          </TestimonialHeaderSubText>
        </TestimonialTextContainer>
        <TestimonialMediaContainer>
          <TestimonialMedia dark={darkMode}>
            <iframe
              width='560'
              height='315'
              src='https://www.youtube.com/embed/A9vhbIIugT8?si=Mn2KOsmvaOzlupuE'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerpolicy='strict-origin-when-cross-origin'
              allowfullscreen
            ></iframe>
          </TestimonialMedia>
        </TestimonialMediaContainer>
      </TestimonialContainer>
    </div>
  );
};

export default Testimonials;
