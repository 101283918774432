import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PersonIcon from '@mui/icons-material/Person';
import styled, { css } from 'styled-components';
import {
  ScriptIcon as BaseScriptIcon,
  BaseStatusChip,
  CardStyle,
  CenterFlexStyle,
  ColumnCenteredDiv,
  ColumnFlexStyle,
  ExclamationIcon,
  ExtraSmallGapStyle,
  LightDarkEssText,
  MediumGapStyle,
  RocketIcon,
  SMGapStyle,
  SMIconSize,
  SMMIconSize,
  SmallGapStyle,
  SmallIconSize,
  SmartListIcon,
  StartAlignedLightDarkSmallText,
  StartAlignedMediumDarkSmallText,
  StopIcon,
} from '../../../../styles/shared-styled-components';
import {
  inProgressStatusKey,
  pausedStatusKey,
  stagingStatusKey,
} from '../../../../utils/constants';

export const EmptyCampaignBox = styled.div`
  ${CardStyle}
  min-height: 200px;
  ${CenterFlexStyle}
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px !important;
  width: 100%;
  ${SmallGapStyle}
`;

export const CampaignsContainer = styled(ColumnCenteredDiv)`
  ${MediumGapStyle}
`;

export const CampaignCard = styled.div`
  ${CardStyle}
  width: calc(100% - ${({ theme }) =>
    `${theme.spacing.smallContainerPadding * 2}px`});
  ${ColumnFlexStyle}
  ${SMGapStyle}
`;

export const CampaignHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CampaignTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${SmallGapStyle}
`;

export const CampaignTitleText = styled(StartAlignedMediumDarkSmallText)``;

export const CampaignSubtitleText = styled(StartAlignedLightDarkSmallText)``;

export const CampaignClientsContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const CampaignParagraphText = styled(LightDarkEssText)`
  text-align: start;
  line-height: 1.3;
  font-size: 17px;
`;

export const CampaignFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledSmartListIcon = styled(SmartListIcon)`
  ${SMMIconSize}
  cursor: pointer;
`;

export const AgentTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${ExtraSmallGapStyle}
  margin-bottom: 10px;
`;

export const PromosTextContainer = styled(AgentTextContainer)`
  margin: 0px;
`;

export const StatusChip = styled(BaseStatusChip)`
  ${(props) =>
    props.status === inProgressStatusKey
      ? css`
          color: ${({ theme }) => theme.color.disabledText};
          background-color: ${({ theme }) => theme.color.disabled};
        `
      : props.status === stagingStatusKey
      ? css`
          color: white;
          background-color: ${({ theme }) => theme.color.lightBlue};
        `
      : props.status === pausedStatusKey
      ? css`
          color: white;
          background-color: ${({ theme }) => theme.color.lightBlue};
        `
      : css`
          color: white;
          background-color: ${({ theme }) => theme.color.brightGreen};
        `}
  min-width: ${(props) => props.fixedMinWidth && `${props.fixedMinWidth}px`};
  margin-left: 10px;
  margin-top: 3px;
`;

export const CampaignActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${SmallGapStyle}
`;

export const ActionIconContainer = styled.div`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: ${(props) =>
    props.variant === 'start'
      ? `1px solid ${props.theme.color.brightGreen}`
      : props.variant === 'stop'
      ? `1px solid ${props.theme.color.lightError}`
      : `1px solid ${props.theme.color.lightPrimary}`};
  color: ${(props) =>
    props.variant === 'start'
      ? props.theme.color.brightGreen
      : props.variant === 'stop'
      ? props.theme.color.lightError
      : props.theme.color.lightPrimary};
  background-color: white;
  ${CenterFlexStyle}
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: ${(props) =>
      props.variant === 'start'
        ? props.theme.color.brightGreen
        : props.variant === 'stop'
        ? props.theme.color.lightError
        : props.theme.color.lightPrimary};
    color: white;
  }
`;

const CampaignActionIconStyle = css`
  ${SMMIconSize}
  color: inherit;
`;

export const LaunchCampaignIcon = styled(RocketIcon)`
  ${CampaignActionIconStyle}
`;

export const PauseCampaignIcon = styled(StopIcon)`
  ${CampaignActionIconStyle}
`;

export const ContactsIcon = styled(PersonIcon)`
  ${CampaignActionIconStyle}
`;

export const PromoIcon = styled(LocalOfferIcon)`
  ${CampaignActionIconStyle}
  height: 20px;
  width: 20px;
`;

export const TemplatesIcon = styled(FormatQuoteIcon)`
  ${CampaignActionIconStyle}
  ${SMIconSize}
`;

export const ScriptIcon = styled(BaseScriptIcon)`
  ${CampaignActionIconStyle}
  ${SmallIconSize}
`;

export const SmartListActionIcon = styled(SmartListIcon)`
  ${CampaignActionIconStyle}
  height: 19px;
  width: 19px;
`;

export const ActionIncompleteIcon = styled(ExclamationIcon)`
  color: ${({ theme }) => theme.color.orange};
  position: absolute;
  top: -9px;
  right: -6px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.orange};
`;
