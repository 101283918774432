import gql from 'graphql-tag';

export const GET_CHAT = gql`
  query getChat($chatId: String!) {
    getChat(chatId: $chatId) {
      id
      status
      campaignId
      locationId
      medium
      type
      phoneNumber
      messages {
        id
        sender
        content
        sentAt
        attachments
        delivered
        sequence
      }
      agentName
      client {
        id
        firstName
        lastName
      }
      disengagement {
        disengagerUserId
        disengagementReason
      }
      interception {
        interceptorUserId
        interceptionReason
      }
      appointmentStatuses
    }
  }
`;

export const GET_CHAT_WITH_MESSAGES = gql`
  query getChatMessages($chatId: String!) {
    getChatMessages(chatId: $chatId) {
      chat {
        id
        status
        campaignId
        medium
        disengagement {
          disengagerUserId
          disengagementReason
        }
        interception {
          interceptorUserId
          interceptionReason
        }
        appointmentStatuses
      }
      messages {
        id
        sender
        senderUserId
        content
        sentAt
        attachments
        delivered
        metadata
        sequence
      }
      calls {
        id
        chatId
        durationSeconds
        direction
        transcript
        metadata
        fromNumber
        bookmark
      }
      newMessages {
        id
        sender
        senderUserId
        content
        sentAt
        delivered
      }
    }
  }
`;

export const GET_MY_LOCATION_CHATS = gql`
  query getMyLocationChats {
    getMyLocationChats {
      id
      status
      campaignId
      medium
      numMessages
      lastMessageAt
      createdAt
      messages {
        id
        sender
        senderUserId
        content
        sentAt
      }
      agentName
      client {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_CHAT_EVENTS = gql`
  query getChatEvents($cursor: String, $contactName: String) {
    getChatEvents(cursor: $cursor, contactName: $contactName)
  }
`;

export const GET_MY_CHATS_UPDATED_AT = gql`
  query getMyChatsUpdatedAt {
    getMyChatsUpdatedAt {
      timestamp
    }
  }
`;
