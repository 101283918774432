import styled from 'styled-components';
import { TextInput } from '../../../styles/shared-styled-components';

export const ClientSearchBoxHeight = 35;

export const ClientSearchBoxComponent = styled(TextInput).attrs({
  fixedHeight: ClientSearchBoxHeight,
  useExtraSmallText: true,
})`
  margin: unset;
  max-width: 250px;
`;
