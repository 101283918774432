import { sortBy } from 'lodash';
import { default as React, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OptionsFilterDropdown from '../../../../components/Analytics/OptionsFilterDropdown/OptionsFilterDropdown';
import { FilterSectionContainer } from '../../../../components/Analytics/shared-analytics-styles';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Header from '../../../../components/NavBar/Header';
import ClientSearchBox from '../../../../components/Search/ClientSearchBox/ClientSearchBox';
import Snackbar from '../../../../components/Snackbar';
import { EmptyDataContainer } from '../../../../components/Training/shared-training-components';
import Paths from '../../../../Paths';
import {
  BaseCard,
  CardActionsContainer,
  CardHeaderContainer,
  CardPageSection,
  CenteredDivWithLargerGap,
  ColumnDivWithGap,
  ContentContainer,
  DarkEssText,
  ExtraSmallText,
  LightDarkLargeTinyText,
  LightDarkTextSpan,
  MediumGapColumnCenteredDiv,
  NoDataIcon,
  PageContainer,
  PageTitleText,
  SecondaryBackIcon,
  StartAlignedMediumDarkLargeTinyText,
  TinyPrimaryButton,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  activeMemberContactTypeKey,
  appointmentContactTypeLabelMap,
  ascendingKey,
  cancelledStatusKey,
  clientContactTypeKey,
  completeStatusKey,
  descendingKey,
  expiredMemberContactTypeKey,
  inboundKey,
  leadContactType,
  noShowStatusKey,
  scheduledStatusKey,
  stagingFilterConversionDelay,
  suspendedMemberContactTypeKey,
  textSeparatorChar,
} from '../../../../utils/constants';
import { dateToTextFormat } from '../../../../utils/date';
import { formatNumber } from '../../../../utils/numbers';
import { formatKeyToLabel } from '../../../../utils/string';
import { CountContainer, StatusChip } from './styled';

const eventLabelMap = {
  follow_up: <>📲&nbsp;&nbsp;Follow-up requests</>,
  appointment: <>🗓️&nbsp;&nbsp;Appointment bookings</>,
  bookmark: <>🔖 &nbsp;&nbsp;Bookmarks</>,
};

const getTransactionIcon = (key, count) => {
  return (
    <>
      {eventLabelMap[key]} ({count})
    </>
  );
};

const revenueTypes = [
  {
    key: 'a',
    label: 'Services',
  },
  {
    key: 'b',
    label: 'Add-ons',
  },
  {
    key: 'a',
    label: 'Product',
  },
];

const appointmentData = sortBy(
  [
    {
      name: 'Jodie Smith',
      date: 'October 29th',
      datetime: '2024-10-29T14:00:00Z',
      status: 'complete',
      type: 'appt',
      total: 165,
      transactions: [
        {
          type: 'appt',
          price: 140,
          description: 'Swedish Massage with Kelly',
        },
        {
          type: 'addon',
          price: 15,
          description: 'Warm Stone Add-On',
        },
      ],
    },
    {
      name: 'Mark Johnson',
      date: 'November 5th',
      datetime: '2024-11-05T11:00:00Z',
      status: 'scheduled',
      type: 'appt',
      total: 110,
      transactions: [
        {
          type: 'appt',
          price: 110,
          description: '60-Minute Deep Tissue Massage with Sarah',
        },
      ],
    },
    {
      name: 'Emily Turner',
      date: 'October 25th',
      datetime: '2024-10-25T16:30:00Z',
      status: 'complete',
      type: 'appt',
      total: 195,
      transactions: [
        {
          type: 'appt',
          price: 150,
          description: 'Signature Facial with Amanda',
        },
        {
          type: 'product',
          price: 45,
          description: 'Cleansing Serum',
        },
      ],
    },
    {
      name: 'Michael Clark',
      date: 'November 2nd',
      datetime: '2024-11-02T09:00:00Z',
      status: 'scheduled',
      type: 'appt',
      total: 135,
      transactions: [
        {
          type: 'appt',
          price: 120,
          description: '90-Minute Assisted Stretch with John',
        },
        {
          type: 'addon',
          price: 15,
          description: 'Aromatherapy Add-On',
        },
      ],
    },
    {
      name: 'Samantha Brooks',
      date: 'October 20th',
      datetime: '2024-10-20T13:45:00Z',
      status: 'complete',
      type: 'appt',
      total: 220,
      transactions: [
        {
          type: 'appt',
          price: 180,
          description: 'Hot Stone Massage with Megan',
        },
        {
          type: 'addon',
          price: 20,
          description: 'CBD Oil Add-On',
        },
        {
          type: 'product',
          price: 20,
          description: 'Relaxation Oil',
        },
      ],
    },
    {
      name: 'Chris Evans',
      date: 'November 10th',
      datetime: '2024-11-10T17:00:00Z',
      status: 'scheduled',
      type: 'appt',
      total: 155,
      transactions: [
        {
          type: 'appt',
          price: 140,
          description: 'Sports Massage with David',
        },
        {
          type: 'addon',
          price: 15,
          description: 'Stretch Add-On',
        },
      ],
    },
    {
      name: 'Linda Foster',
      date: 'October 18th',
      datetime: '2024-10-18T18:00:00Z',
      status: 'complete',
      type: 'appt',
      total: 275,
      transactions: [
        {
          type: 'appt',
          price: 220,
          description: '90-Minute Couples Massage',
        },
        {
          type: 'addon',
          price: 30,
          description: 'Hot Towel Treatment',
        },
        {
          type: 'product',
          price: 25,
          description: 'Massage Lotion',
        },
      ],
    },
    {
      name: 'Tom Richards',
      date: 'November 12th',
      datetime: '2024-11-12T15:30:00Z',
      status: 'scheduled',
      type: 'appt',
      total: 95,
      transactions: [
        {
          type: 'appt',
          price: 95,
          description: 'Express Facial with Laura',
        },
      ],
    },
    {
      name: 'Sarah Williams',
      date: 'October 30th',
      datetime: '2024-10-30T12:00:00Z',
      status: 'complete',
      type: 'appt',
      total: 160,
      transactions: [
        {
          type: 'appt',
          price: 130,
          description: 'Stretch Therapy with Jeff',
        },
        {
          type: 'addon',
          price: 30,
          description: 'Compression Therapy Add-On',
        },
      ],
    },
    {
      name: 'Robert Peterson',
      date: 'November 8th',
      datetime: '2024-11-08T10:00:00Z',
      status: 'scheduled',
      type: 'appt',
      total: 130,
      transactions: [
        {
          type: 'appt',
          price: 130,
          description: 'Therapeutic Massage with Alice',
        },
      ],
    },
  ],
  (a) => a.datetime,
).reverse();

const sortOptions = [
  {
    key: `revenue_${descendingKey}`,
    label: 'Revenue (most to least)',
  },
  {
    key: `revenue_${ascendingKey}`,
    label: 'Revenue (least to most)',
  },
  {
    key: `status_${ascendingKey}`,
    label: 'Appointment status (best to worst)',
  },
  {
    key: `status_${descendingKey}`,
    label: 'Appointment status (worst to best)',
  },
  {
    key: `date_${ascendingKey}`,
    label: 'Date (earliest to latest)',
  },
  {
    key: `date_${descendingKey}`,
    label: 'Date (latest to earliest)',
  },
  {
    key: `clientType_${ascendingKey}`,
    label: 'Client type (least to most familiar)',
  },
  {
    key: `clientType_${descendingKey}`,
    label: 'Client type (most to least familiar)',
  },
  {
    key: `clientName_${ascendingKey}`,
    label: 'Client name (asc.)',
  },
  {
    key: `clientName_${descendingKey}`,
    label: 'Client name (desc.)',
  },
];

const statusOrder = [
  completeStatusKey,
  scheduledStatusKey,
  cancelledStatusKey,
  noShowStatusKey,
];
const clientTypeOrder = [
  leadContactType,
  clientContactTypeKey,
  expiredMemberContactTypeKey,
  suspendedMemberContactTypeKey,
  activeMemberContactTypeKey,
];

const Appointments = ({
  appointments,
  clients,
  loading,
  agentName,
  campaigns,
}) => {
  const navigate = useNavigate();

  const { user, drawerOpen, drawerExpanded } = useContext(BaseContext);

  const [sortField, setSortField] = useState({
    key: 'revenue',
    descending: true,
  });
  const [contactNameSearch, setContactNameSearch] = useState('');
  const [stagingContactNameSearch, setStagingContactNameSearch] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingContactNameSearch !== contactNameSearch) {
        setContactNameSearch(stagingContactNameSearch);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingContactNameSearch]);

  const formattedAppointments = [];
  const allAppointments = Object.values(appointments);
  allAppointments.map((a) => {
    const client = clients?.[a.clientId];
    const clientName = client?.name || '';

    if (
      !contactNameSearch ||
      clientName.toLowerCase().includes(contactNameSearch.toLowerCase())
    ) {
      const items = a.items;
      const revenue =
        items?.length && a.status === completeStatusKey
          ? items?.reduce((total, obj) => total + obj.amount, 0)
          : a.status === completeStatusKey
          ? 0
          : -1;
      formattedAppointments.push({
        ...a,
        clientName: clientName || 'Name unknown',
        chatId: client?.chatId,
        revenue,
      });
    }
  });

  let sortedAppointments = sortBy(formattedAppointments, (a) => {
    const key = sortField.key;
    if (key === 'status') {
      return statusOrder.indexOf(a.status);
    }
    if (key === 'clientType') {
      return clientTypeOrder.indexOf(a.clientType);
    }
    return a[key];
  });
  if (sortField.descending) {
    sortedAppointments = sortedAppointments.reverse();
  }
  const totalNumAppointments = allAppointments.length;
  const numFilteredAppointments = sortedAppointments.length;

  const campaignMap = {};
  campaigns.map((c) => {
    campaignMap[c.key] = c.label;
  });

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <CenteredDivWithLargerGap bottomMargin={10}>
            <Tooltip
              title={<TooltipTitleText>Back to dashboard</TooltipTitleText>}
              placement='left'
            >
              <SecondaryBackIcon onClick={() => navigate(Paths.dashboard)} />
            </Tooltip>
            <PageTitleText>Appointments</PageTitleText>
          </CenteredDivWithLargerGap>
          <CardPageSection>
            <FilterSectionContainer spaced>
              <CountContainer>
                <LightDarkLargeTinyText>
                  {contactNameSearch
                    ? `${numFilteredAppointments} filtered appointment${
                        numFilteredAppointments === 1 ? '' : 's'
                      }`
                    : `${totalNumAppointments} total appointment${
                        totalNumAppointments === 1 ? '' : 's'
                      }`}{' '}
                </LightDarkLargeTinyText>
              </CountContainer>
              <ClientSearchBox
                entityLabel='client'
                value={stagingContactNameSearch}
                onChange={(e) => setStagingContactNameSearch(e.target.value)}
              />
              <OptionsFilterDropdown
                label='Sort by'
                options={sortOptions}
                isMultiSelect={false}
                filtered={[
                  `${sortField.key}_${
                    sortField.descending
                      ? `${descendingKey}`
                      : `${ascendingKey}`
                  }`,
                ]}
                onChange={(updatedSortField) => {
                  const values = updatedSortField.split('_');
                  setSortField({
                    key: values[0],
                    descending: values[1] === descendingKey,
                  });
                }}
              />
            </FilterSectionContainer>
            <MediumGapColumnCenteredDiv>
              {sortedAppointments.length ? (
                <>
                  {sortedAppointments.map((a) => {
                    const {
                      clientId,
                      items,
                      date,
                      staff,
                      status,
                      services,
                      clientType,
                      attribution,
                    } = a;
                    const client = clients[clientId];
                    const clientName = client?.name || 'Unknown client';
                    const hasItems = items?.length;
                    const total = items?.reduce(
                      (total, obj) => total + obj.amount,
                      0,
                    );
                    const dateStr = dateToTextFormat(date);
                    const title =
                      status === completeStatusKey
                        ? `${clientName} - ${
                            hasItems
                              ? total
                                ? `Received $${formatNumber(total)}`
                                : `Free Appointment`
                              : `Revenue Unknown`
                          }`
                        : `${clientName} - ${formatKeyToLabel(status)}`;
                    const campaignKey = attribution.campaignId;
                    const campaignNameText =
                      campaignKey === inboundKey
                        ? `through an inbound phone call`
                        : `as part of the '${campaignMap[campaignKey]}' campaign`;
                    const attributionText = `Booked ${
                      attribution.booker === 'chat'
                        ? `directly by ${agentName}`
                        : `indirectly, prompted by ${agentName}`
                    }, ${campaignNameText}`;
                    return (
                      <BaseCard>
                        <ColumnDivWithGap>
                          <CardHeaderContainer>
                            <DarkEssText>
                              {title}
                              <LightDarkTextSpan>
                                &nbsp;&nbsp;
                                {textSeparatorChar}&nbsp;&nbsp;{dateStr}
                              </LightDarkTextSpan>
                            </DarkEssText>
                            <StatusChip
                              status={status}
                              fixedMinWidth={80}
                            >
                              {formatKeyToLabel(status)}
                            </StatusChip>
                          </CardHeaderContainer>
                          <StartAlignedMediumDarkLargeTinyText>
                            🗓️&nbsp;&nbsp;{attributionText}
                            <br></br>
                            {clientType === leadContactType &&
                            [completeStatusKey, scheduledStatusKey].includes(
                              status,
                            ) ? (
                              <>🙌&nbsp;&nbsp;New client converted!</>
                            ) : (
                              <>
                                👤&nbsp;&nbsp;
                                {appointmentContactTypeLabelMap[clientType]}
                              </>
                            )}
                            <br></br>
                            <br></br>
                            {status === completeStatusKey ? (
                              <>
                                {hasItems ? (
                                  <>
                                    Received services:{' '}
                                    {sortBy(items, (i) => i.amount)
                                      .reverse()
                                      .map(
                                        (i) =>
                                          `${i.name} ($${formatNumber(
                                            i.amount,
                                          )})`,
                                      )
                                      .join(', ')}
                                  </>
                                ) : (
                                  <>
                                    Received services: {services.join(', ')}{' '}
                                    (Unknown revenue)
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {status === scheduledStatusKey
                                  ? `Scheduled`
                                  : `Was scheduled`}{' '}
                                to receive: {services.join(', ')}
                              </>
                            )}
                            <br></br>
                            With staff: {staff}
                          </StartAlignedMediumDarkLargeTinyText>
                        </ColumnDivWithGap>
                        <CardActionsContainer>
                          <TinyPrimaryButton
                            largePadding
                            onClick={() => {
                              const chatPath = `${Paths.chats}?chat-id=${client.chatId}`;
                              navigate(chatPath);
                            }}
                          >
                            View chat
                          </TinyPrimaryButton>
                        </CardActionsContainer>
                      </BaseCard>
                    );
                  })}
                </>
              ) : (
                <>
                  <EmptyDataContainer>
                    <NoDataIcon />
                    <ExtraSmallText>No appointments found</ExtraSmallText>
                  </EmptyDataContainer>
                </>
              )}
            </MediumGapColumnCenteredDiv>
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Appointments;
