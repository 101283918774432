import React from 'react';
import { SmallCheckbox } from '../../../../styles/shared-styled-components';
import { dollarInputType, integerInputType } from '../../../../utils/constants';
import { safeParseInt } from '../../../../utils/numbers';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptTitleText,
  SpacedPromptCheckboxContainer,
} from '../../shared-training-components';

const Cancellations = ({ policy, onChange, onBlur, loading }) => {
  const cancellationPolicy = policy?.cancellation || {};

  const amount = cancellationPolicy?.amount || '';
  const noticeHours = cancellationPolicy?.noticeHours || '';
  const noShowFeeAmount = cancellationPolicy?.noShowFeeAmount || '';
  const policyUrl = cancellationPolicy?.policyUrl || '';
  const appliesToMembers = cancellationPolicy?.appliesToMembers || '';
  const shouldMention = cancellationPolicy?.shouldMention || '';

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer>
        <PromptTitleText>Cancellation fee</PromptTitleText>
        <PromptInput
          value={amount}
          type={dollarInputType}
          onChange={(e) => {
            onChange({
              ...cancellationPolicy,
              amount: safeParseInt(e.target.value),
            });
          }}
        />
      </PromptContainer>
      <PromptContainer>
        <PromptTitleText>Notice hours required</PromptTitleText>
        <PromptInput
          value={noticeHours}
          type={integerInputType}
          onChange={(e) => {
            onChange({
              ...cancellationPolicy,
              noticeHours: safeParseInt(e.target.value),
            });
          }}
        />
      </PromptContainer>
      <PromptContainer>
        <PromptTitleText>No-show fee</PromptTitleText>
        <PromptInput
          value={noShowFeeAmount}
          type={dollarInputType}
          onChange={(e) => {
            onChange({
              ...cancellationPolicy,
              noShowFeeAmount: safeParseInt(e.target.value),
            });
          }}
        />
      </PromptContainer>
      <PromptContainer>
        <PromptTitleText>Policy URL</PromptTitleText>
        <PromptInput
          value={policyUrl}
          onChange={(e) => {
            onChange({
              ...cancellationPolicy,
              policyUrl: e.target.value,
            });
          }}
        />
      </PromptContainer>
      <PromptContainer>
        <SpacedPromptCheckboxContainer>
          <PromptTitleText>Policy applies to members</PromptTitleText>
          <SmallCheckbox
            checked={appliesToMembers}
            onClick={(e) => {
              onChange({
                ...cancellationPolicy,
                appliesToMembers: e.target.checked,
              });
            }}
          />
        </SpacedPromptCheckboxContainer>
        <SpacedPromptCheckboxContainer>
          <PromptTitleText>Mention policy when booking</PromptTitleText>
          <SmallCheckbox
            checked={shouldMention}
            onClick={(e) => {
              onChange({
                ...cancellationPolicy,
                shouldMention: e.target.checked,
              });
            }}
          />
        </SpacedPromptCheckboxContainer>
      </PromptContainer>
    </PromptsSection>
  );
};

export default Cancellations;
