import { useState } from 'react';
import {
  MenuDownArrow,
  MenuItem,
  MenuUpArrow,
  SelectMenuItem,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
} from '../../../styles/shared-styled-components';
import {
  dynamicVariableType,
  staticVariableType,
} from '../../../utils/constants';
import PopperMenu from '../../PopperMenu';
import { VariablesInputButton, VariablesInputContainer } from './styled';

const CustomVariablesMenu = ({
  variables,
  onAddVariable,
  showTypeToggle = true,
  disabled,
}) => {
  const [variablesMenuAnchorEl, setVariablesMenuAnchorEl] = useState();
  const [typeToDisplay, setTypeToDisplay] = useState(staticVariableType);

  const variablesToDisplay = !showTypeToggle
    ? variables
    : variables?.filter((v) => v.type === typeToDisplay);

  const variablesMenuOpenBoolean = Boolean(variablesMenuAnchorEl);

  const onInsert = (variable) => {
    onAddVariable(variable);
    setVariablesMenuAnchorEl();
  };

  return (
    <>
      <VariablesInputContainer>
        <VariablesInputButton
          disabled={disabled}
          onClick={(e) => {
            if (!disabled) {
              setVariablesMenuAnchorEl(e.currentTarget);
            }
          }}
        >
          Insert variable{' '}
          {variablesMenuOpenBoolean ? <MenuUpArrow /> : <MenuDownArrow />}
        </VariablesInputButton>
      </VariablesInputContainer>
      <PopperMenu
        open={variablesMenuOpenBoolean}
        anchorElement={variablesMenuAnchorEl}
        onClose={() => setVariablesMenuAnchorEl()}
        variant='offset'
      >
        <SmallMenuItemTitleContainer disableRipple>
          <SmallMenuItemTitleText>
            Select a variable to insert
          </SmallMenuItemTitleText>
        </SmallMenuItemTitleContainer>
        {showTypeToggle && (
          <MenuItem disableHover>
            <SelectMenuItem>
              <SmallMenuItemText
                selected={typeToDisplay === staticVariableType}
                onClick={() => {
                  if (typeToDisplay !== staticVariableType) {
                    setTypeToDisplay(staticVariableType);
                  }
                }}
              >
                Static Variables
              </SmallMenuItemText>
            </SelectMenuItem>
            <SelectMenuItem>
              <SmallMenuItemText
                selected={typeToDisplay === dynamicVariableType}
                onClick={() => {
                  if (typeToDisplay !== dynamicVariableType) {
                    setTypeToDisplay(dynamicVariableType);
                  }
                }}
              >
                Dynamic Variables
              </SmallMenuItemText>
            </SelectMenuItem>
          </MenuItem>
        )}
        {variablesToDisplay.map((variable) => {
          return (
            <MenuItem
              onClick={() => {
                onInsert(variable.value);
              }}
            >
              <SmallMenuItemText>{variable.name}</SmallMenuItemText>
            </MenuItem>
          );
        })}
      </PopperMenu>
    </>
  );
};

export default CustomVariablesMenu;
