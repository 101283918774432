import styled from 'styled-components';
import {
  CenteredDiv,
  FixedWidthCheckboxContainer,
  FixedWidthDiv,
} from '../../../styles/shared-styled-components';

export const SequenceCheckboxContainer = styled(
  FixedWidthCheckboxContainer,
).attrs({
  fixedWidth: '420px',
})``;

export const SequenceInputDiv = styled(FixedWidthDiv).attrs({
  width: 420,
})``;

export const CadenceTabsContainer = styled(CenteredDiv)`
  margin-top: 20px;
  margin-bottom: 10px;
`;
