import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import { GET_CONTACT_TAGS, GET_MY_CAMPAIGNS } from '../queries/campaigns';

export const useMyCampaigns = ({ skipCondition, shallow = false }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_CAMPAIGNS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
    variables: {
      shallow,
    },
  });

  const campaigns = get(data, 'getMyCampaigns', null);

  return {
    error,
    loading,
    refetch,
    campaigns,
  };
};

export const useContactTags = () => {
  const { data, error, loading, refetch } = useQuery(GET_CONTACT_TAGS, {
    fetchPolicy: 'cache-first',
  });

  const tags = get(data, 'getContactTags', []);

  return {
    error,
    loading,
    refetch,
    tags,
  };
};
