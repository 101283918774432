import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useMyCampaigns } from '../../../api/hooks/campaigns';
import { CREATE_CAMPAIGN_SEQUENCE } from '../../../api/mutations/sequence';
import {
  DarkMLText,
  InputWidthDiv,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  StartAlignedMediumDarkExtraSmallText,
  TinyGapColumnCenteredDiv,
} from '../../../styles/shared-styled-components';
import { dropdownInputType, nowKey } from '../../../utils/constants';
import { formatNumber, safeParseInt } from '../../../utils/numbers';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';

const getSequenceBatchSizeOptions = (numContactable) => {
  const keys = [1, 5, 10, 50, 100, 200, 350, 500, 750, 1000];

  const options = [];

  if (!numContactable) {
    return [];
  }

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (key <= numContactable) {
      options.push({
        key,
        label: `${formatNumber(key)} member${key !== 1 ? 's' : ''}`,
      });
    }
  }

  options.push({
    key: numContactable,
    label: `All contactable (${formatNumber(numContactable)})`,
  });

  return options;
};

const CreateSequenceModal = ({ isOpen, onClose, onCreate }) => {
  const {
    campaigns,
    loading: campaignsLoading,
    refetch: refetchCampaigns,
  } = useMyCampaigns({});
  const [createCampaignSequenceMutation, { loading: createSequenceLoading }] =
    useMutation(CREATE_CAMPAIGN_SEQUENCE);

  const campaignMemberMap = {};
  const campaignOptions = campaigns?.map((c) => {
    campaignMemberMap[c.id] = {
      enrolled: c.numMembers,
      contacted: c.numContacted,
      contactable:
        safeParseInt(c.numMembers) - safeParseInt(c.numContacted) > 0
          ? safeParseInt(c.numMembers) - safeParseInt(c.numContacted)
          : 0,
    };
    return {
      key: c.id,
      label: c.name,
    };
  });

  const [campaignId, setCampaignId] = useState();
  const [batchSize, setBatchSize] = useState();

  const handleClose = () => {
    setCampaignId();
    setBatchSize();
    onClose();
  };

  const handleCreate = () => {
    createCampaignSequenceMutation({
      variables: {
        campaignId,
        batchSize: safeParseInt(batchSize),
        runTime: nowKey,
      },
      onCompleted: async (data) => {
        const success = data.createCampaignSequence;

        handleClose();
        await onCreate(success);
      },
    });
  };

  if (createSequenceLoading || campaignsLoading) {
    return <LoadingIndicator />;
  }

  const contactableForSelectedCampaign =
    campaignMemberMap[campaignId]?.contactable;
  const canContactForCampaign =
    !!campaignId && !!contactableForSelectedCampaign;
  const createEnabled = canContactForCampaign && !!batchSize;

  const batchSizeOptions = getSequenceBatchSizeOptions(
    contactableForSelectedCampaign,
  );
  //   {
  //     key: 1,
  //     label: formatNumber(1),
  //   },
  //   {
  //     key: 10,
  //     label: formatNumber(10),
  //   },
  //   {
  //     key: 50,
  //     label: formatNumber(50),
  //   },
  //   {
  //     key: 100,
  //     label: formatNumber(100),
  //   },
  //   {
  //     key: 200,
  //     label: formatNumber(200),
  //   },
  //   {
  //     key: 350,
  //     label: formatNumber(350),
  //   },
  //   {
  //     key: 500,
  //     label: formatNumber(500),
  //   },
  //   {
  //     key: 750,
  //     label: formatNumber(750),
  //   },
  //   {
  //     key: 1000,
  //     label: formatNumber(1000),
  //   },
  //   {
  //     key: allKey,
  //     label: `All contactable (${formatNumber(
  //       contactableForSelectedCampaign,
  //     )})`,
  //   },
  // ];

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <ModalContainer>
          <DarkMLText>Create Sequence</DarkMLText>
          <MediumGapColumnCenteredDiv>
            <TinyGapColumnCenteredDiv>
              <Input
                id='campaignId'
                value={campaignId}
                onChange={(e) => {
                  setCampaignId(e.target.value);
                }}
                label={'Campaign to run sequence for'}
                isRequired={false}
                type={dropdownInputType}
                options={campaignOptions}
              />
              {campaignId && (
                <InputWidthDiv>
                  <StartAlignedMediumDarkExtraSmallText>
                    {formatNumber(campaignMemberMap[campaignId].enrolled)}{' '}
                    enrolled {' - '}
                    {formatNumber(campaignMemberMap[campaignId].contacted)}{' '}
                    contacted{' -  '}
                    {formatNumber(
                      campaignMemberMap[campaignId].contactable,
                    )}{' '}
                    contactable
                  </StartAlignedMediumDarkExtraSmallText>
                </InputWidthDiv>
              )}
            </TinyGapColumnCenteredDiv>
            {canContactForCampaign && (
              <Input
                id='batch-size'
                value={batchSize}
                onChange={(e) => {
                  setBatchSize(e.target.value);
                }}
                label={'Batch size'}
                isRequired={false}
                type={dropdownInputType}
                options={batchSizeOptions}
                useExtraSmallGap
              />
            )}
          </MediumGapColumnCenteredDiv>
          <ModalActions>
            <MediumPrimaryButton
              disabled={!createEnabled}
              onClick={handleCreate}
            >
              Create
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={handleClose}>
              Close
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default CreateSequenceModal;
