import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useChatAppointments } from '../../../api/hooks/calendar';
import { SAVE_CHAT_APPOINTMENT_ASSIGNMENTS } from '../../../api/mutations/calendar';
import {
  BrightGreenText,
  CenteredDiv,
  ColumnCenteredDiv,
  MLText,
  MediumDarkSmallText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalTitleTextContainer,
  NextPageButton,
  OrangeText,
  PreviousPageButton,
} from '../../../styles/shared-styled-components';
import {
  appointmentDepositRequiredStatus,
  appointmentMonitoringStatus,
  assistantKey,
} from '../../../utils/constants';
import { getTimezoneStringFromUTCDatetime } from '../../../utils/date';
import { getFormattedFullNameFromUser, getInitials } from '../../../utils/name';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../LoadingIndicator';

const ViewChatAppointmentsModal = ({
  isOpen,
  onClose,
  chatId,
  clientName,
  onSave,
}) => {
  const { inDemoMode } = useContext(BaseContext);

  const { data, loading, refetch } = useChatAppointments({
    chatId,
    onlyBooked: false,
    fetchPolicy: 'network-only',
  });
  const chatAppointments = data?.appointments || [];
  const timezone = data?.timezone;

  const [saveChatAppointmentAssignmentsMutation, { loading: saveLoading }] =
    useMutation(SAVE_CHAT_APPOINTMENT_ASSIGNMENTS);

  const [appointmentIdx, setAppointmentIdx] = useState(0);

  useEffect(() => {
    if (chatId) {
      refetch();
    }
  }, [chatId, isOpen]);

  const assignToChat = (appointmentToInput) => {
    const formattedAppointmentToInput = {
      chatId,
      currentlyAssigned: false,
      updatedAssigned: true,
      bookerType: assistantKey,
      bookerUserId: null,
      appointmentId: appointmentToInput.appointment.id,
    };

    saveChatAppointmentAssignmentsMutation({
      variables: {
        appointments: [formattedAppointmentToInput],
        appliedPromo: {},
      },
      onCompleted: async (data) => {
        const success = data.saveChatAppointmentAssignments;
        if (success) {
          onSave();
        }
        onClose();
      },
    });
  };

  const numAppointments = chatAppointments?.length;
  const chatAppointmentToView = numAppointments
    ? chatAppointments[appointmentIdx]
    : null;
  const chatAppointmentToViewStatus = chatAppointmentToView?.status;
  const chatAppointmentToViewAssignedToChat =
    chatAppointmentToView?.assignedToChat;

  if (isOpen && loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    chatId && (
      <Modal
        open={isOpen}
        onClose={onClose}
        maxWidth={600}
      >
        <ModalContainer>
          <ModalTitleTextContainer>
            <MLText>
              Appointments for {inDemoMode ? `${clientName?.[0]}` : clientName}
            </MLText>
          </ModalTitleTextContainer>
          <ColumnCenteredDiv>
            <MediumDarkSmallText>
              {chatAppointmentToViewStatus === appointmentMonitoringStatus ? (
                <>
                  <OrangeText useSmSize>Monitoring</OrangeText>
                  <br></br>
                  {clientName} hasn't yet booked, but they've expressed interest
                  in booking at a later date. So your agent will monitor the
                  calendar, and if a matching appointment comes in, the record
                  of this chat will be updated to reflect the booking.
                </>
              ) : (
                <>
                  {chatAppointmentToViewAssignedToChat ? (
                    <BrightGreenText>Booked through chat</BrightGreenText>
                  ) : (
                    <>Unassigned to chat</>
                  )}
                  <br></br>
                  {getTimezoneStringFromUTCDatetime(
                    chatAppointmentToView?.appointment?.start,
                    timezone,
                  )}
                  <br></br>
                  {chatAppointmentToView?.appointment?.sessionTypeName ||
                    'Unknown service'}{' '}
                  {chatAppointmentToView?.appointment?.staff &&
                    ` with ${
                      inDemoMode
                        ? getInitials(
                            chatAppointmentToView?.appointment?.staff.firstName,
                            chatAppointmentToView?.appointment?.staff.lastName,
                          )
                        : getFormattedFullNameFromUser(
                            chatAppointmentToView?.appointment?.staff,
                          )
                    }`}
                  {chatAppointmentToView?.status ===
                    appointmentDepositRequiredStatus && (
                    <>
                      <br></br>
                      Awaiting deposit payment
                    </>
                  )}
                </>
              )}
            </MediumDarkSmallText>
            {!chatAppointmentToViewAssignedToChat && (
              <MediumPrimaryButton
                onClick={() => assignToChat(chatAppointmentToView)}
              >
                Assign to chat
              </MediumPrimaryButton>
            )}
            {numAppointments > 1 && (
              <CenteredDiv>
                <PreviousPageButton
                  disabled={appointmentIdx === 0}
                  onClick={() => {
                    if (appointmentIdx !== 0) {
                      setAppointmentIdx(appointmentIdx - 1);
                    }
                  }}
                />
                <NextPageButton
                  disabled={appointmentIdx === numAppointments - 1}
                  onClick={() => {
                    if (appointmentIdx !== numAppointments - 1) {
                      setAppointmentIdx(appointmentIdx + 1);
                    }
                  }}
                />
              </CenteredDiv>
            )}
          </ColumnCenteredDiv>
          <ModalActions>
            <MediumSecondaryButton onClick={onClose}>
              Close
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    )
  );
};

export default ViewChatAppointmentsModal;
