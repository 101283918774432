import { gql } from '@apollo/client';

export const GET_MIND_BODY_DATA = gql`
  query getMindBodyData {
    getMindBodyData {
      id
      values
    }
  }
`;

export const GET_MINDBODY_SITE_ACTIVATION_CODE_URL = gql`
  query getMindBodySiteActivationCodeUrl(
    $siteId: String
    $cacheBreaker: String
  ) {
    getMindBodySiteActivationCodeUrl(
      siteId: $siteId
      cacheBreaker: $cacheBreaker
    )
  }
`;

export const VERIFY_MINDBODY_SITE_CONNECTION = gql`
  query verifyMindBodySiteConnection($cacheBreaker: String) {
    verifyMindBodySiteConnection(cacheBreaker: $cacheBreaker)
  }
`;

export const GET_QUICKBOOKS_AUTH_URI = gql`
  query getQuickbooksAuthUri {
    getQuickbooksAuthUri
  }
`;

export const GET_QUICKBOOKS_DATA_SYNC_STATUS = gql`
  query getQuickbooksDataSyncStatus($realmId: String) {
    getQuickbooksDataSyncStatus(realmId: $realmId)
  }
`;

export const GET_GHL_AUTH_URI = gql`
  query getGHLAuthUri {
    getGHLAuthUri
  }
`;

export const GET_GHL_DATA_SYNC_STATUS = gql`
  query getGHLDataSyncStatus($tid: String) {
    getGHLDataSyncStatus(tid: $tid)
  }
`;

export const GET_GOOGLE_ADS_AUTH_URI = gql`
  query getGoogleAdsAuthUri {
    getGoogleAdsAuthUri
  }
`;

export const GET_GOOGLE_ADS_DATA_SYNC_STATUS = gql`
  query getGoogleAdsDataSyncStatus($tid: String) {
    getGoogleAdsDataSyncStatus(tid: $tid)
  }
`;

export const GET_FACEBOOK_ADS_AUTH_URI = gql`
  query getFacebookAdsAuthUri {
    getFacebookAdsAuthUri
  }
`;

export const GET_FACEBOOK_ADS_DATA_SYNC_STATUS = gql`
  query getFacebookAdsDataSyncStatus($tid: String) {
    getFacebookAdsDataSyncStatus(tid: $tid)
  }
`;
