import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled, { css } from 'styled-components';

// Cant import from shared-styled-components as that imports this
const ArrowStyle = css`
  height: 26px;
  width: 26px;
  color: ${(props) =>
    props.color === 'darkGray'
      ? props.theme.color.muiInputDarkGray
      : props.color === 'primary'
      ? props.theme.color.primary
      : props.color === 'lightPrimary'
      ? props.theme.color.lightPrimary
      : 'inherit'};
  cursor: ${(props) => props.pointer && 'pointer'};
`;

export const DownArrow = styled(ExpandMoreIcon)`
  ${ArrowStyle}
`;

export const UpArrow = styled(ExpandLessIcon)`
  ${ArrowStyle}
`;
