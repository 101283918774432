import Typesense from 'typesense';

export const getTypesenseClient = (apiKey) => {
  let typesenseClient = new Typesense.Client({
    nodes: [
      {
        host: process.env.REACT_APP_TYPESENSE_HOST,
        port: 443,
        protocol: 'https',
      },
    ],
    apiKey,
    connectionTimeoutSeconds: 20,
  });
  return typesenseClient;
};
