import {
  ascendingKey,
  campaignMemberStatuses,
  canBeTextedKey,
  containsArrayItems,
  descendingKey,
  doesNotEqualKey,
  dollarInputType,
  equalsKey,
  extendedClientFilterFieldLabelMap,
  firstNameField,
  greaterThanKey,
  greaterThanOrEqualToKey,
  intraStringSeparatorChar,
  lastMessageSentAtField,
  lessThanKey,
  lessThanOrEqualToKey,
} from './constants';
import { valueIsEmpty } from './data';
import { getCookieExpiryObject } from './date';
import { getAbbreviatedMoneyString } from './numbers';

export const getSliderMarks = (min, max, fieldType = dollarInputType) => {
  return [
    {
      value: min,
      label:
        fieldType === dollarInputType ? getAbbreviatedMoneyString(min) : min,
    },
    {
      value: max,
      label: `${
        fieldType === dollarInputType ? getAbbreviatedMoneyString(max) : max
      } +`,
    },
  ];
};

export const operatorNameToCharMap = {
  [doesNotEqualKey]: ':!=',
  [greaterThanOrEqualToKey]: ':>=',
  [greaterThanKey]: ':>',
  [lessThanOrEqualToKey]: ':<=',
  [lessThanKey]: ':<',
  [containsArrayItems]: ':=',
  [equalsKey]: ':',
};

const operatorLabelMap = {
  [doesNotEqualKey]: 'Does not equal',
  [greaterThanOrEqualToKey]: 'Is greater than or equal to',
  [lessThanOrEqualToKey]: 'Is less than or equal to',
  [containsArrayItems]: 'Is',
  [equalsKey]: 'Is',
};

const operatorRegexString = new RegExp(
  `^(.*?)(?:${Object.values(operatorNameToCharMap).join('|')})(.*$)`,
);

export const getTypesenseFilterString = (locationIds, filtersMap) => {
  let filterString = `locationId${
    operatorNameToCharMap[containsArrayItems]
  }[${locationIds.join(',')}]`;

  const filterValues = Object.values(filtersMap).sort();
  filterValues.map((filter) => {
    filter.map((filterX) => {
      const { key, operator, value } = filterX;
      const operatorChar = operatorNameToCharMap[operator];
      if (key !== 'locationId') {
        filterString += ` && ${key}${operatorChar}${
          operator === containsArrayItems ? `[${value}]` : value
        }`;
      }
    });
  });

  return filterString;
};

const parseFilterString = (filterString) => {
  // Executing the regex on the filter string
  const match = filterString.match(operatorRegexString);

  if (match) {
    // Extracting the filter field, operator, and value
    const key = match[1].trim();
    const value = match[2].trim();

    const len = filterString.length;
    const keyLen = key.length;
    const valueLen = value.length;
    const keyEndCharIdx = keyLen;
    const valueStartCharIdx = len - valueLen;
    const operatorStartCharIdx = keyEndCharIdx;
    const operatorEndCharIdx = valueStartCharIdx;
    const operatorValue = filterString.slice(
      operatorStartCharIdx,
      operatorEndCharIdx,
    );
    const operator = Object.keys(operatorNameToCharMap).find(
      (key) => operatorNameToCharMap[key] === operatorValue,
    );

    // Returning an object with the parsed values
    return {
      key,
      operator,
      value,
    };
  } else {
    // If the filter string doesn't match the expected format, return null
    return null;
  }
};

export const getClientFilterMapFromString = (filterString) => {
  const filterMap = {};

  const filterStrings = filterString.split(' && ');
  filterStrings.map((s) => {
    const noSquareBrackets = s.replace(/[\[\]]/g, '');
    const parsed = parseFilterString(noSquareBrackets);
    const key = parsed['key'];
    const existingFilter = filterMap[key];
    filterMap[key] = existingFilter ? [...existingFilter, parsed] : [parsed];
  });

  return filterMap;
};

export const getFormattedClientFilterArrayFromString = (filterString) => {
  const filterStrings = filterString
    .split(' && ')
    .filter((f) => !f.includes('locationId'));

  const filterArray = filterStrings.map((s) => {
    const parsed = parseFilterString(s);
    const key = parsed['key'];
    const operator = parsed['operator'];
    let value = parsed['value'];

    if (Array.isArray(value)) {
      value = value.join(', or ');
    }

    const fieldLabel = extendedClientFilterFieldLabelMap[key].label;
    const operatorLabel = operatorLabelMap[operator];

    return `${fieldLabel} ${operatorLabel} '${value}'`;
  });

  return filterArray;
};

export const updateSearchFiltersCookie = (
  existingFilters,
  newFilters,
  cookieKey,
  cookieSetter,
) => {
  let params = { ...existingFilters };
  params['filters'] = newFilters;
  params['pageNumber'] = 1;

  cookieSetter(cookieKey, params, getCookieExpiryObject);
};

export const getSortParam = (field, direction) => `${field}:${direction}`;

export const baseClientSearchParams = {
  filters: {},
  pageNumber: 1,
  sortParam: getSortParam(firstNameField, ascendingKey),
  searchFilter: '',
};

export const audienceClientSearchParams = {
  ...baseClientSearchParams,
  filters: {
    [canBeTextedKey]: [
      {
        key: canBeTextedKey,
        operator: containsArrayItems,
        value: true,
      },
    ],
  },
};

export const baseChatSearchParams = {
  filters: {},
  pageNumber: 1,
  sortParam: getSortParam(lastMessageSentAtField, descendingKey),
  searchFilter: '',
};

export const getArrayFromInputString = (inputString) => {
  return inputString
    .substring(1, inputString.length - 1) // Remove the square brackets at the beginning and end
    .split(', ') // Split by comma and space
    .map((item) => item.trim())
    .filter((val) => !valueIsEmpty(val));
};

export const getCampaignStatusFilterString = (campaignId, status) => {
  return `${campaignId}${intraStringSeparatorChar}${status}`;
};

export const getCampaignFilterString = (campaignId, locationId) => {
  const containsArrayItemsChar = operatorNameToCharMap[containsArrayItems];

  let filterString = `locationId${containsArrayItemsChar}${locationId}`;

  const allStatuses = campaignMemberStatuses;
  const statusArr = allStatuses.map((s) => {
    return getCampaignStatusFilterString(campaignId, s);
  });

  filterString += ` && campaigns${containsArrayItemsChar}${statusArr.join(
    ', ',
  )}`;

  return filterString;
};

export default {
  getSliderMarks,
};
