import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Header from '../../../components/NavBar/Header';
import {
  ColumnCenteredDiv,
  ContentContainer,
  LargeText,
  PageContainer,
} from '../../../styles/shared-styled-components';

const Callback = () => {
  const navigate = useNavigate();
  const { user, userLoading } = useContext(BaseContext);

  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (user) {
      const userHasOnboarded = user.hasOnboarded;
      if (!userHasOnboarded) {
        navigate(Paths.personalOnboarding);
        return;
      }

      navigate(Paths.dashboard);
    }

    setHasLoaded(true);
  }, [user]);

  if (userLoading || !hasLoaded) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    user && (
      <>
        <Header />
        <PageContainer>
          <ContentContainer>
            <ColumnCenteredDiv>
              <LargeText>Navigating...</LargeText>
            </ColumnCenteredDiv>
          </ContentContainer>
        </PageContainer>
      </>
    )
  );
};

export default Callback;
