import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import styled, { css } from 'styled-components';
import {
  AutoHorizontalMargins,
  CancelIcon,
  CenterFlexStyle,
  ColumnCenteredDiv,
  ExtraSmallIconSize,
  MediumDarkTinyText,
  SMGapStyle,
} from '../../../../styles/shared-styled-components';

export const FilesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  ${AutoHorizontalMargins}
  max-width: 650px;
  ${SMGapStyle}
`;

export const FileContainer = styled(ColumnCenteredDiv)`
  padding: 5px;
`;

export const IconDiv = styled.div`
  position: relative;
`;

export const RemoveFileIcon = styled(CancelIcon)`
  position: absolute;
  ${(props) =>
    props.isImage
      ? css`
          top: -5px;
          right: -5px;
        `
      : css`
          top: 17px;
          right: 4px;
        `}
  background-color: white;
  border-radius: 50%;
  ${ExtraSmallIconSize}
  ${CenterFlexStyle}
  color: ${({ theme }) => theme.color.lightError} !important;
`;

export const ImageFilePreview = styled.img`
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  padding: 20px;
  max-height: 60px;
`;

export const FileTitleText = styled(MediumDarkTinyText)``;

export const FilePlaceholderIcon = styled(InsertDriveFileIcon)`
  color: ${({ theme }) => theme.color.muiInput};
  height: 85px !important;
  width: 50px !important;
`;
