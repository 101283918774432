import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { CREATE_AGENT } from '../../../api/mutations/agent';
import {
  DarkMLText,
  EmptyGapColumnCenteredDiv,
  InputTitleContainer,
  MediumPrimaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  StartAlignedMediumDarkEssText,
} from '../../../styles/shared-styled-components';
import { supportAgentRole, userRoleLabelMap } from '../../../utils/constants';
import { getUserLocationId } from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input';
import SplitInput from '../../Form/SplitInput/SplitInput';
import LoadingIndicator from '../../LoadingIndicator';

const CreateAgentModal = ({ isOpen, onClose, onSave }) => {
  const { user } = useContext(BaseContext);
  const locationId = getUserLocationId(user);

  const [createAgentMutation, { loading: createAgentLoading }] =
    useMutation(CREATE_AGENT);

  const [userFirstName, setUserFirstName] = useState();
  const [userLastName, setUserLastName] = useState();
  const [userEmail, setUserEmail] = useState();

  const onClickSave = () => {
    createAgentMutation({
      variables: {
        locationId,
        userFirstName,
        userLastName,
        userEmail,
      },
      onCompleted: async (data) => {
        onSave(true);
        onClose();
      },
    });
  };

  if (createAgentLoading) {
    return <LoadingIndicator />;
  }

  const saveEnabled = !!userFirstName && !!userLastName && !!userEmail;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer useSmallGap={true}>
        <DarkMLText>Create Human Support Agent</DarkMLText>
        <ModalInputsContainer>
          <Input
            id={'role'}
            value={userRoleLabelMap[supportAgentRole]}
            label={'Role'}
            disabled
          />
          <Input
            id={'email'}
            value={userEmail}
            label={'Email'}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <EmptyGapColumnCenteredDiv>
            <InputTitleContainer addTopMargin>
              <StartAlignedMediumDarkEssText>
                Name
              </StartAlignedMediumDarkEssText>
            </InputTitleContainer>
            <SplitInput
              id={'name'}
              splitInputs={[
                {
                  id: 'first',
                  value: userFirstName,
                  onChange: (e) => setUserFirstName(e.target.value),
                  label: 'First',
                },
                {
                  id: 'last',
                  value: userLastName,
                  onChange: (e) => setUserLastName(e.target.value),
                  label: 'Last',
                },
              ]}
              useExtraSmallGap={true}
            />
          </EmptyGapColumnCenteredDiv>
        </ModalInputsContainer>
        <ModalActions addTopMargin={true}>
          <MediumPrimaryButton
            onClick={onClickSave}
            disabled={!saveEnabled}
          >
            Create
          </MediumPrimaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default CreateAgentModal;
