import React, { useContext, useEffect } from 'react';
import { useDeposit } from '../../../api/hooks/calendar';
import {
  ColumnCenteredDiv,
  LinkTextSpan,
  MLText,
  MediumDarkSmallText,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalTitleTextContainer,
} from '../../../styles/shared-styled-components';
import { completeStatusKey, expiredKey } from '../../../utils/constants';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../LoadingIndicator';

const ViewDepositModal = ({ isOpen, onClose, id, clientName }) => {
  const { inDemoMode } = useContext(BaseContext);

  const { data, loading, refetch } = useDeposit({
    id,
  });
  const status = data?.status;
  const url = data?.url;
  const amount = data?.amount;

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, isOpen]);

  if (isOpen && loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    id && (
      <Modal
        open={isOpen}
        onClose={onClose}
        maxWidth={600}
      >
        <ModalContainer>
          <ModalTitleTextContainer>
            <MLText>
              Deposit for {inDemoMode ? `${clientName?.[0]}` : clientName}
            </MLText>
          </ModalTitleTextContainer>
          <ColumnCenteredDiv>
            <MediumDarkSmallText>
              {status === completeStatusKey
                ? `Collected a`
                : status === expiredKey
                ? `Expired after not receiving payment for a`
                : `Awaiting payment for a`}{' '}
              ${amount} deposit
              <br></br>
              Using this{' '}
              <LinkTextSpan
                useLargerOffset
                href={url}
                target='_blank'
              >
                payment link
              </LinkTextSpan>
            </MediumDarkSmallText>
          </ColumnCenteredDiv>
          <ModalActions>
            <MediumSecondaryButton onClick={onClose}>
              Close
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    )
  );
};

export default ViewDepositModal;
