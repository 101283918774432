import React from 'react';
import { AttachmentThumbnail, AttachmentsContainer } from './styled';

const MessageAttachments = ({ attachments, side }) => {
  return (
    <AttachmentsContainer side={side}>
      {attachments.map((a) => (
        <AttachmentThumbnail src={a.url} />
      ))}
    </AttachmentsContainer>
  );
};

export default MessageAttachments;
