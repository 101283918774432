import styled from 'styled-components';
import ClientSearchBox from '../../../../components/Search/ClientSearchBox/ClientSearchBox';
import {
  CardStyle,
  CenterFlexStyle,
  ColumnFlexStyle,
  PanelStyle,
  RedCancelIcon,
  RefreshIcon,
  ThinScrollBarStyle,
} from '../../../../styles/shared-styled-components';
import {
  contactedCampaignMemberStatusKey,
  declinedCampaignMemberStatusKey,
  hotLeadCampaignMemberStatusKey,
  optedOutCampaignMemberStatusKey,
  staleCampaignMemberStatusKey,
  successfullyBookedCampaignMemberStatusKey,
  uncontactedCampaignMemberStatusKey,
} from '../../../../utils/constants';

export const PipelineContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  font-family: ${({ theme }) => theme.typography.baseFont};
  height: 80%;
  max-height: 80%;
  width: 100%;
  overflow-x: scroll;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const PipelineFiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
`;

export const PipelineFilterSubtext = styled.div`
  margin: 5px 0;
  font-size: 0.8em;
  color: ${({ theme }) => theme.color.darkGray};
  font-family: ${({ theme }) => theme.typography.baseFont};
`;

export const PipelineDropDownContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PipelineFilterResults = styled.div`
  font-family: ${({ theme }) => theme.typography.baseFont};
  padding: 10px 30px;
  font-size: 0.85em;
  margin: 10px;
  width: fit-content;
  border: 1px solid ${({ theme }) => theme.color.lightPrimary};
  border-radius: ${({ theme }) => theme.spacing.mlBorderRadius}px;
  color: ${({ theme }) => theme.color.primary};
`;

export const PipelineColumn = styled.div`
  min-width: 280px;
  max-width: 280px;
  height: 100%;
  max-height: 90%;
  padding: 15px;
  border-left: ${(props) =>
    `${props.start ? `1px` : `0.5px`} dotted ${
      props.theme.color.muiInputLightGray
    }`};
  border-right: ${(props) =>
    `${props.end ? `1px` : `0.5px`} dotted ${
      props.theme.color.muiInputLightGray
    }`};
  :nth-child(1) {
    box-shadow: 0px -2px 0px 0px ${({ theme }) => theme.color.defaultGray};
  }
  :nth-child(2) {
    box-shadow: 0px -2px 0px 0px ${({ theme }) => theme.color.primary};
  }
  :nth-child(3) {
    box-shadow: 0px -2px 0px 0px ${({ theme }) => theme.color.darkGold};
  }
  :nth-child(4) {
    box-shadow: 0px -2px 0px 0px ${({ theme }) => theme.color.deepGreen};
  }
  :nth-child(5) {
    box-shadow: 0px -2px 0px 0px ${({ theme }) => theme.color.error};
  }

  transition: all 0.3s;
`;

export const PiplineCampaignFilter = styled.div`
  ${CenterFlexStyle}
`;

export const PipelineSearchBox = styled(ClientSearchBox)`
  margin: 0;
`;

export const PipelineColumnHeader = styled.div`
  width: 100%;
  max-height: 15%;
  padding-bottom: 25px;
  margin-top: -15px;
  ${ColumnFlexStyle}
  align-items: ${(props) =>
    props.start ? 'start' : props.end ? 'end' : 'center'};
  padding-left: ${(props) => props.start && '10px'};
`;

const statusColorMap = (props) => ({
  [uncontactedCampaignMemberStatusKey]: props.theme.color.muiInput,
  [contactedCampaignMemberStatusKey]: props.theme.color.lightPrimary,
  [staleCampaignMemberStatusKey]: props.theme.color.gold,
  [declinedCampaignMemberStatusKey]: props.theme.color.lightError,
  [optedOutCampaignMemberStatusKey]: props.theme.color.error,
  [successfullyBookedCampaignMemberStatusKey]: props.theme.color.brightGreen,
  [hotLeadCampaignMemberStatusKey]: props.theme.color.lightOrange,
});

export const PipelineColumnHeaderTitle = styled.div`
  font-size: 1.2em;
  border-top: 2px solid
    ${(props) => (props.status ? statusColorMap(props)[props.status] : 'red')};
  padding-top: 10px;
  ${CenterFlexStyle}
`;

export const PipelineColumnHeaderSubtext = styled.div`
  font-size: 0.9em;
  margin: 5px 0;
  color: ${({ theme }) => theme.color.darkGray};
`;

export const PipelineClientContainer = styled.div`
  overflow-y: scroll;
  ${ThinScrollBarStyle}
  overflow-x: hidden;
  height: 60vh;
  padding-right: ${(props) => (props.end ? '10px' : '19px')};
  padding-left: ${(props) => (props.end ? '15px' : '5px')};
`;

export const PipelineClient = styled.div`
  max-height: 20%;
  width: 90%;
  padding: 15px;
  margin: 15px 0;
  transition: all 0.3s;
  ${PanelStyle}
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    width: 12px;
    height: 12px;
    border-top: 2px solid ${(props) => statusColorMap(props)[props.status]}; /* Accent color */
    border-right: 2px solid ${(props) => statusColorMap(props)[props.status]}; /* Accent color */
    border-radius: 0 8px 0 0;
    opacity: 0.7;
  }
`;

export const PipelineClientName = styled.div``;

export const PipelineClientContacted = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.darkGray};
  font-size: 0.8em;
`;

export const PipelineClientTooltips = styled.div`
  margin-top: 10px;
`;

export const TopSectionContainer = styled.div`
  ${CardStyle}
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => !props.useDynamicHeight && '100px'};
  flex-wrap: wrap;
  padding: ${(props) =>
    props.useLargePadding
      ? '40px'
      : `${props.theme.spacing.smallContainerPadding}px ${props.theme.spacing.smContainerPadding}px`};
  margin-bottom: 60px;
  margin-top: 10px;
  border: ${(props) => props.hideBorder && 'none'};
`;

export const SearchIconContainer = styled.div`
  margin-left: 1px;
  ${CenterFlexStyle}
`;

export const LoadMoreIcon = styled(RefreshIcon)``;

export const ResetFiltersIcon = styled(RedCancelIcon)``;
