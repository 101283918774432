import React from 'react';
import {
  CenteredDiv,
  MediumDarkSMText,
  Modal,
  ModalActions,
  ModalContainer,
  ModalTitleText,
  PrimaryButton,
} from '../../../styles/shared-styled-components';
import { StyledSyncIcon } from './styled';

const DataSyncStatusModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth={830}
    >
      <ModalContainer>
        <CenteredDiv>
          <StyledSyncIcon />
        </CenteredDiv>
        <ModalTitleText>Data Sync Status</ModalTitleText>
        <MediumDarkSMText>
          Your Practice Management, Marketing, and Accounting data automatically
          syncs every 12 hours.<br></br>
          <br></br>
          Your data was last synced at 7:00AM this morning, and will be synced
          again at 7:00PM tonight.
        </MediumDarkSMText>
        <ModalActions>
          <PrimaryButton onClick={onClose}>Got it!</PrimaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default DataSyncStatusModal;
