import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMyBookableSessionTypes } from '../../../api/hooks/enterprise';
import { EDIT_STAFF } from '../../../api/mutations/enterprise';
import {
  Chip,
  ChipCancelIcon,
  ColumnStartAlignedDiv,
  Dropdown,
  DropdownLabel,
  EmptyGapColumnCenteredDiv,
  Form,
  FormControlLabel,
  FormGroup,
  FullWidthStartAlignedColumnDiv,
  MLText,
  MediumPrimaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SMCheckbox,
  Stack,
  StartAlignedEssText,
} from '../../../styles/shared-styled-components';
import {
  booleanOptions,
  dropdownInputType,
  genderOptions,
  resyncKey,
  resyncLabel,
} from '../../../utils/constants';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input';
import SplitInput from '../../Form/SplitInput/SplitInput';

const EditStaffModal = ({ isOpen, onClose, staff, onSave }) => {
  const { user, inDemoMode } = useContext(BaseContext);

  const { data: sessionTypeData, loading: sessionTypesLoading } =
    useMyBookableSessionTypes({});
  const [editStaffMutation, { loading: editStaffLoading }] =
    useMutation(EDIT_STAFF);

  const categories = get(sessionTypeData, '[categories]', {});
  const sessionTypes = get(sessionTypeData, '[sessionTypesByCategory]', {});
  const sessionTypesMap = get(sessionTypeData, '[sessionTypes]', {});
  const categoryIds = Object.keys(sessionTypes) || [];

  const [firstName, setFirstName] = useState(staff?.firstName || '');
  const [lastName, setLastName] = useState(staff?.lastName || '');
  const [gender, setGender] = useState(staff?.gender || '');
  const [isBookable, setIsBookable] = useState(staff?.isBookable || '');
  const [selectedSessionTypeIds, setSelectedSessionTypeIds] = useState(
    staff?.eligibleSessionTypeIds || [],
  );

  useEffect(() => {
    if (staff) {
      setFirstName(staff.firstName);
      setLastName(staff.lastName);
      setGender(staff.gender);
      setIsBookable(staff.isBookable);
      setSelectedSessionTypeIds(staff.eligibleSessionTypeIds || []);
    }
  }, [staff]);

  const onClickSave = () => {
    editStaffMutation({
      variables: {
        id: staff.id,
        firstName,
        lastName,
        gender,
        isBookable,
        eligibleSessionTypeIds: selectedSessionTypeIds,
      },
      onCompleted: (data) => {
        const success = data.editStaff;
        onClose();
        if (success) {
          onSave(success);
        }
      },
    });
  };

  const resyncingSessionTypeEligibility =
    selectedSessionTypeIds.length === 1 &&
    selectedSessionTypeIds?.[0] === resyncKey;

  const saveEnabled =
    !!firstName && !!lastName && !!gender && isBookable != null;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer useSmallGap={true}>
        <MLText>Edit Staff</MLText>
        <ModalInputsContainerScrollWrapper>
          <ModalInputsContainer>
            <EmptyGapColumnCenteredDiv>
              <SplitInput
                id={'name'}
                splitInputs={[
                  {
                    id: 'first',
                    value: inDemoMode ? firstName?.[0] : firstName,
                    onChange: (e) => setFirstName(e.target.value),
                    label: 'First name',
                  },
                  {
                    id: 'last',
                    value: inDemoMode ? lastName?.[0] : lastName,
                    onChange: (e) => setLastName(e.target.value),
                    label: 'Last name',
                  },
                ]}
                useExtraSmallGap={true}
              />
            </EmptyGapColumnCenteredDiv>
            <Input
              id={'gender'}
              value={gender}
              label={'Gender'}
              onChange={(e) => setGender(e.target.value)}
              options={genderOptions}
              type={dropdownInputType}
            />
            <Input
              id={'bookable'}
              value={isBookable}
              label={'Is bookable'}
              onChange={(e) => setIsBookable(e.target.value)}
              options={booleanOptions}
              type={dropdownInputType}
            />
            {sessionTypeData && isBookable !== false && false && (
              <Input
                id='sessiontypes'
                value={selectedSessionTypeIds}
                CustomInputComponent={
                  <Form fitContentHeight>
                    <DropdownLabel>Eligible services to provide</DropdownLabel>
                    <Dropdown
                      multiple
                      label={`Eligible services to provide`}
                      value={selectedSessionTypeIds}
                      renderValue={(selected) => {
                        return (
                          <Stack>
                            {Object.values(selected)?.map(
                              (selectedSessionTypeId) => {
                                const label =
                                  selectedSessionTypeId === resyncKey
                                    ? resyncLabel
                                    : sessionTypesMap[selectedSessionTypeId] ||
                                      'Unknown';
                                return (
                                  <Chip
                                    key={selectedSessionTypeId}
                                    label={label}
                                    ternary
                                    onDelete={() => {
                                      const updatedEligibleSessionTypes = [
                                        ...selectedSessionTypeIds.filter(
                                          (s) => s !== selectedSessionTypeId,
                                        ),
                                      ];
                                      setSelectedSessionTypeIds(
                                        updatedEligibleSessionTypes,
                                      );
                                    }}
                                    deleteIcon={
                                      <ChipCancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                  />
                                );
                              },
                            )}
                          </Stack>
                        );
                      }}
                    >
                      <FullWidthStartAlignedColumnDiv>
                        <FormGroup key={resyncKey}>
                          <FormControlLabel
                            control={
                              <SMCheckbox
                                checked={resyncingSessionTypeEligibility}
                                onClick={(e) => {
                                  const selected = e.target.checked;
                                  let updatedEligibleSessionTypes;
                                  if (selected) {
                                    updatedEligibleSessionTypes = [resyncKey];
                                  } else {
                                    updatedEligibleSessionTypes = [];
                                  }
                                  setSelectedSessionTypeIds(
                                    updatedEligibleSessionTypes,
                                  );
                                }}
                              />
                            }
                            label={resyncLabel}
                          />
                        </FormGroup>
                        {!resyncingSessionTypeEligibility &&
                          categoryIds?.map((categoryId) => {
                            const categoryName = categories[categoryId];
                            const sessionTypesInCategory =
                              sessionTypes[categoryId];
                            return (
                              <ColumnStartAlignedDiv>
                                <StartAlignedEssText addLeftMargin>
                                  {categoryName}
                                </StartAlignedEssText>
                                <EmptyGapColumnCenteredDiv>
                                  {sessionTypesInCategory.map((s) => {
                                    const { id, displayName } = s;
                                    return (
                                      <FormGroup key={id}>
                                        <FormControlLabel
                                          control={
                                            <SMCheckbox
                                              checked={selectedSessionTypeIds.includes(
                                                id,
                                              )}
                                              onClick={(e) => {
                                                const selected =
                                                  e.target.checked;
                                                let updatedEligibleSessionTypes;
                                                if (selected) {
                                                  updatedEligibleSessionTypes =
                                                    [
                                                      ...selectedSessionTypeIds,
                                                      id,
                                                    ];
                                                } else {
                                                  updatedEligibleSessionTypes =
                                                    [
                                                      ...selectedSessionTypeIds.filter(
                                                        (s) => s !== id,
                                                      ),
                                                    ];
                                                }
                                                setSelectedSessionTypeIds(
                                                  updatedEligibleSessionTypes,
                                                );
                                              }}
                                            />
                                          }
                                          label={displayName}
                                        />
                                      </FormGroup>
                                    );
                                  })}
                                </EmptyGapColumnCenteredDiv>
                              </ColumnStartAlignedDiv>
                            );
                          })}
                      </FullWidthStartAlignedColumnDiv>
                    </Dropdown>
                  </Form>
                }
              />
            )}
          </ModalInputsContainer>
        </ModalInputsContainerScrollWrapper>
        <ModalActions addTopMargin={true}>
          <MediumPrimaryButton
            onClick={onClickSave}
            disabled={!saveEnabled}
          >
            Save
          </MediumPrimaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default EditStaffModal;
