import { GET_MY_AGENTS } from '../api/queries/agent';
import { GET_MY_CAMPAIGNS } from '../api/queries/campaigns';
import { GET_CHAT, GET_MY_LOCATION_CHATS } from '../api/queries/chat';
import {
  GET_MY_LOCATIONS,
  GET_MY_LOCATION_OVERVIEW,
  GET_MY_PROMOS,
  GET_PROMO_BY_ID,
} from '../api/queries/enterprise';
import { GET_MY_USER } from '../api/queries/users';

export const updateMyUserInCache = async (data, cache) => {
  const myUserDataFetch = cache.readQuery({
    query: GET_MY_USER,
    variables: {
      email: data.email,
    },
  });

  if (myUserDataFetch) {
    const existingUser = myUserDataFetch.getMyUser;
    const updatedUser = {
      ...existingUser,
      ...data,
    };

    // Update the cache
    await cache.writeQuery({
      query: GET_MY_USER,
      data: {
        getMyUser: updatedUser,
      },
      variables: {
        email: data.email,
      },
    });
  }
};

export const completeUserOnboardingInCache = async (
  email,
  enterprise,
  role,
  cache,
) => {
  const myUserDataFetch = cache.readQuery({
    query: GET_MY_USER,
    variables: {
      email: email,
    },
  });

  if (myUserDataFetch) {
    const existingUser = myUserDataFetch.getMyUser;
    const updatedUser = {
      ...existingUser,
      role,
      hasOnboarded: true,
      enterprise,
    };

    // Update the cache
    await cache.writeQuery({
      query: GET_MY_USER,
      data: {
        getMyUser: updatedUser,
      },
      variables: {
        email,
      },
    });
  }
};

export const updateMyUserAppConnectionsInCache = async (
  appName,
  adding,
  email,
  cache,
) => {
  const myUserDataFetch = cache.readQuery({
    query: GET_MY_USER,
    variables: {
      email,
    },
  });

  if (myUserDataFetch) {
    const existingUser = myUserDataFetch.getMyUser;
    const existingConnectedApps = existingUser?.connectedApps || [];
    let updatedConnectedApps = existingConnectedApps;
    if (adding) {
      updatedConnectedApps.push({ name: appName });
    } else {
      updatedConnectedApps = updatedConnectedApps.filter(
        (i) => i.name !== appName,
      );
    }
    const updatedUser = {
      ...existingUser,
      connectedApps: updatedConnectedApps,
    };

    // Update the cache
    await cache.writeQuery({
      query: GET_MY_USER,
      data: {
        getMyUser: updatedUser,
      },
      variables: {
        email,
      },
    });
  }
};

export const addToMyLocationsInCache = async (data, cache) => {
  const myLocationsDataFetch = cache.readQuery({
    query: GET_MY_LOCATIONS,
  });

  if (myLocationsDataFetch) {
    const existingLocations = myLocationsDataFetch.getMyLocations;

    const updatedLocations = [...existingLocations, data];

    // Update the cache
    await cache.writeQuery({
      query: GET_MY_LOCATIONS,
      data: {
        getMyLocations: updatedLocations,
      },
    });
  }
};

export const updateMyLocationsInCache = async (updatedLocation, cache) => {
  const myLocationsDataFetch = cache.readQuery({
    query: GET_MY_LOCATIONS,
  });

  if (myLocationsDataFetch) {
    const idToUpdate = updatedLocation.id;

    const existingLocations = myLocationsDataFetch.getMyLocations;

    const updatedLocations = existingLocations.map((l) => {
      if (l.id === idToUpdate) {
        return {
          ...l,
          updatedLocation,
        };
      } else {
        return l;
      }
    });

    // Update the cache
    await cache.writeQuery({
      query: GET_MY_LOCATIONS,
      data: {
        getMyLocations: updatedLocations,
      },
    });
  }
};

export const updateMyLocationOverviewInCache = async (
  updatedOverview,
  cache,
) => {
  const myLocationOverviewDataFetch = cache.readQuery({
    query: GET_MY_LOCATION_OVERVIEW,
  });

  if (myLocationOverviewDataFetch) {
    // Update the cache
    await cache.writeQuery({
      query: GET_MY_LOCATION_OVERVIEW,
      data: {
        getMyLocationOverview: updatedOverview,
      },
    });
  }
};

export const addAgentToMyUserInCache = async (newAgent, email, cache) => {
  const myUserDataFetch = cache.readQuery({
    query: GET_MY_USER,
    variables: {
      email,
    },
  });

  if (myUserDataFetch) {
    const existingUser = myUserDataFetch.getMyUser;
    const existingEnterprise = existingUser?.enterprise;
    const existingLocation = existingEnterprise?.location;
    const existingAgents = existingLocation?.agents || [];

    let updatedAgents = [...existingAgents, newAgent];

    const updatedUser = {
      ...existingUser,
      enterprise: {
        ...existingEnterprise,
        location: {
          ...existingLocation,
          agents: updatedAgents,
        },
      },
    };

    // Update the cache
    await cache.writeQuery({
      query: GET_MY_USER,
      data: {
        getMyUser: updatedUser,
      },
      variables: {
        email,
      },
    });
  }
};

export const updateAgentInCache = async (updatedAgent, email, cache) => {
  const updatedAgentId = updatedAgent.id;

  const myUserDataFetch = cache.readQuery({
    query: GET_MY_USER,
    variables: {
      email,
    },
  });

  if (myUserDataFetch) {
    const existingUser = myUserDataFetch.getMyUser;
    const existingEnterprise = existingUser?.enterprise;
    const existingLocation = existingEnterprise?.location;
    const existingAgents = existingLocation?.agents || [];

    let updatedAgents = existingAgents.map((a) => {
      if (a.id === updatedAgentId) {
        return updatedAgent;
      } else {
        return a;
      }
    });

    const updatedUser = {
      ...existingUser,
      enterprise: {
        ...existingEnterprise,
        location: {
          ...existingLocation,
          agents: updatedAgents,
        },
      },
    };

    // Update the cache
    await cache.writeQuery({
      query: GET_MY_USER,
      data: {
        getMyUser: updatedUser,
      },
      variables: {
        email,
      },
    });
  }

  const myAgentsDataFetch = cache.readQuery({
    query: GET_MY_AGENTS,
  });

  if (myAgentsDataFetch) {
    const existingAgents = myAgentsDataFetch.getMyAgents;

    let updatedAgents = existingAgents.map((a) => {
      if (a.id === updatedAgentId) {
        return updatedAgent;
      } else {
        return a;
      }
    });

    // Update the cache
    await cache.writeQuery({
      query: GET_MY_AGENTS,
      data: {
        getMyAgents: updatedAgents,
      },
    });
  }
};

export const updateAgentInCampaigns = async (agent, cache) => {
  // Update both shallow and non-shallow queries
  const shallowValues = [true, false];

  const agentIdToUpdate = agent.id;

  await Promise.all(
    shallowValues.map(async (value) => {
      const queryObject = {
        query: GET_MY_CAMPAIGNS,
        variables: {
          shallow: value,
        },
      };
      let myCampaignsDataFetch = cache.readQuery(queryObject);

      if (myCampaignsDataFetch) {
        const existingCampaigns = myCampaignsDataFetch.getMyCampaigns || [];

        const updatedCampaigns = existingCampaigns.map((c) => {
          if (c?.agent?.id === agentIdToUpdate) {
            return {
              ...c,
              agent,
            };
          } else {
            return c;
          }
        });

        // Update the cache
        await cache.writeQuery({
          ...queryObject,
          data: {
            getMyCampaigns: updatedCampaigns,
          },
        });
      }
    }),
  );
};

export const addMyCampaignInCache = async (newCampaign, cache) => {
  const shallowValues = [true, false];

  await Promise.all(
    shallowValues.map(async (value) => {
      const queryObject = {
        query: GET_MY_CAMPAIGNS,
        variables: {
          shallow: value,
        },
      };
      let myCampaignsDataFetch = cache.readQuery(queryObject);

      if (myCampaignsDataFetch) {
        const existingCampaigns = myCampaignsDataFetch.getMyCampaigns || [];

        const updatedCampaigns = [...existingCampaigns, newCampaign];

        // Update the cache
        await cache.writeQuery({
          ...queryObject,
          data: {
            getMyCampaigns: updatedCampaigns,
          },
        });
      }
    }),
  );
};

export const updateMyCampaignInCache = async (updatedCampaign, cache) => {
  const shallowValues = [true, false];

  await Promise.all(
    shallowValues.map(async (value) => {
      const queryObject = {
        query: GET_MY_CAMPAIGNS,
        variables: {
          shallow: value,
        },
      };
      let myCampaignsDataFetch = cache.readQuery(queryObject);

      if (myCampaignsDataFetch) {
        const existingCampaigns = myCampaignsDataFetch.getMyCampaigns || [];

        const updatedCampaigns = existingCampaigns.map((c) => {
          if (c?.id !== updatedCampaign?.id) {
            return c;
          } else {
            return {
              ...c,
              ...updatedCampaign,
            };
          }
        });

        // Update the cache
        await cache.writeQuery({
          ...queryObject,
          data: {
            getMyCampaigns: updatedCampaigns,
          },
        });
      }
    }),
  );
};

export const deleteMyCampaignInCache = async (campaignIdToDelete, cache) => {
  const shallowValues = [true, false];

  await Promise.all(
    shallowValues.map(async (value) => {
      const queryObject = {
        query: GET_MY_CAMPAIGNS,
        variables: {
          shallow: value,
        },
      };
      let myCampaignsDataFetch = cache.readQuery(queryObject);

      if (myCampaignsDataFetch) {
        const existingCampaigns = myCampaignsDataFetch.getMyCampaigns || [];

        const updatedCampaigns = [];
        existingCampaigns.map((c) => {
          if (c.id !== campaignIdToDelete) {
            updatedCampaigns.push(c);
          }
        });

        // Update the cache
        await cache.writeQuery({
          ...queryObject,
          data: {
            getMyCampaigns: updatedCampaigns,
          },
        });
      }
    }),
  );
};

export const updateMyCampaignMembersInCache = async (
  campaignId,
  updatedMemberIds,
  cache,
) => {
  // Update both shallow and non-shallow queries
  const shallowValues = [true, false];

  await Promise.all(
    shallowValues.map(async (value) => {
      const queryObject = {
        query: GET_MY_CAMPAIGNS,
        variables: {
          shallow: value,
        },
      };
      let myCampaignsDataFetch = cache.readQuery(queryObject);

      if (myCampaignsDataFetch) {
        const existingCampaigns = myCampaignsDataFetch.getMyCampaigns || [];

        const updatedCampaigns = existingCampaigns.map((c) => {
          if (c.id === campaignId) {
            return {
              ...c,
              memberIds: updatedMemberIds,
              numMembers: Object.keys(updatedMemberIds).length,
            };
          } else {
            return c;
          }
        });

        // Update the cache
        await cache.writeQuery({
          ...queryObject,
          data: {
            getMyCampaigns: updatedCampaigns,
          },
        });
      }
    }),
  );
};

export const updateAllMyCampaignsMembersInCache = async (
  updatedCampaigns,
  cache,
) => {
  // Update both shallow and non-shallow queries
  const shallowValues = [true, false];

  const campaignMemberIdMap = {};
  updatedCampaigns.map((c) => (campaignMemberIdMap[c.id] = c.memberIds));

  await Promise.all(
    shallowValues.map(async (value) => {
      const queryObject = {
        query: GET_MY_CAMPAIGNS,
        variables: {
          shallow: value,
        },
      };

      let myCampaignsDataFetch = cache.readQuery(queryObject);

      if (myCampaignsDataFetch) {
        const existingCampaigns = myCampaignsDataFetch.getMyCampaigns;

        const updatedCampaigns = existingCampaigns.map((c) => {
          const campaignId = c.id;
          const updatedMemberIds = campaignMemberIdMap[campaignId];
          const numMembers = Object.keys(updatedMemberIds).length;
          return {
            ...c,
            memberIds: updatedMemberIds,
            numMembers,
          };
        });

        // Update the cache
        await cache.writeQuery({
          ...queryObject,
          data: {
            getMyCampaigns: updatedCampaigns,
          },
        });
      }
    }),
  );
};

export const updatePromoByIdInCache = async (promoId, updatedPromo, cache) => {
  const editingValues = [true, false];

  await Promise.all(
    editingValues.map(async (value) => {
      const queryObject = {
        query: GET_PROMO_BY_ID,
        variables: {
          id: promoId,
          editing: value,
        },
      };
      let promoDataFetch = cache.readQuery(queryObject);

      if (promoDataFetch) {
        const initialPromoResult = promoDataFetch.getPromoById;

        const updatedPromoResult = {
          ...initialPromoResult,
          promo: {
            ...initialPromoResult.promo,
            ...updatedPromo,
          },
        };

        // Update the cache
        await cache.writeQuery({
          ...queryObject,
          data: {
            getPromoById: updatedPromoResult,
          },
        });
      }
    }),
  );
};

export const deletePromoInCache = async (promoIdToDelete, cache) => {
  const queryObject = {
    query: GET_MY_PROMOS,
  };
  let myPromosDataFetch = cache.readQuery(queryObject);

  if (myPromosDataFetch) {
    const existingPromos = myPromosDataFetch.getMyPromos || [];

    const updatedPromos = [];
    existingPromos.map((p) => {
      if (p.id !== promoIdToDelete) {
        updatedPromos.push(p);
      }
    });

    // Update the cache
    await cache.writeQuery({
      ...queryObject,
      data: {
        getMyPromos: updatedPromos,
      },
    });
  }
};

export const deletePromoFromCampaignsInCache = async (
  promoIdToDelete,
  cache,
) => {
  const shallowValues = [true, false];

  await Promise.all(
    shallowValues.map(async (value) => {
      const queryObject = {
        query: GET_MY_CAMPAIGNS,
        variables: {
          shallow: value,
        },
      };
      let myCampaignsDataFetch = cache.readQuery(queryObject);

      if (myCampaignsDataFetch) {
        const existingCampaigns = myCampaignsDataFetch.getMyCampaigns || [];

        const updatedCampaigns = existingCampaigns.map((c) => {
          if (c.promoIds?.includes(promoIdToDelete)) {
            const updatedPromoIds = c.promoIds.filter(
              (id) => id !== promoIdToDelete,
            );
            return {
              ...c,
              promoIds: updatedPromoIds,
            };
          } else {
            return c;
          }
        });

        // Update the cache
        await cache.writeQuery({
          ...queryObject,
          data: {
            getMyCampaigns: updatedCampaigns,
          },
        });
      }
    }),
  );
};

export const updateChatMessages = async (chat, messages, cache) => {
  const { id: chatId, status } = chat;

  const queryObject = {
    query: GET_CHAT,
    variables: {
      chatId,
    },
  };

  const myChatDataFetch = cache.readQuery(queryObject);

  if (myChatDataFetch) {
    const chat = myChatDataFetch.getChat || null;

    if (chat) {
      const updatedChat = {
        ...chat,
        messages,
        status,
      };

      // Update the cache
      await cache.writeQuery({
        ...queryObject,
        data: {
          getChat: updatedChat,
        },
      });
    }
  }

  const allChatsQueryObject = {
    query: GET_MY_LOCATION_CHATS,
  };

  const myChatsDataFetch = cache.readQuery(allChatsQueryObject);

  if (myChatsDataFetch) {
    const chatsInCache = myChatsDataFetch.getMyLocationChats || [];

    const numMessages = messages.length;
    const lastMessageAt = messages[numMessages - 1].sentAt;

    const updatedChats = chatsInCache.map((c) => {
      if (c.id !== chatId) {
        return c;
      } else {
        return {
          ...c,
          lastMessageAt,
          numMessages,
          status,
        };
      }
    });

    // Update the cache
    await cache.writeQuery({
      ...allChatsQueryObject,
      data: {
        getMyLocationChats: updatedChats,
      },
    });
  }
};

export const addChatToCache = async (chat, cache) => {
  const allChatsQueryObject = {
    query: GET_MY_LOCATION_CHATS,
  };

  const myChatsDataFetch = cache.readQuery(allChatsQueryObject);

  if (myChatsDataFetch) {
    const chatsInCache = myChatsDataFetch.getMyLocationChats || [];
    const updatedChats = [...chatsInCache, chat];

    // Update the cache
    await cache.writeQuery({
      ...allChatsQueryObject,
      data: {
        getMyLocationChats: updatedChats,
      },
    });
  }
};

export const removeChatFromCache = async (chatId, cache) => {
  const queryObject = {
    query: GET_CHAT,
    variables: {
      chatId,
    },
  };

  const myChatDataFetch = cache.readQuery(queryObject);

  if (myChatDataFetch) {
    await cache.writeQuery({
      ...queryObject,
      data: {
        getChat: null,
      },
    });
  }

  const allChatsQueryObject = {
    query: GET_MY_LOCATION_CHATS,
  };

  const myChatsDataFetch = cache.readQuery(allChatsQueryObject);

  if (myChatsDataFetch) {
    const chatsInCache = myChatsDataFetch.getMyLocationChats || [];
    const updatedChats = chatsInCache.filter((c) => c.id !== chatId);

    // Update the cache
    await cache.writeQuery({
      ...allChatsQueryObject,
      data: {
        getMyLocationChats: updatedChats,
      },
    });
  }
};

export const updateTrainingOverviewInCache = async (key, value, cache) => {};
