import React, { useState } from 'react';
import {
  MLText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainerWithSpacing,
} from '../../../styles/shared-styled-components';
import {
  chatInterceptionReasonDropdownOptions,
  dropdownInputType,
} from '../../../utils/constants';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';

const InterceptChatModal = ({
  isOpen,
  onClose,
  onIntercept,
  clientName,
  loading,
}) => {
  const [reason, setReason] = useState();
  const [notes, setNotes] = useState();

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <MLText>Intercept Chat with {clientName}</MLText>
        <ModalInputsContainerWithSpacing>
          <Input
            id='reason'
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
            label='Reason for intercepting *'
            type={dropdownInputType}
            options={chatInterceptionReasonDropdownOptions}
            removeGap
          />
          <Input
            id='notes'
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            label='Notes'
            removeGap
          />
        </ModalInputsContainerWithSpacing>
        <ModalActions>
          <MediumPrimaryButton
            onClick={() => {
              onIntercept(reason, notes);
            }}
            disabled={!reason}
          >
            Intercept
          </MediumPrimaryButton>
          <MediumSecondaryButton onClick={onClose}>Close</MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default InterceptChatModal;
