import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  BoxShadowStyle,
  CenterFlexStyle,
  ColumnFlexStyle,
  DarkMLText,
  EmptyGapColumnCenteredDiv,
  GinormousGapStyle,
  LightDarkSMText,
  SmallText,
} from '../../../styles/shared-styled-components';

export const AgentSection = styled.div`
  ${CenterFlexStyle}
  ${GinormousGapStyle}
  flex-wrap: wrap;
  margin-top: 50px;
`;

export const AgentContainer = styled.div`
  ${BoxShadowStyle}
  padding: 10px;
  padding-bottom: 30px;
  border-radius: ${({ theme }) => theme.spacing.mlBorderRadius}px;
  border: 5px solid ${({ theme }) => theme.color.lightPrimary};
  ${ColumnFlexStyle}
  width: 300px;
`;

export const AgentHeadShotContainer = styled.div`
  margin-top: -60px;
  ${AutoHorizontalMargins}
  object-fit: cover;
`;

export const AgentHeadShot = styled.img`
  height: 200px;
  width: fit-content;
  border-radius: 50%;
  border: 3px solid
    ${(props) =>
      props.color === 'green'
        ? props.theme.color.turquoise
        : props.color === 'pink'
        ? props.theme.color.pink
        : props.color === 'red'
        ? props.theme.color.lightError
        : 'white'};
  background-color: #dbdddd;
`;

export const AgentTitleContainer = styled(EmptyGapColumnCenteredDiv)`
  margin: 20px 0px;
`;

export const AgentTitleText = styled(DarkMLText)`
  font-family: ${({ theme }) => theme.typography.logoFont};
`;

export const AgentSubtitleText = styled(LightDarkSMText)`
  line-height: 1.2;
  min-height: 58px;
`;

export const ActionSectionContainer = styled.div`
  height: 50px;
  ${CenterFlexStyle}
`;

export const ComingSoonText = styled(SmallText)`
  color: ${({ theme }) => theme.color.darkGold};
`;
