import { dateInputType, descendingKey, floatInputType } from './constants';
import { stripAllSpacesFromString } from './string';

export const valueIsEmpty = (value) => {
  const formattedValue =
    typeof value === 'string' ? stripAllSpacesFromString(value) : value;

  return (
    formattedValue == null ||
    formattedValue === '' ||
    (Array.isArray(formattedValue) && formattedValue.length === 0)
  );
};

export const objectIsEmpty = (obj) => {
  return !obj || !Object?.keys(obj)?.length;
};

const checkIfValidEvent = (code) => {
  const allowedActionCodes = [
    'Backspace',
    // 'KeyR',
    // 'KeyC',
    // 'KeyV',
    'Tab',
    'Enter',
    'ArrowLeft',
    'ArrowRight',
    // 'KeyA',
  ];

  if (allowedActionCodes.includes(code)) {
    return true;
  }
};

const numericFieldTypes = ['currency', 'integer', 'percentage'];

const checkIfNumericInput = (event) => {
  const { key, code } = event;

  const isValidEvent = checkIfValidEvent(code);
  if (isValidEvent) {
    return true;
  }

  // Use an integer-only regex
  return /^\d+$/.test(key);
};

export const sanitizeInput = (event, fieldType) => {
  if (numericFieldTypes.includes(fieldType)) {
    if (!checkIfNumericInput(event)) {
      event.preventDefault();
      return false;
    }
  }

  return true;
};

export const getLabelFromKey = (optionsArray, typeKey, type = 'full') => {
  if (valueIsEmpty(typeKey)) {
    return '';
  }

  const matchingOption = optionsArray.find((option) => typeKey === option.key);

  return type === 'abbreviated'
    ? matchingOption.abbreviatedLabel || ''
    : matchingOption?.label || '';
};

export const getValueFromKey = (optionsArray, typeKey) => {
  if (valueIsEmpty(typeKey)) {
    return '';
  }

  const matchingOption = optionsArray.find((option) => typeKey === option.key);

  return matchingOption.value || null;
};

export const getStringFromArrayOfKeys = (arrayOfKeys, optionsArray) => {
  if (!arrayOfKeys.length) {
    return '';
  }

  let labels = [];
  arrayOfKeys.map((key) => {
    const label = getLabelFromKey(optionsArray, key);
    labels.push(label);
  });

  return labels.join(', ');
};

export const getDefaultSortValueForEmptyField = (sortDirection, fieldType) => {
  if (fieldType === floatInputType) {
    if (sortDirection === descendingKey) {
      return -1;
    } else {
      return 1000000000000;
    }
  } else if (fieldType === dateInputType) {
    const currentDate = new Date();
    if (sortDirection === descendingKey) {
      const oldDate = currentDate.setFullYear(currentDate.getFullYear() - 100);
      return oldDate;
    } else {
      return currentDate;
    }
  }
};

export const removeItemObject = (baseObject, id) => {
  let objectToUpdate = { ...baseObject };
  objectToUpdate = Object.fromEntries(
    Object.entries(objectToUpdate).filter(([entryId, _]) => entryId !== id),
  );
  return objectToUpdate;
};

export const checkForIncompleteValueInObject = (obj) => {
  for (const key in obj) {
    const value = obj[key];
    if (typeof value === 'object' && value !== null) {
      if (checkForIncompleteValueInObject(value)) {
        return true;
      }
    } else if (value === false) {
      return true;
    }
  }
  return false;
};

export default {
  valueIsEmpty,
  sanitizeInput,
  getLabelFromKey,
  getStringFromArrayOfKeys,
  getValueFromKey,
};
