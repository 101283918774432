import { sortBy } from 'lodash';
import React, { useContext } from 'react';
import { useCatalogDiagnostics } from '../../../api/hooks/enterprise';
import {
  EmptyGapColumnCenteredDiv,
  MLText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SmallText,
  SpaceBetweenFlexDiv,
  StartAlignedMediumDarkLargeTinyText,
} from '../../../styles/shared-styled-components';
import { sessionTypeTypesMap } from '../../../utils/constants';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../LoadingIndicator';

const CatalogDiagnosticsModal = ({ isOpen, onClose, onTest }) => {
  const { user } = useContext(BaseContext);

  const isYusuf = user?.email === 'yusuf@medacquire.com';

  const { data, loading } = useCatalogDiagnostics({ skipCondition: !isOpen });

  const numTested = data?.length;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer useSmallGap={true}>
        <MLText>Catalog Diagnostics</MLText>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ModalInputsContainer>
              <ModalInputsContainerScrollWrapper
                columnFlex
                largeGap
              >
                <EmptyGapColumnCenteredDiv>
                  {sortBy(data, (d) => d.name)?.map((val) => {
                    const json = JSON.parse(val.json);
                    const numTimeslots = json.numTimeslots || 0;
                    const errorMessage = json.errorMessage;
                    const bookable = !errorMessage
                      ? true
                      : errorMessage === 'unbookable'
                      ? false
                      : null;
                    return (
                      <SpaceBetweenFlexDiv>
                        <StartAlignedMediumDarkLargeTinyText>
                          {val.name} ({sessionTypeTypesMap[val.type]}): &nbsp;
                          {bookable
                            ? `Bookable in the EMR`
                            : bookable === false
                            ? `Unbookable in the EMR`
                            : `Unsure (Rate-limited)`}
                        </StartAlignedMediumDarkLargeTinyText>
                        {bookable ? (
                          <>✅</>
                        ) : bookable === false ? (
                          <>❌</>
                        ) : (
                          <>🤷</>
                        )}
                      </SpaceBetweenFlexDiv>
                    );
                  })}
                </EmptyGapColumnCenteredDiv>
              </ModalInputsContainerScrollWrapper>
            </ModalInputsContainer>
            <ModalActions addTopMargin={true}>
              {!numTested && isYusuf ? (
                <MediumPrimaryButton onClick={onTest}>
                  Run diagnostics
                </MediumPrimaryButton>
              ) : !numTested && !isYusuf ? (
                <SmallText>Ask Yusuf to run diagnostics</SmallText>
              ) : (
                <MediumSecondaryButton onClick={onClose}>
                  Close
                </MediumSecondaryButton>
              )}
            </ModalActions>
          </>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default CatalogDiagnosticsModal;
