import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { EDIT_LOCATION } from '../../../../api/mutations/training';
import { SmallerTextInputWidthStyle } from '../../../../styles/shared-styled-components';
import { integerInputType } from '../../../../utils/constants';
import { safeParseInt } from '../../../../utils/numbers';
import LoadingIndicator from '../../../LoadingIndicator';
import LocationSearchBox from '../../../Search/LocationSearchBox/LocationSearchBox';
import {
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const formatResStr = (
  numLocations,
  address,
  humanReadableAddress,
  addressInstructions,
) => {
  return `${numLocations}${address}${humanReadableAddress}${addressInstructions}`;
};

const Location = ({ res }) => {
  const { data, loading, refetch } = res;

  const [saveLocationMutation, { loading: saveLoading }] =
    useMutation(EDIT_LOCATION);

  const locationInputRef = useRef();

  const location = data?.location || '';
  const address = location?.address || '';
  const humanReadableAddress = location?.humanReadableAddress;
  const postalCode = location?.postalCode;
  const lat = location?.lat;
  const lng = location?.lng;
  const timezone = location?.timezone;
  const instructions = location?.addressInstructions || '';

  const addressObject = {
    address,
    humanReadableAddress,
    lat,
    lng,
    postalCode,
    timezone,
  };

  const baseNumLocations = location?.num;

  const parsedLastSavedResStr = formatResStr(
    baseNumLocations,
    address,
    humanReadableAddress,
    instructions,
  );

  const [hasLoaded, setHasLoaded] = useState(false);
  const [numLocations, setNumLocations] = useState(location?.num);
  const [addressInfo, setAddressInfo] = useState(addressObject);
  const [addressInstructions, setAddressInstructions] = useState(instructions);
  const [lastSavedResStr, setLastSavedResStr] = useState(parsedLastSavedResStr);

  useEffect(() => {
    if (data) {
      setHasLoaded(true);
      setNumLocations(location?.num);
      setAddressInfo(addressObject);
      setAddressInstructions(instructions);
      setLastSavedResStr(parsedLastSavedResStr);
    }
  }, [data]);

  const autoSave = async (updatedAddressInfo = null) => {
    const addressInfoToInput = updatedAddressInfo || addressInfo;

    const currentResStr = formatResStr(
      numLocations,
      addressInfoToInput?.address,
      addressInfoToInput?.humanReadableAddress,
      addressInstructions,
    );

    if (currentResStr !== lastSavedResStr) {
      try {
        await saveLocationMutation({
          variables: {
            numLocations,
            addressInfo: addressInfoToInput,
            addressInstructions,
          },
          onCompleted: async (data) => {
            const updatedRes = data.editLocation;
            const updatedParsedLastSavedResStr = formatResStr(
              updatedRes?.numLocations,
              updatedRes?.address,
              updatedRes?.addressInstructions,
            );
            setLastSavedResStr(updatedParsedLastSavedResStr);
            refetch();
          },
        });
        console.log('Location saved successfully');
      } catch (error) {
        console.error('Error saving location:', error);
      }
    }
  };

  // Using a shorter update delay because I havent yet ported the state over to the Training component as I should,
  // and therefore the changes aren't saved when the user quickly changes tabs
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!saveLoading) {
        autoSave();
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [numLocations, addressInfo, addressInstructions, lastSavedResStr]);

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer>
        <PromptTitleText>Number of locations</PromptTitleText>
        <PromptInput
          type={integerInputType}
          value={numLocations}
          onChange={(e) => setNumLocations(safeParseInt(e.target.value))}
          onBlur={() => autoSave()}
        />
      </PromptContainer>
      <PromptContainer addMediumGap>
        <PromptTitleText>Location address</PromptTitleText>
        <LocationSearchBox
          customLabel='Geocoded address'
          value={addressInfo?.address}
          useSmallWidth
          onChange={(newAddress) => {
            setAddressInfo({
              humanReadableAddress: addressInfo.humanReadableAddress,
              address: newAddress,
            });
          }}
          onEnter={(newAddress, newLat, newLng, newTimezone, newPostalCode) => {
            const updatedAddressInfo = {
              ...addressInfo,
              address: newAddress,
              lat: newLat,
              lng: newLng,
              timezone: newTimezone,
              postalCode: newPostalCode,
            };
            setAddressInfo(updatedAddressInfo);
            autoSave(updatedAddressInfo);
          }}
          customPopperMenuStyle={SmallerTextInputWidthStyle}
          ref={locationInputRef}
        />
        <PromptInput
          label={`Address as you'd say it out loud`}
          value={addressInfo?.humanReadableAddress}
          onChange={(e) => {
            setAddressInfo({
              ...addressInfo,
              humanReadableAddress: e.target.value,
            });
          }}
          smallGap
        />
      </PromptContainer>
      <PromptContainer>
        <PromptTitleText>Address instructions</PromptTitleText>
        <PromptInput
          value={addressInstructions}
          onChange={(e) => {
            setAddressInstructions(e.target.value);
          }}
          multiline
          numRows={3}
        />
      </PromptContainer>
    </PromptsSection>
  );
};

export default Location;
