import React, { useState } from 'react';
import { LargeTinyText } from '../../../styles/shared-styled-components';
import {
  basePalette,
  ChartContainer,
  ChartTitleContainer,
  StyledChart,
} from '../shared-dashboard-styles';

const PieChart = ({ data, series, fills, title }) => {
  const [options, setOptions] = useState({
    theme: {
      palette: {
        ...basePalette,
        fills: fills || basePalette.fills,
      },
    },
    chart: {
      borderRadius: 10,
      height: '100%',
    },
    data,
    series,
    legend: {
      position: 'bottom',
      item: {
        label: {
          fontSize: 14,
          fontFamily: 'SeldonFont',
        },
        marker: {
          size: 10,
          shape: 'circle',
        },
      },
    },
    tooltip: {
      position: {
        type: 'pointer',
        xOffset: 70,
        yOffset: 140,
      },
    },
  });

  return (
    <ChartContainer>
      <StyledChart options={options} />
      <ChartTitleContainer>
        <LargeTinyText>{title}</LargeTinyText>
      </ChartTitleContainer>
    </ChartContainer>
  );
};

export default PieChart;
