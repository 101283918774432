import React from 'react';
import {
  AutoScrollWrapper,
  StartAlignedMediumDarkEssText,
} from '../../../../styles/shared-styled-components';
import { paymentTypeOptions } from '../../../../utils/constants';
import LoadingIndicator from '../../../LoadingIndicator';
import SwitchToggle from '../../../Micro/SwitchToggle/SwitchToggle';
import {
  ListInputContainer,
  ListInputSection,
  ListInputTitleContainer,
  PromptContainer,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const Payments = ({ policy, onChange, loading }) => {
  const paymentPolicy = policy?.payment || {};
  const acceptedTypes = paymentPolicy?.acceptedTypes || [];
  const acceptedTypesMap = {};
  acceptedTypes?.map((t) => (acceptedTypesMap[t] = true));

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection hideOverflow>
      <PromptContainer overflow>
        <ListInputTitleContainer>
          <PromptTitleText>
            Select which of these payment types your business supports
          </PromptTitleText>
        </ListInputTitleContainer>
        <ListInputSection widthPercentage={60}>
          <AutoScrollWrapper>
            {paymentTypeOptions.map((type) => {
              const { key, label } = type;
              const included = !!acceptedTypesMap[key];
              return (
                <ListInputContainer>
                  <StartAlignedMediumDarkEssText>
                    {label}
                  </StartAlignedMediumDarkEssText>
                  <SwitchToggle
                    value={included}
                    onChange={() => onChange(key, !included)}
                  />
                </ListInputContainer>
              );
            })}
          </AutoScrollWrapper>
        </ListInputSection>
      </PromptContainer>
    </PromptsSection>
  );
};

export default Payments;
