import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import {
  GET_APPOINTMENT_CLIENTS,
  GET_MY_ANALYTICS,
} from '../queries/analytics';

export const useMyAnalytics = ({ filteredCampaignIds, start, end }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_ANALYTICS, {
    variables: {
      filteredCampaignIds,
      start,
      end,
    },
    fetchPolicy: 'network-only',
  });

  return {
    error,
    loading,
    refetch,
    analytics: get(data, 'getMyAnalytics', null),
  };
};

export const useAppointmentClients = ({ clientIds, skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_APPOINTMENT_CLIENTS, {
    variables: {
      clientIds,
    },
    skip: skipCondition,
  });

  return {
    error,
    loading,
    refetch,
    clients: get(data, 'getAppointmentClients', null),
  };
};
