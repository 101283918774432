import { getPathnameToReturnTo } from './routing';

export const returnToUrlLocalStorageKey = 'return_to_url';
export const clinicCookieKey = 'medacquire_clinic_id';
export const crmCookieKey = 'crm_pass';
export const partnerCrmCookieKey = 'medacquire_partner_crm_password';
export const hasSeenConfettiCookieKey = 'has-seen-confetti';
export const justCompletedOnboardingCookieKey = 'just-completed-onboarding';
export const justCompletedOnboardingSnackbarMessage =
  'Your LiveIQ Agent has been created 🎉';

export const handleLogin = async ({
  onClick,
  pathname,
  pathToReturnTo,
  loginWithRedirect,
}) => {
  onClick();

  const pathnameToReturnTo = pathToReturnTo
    ? pathToReturnTo
    : getPathnameToReturnTo(pathname);

  await loginWithRedirect({
    appState: {
      returnTo: `${pathnameToReturnTo}`,
    },
  });
};

export const handleSignup = async ({
  onClick,
  pathname,
  loginWithRedirect,
}) => {
  onClick();

  const pathnameToReturnTo = getPathnameToReturnTo(pathname);

  await loginWithRedirect({
    appState: {
      returnTo: pathnameToReturnTo,
    },
    authorizationParams: {
      screen_hint: 'signup',
    },
  });
};

export default {
  clinicCookieKey,
  crmCookieKey,
  partnerCrmCookieKey,
  justCompletedOnboardingCookieKey,
  justCompletedOnboardingSnackbarMessage,
  hasSeenConfettiCookieKey,
  handleLogin,
  handleSignup,
};
