import React from 'react';
import {
  CenteredDiv,
  ColumnDivWithGap,
  ExclamationIcon,
  MediumDarkMediumText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalContainer,
} from '../../../styles/shared-styled-components';
import LoadingIndicator from '../../LoadingIndicator';

const DefaultConfirmationModal = ({
  isOpen,
  onClose,
  paragraphText,
  buttonText = 'Continue',
  onClick,
  loading,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth={830}
    >
      <ModalContainer>
        <CenteredDiv>
          <ExclamationIcon />
        </CenteredDiv>
        <MediumDarkMediumText>
          Are you sure you want to {paragraphText}
        </MediumDarkMediumText>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ColumnDivWithGap>
            <MediumPrimaryButton onClick={() => onClick()}>
              {buttonText}
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={onClose}>
              Cancel
            </MediumSecondaryButton>
          </ColumnDivWithGap>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default DefaultConfirmationModal;
