import { gql } from '@apollo/client';

export const GET_MODULE_STATUS = gql`
  query getModuleStatus {
    getModuleStatus
  }
`;

export const GET_LAUNCH_STATUS = gql`
  query getLaunchStatus {
    getLaunchStatus
  }
`;

export const GET_AGENT_SETTINGS = gql`
  query getAgentSettings {
    getAgentSettings
  }
`;

export const GET_OVERVIEW = gql`
  query getOverview {
    getOverview
  }
`;

export const GET_CLOSURES = gql`
  query getClosures {
    getClosures
  }
`;

export const GET_SERVICE_CORPUS = gql`
  query getServiceCorpus {
    getServiceCorpus
  }
`;

export const GET_MEMBERSHIPS = gql`
  query getMemberships {
    getMemberships
  }
`;
