import gql from 'graphql-tag';

export const GET_MY_SEQUENCES = gql`
  query getMySequences($locations: Quantity!) {
    getMySequences(locations: $locations) {
      id
      type
      status
      startDate
      endDate
      campaignName
      agentVersionName
      numChats
    }
  }
`;

export const GET_MY_CHAT_AGENT_VERSION_SEQUENCES = gql`
  query getMyChatAgentVersionSequences {
    getMyChatAgentVersionSequences
  }
`;

export const GET_SEQUENCE_SETTINGS = gql`
  query getSequenceSettings {
    getSequenceSettings {
      messageWhenBusinessClosed
      continueIfUpcomingVisit
      stopWord
      timing
      numberOfClientFollowUps
      numberOfLeadFollowUps
      maxNewChatsPerCampaignPerBatch
      maxFollowUpsPerCampaignPerBatch
      defaultBatchSize
      dripCadence
    }
  }
`;
