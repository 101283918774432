import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect } from 'react';
import Paths from '../../../Paths';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import Header from '../../../components/NavBar/Header/Header';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  ColumnCenteredDiv,
  ContentContainer,
  MediumDarkMediumText,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
} from '../../../styles/shared-styled-components';
import { AuthSection } from './styled';

const MiddleRouter = () => {
  const { user, userLoading } = useContext(BaseContext);
  const { user: auth0User, isLoading: auth0UserLoading, logout } = useAuth0();

  const onClickLogout = () => {
    window.localStorage.clear();
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}${Paths.homepage}`,
      },
    });
  };

  useEffect(() => {
    if (!user && !userLoading && !auth0User && !auth0UserLoading) {
      onClickLogout();
    }
  }, [user, userLoading, auth0User, auth0UserLoading]);

  return (
    !auth0UserLoading &&
    !userLoading && (
      <>
        <MetaSetter title={'Auth Router'} />
        <Header blankMode />
        <ContentContainer>
          <AuthSection>
            <MediumGapColumnCenteredDiv>
              <MediumDarkMediumText>
                Your session has expired, please log out and re-authenticate
              </MediumDarkMediumText>
              <ColumnCenteredDiv>
                <MediumPrimaryButton onClick={onClickLogout}>
                  Logout
                </MediumPrimaryButton>
              </ColumnCenteredDiv>
            </MediumGapColumnCenteredDiv>
          </AuthSection>
        </ContentContainer>
      </>
    )
  );
};

export default MiddleRouter;
