import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppointmentById } from '../../../api/hooks/calendar';
import { UPDATE_APPOINTMENT } from '../../../api/mutations/calendar';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  CardPageSection,
  ColumnCenteredDiv,
  ContentContainer,
  EmptyGapColumnCenteredDiv,
  MediumDarkSMText,
  PageContainer,
  PageTitleText,
  SMText,
} from '../../../styles/shared-styled-components';
import {
  cancelledStatusKey,
  depositHoldStatusKey,
  scheduledStatusKey,
} from '../../../utils/constants';

// Metadata
// {"id": "ad039642-974a-4a55-92eb-018fab4929e7",
//   "url": "https://liveiq.ai/app/appointment/ad039642-974a-4a55-92eb-018fab4929e7",
//   "status": "deposit_hold", "deposit": {"id": "bbc4afc8-9d81-46bf-9722-c01568bc7957", "amount": 25},
//   "timeslot": "16:00", "startedAt": "2024-10-13T16:00:00",
//   "appointment": "Booked for a 'Botox' session at 16:00 on 2024-10-13",
//   "serviceName": ["Botox"], "callSourceId": "call_57579e20202f4a119ed540ddaff"}

let allowAction = false;

const Appointment = () => {
  const contentContainerRef = useRef();
  const { appointmentId } = useParams();

  const { appointment, loading } = useAppointmentById({ id: appointmentId });
  console.log('appointment', appointment);
  const [updateAppointmentMutation, { loading: updateLoading }] =
    useMutation(UPDATE_APPOINTMENT);

  const [snackbarMessage, setSnackbarMessage] = useState();

  const metadata = appointment?.metadata;
  const id = metadata?.id;
  const callSourceId = metadata?.callSourceId;
  const status = metadata?.status;
  const action = allowAction
    ? null
    : status === depositHoldStatusKey
    ? `Simulate deposit payment`
    : null;
  console.log('metadata', metadata);

  const onUpdateStatus = (currentStatus) => {
    const updatedStatus =
      currentStatus === depositHoldStatusKey
        ? scheduledStatusKey
        : currentStatus === scheduledStatusKey
        ? cancelledStatusKey
        : null;

    if (updatedStatus) {
      updateAppointmentMutation({
        variables: {
          appointment: {
            id,
            status: updatedStatus,
            callSourceId,
          },
        },
        onCompleted: async (data) => {
          const message =
            updatedStatus === scheduledStatusKey
              ? 'Deposit received'
              : updatedStatus === cancelledStatusKey
              ? `Appointment cancelled`
              : '';
          setSnackbarMessage(message);
        },
      });
    }
  };

  if (loading || updateLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <MetaSetter
        title={`Appointment`}
        description={`Appointment`}
      />
      <Header
        displayDrawer={false}
        label='LiveIQ Appointment'
      />
      <PageContainer>
        <ContentContainer ref={contentContainerRef}>
          <ColumnCenteredDiv>
            <PageTitleText>Appointment Info</PageTitleText>
          </ColumnCenteredDiv>
          <CardPageSection>
            <ColumnCenteredDiv bottomMargin={20}>
              <MediumDarkSMText>
                {status === depositHoldStatusKey ? (
                  <>
                    This is an example of how your clients would be sent a link
                    to pay their deposit. This page can be customized, and the
                    payment is able to be processed however you like: through
                    your EMR, CRM, or natively through LiveIQ.
                    <br></br>
                    <br></br>
                    When the deposit is paid, the appointment would be confirmed
                    in your EMR. If the deposit hold expires, your LiveIQ agent
                    will remove it from your calendar automatically.
                  </>
                ) : status === scheduledStatusKey ? (
                  <>
                    You've confirmed your booking by making a (simulated)
                    deposit payment. Now if you call Ava back using the same
                    phone number, she'll recognize you and see that your
                    appointment has been locked in.
                  </>
                ) : status === cancelledStatusKey ? (
                  <>Your appointment has successfully been cancelled.</>
                ) : (
                  <></>
                )}
              </MediumDarkSMText>
            </ColumnCenteredDiv>
            <EmptyGapColumnCenteredDiv>
              <SMText>Here's your appointment status:</SMText>
              <SMText>{metadata?.appointment}</SMText>
            </EmptyGapColumnCenteredDiv>
            {/* {action && (
              <CenteredDivWithGap topMargin={40}>
                <MediumPrimaryButton onClick={() => onUpdateStatus(status)}>
                  {action}
                </MediumPrimaryButton>
              </CenteredDivWithGap>
            )} */}
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Appointment;
