import { useContext } from 'react';
import { Position } from 'reactflow';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import {
  CenteredDiv,
  EmptyGapFlexDiv,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  bookingScriptNodeType,
  conditionalScriptNodeType,
  messageScriptNodeType,
  nodeToEditIdCookieKey,
  nodeTypeLabelMap,
} from '../../../../utils/constants';
import { getCookieExpiryObject } from '../../../../utils/date';
import {
  BookingIcon,
  ConditionalIcon,
  ContentPreviewContainer,
  ContentPreviewText,
  EditIconContainer,
  EditNodeIcon,
  MessageTypeIcon,
  NodeButtonsContainer,
  NodeContainer,
  NodeText,
  StyledHandle,
  TypeIconContainer,
} from './styled';

const cookieExpiryObject = getCookieExpiryObject();

const formatLabel = (label) => {
  return label.length > 15 ? `${label.slice(0, 13)}...` : label;
};

const emptyContentText = 'No content';

const Node = ({ data }) => {
  const { setCookie } = useContext(BaseContext);

  const id = data.id;
  const type = data.type;

  const onEdit = (id) => {
    setCookie(nodeToEditIdCookieKey, id, cookieExpiryObject);
  };

  const content = (data.content || emptyContentText).trim();

  return (
    <EmptyGapFlexDiv>
      <NodeContainer type={type}>
        <NodeButtonsContainer>
          <TypeIconContainer>
            <Tooltip
              title={
                <TooltipTitleText>
                  {nodeTypeLabelMap[type]} node
                </TooltipTitleText>
              }
              placement='right'
            >
              {type === messageScriptNodeType ? (
                <MessageTypeIcon />
              ) : type === conditionalScriptNodeType ? (
                <CenteredDiv>
                  <ConditionalIcon />
                </CenteredDiv>
              ) : type === bookingScriptNodeType ? (
                <BookingIcon />
              ) : (
                <></>
              )}
            </Tooltip>
          </TypeIconContainer>
          <EditIconContainer onClick={() => onEdit(id)}>
            <Tooltip
              title={<TooltipTitleText>Edit node</TooltipTitleText>}
              placement='right'
            >
              <EditNodeIcon />
            </Tooltip>
          </EditIconContainer>
        </NodeButtonsContainer>
        <StyledHandle
          type='target'
          position={Position.Top}
        />
        <NodeText>{formatLabel(data.label)}</NodeText>
        <StyledHandle
          type='source'
          position={Position.Bottom}
          id='a'
        />
      </NodeContainer>
      <ContentPreviewContainer type={type}>
        <ContentPreviewText>
          {content === emptyContentText ? (
            <>{emptyContentText}</>
          ) : (
            <>
              "
              {content.length > 60
                ? `${content.slice(0, 60)}...`
                : `${content}`}
              "
            </>
          )}
        </ContentPreviewText>
      </ContentPreviewContainer>
    </EmptyGapFlexDiv>
  );
};

export default Node;
