import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  EssGapStyle,
} from '../../../styles/shared-styled-components';

export const AttachmentsContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.side === 'left' ? 'flex-start' : 'flex-end'};
  width: calc(100% - 60px);
  ${AutoHorizontalMargins}
  ${EssGapStyle}
`;

export const AttachmentThumbnail = styled.img`
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  max-height: 200px;
`;
