import { useMutation } from '@apollo/client';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Background,
  Controls,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import Paths from '../../../../Paths';
import { useScript } from '../../../../api/hooks/content';
import { useMySessionTypes } from '../../../../api/hooks/enterprise';
import { EDIT_SCRIPT_DATA } from '../../../../api/mutations/content';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import NodeDrawer from '../../../../components/Content/Scripts/NodeDrawer';
import Header from '../../../../components/NavBar/Header';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import withProps from '../../../../custom-hooks/with-props';
import {
  BottomMessageContainer,
  CardPageSection,
  CenteredDivWithLargerGap,
  CompleteButtonCheckmarkIcon,
  LightExtraTinyText,
  PageContainer,
  PageHeaderContainer,
  PageSubtitleText,
  PageTitleBottomBorderContainer,
  SecondaryBackIcon,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  autoSaveDelayMs,
  conditionalScriptNodeType,
  messageScriptNodeType,
  nodeToEditIdCookieKey,
  packageKey,
  packagesLabel,
} from '../../../../utils/constants';
import Node from './Node';
import { ScriptContentContainer, StyledReactFlow } from './styled';

const customNodeTypeKey = 'custom';

const formatNode = (node) => {
  return {
    id: node.id,
    position: node.configJson.position,
    data: {
      id: node.id,
      label: node.title,
      type: node.type || messageScriptNodeType,
      content: node.content,
      condition: node.conditionJson?.text,
      expectedResponse: node?.expectedResponseJson?.text,
      sessionTypeId: node.sessionTypeId,
      bookingOffering: node.bookingOffering,
    },
    type: customNodeTypeKey,
  };
};

const formatNodes = (nodes) => {
  return nodes.map((node) => {
    return formatNode(node);
  });
};

const EditScript = () => {
  const navigate = useNavigate();
  const { width, drawerOpen, drawerExpanded, cookies, removeCookie } =
    useContext(BaseContext);

  const nodeToEditIdCookie = cookies[nodeToEditIdCookieKey];

  const contentContainerRef = useRef();
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);

  const { screenToFlowPosition, fitView } = useReactFlow();

  const { scriptId } = useParams();
  const { script, loading, refetch } = useScript({
    id: scriptId,
  });
  const {
    sessionTypes: catalog,
    loading: sessionTypesLoading,
    refetch: refetchSessionTypeCatalog,
  } = useMySessionTypes({
    onlyBookable: true,
  });
  const categoriesMap = catalog?.categories || {};
  const allCategories = Object.values(categoriesMap);

  const [editScriptMutation, { loading: editScriptLoading }] =
    useMutation(EDIT_SCRIPT_DATA);

  const [nodes, setNodes, onNodesChange] = useNodesState(
    formatNodes(script?.nodes || []),
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(script?.edges || []);
  const [nodeToEditId, setNodeToEditId] = useState(nodeToEditIdCookie);
  const [changesMade, setChangesMade] = useState(false);
  const [availableOfferings, setAvailableOfferings] = useState([]);
  const [remainingHeight, setRemainingHeight] = useState(0);
  const [autoSaved, setAutoSaved] = useState(false);

  useEffect(() => {
    fitView();
  }, []);

  useEffect(() => {
    if (script) {
      setNodes(formatNodes(script.nodes || []));
      setEdges(script.edges || []);
    }
  }, [script]);

  useEffect(() => {
    if (nodeToEditIdCookie) {
      setNodeToEditId(nodeToEditIdCookie);
    } else {
      setNodeToEditId();
    }
  }, [nodeToEditIdCookie]);

  useEffect(() => {
    if (catalog) {
      let allSessionTypes = [];
      allCategories.map((c) => {
        const allCategoryServices = [
          ...(c?.dropIns || []),
          ...(c?.consultations || []),
          ...(c?.addOns || []),
        ];
        allSessionTypes.push({
          categoryId: c.id,
          categoryName: c.name,
          services: allCategoryServices,
        });
      });
      allSessionTypes.push({
        categoryId: packageKey,
        categoryName: packagesLabel,
        services: Object.values(catalog?.packages) || [],
      });
      setAvailableOfferings(allSessionTypes);
    }
  }, [catalog]);

  useEffect(() => {
    const handleResize = () => {
      const divHeight =
        contentContainerRef?.current?.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const heightDifference = windowHeight - divHeight;
      setRemainingHeight(heightDifference);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width, script]);

  useEffect(() => {
    if (autoSaved) {
      const timer = setTimeout(() => {
        setAutoSaved(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [autoSaved]);

  const autoSaveScript = (updatedNodes = null) => {
    const nodesToSave = updatedNodes || nodes;
    editScriptMutation({
      variables: {
        scriptId,
        nodes: nodesToSave,
        edges,
      },
      onCompleted: async (data) => {
        await refetchScript();
        setChangesMade(false);
        setAutoSaved(true);
      },
    });
  };

  // Auto-save script
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (changesMade) {
        autoSaveScript();
      }
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [changesMade, nodes, edges]);

  const refetchScript = async () => {
    await refetch();
  };

  const handleNodesChange = (data) => {
    const changedNode = data?.[0];
    const changedNodeId = changedNode?.id;
    const isRemoving = changedNode.type === 'remove';
    const isDragging = changedNode?.dragging;

    const changedNodeType = changedNode?.type;

    if (isRemoving) {
      const nodeEdge = edges.find((e) => e.target === changedNodeId);
      const nodeIdsToRenderUnconditional = [];
      edges.map((e) => {
        if (e?.source === nodeEdge?.source && e?.target !== changedNodeId) {
          nodeIdsToRenderUnconditional.push(e.target);
        }
      });

      // If removing a branch, and only one other branch with same parent, make other branch unconditonal
      if (nodeIdsToRenderUnconditional.length === 1) {
        setNodes((existingNodes) => {
          const formattedNodes = [];
          existingNodes.map((n) => {
            if (nodeIdsToRenderUnconditional.includes(n.id)) {
              formattedNodes.push({
                ...n,
                data: {
                  ...n.data,
                  type: messageScriptNodeType,
                },
              });
            } else if (n.id !== changedNodeId) {
              formattedNodes.push(n);
            }
          });
          return formattedNodes;
        });
      } else {
        onNodesChange(data);
      }
    } else if (isDragging) {
      const updatedNodes = nodes.map((node) =>
        node.id === changedNodeId
          ? {
              ...node,
              position: changedNode.position,
              positionAbsolute: changedNode.positionAbsolute,
            }
          : node,
      );
      setNodes(updatedNodes);
    } else {
      onNodesChange(data);
    }

    const saveRequired =
      !['dimensions', 'select', 'position'].includes(changedNodeType) ||
      isDragging;
    if (saveRequired) {
      setChangesMade(true);
    }
  };

  const handleEdgesChange = (data) => {
    onEdgesChange(data);
    console.log('on update edges');
    setChangesMade(true);
  };

  const onUpdateNode = (data, shouldCloseDrawer = false) => {
    const updatedNodes = [...nodes].map((n) => {
      if (n.id === data.id) {
        const updatedNode = {
          ...n,
          data: {
            ...n.data,
            ...data,
          },
        };
        return updatedNode;
      }
      return n;
    });
    setNodes(updatedNodes);

    autoSaveScript(updatedNodes);

    if (shouldCloseDrawer) {
      removeCookie(nodeToEditIdCookieKey);
    }
  };

  const nodeTypes = useMemo(
    () => ({
      [customNodeTypeKey]: withProps(Node, {}),
    }),
    [],
  );

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges],
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = uuidv4();

        const sourceNode = connectingNodeId.current;
        const nodeIdsToRenderConditionalMap = {};

        setEdges((existingEdges) => {
          existingEdges.map((e) => {
            if (e.source === sourceNode) {
              nodeIdsToRenderConditionalMap[e.target] = true;
            }
          });

          nodeIdsToRenderConditionalMap[id] = true;

          return existingEdges.concat({
            id,
            source: sourceNode,
            target: id,
          });
        });
        setNodes((existingNodes) => {
          const newNodeIsConditional =
            Object.keys(nodeIdsToRenderConditionalMap).length > 1;

          const existingNodeNameMap = {};

          const formattedNodes = existingNodes.map((n) => {
            existingNodeNameMap[n.data.label] = true;

            if (n.id in nodeIdsToRenderConditionalMap) {
              return {
                ...n,
                data: {
                  ...n.data,
                  type: conditionalScriptNodeType,
                },
              };
            }
            return n;
          });

          let title = `Node ${existingNodes.length + 1}`;
          if (title in existingNodeNameMap) {
            let i = 1;
            let updatedTitle = `${title} (${i})`;
            while (updatedTitle in existingNodeNameMap) {
              i += 1;
              updatedTitle = `${title} (${i})`;
            }
            title = updatedTitle;
          }

          const newNode = formatNode({
            id,
            configJson: {
              position: screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
              }),
              origin: [0.5, 0.0],
            },
            title,
          });
          const formattedNewNode = {
            ...newNode,
            data: {
              ...newNode.data,
              type: newNodeIsConditional
                ? conditionalScriptNodeType
                : messageScriptNodeType,
            },
          };

          return [...formattedNodes, formattedNewNode];
        });
      }
    },
    [screenToFlowPosition],
  );

  const nodeToEdit = nodeToEditId
    ? nodes?.find((n) => n.id === nodeToEditId)
    : null;
  const nodeDrawerDisplayed = !!nodeToEdit;

  return (
    script && (
      <>
        <MetaSetter
          title={`Edit Script`}
          description={`Edit Script`}
        />
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ScriptContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
            nodeDrawerDisplayed={nodeDrawerDisplayed}
            fullWidth
            removeGap
            hideOverflow
          >
            <PageHeaderContainer ref={contentContainerRef}>
              <CenteredDivWithLargerGap>
                <Tooltip
                  title={<TooltipTitleText>Back to campaigns</TooltipTitleText>}
                  placement='left'
                >
                  <SecondaryBackIcon
                    onClick={() => navigate(Paths.campaigns)}
                  />
                </Tooltip>
                <PageSubtitleText>Editing Campaign Script</PageSubtitleText>
              </CenteredDivWithLargerGap>
              <PageTitleBottomBorderContainer />
            </PageHeaderContainer>
            {remainingHeight && (
              <CardPageSection
                fixedHeight={remainingHeight - 50}
                fullWidth
                ref={reactFlowWrapper}
              >
                <StyledReactFlow
                  nodes={nodes}
                  edges={edges}
                  nodeTypes={nodeTypes}
                  onNodesChange={handleNodesChange}
                  onEdgesChange={handleEdgesChange}
                  onConnect={onConnect}
                  onConnectStart={onConnectStart}
                  onConnectEnd={onConnectEnd}
                  fitView
                >
                  <Controls showInteractive={true} />
                  <Background />
                </StyledReactFlow>
              </CardPageSection>
            )}
            {(autoSaved || changesMade) && (
              <BottomMessageContainer saved={autoSaved}>
                {autoSaved ? (
                  <>
                    <LightExtraTinyText>Auto-saved</LightExtraTinyText>
                    <CompleteButtonCheckmarkIcon small />
                  </>
                ) : (
                  <LightExtraTinyText>
                    Changes pending save...
                  </LightExtraTinyText>
                )}
              </BottomMessageContainer>
            )}
          </ScriptContentContainer>
        </PageContainer>
        <NodeDrawer
          isOpen={nodeDrawerDisplayed}
          node={nodeToEdit}
          onUpdate={onUpdateNode}
          nodes={nodes}
          offerings={availableOfferings}
        />
      </>
    )
  );
};

const WrappedEditScript = () => {
  return (
    <ReactFlowProvider>
      <EditScript />
    </ReactFlowProvider>
  );
};

export default WrappedEditScript;
