import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EDIT_LOCATION_ONBOARDING } from '../../../api/mutations/enterprise';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import FormPage from '../../../components/Form/FormPage';
import Input from '../../../components/Form/Input';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Paths from '../../../Paths';
import {
  bookingSystems,
  crmSystems,
  dropdownInputType,
} from '../../../utils/constants';
import { valueIsEmpty } from '../../../utils/data';
import { getUserLocation } from '../../../utils/user';

const System = () => {
  const { user, userLoading, refetchUser } = useContext(BaseContext);
  const navigate = useNavigate();

  const [
    editLocationOnboardingMutation,
    { loading: editLocationOnboardingLoading },
  ] = useMutation(EDIT_LOCATION_ONBOARDING);

  const location = getUserLocation(user);
  const onboardingData = location.onboardingStatus || {};

  const [bookingSystem, setBookingSystem] = useState(
    onboardingData.bookingSystem,
  );
  const [crmSystem, setCrmSystem] = useState(onboardingData.crmSystem);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (location) {
      setBookingSystem(onboardingData.bookingSystem);
      setCrmSystem(onboardingData.crmSystem);
    }
  }, [user]);

  const onClickContinue = () => {
    editLocationOnboardingMutation({
      variables: {
        onboardingStatus: {
          ...onboardingData,
          crmSystem,
          bookingSystem,
        },
      },
      onCompleted: async (data) => {
        const success = data.editLocationOnboarding?.success;
        if (success) {
          setRedirecting(true);
          await refetchUser();
          navigate(Paths.integrationOnboarding);
        }
      },
    });
  };

  const inputs = [
    <Input
      id='booking-system'
      title='What booking system does your business use?'
      value={bookingSystem}
      onChange={(e) => {
        setBookingSystem(e.target.value);
      }}
      type={dropdownInputType}
      options={bookingSystems}
      isRequired={false}
      label={'Booking system'}
    />,
    <Input
      id='crm-system'
      title='What CRM system does your business use?'
      value={crmSystem}
      onChange={(e) => {
        setCrmSystem(e.target.value);
      }}
      type={dropdownInputType}
      options={crmSystems}
      isRequired={false}
      label={'CRM system'}
    />,
  ];

  const continueEnabled =
    !valueIsEmpty(bookingSystem) && !valueIsEmpty(crmSystem);

  const loading = userLoading || editLocationOnboardingLoading || redirecting;
  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    user && (
      <FormPage
        title='Location Setup - Your Systems'
        currentStep={1}
        totalNumSteps={3}
        isLoading={loading}
        inputs={inputs}
        continueEnabled={continueEnabled}
        onContinue={onClickContinue}
      />
    )
  );
};

export default System;
