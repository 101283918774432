import React, { useContext, useRef, useState } from 'react';
import { useMyAgents } from '../../../api/hooks/agent';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import EmptyCard from '../../../components/Micro/EmptyCard';
import CreateAgentModal from '../../../components/Modals/CreateAgentModal/CreateAgentModal';
import EditAgentModal from '../../../components/Modals/EditAgentModal';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  BaseCard,
  CardHeaderContainer,
  CardPageSection,
  CardPageSectionActionsContainer,
  ColumnCenteredDiv,
  ColumnDivWithGap,
  ContentContainer,
  DarkSMText,
  MediumGapColumnCenteredDiv,
  PageContainer,
  PageTitleText,
  SmallAddIcon,
  SmallSecondaryButton,
  StartAlignedEssText,
  StartAlignedLightDarkEssText,
} from '../../../styles/shared-styled-components';
import {
  agentProductMap,
  assistantKey,
  humanAgentKey,
  senderTypeLabelMap,
} from '../../../utils/constants';
import { getInitials } from '../../../utils/name';
import { getUserLocation } from '../../../utils/user';
import { AgentTypeChip } from './styled';

const Agents = () => {
  const { inDemoMode, user, drawerOpen, drawerExpanded } =
    useContext(BaseContext);

  const location = getUserLocation(user);

  const contentContainerRef = useRef();

  const {
    agents,
    loading: agentsLoading,
    refetch: refetchAgents,
  } = useMyAgents();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editAgentModalOpen, setEditAgentModalOpen] = useState(false);
  const [agentToEdit, setAgentToEdit] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState('');

  if (agentsLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    agents && (
      <>
        <MetaSetter
          title={`Agents`}
          description={`Your LiveIQ Agents`}
        />
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
            ref={contentContainerRef}
          >
            <ColumnCenteredDiv>
              <PageTitleText>Agents</PageTitleText>
            </ColumnCenteredDiv>
            <CardPageSection>
              <CardPageSectionActionsContainer>
                <SmallSecondaryButton
                  onClick={() => setCreateModalOpen(true)}
                  addGap
                >
                  <SmallAddIcon /> Add human agent
                </SmallSecondaryButton>
              </CardPageSectionActionsContainer>
              {agents.length === 0 ? (
                <EmptyCard text='No agents created' />
              ) : (
                <MediumGapColumnCenteredDiv>
                  {agents.map((agent) => {
                    const product = agentProductMap[agent.productKey];
                    const description = product?.description;
                    const agentType = agent.productKey
                      ? assistantKey
                      : humanAgentKey;
                    const agentVersion = agent.agentVersion;
                    return (
                      <BaseCard>
                        {agentType === assistantKey ? (
                          <>
                            <ColumnDivWithGap>
                              <CardHeaderContainer>
                                <DarkSMText>{agent.name}</DarkSMText>
                                <AgentTypeChip type={agentType}>
                                  {senderTypeLabelMap[agentType]}
                                </AgentTypeChip>
                              </CardHeaderContainer>
                              <StartAlignedEssText>
                                Running v{agentVersion?.version}:{' '}
                                {agentVersion?.name}
                              </StartAlignedEssText>
                            </ColumnDivWithGap>
                          </>
                        ) : (
                          <>
                            <CardHeaderContainer>
                              <DarkSMText>
                                {inDemoMode
                                  ? `${getInitials(
                                      agent.name.split(' ')[0],
                                      agent.name.split(' ')[1],
                                    )}`
                                  : agent.name}
                              </DarkSMText>
                              <AgentTypeChip type={agentType}>
                                {senderTypeLabelMap[agentType]}
                              </AgentTypeChip>
                            </CardHeaderContainer>
                            {!inDemoMode && (
                              <div>
                                <StartAlignedLightDarkEssText>
                                  {agent.email}
                                </StartAlignedLightDarkEssText>
                              </div>
                            )}
                          </>
                        )}
                      </BaseCard>
                    );
                  })}
                </MediumGapColumnCenteredDiv>
              )}
            </CardPageSection>
          </ContentContainer>
        </PageContainer>
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          quick
        />
        <CreateAgentModal
          isOpen={createModalOpen}
          onClose={() => {
            setCreateModalOpen(false);
          }}
          onSave={() => {
            refetchAgents();
            setSnackbarMessage('Agent created');
          }}
        />
        <EditAgentModal
          isOpen={editAgentModalOpen}
          onClose={() => {
            setEditAgentModalOpen(false);
            setAgentToEdit();
          }}
          agent={agentToEdit}
          onSave={(shouldRefetch) => {
            if (shouldRefetch) {
              refetchAgents();
            } else {
              setEditAgentModalOpen(false);
              setAgentToEdit();
            }
            setSnackbarMessage('Saved changes');
          }}
        />
      </>
    )
  );
};

export default Agents;
