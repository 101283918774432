import { useMutation } from '@apollo/client';
import React from 'react';
import { REPLACE_MESSAGE_TEMPLATE_TYPE } from '../../../api/mutations/content';
import {
  CenteredDiv,
  ColumnDivWithGap,
  ExclamationIcon,
  MediumDarkMediumText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalContainer,
} from '../../../styles/shared-styled-components';
import {
  scriptTemplateType,
  singleNodeTemplateType,
  templateTypeLabelMap,
} from '../../../utils/constants';
import LoadingIndicator from '../../LoadingIndicator';

const EditTemplateTypeModal = ({ isOpen, onClose, campaign }) => {
  const [replaceMessageTemplateTypeMutation, { loading }] = useMutation(
    REPLACE_MESSAGE_TEMPLATE_TYPE,
  );

  const campaignName = campaign?.name;
  const currentType = campaign?.template?.type;

  const replaceTemplate = () => {};

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth={830}
    >
      <ModalContainer>
        <CenteredDiv>
          <ExclamationIcon />
        </CenteredDiv>
        <MediumDarkMediumText>
          Are you sure you want to replace the existing{' '}
          {templateTypeLabelMap[currentType]} for the "{campaignName}" campaign
          with a{' '}
          {currentType === scriptTemplateType
            ? templateTypeLabelMap[singleNodeTemplateType]
            : templateTypeLabelMap[scriptTemplateType]}
          ?
        </MediumDarkMediumText>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ColumnDivWithGap>
            <MediumPrimaryButton onClick={() => replaceTemplate()}>
              Replace
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={onClose}>
              Cancel
            </MediumSecondaryButton>
          </ColumnDivWithGap>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default EditTemplateTypeModal;
