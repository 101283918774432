import React from 'react';
import { LightDarkMediumText } from '../../../styles/shared-styled-components';
import { EmptyCardComponent } from './styled';

const EmptyCard = ({ text }) => {
  return (
    <EmptyCardComponent>
      <LightDarkMediumText>{text}</LightDarkMediumText>
    </EmptyCardComponent>
  );
};

export default EmptyCard;
