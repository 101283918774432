import moment from 'moment-timezone';

const months = [
  'Jan',
  'Feb',
  'March',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getDateStringForCookie = (date) => {
  const formattedDate = new Date(date);

  const day = formattedDate.getUTCDate().toString().padStart(2, '0');
  const month = months[formattedDate.getUTCMonth()];
  const year = formattedDate.getUTCFullYear();
  const hours = formattedDate.getUTCHours().toString().padStart(2, '0');
  const minutes = formattedDate.getUTCMinutes().toString().padStart(2, '0');
  const seconds = formattedDate.getUTCSeconds().toString().padStart(2, '0');

  return `${day} ${month} ${year} ${hours}:${minutes}:${seconds} GMT`;
};

export const dateToDMYFormat = (date) => {
  if (date) {
    return new Date(date).toLocaleDateString('en-GB');
  }
};

export const dateToTextFormat = (date) => {
  if (!date) {
    return '';
  }

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('en-US', options);

  return formattedDate;
};

const torontoTimezone = 'America/Toronto';
const chicagoTimezone = 'America/Chicago';
const newYorkTimezone = 'America/New_York';
const laTimezone = 'America/Los_Angeles';
const denverTimezone = 'America/Denver';
const phoenixTimezone = 'America/Phoenix';
const anchorageTimezone = 'America/Anchorage';
const honoluluTimezone = 'Pacific/Honolulu';
const mexicoCityTimezone = 'America/Mexico_City';
const vancouverTimezone = 'America/Vancouver';
const halifaxTimezone = 'America/Halifax';
const winnipegTimezone = 'America/Winnipeg';

export const timezoneLabelMap = {
  [torontoTimezone]: 'EST',
  [newYorkTimezone]: 'EST',
  [chicagoTimezone]: 'CST',
  [laTimezone]: 'PST',
  [denverTimezone]: 'MST',
  [phoenixTimezone]: 'MST',
  [anchorageTimezone]: 'AK',
  [honoluluTimezone]: 'HAST',
  [mexicoCityTimezone]: 'CST',
  [vancouverTimezone]: 'PST',
  [halifaxTimezone]: 'AST',
  [winnipegTimezone]: 'CST',
};

export const getFormattedDateString = (dateInput, includeYear = true) => {
  // Create a new Date object
  const date = new Date(dateInput);

  const year = date.getFullYear();

  // Get the month name
  const month = months[date.getMonth()];

  // Get the formatted output
  const formattedDate = `${month} ${date.getDate()}${
    date.getDate() % 10 === 1 && date.getDate() !== 11
      ? 'st'
      : date.getDate() % 10 === 2 && date.getDate() !== 12
      ? 'nd'
      : date.getDate() % 10 === 3 && date.getDate() !== 13
      ? 'rd'
      : 'th'
  }${includeYear ? `, ${year}` : ''}`;

  return formattedDate;
};

export const getTimezoneStringFromUTCDatetime = (
  dateString,
  timezone,
  includeYear = false,
) => {
  const date = moment(dateString);
  const formatString = `dddd, MMMM Do ${includeYear ? 'YYYY' : ''} [at] h:mmA`;
  return date?.tz(timezone)?.format(formatString);
};

// Doesnt seem to work
export const convertDateTimeFromUTCToTimezone = (dateString, timezone) => {
  const dateInUTC = moment.tz(dateString, 'UTC');
  const dateInTimezone = dateInUTC.tz(timezone).utcOffset(0).toISOString();
  return dateInTimezone;
};

export const getDateFromUTCDateTime = (dateTimeString, timezone) => {
  if (!timezone) {
    return '';
  }

  const dateTimeInTimezone = convertDateTimeFromUTCToTimezone(
    dateTimeString,
    timezone,
  );
  const dateInTimezone = dateTimeInTimezone.split('T')[0];
  return dateInTimezone;
};

const getDateSuffix = (dateNumber) => {
  const suffix =
    dateNumber % 10 === 1 && dateNumber !== 11
      ? 'st'
      : dateNumber % 10 === 2 && dateNumber !== 12
      ? 'nd'
      : dateNumber % 10 === 3 && dateNumber !== 13
      ? 'rd'
      : 'th';
  return suffix;
};

export const getTimestamp = (
  dateInput,
  timeOnly = false,
  dateOnly = false,
  removeCaps = false,
) => {
  if (!dateInput) {
    return '';
  }

  const userOffsetInMinutes = new Date().getTimezoneOffset();

  const utcDate = new Date(dateInput);
  const date = new Date(utcDate.getTime() - userOffsetInMinutes * 60000);
  const dateString = date.toDateString();

  const currentUtcDate = new Date();
  const currentDate = new Date(
    currentUtcDate.getTime() - userOffsetInMinutes * 60000,
  );
  const currentDateString = currentDate.toDateString();
  const isToday = dateString === currentDateString;

  const yesterday = new Date(currentDate.setDate(currentDate.getDate() - 1));
  const yesterdayDateString = yesterday.toDateString();
  const isYesterday = dateString === yesterdayDateString;

  const timeString = date.toISOString().split('T')[1];
  const timeElements = timeString.split(':');
  let hourString = timeElements[0];
  if (parseInt(hourString) < 10) {
    hourString = hourString.slice(1);
  }

  let hour = parseInt(hourString);
  const minute = timeElements[1];

  let suffix = 'pm';
  if (hour > 12) {
    hour = hour - 12;
  } else if (hour < 12) {
    suffix = 'am';
    if (hour === 0) {
      hour = '12';
    }
  }

  if (isToday || isYesterday) {
    return dateOnly
      ? `${
          isYesterday
            ? removeCaps
              ? `yesterday`
              : `Yesterday`
            : removeCaps
            ? `today`
            : `Today`
        }`
      : timeOnly
      ? `${hour}:${minute}${suffix}`
      : `${
          isYesterday
            ? removeCaps
              ? 'yesterday'
              : 'Yesterday'
            : removeCaps
            ? 'today'
            : 'Today'
        } at ${hour}:${minute}${suffix}`;
  }

  const newDate = utcDate.toISOString()?.split('T')?.[0];
  const elements = newDate.split('-');
  const month = months[parseInt(elements[1]) - 1];
  const dayNumber = parseInt(elements[2]);
  const dateSuffix = getDateSuffix(dayNumber);

  return `${month} ${dayNumber}${dateSuffix}${
    dateOnly ? '' : ` at ${hour}:${minute}${suffix}`
  }`;
};

export const getDateRange = ({ unit = 'month', value, hardcoded = true }) => {
  if (hardcoded) {
    const startDateString = 'Sun Jan 14 2023 22:21:40 GMT-0500';
    const startDate = new Date(startDateString);
    const endDateString = 'Sun Jan 14 2024 22:21:40 GMT-0500';
    const endDate = new Date(endDateString);
    return {
      start: startDate,
      end: endDate,
    };
  }

  const currentDate = new Date();
  let start;

  if (unit === 'month') {
    start = new Date(currentDate.setMonth(currentDate.getMonth() - value));
  } else if (unit === 'week') {
    start = new Date(currentDate.setDate(currentDate.getDate() - value * 7));
  }

  const end = new Date();
  return { start, end };
};

export const getDaysBetweenDates = (date1, date2, timezone) => {
  try {
    const d1 = moment.tz(date1, timezone).startOf('day');
    const d2 = moment.tz(date2, timezone).startOf('day');

    // Check if the dates are on the same day in the given timezone
    if (d1.isSame(d2, 'day')) {
      return 0;
    }

    // Calculate the difference in days
    const diffDays = Math.abs(d2.diff(d1, 'days'));

    return diffDays;
  } catch (error) {
    console.error('Error calculating days between dates:', error);
    return null;
  }
};

export const getDaysSinceIntTimestamp = (timestamp) => {
  const now = new Date();
  const timeDifference = now.getTime() - timestamp;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  return Math.floor(daysDifference);
};

export const getRandomDates = (n) => {
  const currentDate = new Date();
  const threeYearsAgo = new Date(currentDate);
  threeYearsAgo.setFullYear(currentDate.getFullYear() - 3);

  const dates = [];

  for (let i = 0; i < n; i++) {
    const randomTime =
      Math.random() * (currentDate.getTime() - threeYearsAgo.getTime()) +
      threeYearsAgo.getTime();
    const randomDate = new Date(randomTime);

    // Formatting the date to the specified format
    const formattedDate = randomDate.toISOString();

    dates.push(formattedDate);
  }

  return dates;
};

export const getTimeFromDateTime = (dateTimeString) => {
  if (!dateTimeString) {
    return '';
  }

  return dateTimeString.slice(
    dateTimeString.indexOf('T') + 1,
    dateTimeString.indexOf('T') + 6,
  );
};

export const getFormattedTimeStringFromDateTime = (dateTimeString) => {
  if (!dateTimeString) {
    return '';
  }

  const time = getTimeFromDateTime(dateTimeString);

  const elems = time.split(':');
  let hours = parseInt(elems[0]);
  const minutes = elems[1];

  const suffix = hours >= 12 ? 'PM' : 'AM';

  if (hours > 12) {
    hours = hours - 12;
  }

  return `${hours}:${minutes} ${suffix}`;
};

export const formatTime = (inputTime) => {
  if (!inputTime) {
    return '';
  }

  // Splitting the input time string into hours, minutes, and seconds
  const [hours, minutes] = inputTime.split(':');

  // Parsing hours and minutes as integers
  const parsedHours = parseInt(hours, 10);
  const parsedMinutes = parseInt(minutes, 10);

  // Determining whether it's AM or PM
  const period = parsedHours >= 12 ? ' PM' : ' AM';

  // Converting hours to 12-hour format
  const formattedHours = parsedHours % 12 || 12;

  // Constructing the formatted time string
  const formattedTime = `${formattedHours}:${minutes}${period}`;

  return formattedTime;
};

export const getCookieExpiryObject = () => {
  const cookieExpiryDate = new Date();
  cookieExpiryDate.setDate(cookieExpiryDate.getDate() + 365);
  const cookieExpiryObject = {
    expires: cookieExpiryDate,
  };
  return cookieExpiryObject;
};

export const getClientTimezone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone;
};

export const getCurrentDateString = () => {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'long',
  };
  const dt = new Intl.DateTimeFormat('en-US', options).format(new Date());
  console.log(dt);
  return dt;
};

export default {
  getDateStringForCookie,
  dateToDMYFormat,
  dateToTextFormat,
  getDateRange,
  getRandomDates,
};
