import React, { useContext, useState } from 'react';
import {
  DarkMLText,
  FullWidthStartAlignedColumnDiv,
  InputContainerForSmallGap,
  InputSubtitleTextContainer,
  LightDarkEssText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SmallCheckbox,
  StartAlignedInputTextContainer,
  StartAlignedLightDarkLargeTinyText,
} from '../../../styles/shared-styled-components';
import { checkIfUserIsSuperAdmin } from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input';

const CreateBookingFlowModal = ({ isOpen, onClose, category, onCreate }) => {
  const { user } = useContext(BaseContext);

  const isSuperAdmin = checkIfUserIsSuperAdmin(user);

  const [title, setTitle] = useState();
  const [utterance, setUtterance] = useState();
  const [universal, setUniversal] = useState(isSuperAdmin);

  const handleClose = () => {
    setTitle();
    setUtterance();
    onClose();
  };

  const handleCreate = () => {
    onCreate(title, utterance, universal);
    handleClose();
  };

  const categoryName = category?.name;
  const createEnabled = !!title && !!utterance;

  return (
    categoryName && (
      <>
        <Modal
          open={isOpen}
          onClose={handleClose}
        >
          <ModalContainer>
            <DarkMLText>Create {categoryName} Booking Flow</DarkMLText>
            <ModalInputsContainer>
              <ModalInputsContainerScrollWrapper>
                <Input
                  id='title'
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  label={'Title'}
                  isRequired={false}
                  useSmallGap
                />
                <FullWidthStartAlignedColumnDiv>
                  <Input
                    id='utterance'
                    value={utterance}
                    onChange={(e) => {
                      setUtterance(e.target.value);
                    }}
                    label={'Example phrase'}
                    isRequired={false}
                    multiline
                    numRows={2}
                    useSmallGap
                  />
                  <InputSubtitleTextContainer>
                    <StartAlignedLightDarkLargeTinyText>
                      Try to use generic phrasing, such as
                      <br></br>
                      <i>"I want to book a __ session"</i>, or{' '}
                      <i>"Do you offer __"</i>
                    </StartAlignedLightDarkLargeTinyText>
                  </InputSubtitleTextContainer>
                </FullWidthStartAlignedColumnDiv>
                {isSuperAdmin && (
                  <InputContainerForSmallGap>
                    <StartAlignedInputTextContainer>
                      <LightDarkEssText>Create universally</LightDarkEssText>
                      <SmallCheckbox
                        checked={universal}
                        onChange={() => {
                          setUniversal(!universal);
                        }}
                      />
                    </StartAlignedInputTextContainer>
                  </InputContainerForSmallGap>
                )}
              </ModalInputsContainerScrollWrapper>
            </ModalInputsContainer>
            <ModalActions>
              <MediumPrimaryButton
                disabled={!createEnabled}
                onClick={handleCreate}
              >
                Create
              </MediumPrimaryButton>
              <MediumSecondaryButton onClick={handleClose}>
                Close
              </MediumSecondaryButton>
            </ModalActions>
          </ModalContainer>
        </Modal>
      </>
    )
  );
};

export default CreateBookingFlowModal;
