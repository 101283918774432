import MuiCloseIcon from '@mui/icons-material/Close';
import MuiAppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import MuiToolbar from '@mui/material/Toolbar';
import styled, { css } from 'styled-components';
import {
  CenteredDiv,
  ColumnFlexStyle,
  EssGapStyle,
  HorizontalSectionMenuDivider,
  LargeGapStyle,
  LargeText,
  MLGapStyle,
  MediumIconSize,
  RemoveIcon,
  SMGapStyle,
  SMMIconSize,
  SmallGapStyle,
  SmallIconSize,
  SmallText,
  UnderlineStyle,
} from '../../../styles/shared-styled-components';
import { DownArrow } from '../../Micro/ArrowIcon/styled';
import UpTransition from '../../Micro/UpTransition';

export const AppBar = styled(MuiAppBar).attrs({
  position: 'fixed',
  elevation: 3,
})`
  z-index: ${({ theme }) => theme.spacing.headerZIndex};
  padding-right: 0px !important;
`;

export const Toolbar = styled(MuiToolbar).attrs({
  disableGutters: true,
})`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: ${({ theme }) => theme.spacing.headerVerticalPadding}px
    ${({ theme }) => theme.spacing.pageHorizontalPadding}px;
  height: ${({ theme }) => theme.spacing.headerHeight}px;
  min-height: ${({ theme }) => theme.spacing.headerHeight}px;
`;

export const ToolbarContainerLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.isMobileScreen
      ? css`
          ${EssGapStyle}
        `
      : css`
          ${SMGapStyle}
        `}
`;

export const ToolbarContainerRight = styled.div`
  display: flex;
  ${MLGapStyle}
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  ${SMGapStyle}
`;

export const ToolbarDivider = styled(HorizontalSectionMenuDivider)`
  margin: auto;
  height: ${({ theme }) => theme.spacing.headerHeight * 0.5}px;
  border: 0.5px dashed ${({ theme }) => theme.color.lightPrimary};
  margin-left: -11px;
`;

const HeaderTextStyle = css`
  cursor: pointer;
  ${(props) =>
    props.current === 'true' &&
    css`
      ${UnderlineStyle}
    `}
`;

export const NativeHeaderSectionText = styled(SmallText)`
  ${HeaderTextStyle}
  font-size: 22px;
`;

export const DrawerHeaderSectionText = styled(LargeText)`
  ${HeaderTextStyle}
`;

export const FullScreenHeader = styled(Dialog).attrs({
  fullScreen: true,
  TransitionComponent: UpTransition,
})`
  height: 100vh;
  z-index: 100000;
`;

export const CloseIcon = styled(MuiCloseIcon)`
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
  ${MediumIconSize}
`;

export const ExpandedDrawerOptionsContainer = styled.div`
  ${ColumnFlexStyle}
  padding-top: 30px;
  ${LargeGapStyle}
  height: 100vh;
  overflow: hidden !important;
  background-color: ${({ theme }) => theme.color.lightGray};
`;

export const AuthButtonsContainer = styled.div`
  display: flex;
  ${SmallGapStyle}
  margin-left: 20px;
`;

export const AuthButtonsContainerInDrawer = styled.div`
  ${ColumnFlexStyle}
  ${SmallGapStyle}
  position: absolute;
  bottom: 0px;
  padding-bottom: 100px;
  padding-top: 20px;
  width: 100vw;
  border-top: 2px solid ${({ theme }) => theme.color.primary};
`;

export const MinimizedProfileAndDrawerContainer = styled(CenteredDiv)`
  ${SmallGapStyle}
`;

export const CancelSelectedBusinessIcon = styled(RemoveIcon)`
  ${SmallIconSize}
  color: ${({ theme }) => theme.color.lightError};
  margin-left: -5px;
`;

export const SwitchBusinessIcon = styled(DownArrow)`
  ${SMMIconSize}
  color: #8d8d8d;
  margin-right: -8px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.darkGray};
  }
`;
