import styled from 'styled-components';
import { homepageMobilePixelDistance } from '../../../utils/constants';
import {
  Features1LeftContainer,
  Features1RightContainer,
  Features1Sentence,
} from './style-Features1';
import { TestimonialContainer } from './style-Testimonials';

export const FAQContainer = styled(TestimonialContainer)`
  background-color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.charcoal
      : ({ theme }) => theme.color.lightGray};
  box-shadow: none;
  border: none;
  background: transparent;

  :hover {
    background-size: 48%;
  }
  padding-bottom: 0px;
  @media (max-width: 1024px) {
    margin-left: 0;
    max-width: 80%;

    margin: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${homepageMobilePixelDistance}px;
    padding: 0;
  }
`;

export const FAQTextContainer = styled(Features1LeftContainer)`
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  background: none;
  margin-bottom: auto;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220%200%20100%20100%22 version%3D%221.1%22%3E%3Cdefs%3E%3ClinearGradient id%3D%22sw-gradient%22 x1%3D%220%22 x2%3D%221%22 y1%3D%221%22 y2%3D%220%22%3E%3Cstop id%3D%22stop1%22 stop-color%3D%22rgba(57%2C%2049%2C%2080%2C%20.02)%22 offset%3D%220%25%22/%3E%3Cstop id%3D%22stop2%22 stop-color%3D%22rgba(113%2C%2097%2C%20160%2C%201)%22 offset%3D%22100%25%22/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill%3D%22url(%23sw-gradient)%22 d%3D%22M12.3%2C-15.4C19.8%2C-11.4%2C32.5%2C-13%2C34.6%2C-10.4C36.6%2C-7.7%2C28.1%2C-0.7%2C24.5%2C7.2C20.9%2C15%2C22.2%2C23.7%2C18.9%2C24.3C15.7%2C24.9%2C7.8%2C17.5%2C0.4%2C16.9C-7.1%2C16.4%2C-14.1%2C22.8%2C-16.2%2C21.8C-18.2%2C20.8%2C-15.2%2C12.4%2C-16%2C6.1C-16.8%2C-0.2%2C-21.4%2C-4.3%2C-21.7%2C-8.2C-22%2C-12.1%2C-18%2C-15.7%2C-13.7%2C-20.8C-9.4%2C-25.8%2C-4.7%2C-32.3%2C-1.2%2C-30.7C2.4%2C-29.1%2C4.7%2C-19.4%2C12.3%2C-15.4Z%22 width%3D%22100%25%22 height%3D%22100%25%22 transform%3D%22translate(50%2050)%22 style%3D%22transition%3A%200.3s%3B%22 stroke-width%3D%220%22/%3E%3C/svg%3E');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: left 50px bottom -80px;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const FAQHeaderSubText = styled.div`
  font-size: 30px;
  letter-spacing: -2px;
  @media (max-width: 1024px) {
    font-size: 20px;
    letter-spacing: -1px;
  }
  @media (max-width: 576px) {
    font-size: 15px;
    letter-spacing: 0px;
  }
`;

export const FAQHeaderText = styled.div`
  font-size: 60px;

  @media (max-width: 1024px) {
    font-size: 40px;
    letter-spacing: -2px;
  }
  @media (max-width: 576px) {
    font-size: 25px;
    letter-spacing: -1px;
  }
`;

export const FAQQuestionsContainer = styled(Features1RightContainer)`
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
  max-width: 90%;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const FAQQuestionContainer = styled(Features1Sentence)`
  text-align: left;
  border-radius: 30px;
  transition: all 0.3s ease-in-out; /* Smooth transition */
  height: fit-content;
  :hover {
    cursor: pointer;
  }
`;

export const FAQQuestion = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FAQAnswer = styled.div`
  padding-top: ${(props) => (props.expanded ? '20px' : '0px')};
  transition: all 0.3s ease-in-out; /* Smooth opacity transition */
  opacity: ${(props) => (props.expanded ? 1 : 0)};
  height: ${(props) =>
    props.expanded
      ? 'auto'
      : '0px'}; /* Use height to handle expanding and collapsing */
  overflow: hidden;
`;
