import styled, { css } from 'styled-components';
import {
  AutoHorizontalMargins,
  FullWidthStartAlignedColumnDiv,
} from '../../../../styles/shared-styled-components';
import { PromptsSection } from '../../shared-training-components';

export const CategoryNameContainer = styled(FullWidthStartAlignedColumnDiv)`
  ${AutoHorizontalMargins}
  width: 85%;
  background-color: ${(props) => props.selected && props.theme.color.offwhite};
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${props.theme.color.offwhite};
      }
    `}
`;

export const AddOnsSection = styled(PromptsSection)`
  z-index: 10000;
`;
