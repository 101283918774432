import { useApolloClient, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { GET_CHAT_WITH_MESSAGES } from '../../../api/queries/chat';
import { CHAT_MESSAGES_SUBSCRIPTION } from '../../../api/subscriptions/chat';
import { updateChatMessages } from '../../../utils/cache';
import {
  appointmentBookedMessageContent,
  appointmentBookedStatus,
  appointmentMonitoringMessageContent,
  appointmentMonitoringStatus,
  chatDisengagementReasonLabelMap,
  chatInterceptionReasonLabelMap,
  systemKey,
} from '../../../utils/constants';
import { getFormattedFullNameFromUser } from '../../../utils/name';
import LoadingIndicator from '../../LoadingIndicator';
import MessageRenderer from '../MessageRenderer';

const getDisengagementText = (userMap, disengagement) => {
  if (!disengagement) {
    return null;
  }

  const disengagerUserId = disengagement.disengagerUserId;
  const disengager = disengagerUserId
    ? `${userMap[disengagerUserId]} Disengaged Agent`
    : 'Agent Disengaged';

  const reasonLabel =
    chatDisengagementReasonLabelMap[disengagement.disengagementReason];

  return `${disengager}${reasonLabel ? `: ${reasonLabel}` : ''}`;
};

const getInterceptionText = (userMap, interception) => {
  if (!interception) {
    return null;
  }

  const interceptorUserId = interception.interceptorUserId;
  const interceptor =
    (interceptorUserId && userMap[interceptorUserId]) || 'Human Agent';

  return `${interceptor} Intercepted: ${
    chatInterceptionReasonLabelMap[interception.interceptionReason]
  }`;
};

const MessageQueryManager = ({
  chat,
  context,
  onNewAppointment = (_, __, ___) => {},
  onClickAppointment,
  onClickDeposit,
  userMap,
  snackbarSetter,
}) => {
  const { cache } = useApolloClient();

  const agentName = chat?.agentName;
  const client = chat?.client;
  const chatId = chat?.id;

  const { subscribeToMore, refetch, ...result } = useQuery(
    GET_CHAT_WITH_MESSAGES,
    {
      variables: { chatId },
      skip: !chatId,
      fetchPolicy: 'network-only',
    },
  );

  const isLoading = result?.loading;

  const getUpdatedData = (prev, subscriptionData) => {
    if (!subscriptionData.data) return prev.getChatMessages;

    const updatedData = subscriptionData?.data?.newChatMessagesAdded;

    const updatedChat = updatedData?.chat;

    const newMessages = updatedData?.newMessages || [];

    let appointmentStatusInNewBooking;
    newMessages.map((m) => {
      const { sender, content } = m;

      const isAppointmentBooking = content.includes(
        appointmentBookedMessageContent,
      );
      const isAppointmentMonitoring = content.includes(
        appointmentMonitoringMessageContent,
      );

      if (
        sender === systemKey &&
        (isAppointmentBooking || isAppointmentMonitoring)
      ) {
        appointmentStatusInNewBooking = isAppointmentBooking
          ? appointmentBookedStatus
          : appointmentMonitoringStatus;
      }
    });

    if (appointmentStatusInNewBooking) {
      const clientName = getFormattedFullNameFromUser(client);
      onNewAppointment(
        clientName,
        chatId,
        appointmentStatusInNewBooking,
        refetch,
      );
    }

    const previousMessages = prev.getChatMessages?.messages || [];
    const previousMessageIds = previousMessages.map((m) => m.id);
    const updatedMessages = [
      ...previousMessages,
      ...newMessages.filter((m) => !previousMessageIds.includes(m.id)),
    ];

    return {
      updatedChat,
      updatedMessages,
    };
  };

  const handleUpdatedSubscriptionData = (
    prev,
    subscriptionData,
    refetchFcn,
  ) => {
    refetchFcn();

    const { updatedChat, updatedMessages } = getUpdatedData(
      prev,
      subscriptionData,
    );

    updateChatMessages(updatedChat, updatedMessages, cache);

    return {
      getChatMessages: {
        chat: updatedChat,
        messages: updatedMessages,
      },
    };
  };

  useEffect(() => {
    if (chatId) {
      subscribeToMore({
        document: CHAT_MESSAGES_SUBSCRIPTION,
        variables: { chatId },
        updateQuery: (prev, { subscriptionData }) => {
          return handleUpdatedSubscriptionData(prev, subscriptionData, refetch);
        },
      });
    }
  }, [chatId]);

  const data = result?.data?.getChatMessages;
  const messages = data?.messages;
  const calls = data?.calls;
  const callMap = {};
  calls?.map((c) => {
    callMap[c.id] = c;
  });
  const updatedChat = data?.chat;

  if (isLoading) {
    return <LoadingIndicator fullScreen />;
  }

  const disengagementText = getDisengagementText(
    userMap,
    updatedChat?.disengagement,
  );
  const interceptionText = getInterceptionText(
    userMap,
    updatedChat?.interception,
  );

  return (
    chatId && (
      <MessageRenderer
        {...result}
        messages={messages}
        callMap={callMap}
        subscribeToNewMessages={() => {
          subscribeToMore({
            document: CHAT_MESSAGES_SUBSCRIPTION,
            variables: { chatId },
            updateQuery: (prev, { subscriptionData }) => {
              return handleUpdatedSubscriptionData(
                prev,
                subscriptionData,
                refetch,
              );
            },
          });
        }}
        refetch={refetch}
        context={context}
        chatType={chat?.type}
        agentName={agentName}
        client={client}
        disengagementText={disengagementText}
        interceptionText={interceptionText}
        onClickAppointment={onClickAppointment}
        onClickDeposit={onClickDeposit}
        userMap={userMap}
        snackbarSetter={snackbarSetter}
      />
    )
  );
};

export default MessageQueryManager;
