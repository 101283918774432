import './logo-font.css';

export const logoFont = {
  fontFamily: 'LogoFont',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
};

export default logoFont;
