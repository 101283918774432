import React from 'react';
import {
  InputContainer,
  InputTitleText,
  MediumDarkSmallText,
  SplitInputContainer,
} from '../../../styles/shared-styled-components';
import Input from '../Input/Input';

const SplitInput = ({
  title,
  id,
  subtitle = '',
  splitInputs,
  renderCondition = true,
  isRequired = false,
  useSmallGap = false,
  useExtraSmallGap = false,
  removeGap = false,
}) => {
  return (
    renderCondition && (
      <InputContainer
        id={id}
        useSmallGap={useSmallGap}
        useExtraSmallGap={useExtraSmallGap}
        removeGap={removeGap}
      >
        <InputTitleText>
          {title}
          {isRequired && (
            <>
              &nbsp;
              <sup>*</sup>
            </>
          )}
          {subtitle && <MediumDarkSmallText>{subtitle}</MediumDarkSmallText>}
        </InputTitleText>
        <SplitInputContainer>
          {splitInputs.map((splitInput) => {
            const {
              id,
              type,
              value,
              onChange,
              onBlur,
              label,
              options,
              containsError,
              errorMessage,
              CustomInputComponent,
            } = splitInput;
            return (
              <Input
                id={id}
                type={type}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label={label}
                options={options}
                containsError={containsError}
                errorMessage={errorMessage}
                CustomInputComponent={CustomInputComponent}
                renderInputOnly
                displayErroMessages={false}
              />
            );
          })}
        </SplitInputContainer>
      </InputContainer>
    )
  );
};

export default SplitInput;
