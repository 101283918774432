import validator from 'validator';
import {
  arrayInputType,
  integerInputType,
  percentInputType,
} from './constants';
import { valueIsEmpty } from './data';

export const checkIfIsValidUrl = (url) => {
  return validator.isURL(url);
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const checkIfIsValidEmail = (email) => {
  return emailRegex.test(email);
};

export const getInitialQueryValue = (value, type = integerInputType) => {
  if (valueIsEmpty(value)) {
    const fallbackValue = type === arrayInputType ? [] : '';
    return fallbackValue;
  }

  if (type === percentInputType) {
    return value * 100;
  }

  return value;
};

export const getIdOfFirstEmptyField = (inputs) => {
  let idOfFirstEmptyField;

  for (var i = 0; i < inputs.length; i++) {
    let input = inputs[i];

    const { id, value, renderCondition } = input.props;
    const fieldIsVisible = renderCondition == null || renderCondition === true;

    if (fieldIsVisible && valueIsEmpty(value)) {
      idOfFirstEmptyField = id;
      break;
    }
  }
  return idOfFirstEmptyField;
};

export default {
  checkIfIsValidUrl,
  getInitialQueryValue,
  checkIfIsValidEmail,
};
