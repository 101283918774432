import styled from 'styled-components';
import {
  CardStyle,
  CenterFlexStyle,
  SecondaryLargeText,
} from '../../../styles/shared-styled-components';

export const EmptyCardComponent = styled.div`
  ${CardStyle}
  min-height: 200px;
  ${CenterFlexStyle}
`;

export const NoDataText = styled(SecondaryLargeText)``;
