import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  BaseDrawerContainer,
  CenterFlexStyle,
  ChatHeaderContainerHeight,
  ColumnFlexStyle,
  ExtraSmallGapStyle,
  MediumDarkExtraSmallText,
  MediumDarkSmallText,
  MediumGapStyle,
  NextIcon,
  NodeDrawerWidth,
  ScrollStyle,
  SmallGapStyle,
  SmallIconSize,
} from '../../../../styles/shared-styled-components';

// Small icon size width
const IconWidth = 24;
const IconPadding = 2;
const LeftPadding = IconWidth / 2 + IconPadding;

export const DrawerContainer = styled(BaseDrawerContainer).attrs({
  removeScroll: true,
})`
  right: 0px;
  width: ${NodeDrawerWidth}px;
  padding: 0px;
  padding-left: ${LeftPadding}px;
  background-color: unset;
  overflow: hidden;
  z-index: 1000;
`;

export const CloseDrawerIconSection = styled.div`
  position: absolute;
  height: fit-content;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 0px;
  z-index: 100000;
  height: ${ChatHeaderContainerHeight}px;
  margin-top: 8px;
`;

export const CloseDrawerIconContainer = styled.div`
  height: fit-content;
  width: fit-content;
  padding: ${IconPadding}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.muiInputLightGray};
  color: ${({ theme }) => theme.color.muiInputDarkGray};
  left: -20px;
  ${CenterFlexStyle}
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.color.muiInput};
  }
`;

export const CloseDrawerIcon = styled(NextIcon)`
  ${SmallIconSize}
`;

export const DrawerContentContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.muiInput};
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px 10px;
  ${ScrollStyle}
  ${ColumnFlexStyle}
  margin-left: -5px;
`;

export const NameContainer = styled.div`
  ${CenterFlexStyle}
  width: calc(100% - 12px);
  ${AutoHorizontalMargins}
  margin-left: -2px;
  margin-bottom: 20px;
  border-bottom: 2px dotted ${({ theme }) => theme.color.muiInput};
  height: 45px;
  margin-top: 7px;
  align-items: flex-start;
`;

export const NameText = styled(MediumDarkSmallText)`
  line-height: 1.2;
`;

export const SubfieldsSection = styled.div`
  ${ColumnFlexStyle}
  ${MediumGapStyle}
  ${AutoHorizontalMargins}
  ${ScrollStyle}
  padding-top: 10px;
  width: 95%;
  height: 100%;
`;

export const SubfieldContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: center;
  justify-content: center;
  ${ExtraSmallGapStyle}
  word-wrap: break-word;
`;

export const SubfieldTitleText = styled(MediumDarkExtraSmallText)`
  font-size: 15px;
  line-height: 1.1;
`;

export const SubfieldValueText = styled(SubfieldTitleText)`
  color: ${({ theme }) => theme.color.primary};
  text-align: center;
  line-height: 1.25;
  width: 100%;
`;

export const CenteredTextSpan = styled.span`
  text-align: center !important;
`;

export const InputContainer = styled.div`
  width: 95%;
  margin-left: 5px;
  margin-right: auto;
`;

export const ActionButtonsContainer = styled.div`
  bottom: 0px;
  right: 0px;
  height: 100px;
  border-top: 1px solid ${({ theme }) => theme.color.muiInput};
  border-left: 1px solid ${({ theme }) => theme.color.muiInput};
  margin-left: -5px;
  background-color: white;
  ${SmallGapStyle}
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
`;
