import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  BaseDrawerContainer,
  CenterFlexStyle,
  ChatHeaderContainerHeight,
  ClientProfileDrawerWidth,
  ColumnFlexStyle,
  ExtraSmallGapStyle,
  MediumDarkExtraSmallText,
  MediumDarkSmallText,
  MediumGapStyle,
  NextIcon,
  ScrollStyle,
  SmallGapStyle,
  SmallIconSize,
} from '../../../styles/shared-styled-components';

// Small icon size width
const IconWidth = 18;
const IconPadding = 2;
const LeftPadding = IconWidth / 2 + IconPadding + 4;

export const DrawerContainer = styled(BaseDrawerContainer)`
  right: 12px;
  width: ${ClientProfileDrawerWidth}px;
  padding: 0px;
  padding-left: ${LeftPadding}px;
  background-color: unset;
  overflow-x: hidden;
  z-index: 1000;
`;

export const CloseDrawerIconSection = styled.div`
  position: absolute;
  height: fit-content;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 0px;
  z-index: 100000;
  height: ${ChatHeaderContainerHeight}px;
`;

export const CloseDrawerIconContainer = styled.div`
  height: fit-content;
  width: fit-content;
  padding: ${IconPadding}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.muiInputLightGray};
  color: ${({ theme }) => theme.color.muiInputDarkGray};
  left: -20px;
  ${CenterFlexStyle}
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.color.muiInput};
  }
`;

export const CloseDrawerIcon = styled(NextIcon)`
  ${SmallIconSize}
`;

export const DrawerContentContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.muiInput};
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px 10px;
  ${ScrollStyle}
  ${ColumnFlexStyle}
  margin-left: -5px;
`;

export const NameContainer = styled.div`
  ${CenterFlexStyle}
  width: 92%;
  ${AutoHorizontalMargins}
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px dotted ${({ theme }) => theme.color.muiInput};
  height: ${ChatHeaderContainerHeight}px;
  ${SmallGapStyle}
  margin-top: 6px;
`;

export const NameText = styled(MediumDarkSmallText)`
  line-height: 1.2;
`;

export const SubfieldsSection = styled.div`
  ${ColumnFlexStyle}
  ${MediumGapStyle}
  ${AutoHorizontalMargins}
  width: 95%;
`;

export const SubfieldContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: center;
  justify-content: center;
  ${ExtraSmallGapStyle}
  word-wrap: break-word;
`;

export const SubfieldTitleText = styled(MediumDarkExtraSmallText)`
  font-size: 15px;
  line-height: 1.1;
`;

export const SubfieldValueText = styled(SubfieldTitleText)`
  color: ${({ theme }) => theme.color.primary};
  text-align: center;
  line-height: 1.25;
  width: 100%;
`;

export const CenteredTextSpan = styled.span`
  text-align: center !important;
`;
