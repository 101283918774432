import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { VERIFY_MINDBODY_SITE_CONNECTION } from '../../../api/queries/app-connections';
import {
  CenteredDiv,
  ColumnCenteredDiv,
  LightErrorText,
  LinkTextSpan,
  MindBodyLogo,
  Modal,
  ModalActions,
  ModalContainer,
  ModalParagraphText,
  ModalTitleText,
  PrimaryButton,
  TinyText,
} from '../../../styles/shared-styled-components';
import LoadingIndicator from '../../LoadingIndicator';

const MindBodyActivationCodeUrlModal = ({
  activationCodeUrl,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [
    verifyMindBodySiteConnection,
    { data, loading: verifyMindBodySiteConnectionLoading },
  ] = useLazyQuery(VERIFY_MINDBODY_SITE_CONNECTION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (data) {
      const success = data.verifyMindBodySiteConnection;
      if (!success) {
        setErrorMessage('Connection unable to be verified, please try again');
      } else {
        onSuccess();
      }
    }
  }, [data]);

  const onCheck = () => {
    setErrorMessage();
    verifyMindBodySiteConnection({
      variables: {
        cacheBreaker: uuidv4(),
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <CenteredDiv>
          <MindBodyLogo />
        </CenteredDiv>
        <ModalTitleText>Open the Activation&nbsp;URL</ModalTitleText>
        <ModalParagraphText>
          Open{' '}
          <LinkTextSpan
            href={activationCodeUrl}
            target='_blank'
          >
            this activation URL
          </LinkTextSpan>{' '}
          and sign-in with the MindBody account registered as the <b>owner</b>.
          <br></br>
          <br></br>Then click the button below to verify the connection.
        </ModalParagraphText>
        <ModalActions>
          <ColumnCenteredDiv>
            {verifyMindBodySiteConnectionLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                <PrimaryButton onClick={onCheck}>
                  Verify connection
                </PrimaryButton>
                {errorMessage && (
                  <TinyText>
                    <LightErrorText>{errorMessage}</LightErrorText>
                  </TinyText>
                )}
              </>
            )}
          </ColumnCenteredDiv>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default MindBodyActivationCodeUrlModal;
