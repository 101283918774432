import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  ColumnFlexStyle,
  ExtraSmallGapStyle,
  SMGapStyle,
  TextInputWidthStyle,
} from '../../../styles/shared-styled-components';

export const FormContainer = styled.div`
  margin-top: 40px;
`;

export const InputToggleSection = styled.div`
  ${ColumnFlexStyle}
  margin-top: 20px;
`;

export const InputToggleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${TextInputWidthStyle}
  ${AutoHorizontalMargins}
  ${ExtraSmallGapStyle}
`;

export const ExpandedBookableStatusContainer = styled.div`
  margin-top: ${(props) => (props.smallTopMargin ? '15px' : '27px')};
  display: flex;
  align-items: center;
  ${SMGapStyle}
`;
