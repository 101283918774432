import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Paths from '../../../Paths';
import { EDIT_BUSINESS_USER_LOCATION_VIEW } from '../../../api/mutations/users';
import {
  CenteredDivWithExtraSmallGap,
  CopyIdIcon,
  DarkSMText,
  SmallLogo,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import {
  multiLocationPrivilegedRoles,
  singleLocationBusinessType,
  superAdminRole,
  unselectActionKey,
} from '../../../utils/constants';
import { copyTextToClipboard } from '../../../utils/string';
import {
  checkIfUserIsMultiLocationBusinessOwner,
  getUserBusiness,
  getUserLocation,
  locationIdSelectedKey,
} from '../../../utils/user';
import {
  drawerExpandedKey,
  screenWidthIsIpadSizeOrSmaller,
  screenWidthIsSmallMobileSizeOrSmaller,
} from '../../../utils/view';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoginButton from '../../Auth/LoginButton';
import SignupButton from '../../Auth/SignupButton';
import SelectLocationModal from '../../Modals/SelectLocationModal/SelectLocationModal';
import Drawer from '../Drawer';
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown';
import { ProfileMenuButtonPlaceholder } from '../ProfileDropdown/styled';
import {
  AppBar,
  AuthButtonsContainer,
  LogoContainer,
  SwitchBusinessIcon,
  Toolbar,
  ToolbarContainerLeft,
  ToolbarContainerRight,
  ToolbarDivider,
} from './styled';

export default function Header({
  embeddingsSyncStatus,
  onClickEmbeddingsSync,
  onRemoveLocation = () => {},
  blankMode = false,
  label = null,
}) {
  const {
    inDemoMode,
    inMonitoringMode,
    onHeadOfficeTab,
    width,
    user,
    refetchUser,
    userLoading,
    role,
    drawerOpen,
    drawerExpanded,
  } = useContext(BaseContext);
  const { pathname: pathName } = useLocation();
  const navigate = useNavigate();

  const [editBusinessUserLocationViewMutation] = useMutation(
    EDIT_BUSINESS_USER_LOCATION_VIEW,
  );

  const isSmallMobileScreen = screenWidthIsSmallMobileSizeOrSmaller(width);
  const isIpadScreen = screenWidthIsIpadSizeOrSmaller(width);

  const [searchParams] = useSearchParams();

  const utm = searchParams?.get('utm');
  const selected = searchParams?.get('selected');
  let urlParams = utm ? `?utm=${utm}` : '';
  if (selected) {
    const selectedParam = `selected=${selected}`;
    if (utm) {
      urlParams += `&${selectedParam}`;
    } else {
      urlParams += `?selected=${selected}`;
    }
  }

  const business = getUserBusiness(user);
  const isMultiLocationBusinessOwner = checkIfUserIsMultiLocationBusinessOwner(
    role,
    business,
  );
  const isSuperAdmin = role === superAdminRole;

  const location = getUserLocation(user);
  const locationName = location?.name;
  const locationNickname = location?.nickname;
  const locationId = location?.id;

  const parseLocationNameString = () => {
    return inMonitoringMode
      ? 'Monitoring Chats'
      : business?.type !== singleLocationBusinessType
      ? onHeadOfficeTab
        ? `${business.name} - Head Office View`
        : locationNickname || locationName
        ? `${business.name} - ${locationNickname || locationName}`
        : null
      : locationName;
  };

  const baseLocationNameString = parseLocationNameString();

  const [selectLocationModalOpen, setSelectLocationModalOpen] = useState(false);
  const [locationNameString, setLocationNameString] = useState(
    baseLocationNameString,
  );

  useEffect(() => {
    if (!userLoading && user) {
      // Determine the final location name string
      const newLocationNameString = parseLocationNameString();

      // Only update the state if we have new valid data to avoid unnecessary re-renders
      if (newLocationNameString !== locationNameString) {
        setLocationNameString(newLocationNameString);
      }
    }
  }, [userLoading, user, inDemoMode, inMonitoringMode]);

  const onUnselectLocation = () => {
    editBusinessUserLocationViewMutation({
      variables: {
        businessId: null,
        action: unselectActionKey,
      },
      onCompleted: async (data) => {
        const success = data.editBusinessUserLocationView;

        if (success === true) {
          localStorage.setItem(locationIdSelectedKey, '');
          onRemoveLocation();
          refetchUser();
          navigate(Paths.headOffice);
        }
      },
    });
  };

  const onLoginOrSignup = () => {
    const utmSource = searchParams.get('utm');

    if (utmSource) {
      localStorage.setItem('utm', utmSource);
    }
  };

  const onExpandDrawer = () => {
    localStorage.setItem(drawerExpandedKey, 'true');
  };

  const onCollapseDrawer = () => {
    localStorage.setItem(drawerExpandedKey, 'false');
  };

  return !userLoading ? (
    <>
      <AppBar>
        <Toolbar>
          <ToolbarContainerLeft isMobileScreen={isIpadScreen}>
            {blankMode ? (
              <LogoContainer>
                <Tooltip
                  title={<TooltipTitleText>Back to homepage</TooltipTitleText>}
                >
                  <SmallLogo onClick={() => navigate(Paths.homepage)} />
                </Tooltip>
                <DarkSMText>LiveIQ</DarkSMText>
              </LogoContainer>
            ) : locationNameString || isSuperAdmin ? (
              <>
                <LogoContainer>
                  <Tooltip
                    title={
                      <TooltipTitleText>Back to homepage</TooltipTitleText>
                    }
                  >
                    <SmallLogo onClick={() => navigate(Paths.homepage)} />
                  </Tooltip>
                  <DarkSMText>
                    {locationNameString || 'None selected'}
                  </DarkSMText>
                </LogoContainer>
                {isMultiLocationBusinessOwner ||
                multiLocationPrivilegedRoles.includes(role) ? (
                  <CenteredDivWithExtraSmallGap>
                    <Tooltip
                      title={
                        <TooltipTitleText>Select location</TooltipTitleText>
                      }
                      placement='bottom'
                    >
                      <SwitchBusinessIcon
                        onClick={() => setSelectLocationModalOpen(true)}
                      />
                    </Tooltip>
                    {isSuperAdmin && (
                      <Tooltip
                        title={
                          <TooltipTitleText>
                            Copy ID {locationId?.slice(0, 4)}
                          </TooltipTitleText>
                        }
                        placement='bottom'
                      >
                        <CopyIdIcon
                          addLeftMargin
                          onClick={() => copyTextToClipboard(locationId)}
                        />
                      </Tooltip>
                    )}
                  </CenteredDivWithExtraSmallGap>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <LogoContainer>
                <Tooltip
                  title={<TooltipTitleText>Back to homepage</TooltipTitleText>}
                >
                  <SmallLogo onClick={() => navigate(Paths.homepage)} />
                </Tooltip>
                <DarkSMText>{locationNameString || 'None selected'}</DarkSMText>
              </LogoContainer>
            )}
          </ToolbarContainerLeft>
          {!label && !blankMode && (
            <ToolbarContainerRight>
              {!userLoading ? (
                <>
                  {!user && !isSmallMobileScreen ? (
                    <AuthButtonsContainer>
                      <SignupButton onClick={onLoginOrSignup} />
                      <LoginButton
                        onClick={onLoginOrSignup}
                        variant={'secondary'}
                      />
                    </AuthButtonsContainer>
                  ) : (
                    <ProfileDropdown
                      embeddingsSyncStatus={embeddingsSyncStatus}
                      onClickEmbeddingsSync={onClickEmbeddingsSync}
                    />
                  )}
                </>
              ) : (
                <ProfileMenuButtonPlaceholder />
              )}
            </ToolbarContainerRight>
          )}
        </Toolbar>
      </AppBar>
      {!blankMode && (
        <Drawer
          open={drawerOpen}
          expanded={drawerExpanded}
          onExpand={onExpandDrawer}
          onCollapse={onCollapseDrawer}
          pathName={pathName}
        />
      )}
      <SelectLocationModal
        isOpen={selectLocationModalOpen}
        onClose={() => setSelectLocationModalOpen(false)}
      />
    </>
  ) : (
    <AppBar>
      <Toolbar>
        <ToolbarContainerLeft isMobileScreen={isIpadScreen}>
          <a href={`${Paths.homepage}${urlParams}`}>
            <Tooltip
              title={<TooltipTitleText>Back to Homepage</TooltipTitleText>}
              arrow
            >
              <SmallLogo
                marginLeft={-3}
                marginRight={2}
              />
            </Tooltip>
          </a>
          <ToolbarDivider />
        </ToolbarContainerLeft>
      </Toolbar>
    </AppBar>
  );
}
