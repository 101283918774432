import {
  CalendarMonthRounded,
  Person2Rounded,
  RecyclingRounded,
  ReviewsRounded,
  SellRounded,
} from '@mui/icons-material';
import styled, { css } from 'styled-components';
import { DemoButton } from './style-Homepage';
import {
  TestimonialContainer,
  TestimonialMedia,
  TestimonialMediaContainer,
  TestimonialTextContainer,
} from './style-Testimonials';

const lightCardColors = [
  'linear-gradient(135deg, #b2d8d8, #f0f4f5)', // Cool Mint to Light Blue-Gray
  'linear-gradient(135deg, #c5c4e7, #ececf6)', // Cool Lavender to Soft Lilac
  'linear-gradient(135deg, #b4d1e0, #e0f2f5)', // Aqua Blue to Light Cyan
  'linear-gradient(135deg, #e0b4c8, #f8e3ec)', // Cool Blush to Light Rose
  'linear-gradient(135deg, #ded1b4, #f4f0e2)', // Warm Beige to Soft Off-White
];

const darkCardColors = [
  'linear-gradient(135deg, #4e7d7a, #6d8b89)', // Deep Teal to Slate Blue
  'linear-gradient(135deg, #5e5b8a, #7d7aa4)', // Dark Lavender to Dusty Purple
  'linear-gradient(135deg, #507b94, #6e9db2)', // Deep Aqua to Cool Blue
  'linear-gradient(135deg, #b27085, #d78da2)', // Rich Mauve to Dusty Pink
  'linear-gradient(135deg, #b8a37e, #d4c7aa)', // Warm Tan to Soft Taupe
];

export const CampaignTypesContainer = styled(TestimonialContainer)`
  position: sticky;
  box-shadow: none;
  opacity: 1;
  height: 300px;

  &:nth-child(1) {
    top: 150px;
    background: ${(props) =>
      props.dark ? darkCardColors[0] : lightCardColors[0]};
  }
  &:nth-child(2) {
    top: 170px;
    background: ${(props) =>
      props.dark ? darkCardColors[1] : lightCardColors[1]};
  }
  &:nth-child(3) {
    top: 190px;
    background: ${(props) =>
      props.dark ? darkCardColors[2] : lightCardColors[2]};
  }
  &:nth-child(4) {
    top: 210px;
    background: ${(props) =>
      props.dark ? darkCardColors[3] : lightCardColors[3]};
  }
  &:nth-child(5) {
    top: 230px;
    background: ${(props) =>
      props.dark ? darkCardColors[4] : lightCardColors[4]};
  }
  z-index: 200;

  @media (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const CampaignTypesMediaContainer = styled(TestimonialMediaContainer)`
  height: 100px;
  margin-top: 30px;
`;

export const CampaignTypesExampleButton = styled(DemoButton)`
  background: ${(props) =>
    props.dark ? 'none' : ({ theme }) => theme.color.lightGray};
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
  transition: all 0.3s;
  :hover {
    background: ${(props) =>
      props.dark ? 'none' : ({ theme }) => theme.color.superLightGray};
    opacity: 0.7;
  }
`;
export const CampaignTypesWrapper = styled.div`
  position: relative;
`;

export const CampaignTypesMedia = styled(TestimonialMedia)`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20%;
    height: auto;
  }
`;

const CampaignTypesIconStyle = css`
  height: 10px;
`;

export const NewClientIcon = styled(Person2Rounded)`
  ${CampaignTypesIconStyle}
`;

export const FillCancellationsIcon = styled(CalendarMonthRounded)`
  ${CampaignTypesIconStyle}
`;

export const ReactivateIcon = styled(RecyclingRounded)`
  ${CampaignTypesIconStyle}
`;

export const MembershipsIcon = styled(SellRounded)`
  ${CampaignTypesIconStyle}
`;

export const ReviewsIcon = styled(ReviewsRounded)`
  ${CampaignTypesIconStyle}
`;

export const CampaignTypesLeftContainer = styled(TestimonialTextContainer)``;
