import React, { useContext, useRef } from 'react';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import Header from '../../../../components/NavBar/Header';
import ClientSearch from '../../../../components/Search/ClientSearch/ClientSearch';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import {
  ColumnCenteredDiv,
  ContentContainer,
  FullWidthCenteredDiv,
  PageContainer,
  PageTitleText,
} from '../../../../styles/shared-styled-components';
import { campaignMemberManagementContextKey } from '../../../../utils/constants';
import { getUserLocationId } from '../../../../utils/user';

const Audience = () => {
  const { user, drawerOpen, drawerExpanded, cookies, setCookie } =
    useContext(BaseContext);
  const locationId = getUserLocationId(user);

  const contentContainerRef = useRef();

  return (
    <>
      <MetaSetter
        title={`Audience`}
        description={`Campaign Audience`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
        >
          <ColumnCenteredDiv>
            <PageTitleText>Audience</PageTitleText>
          </ColumnCenteredDiv>
          <FullWidthCenteredDiv>
            <ClientSearch
              locationId={locationId}
              context={campaignMemberManagementContextKey}
            />
          </FullWidthCenteredDiv>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default Audience;
