import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import {
  GET_MESSAGE_TEMPLATE_CONTENT,
  GET_MY_FAQS,
  GET_MY_FILES,
  GET_MY_MESSAGE_TEMPLATES,
  GET_SCRIPT,
} from '../queries/content';

export const useMyMessageTemplates = () => {
  const { data, error, loading, refetch } = useQuery(GET_MY_MESSAGE_TEMPLATES, {
    fetchPolicy: 'cache-first',
  });

  const sortedTemplates = sortBy(
    get(data, 'getMyMessageTemplates', []),
    (t) => t.updatedAt,
  ).reverse();

  return {
    error,
    loading,
    refetch,
    templates: sortedTemplates,
  };
};

export const useMessageTemplateContent = ({ id, skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_MESSAGE_TEMPLATE_CONTENT,
    {
      fetchPolicy: 'network-only',
      skip: !id || skipCondition,
      variables: {
        id,
      },
    },
  );

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getMessageTemplateContent', null),
  };
};

export const useScript = ({ id }) => {
  const { data, error, loading, refetch } = useQuery(GET_SCRIPT, {
    fetchPolicy: 'cache-first',
    skip: !id,
    variables: {
      id,
    },
  });

  return {
    error,
    loading,
    refetch,
    script: get(data, 'getScript', null),
  };
};

export const useMyFiles = () => {
  const { data, error, loading, refetch } = useQuery(GET_MY_FILES, {
    fetchPolicy: 'cache-first',
  });

  const sortedFiles = sortBy(get(data, 'getMyFiles', []), (t) => t.updatedAt);

  return {
    error,
    loading,
    refetch,
    files: sortedFiles,
  };
};

export const useMyFaqs = () => {
  const { data, error, loading, refetch } = useQuery(GET_MY_FAQS, {
    fetchPolicy: 'cache-first',
  });

  const sortedFaqs = sortBy(get(data, 'getMyFaqs', []), (f) => f.updatedAt);

  return {
    error,
    loading,
    refetch,
    faqs: sortedFaqs,
  };
};
