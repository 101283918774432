import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import {
  GET_FACEBOOK_ADS_DATA_SYNC_STATUS,
  GET_GHL_DATA_SYNC_STATUS,
  GET_GOOGLE_ADS_DATA_SYNC_STATUS,
  GET_QUICKBOOKS_AUTH_URI,
  GET_QUICKBOOKS_DATA_SYNC_STATUS,
} from '../queries/app-connections';

export const useQuickbooksAuthUri = () => {
  const { data, error, loading, refetch } = useQuery(GET_QUICKBOOKS_AUTH_URI, {
    fetchPolicy: 'cache-first',
  });

  return {
    error,
    loading,
    refetch,
    uri: get(data, 'getQuickbooksAuthUri', null),
  };
};

export const useQuickbooksDataSyncStatus = (realmId, onCompletedFunction) => {
  const runOnCompleted = async (data) => {
    await onCompletedFunction(data);
  };

  const { data, error, loading, refetch } = useQuery(
    GET_QUICKBOOKS_DATA_SYNC_STATUS,
    {
      fetchPolicy: 'cache-first',
      variables: {
        realmId,
      },
      onCompleted: (data) => runOnCompleted(data),
    },
  );

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getQuickbooksDataSyncStatus', null),
  };
};

export const useGHLDataSyncStatus = (tokenId, onCompletedFunction) => {
  const runOnCompleted = async (data) => {
    await onCompletedFunction(data);
  };

  const { data, error, loading, refetch } = useQuery(GET_GHL_DATA_SYNC_STATUS, {
    fetchPolicy: 'cache-first',
    variables: {
      tid: tokenId,
    },
    onCompleted: (data) => runOnCompleted(data),
  });

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getGHLDataSyncStatus', null),
  };
};

export const useGoogleAdsDataSyncStatus = (tokenId, onCompletedFunction) => {
  const runOnCompleted = async (data) => {
    await onCompletedFunction(data);
  };

  const { data, error, loading, refetch } = useQuery(
    GET_GOOGLE_ADS_DATA_SYNC_STATUS,
    {
      fetchPolicy: 'cache-first',
      variables: {
        tid: tokenId,
      },
      onCompleted: (data) => runOnCompleted(data),
    },
  );

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getGoogleAdsDataSyncStatus', null),
  };
};

export const useFacebookAdsDataSyncStatus = (tokenId, onCompletedFunction) => {
  const runOnCompleted = async (data) => {
    await onCompletedFunction(data);
  };

  const { data, error, loading, refetch } = useQuery(
    GET_FACEBOOK_ADS_DATA_SYNC_STATUS,
    {
      fetchPolicy: 'cache-first',
      variables: {
        tid: tokenId,
      },
      onCompleted: (data) => runOnCompleted(data),
    },
  );

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getFacebookAdsDataSyncStatus', null),
  };
};
