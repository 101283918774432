import styled from 'styled-components';
import {
  homepageMobilePixelDistance,
  homepageMobileSideMargin,
  homepagePercentageWidth,
  homepagePixelDistance,
} from '../../../utils/constants';

export const Features1Container = styled.div`
  margin: 0 auto;
  margin-top: ${homepagePixelDistance}px;

  padding-left: ${homepageMobileSideMargin}px;
  padding-right: ${homepageMobileSideMargin}px;
  width: ${homepagePercentageWidth}%;
  height: fit-content;
  display: flex;
  align-items: top;

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: ${homepageMobilePixelDistance}px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    width: 80%;
  }

  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220%200%20100%20100%22 version%3D%221.1%22%3E%3Cdefs%3E%3ClinearGradient id%3D%22sw-gradient%22 x1%3D%220%22 x2%3D%221%22 y1%3D%221%22 y2%3D%220%22%3E%3Cstop id%3D%22stop1%22 stop-color%3D%22rgba(57%2C%2049%2C%2080%2C%20.02)%22 offset%3D%220%25%22/%3E%3Cstop id%3D%22stop2%22 stop-color%3D%22rgba(113%2C%2097%2C%20160%2C%201)%22 offset%3D%22100%25%22/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill%3D%22url(%23sw-gradient)%22 d%3D%22M12.3%2C-15.4C19.8%2C-11.4%2C32.5%2C-13%2C34.6%2C-10.4C36.6%2C-7.7%2C28.1%2C-0.7%2C24.5%2C7.2C20.9%2C15%2C22.2%2C23.7%2C18.9%2C24.3C15.7%2C24.9%2C7.8%2C17.5%2C0.4%2C16.9C-7.1%2C16.4%2C-14.1%2C22.8%2C-16.2%2C21.8C-18.2%2C20.8%2C-15.2%2C12.4%2C-16%2C6.1C-16.8%2C-0.2%2C-21.4%2C-4.3%2C-21.7%2C-8.2C-22%2C-12.1%2C-18%2C-15.7%2C-13.7%2C-20.8C-9.4%2C-25.8%2C-4.7%2C-32.3%2C-1.2%2C-30.7C2.4%2C-29.1%2C4.7%2C-19.4%2C12.3%2C-15.4Z%22 width%3D%22100%25%22 height%3D%22100%25%22 transform%3D%22translate(50%2050)%22 style%3D%22transition%3A%200.3s%3B%22 stroke-width%3D%220%22/%3E%3C/svg%3E');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: left -100px center;
  z-index: 5;
`;

export const Features1LeftContainer = styled.div`
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;

  @media (max-width: 1024px) {
    width: 100%;
  }
  z-index: 5;
`;

export const Features1Header = styled.div`
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  letter-spacing: 10px;
  font-weight: 600;
  font-size: calc(18px + 1vw);
  margin-bottom: 1em;

  @media (max-width: 1024px) {
    padding-left: ${homepageMobileSideMargin}px;
  }
  z-index: 5;
`;

export const Features1RightContainer = styled.div`
  min-width: 50%;
  width: 50%;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 20px;
  @media (max-width: 1024px) {
    width: 100%;
  }
  z-index: 5;
`;

export const Features1Sentence = styled.div`
  height: 12%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;

  background: rgba(245, 245, 245, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.02);

  border-radius: 10px;
  margin-bottom: 20px;
  transition: all 0.3s;

  :hover {
    transform: translate(10px, 0);
  }

  @media (max-width: 1024px) {
    max-width: 90%;
  }
  z-index: 5;
`;

export const Feature1SentenceHeader = styled.div`
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: 600;
  z-index: 5;
`;

export const Feature1SentenceSubtext = styled.div`
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
  font-size: 13px;
  z-index: 5;
`;

export const Features1Media = styled.div``;
