import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import {
  GET_AGENT_SETTINGS,
  GET_CLOSURES,
  GET_LAUNCH_STATUS,
  GET_MEMBERSHIPS,
  GET_MODULE_STATUS,
  GET_OVERVIEW,
  GET_SERVICE_CORPUS,
} from '../queries/training';

export const useModuleStatus = () => {
  const { data, error, loading, refetch } = useQuery(GET_MODULE_STATUS, {
    fetchPolicy: 'cache-first',
  });

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getModuleStatus', null),
  };
};

export const useLaunchStatus = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_LAUNCH_STATUS, {
    fetchPolicy: 'network-only',
    skip: skipCondition,
  });

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getLaunchStatus', null),
  };
};

export const useAgentSettings = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_AGENT_SETTINGS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const settings = get(data, 'getAgentSettings', null);

  return {
    error,
    loading,
    refetch,
    data: settings,
  };
};

export const useOverview = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_OVERVIEW, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const overview = get(data, 'getOverview', null);

  return {
    error,
    loading,
    refetch,
    data: overview,
  };
};

export const useClosures = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_CLOSURES, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const closures = get(data, 'getClosures', null);

  return {
    error,
    loading,
    refetch,
    data: closures,
  };
};

export const useServiceCorpus = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_SERVICE_CORPUS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const corpus = get(data, 'getServiceCorpus', null);

  return {
    error,
    loading,
    refetch,
    data: corpus,
  };
};

export const useMemberships = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_MEMBERSHIPS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const memberships = get(data, 'getMemberships', null);

  return {
    error,
    loading,
    refetch,
    data: memberships,
  };
};
