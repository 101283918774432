export const screenWidthIsIpadSizeOrSmaller = (width) => {
  return width <= 1080;
};

export const screenWidthIsMobileSizeOrSmaller = (width) => {
  return width <= 768;
};

export const screenWidthIsMediumMobileSizeOrSmaller = (width) => {
  return width <= 700;
};

export const screenWidthIsSmallMobileSizeOrSmaller = (width) => {
  return width <= 475;
};

export const screenWidthIsExtraSmallMobileSize = (width) => {
  return width <= 390;
};

export const scrollElementIntoView = (
  document,
  elementId,
  elementScrollPosition = 'center',
  inline = 'nearest',
  scrollBehavior = 'smooth',
) => {
  const mainRoot = document.getElementById(elementId);
  if (mainRoot) {
    mainRoot.scrollIntoView({
      behavior: scrollBehavior,
      block: elementScrollPosition,
      inline,
    });
  }
};

export const drawerOpenKey = 'drawer_open';
export const drawerOpenOverrideKey = 'drawer_open_override';
export const drawerExpandedKey = 'drawer_expanded';
export const drawerExpandedOverrideKey = 'drawer_expanded_override';

export default {
  screenWidthIsIpadSizeOrSmaller,
  screenWidthIsMobileSizeOrSmaller,
  screenWidthIsMediumMobileSizeOrSmaller,
  screenWidthIsSmallMobileSizeOrSmaller,
  screenWidthIsExtraSmallMobileSize,
  scrollElementIntoView,
  drawerOpenKey,
  drawerOpenOverrideKey,
  drawerExpandedKey,
  drawerExpandedOverrideKey,
};
