import styled from 'styled-components';
import {
  homepageMobilePixelDistance,
  homepagePixelDistance,
} from '../../../utils/constants';

export const FiveMinuteSetupContainer = styled.div`
  margin: 0 auto;
  margin-top: ${homepagePixelDistance}px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  height: auto;
  background-color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.charcoal
      : ({ theme }) => theme.color.lightGray};
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    max-width: 100%;
    align-items: top;
    margin-top: ${homepageMobilePixelDistance};
  }

  background: rgba(245, 245, 245, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.02);
  border-radius: 10px;

  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};

  /* background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220%200%20100%20100%22%3E%3Cdefs%3E%3ClinearGradient fill-opacity%3D%220.1%22 id%3D%22sw-gradient%22 x1%3D%220%22 x2%3D%221%22 y1%3D%221%22 y2%3D%220%22%3E%3Cstop id%3D%22stop1%22 stop-color%3D%22rgba(57%2C%2049%2C%2080%2C%200.2)%22 offset%3D%220%25%22/%3E%3Cstop id%3D%22stop2%22 stop-color%3D%22rgba(113%2C%2097%2C%20160%2C%201)%22 offset%3D%22100%25%22/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill%3D%22url(%23sw-gradient)%22 d%3D%22M10.6%2C-16.7C14.8%2C-16%2C19.8%2C-15.2%2C25.7%2C-12.4C31.6%2C-9.5%2C38.3%2C-4.8%2C40.9%2C1.5C43.5%2C7.8%2C42%2C15.6%2C35.1%2C16.6C28.2%2C17.6%2C15.8%2C11.8%2C9.1%2C13.5C2.3%2C15.1%2C1.2%2C24.1%2C-1.8%2C27.3C-4.8%2C30.5%2C-9.7%2C27.8%2C-16.3%2C26.1C-23%2C24.5%2C-31.6%2C23.8%2C-35.8%2C19.6C-40.1%2C15.4%2C-40.1%2C7.7%2C-36.2%2C2.2C-32.3%2C-3.2%2C-24.6%2C-6.5%2C-18.2%2C-7.1C-11.9%2C-7.7%2C-7.1%2C-5.8%2C-4.3%2C-7.3C-1.5%2C-8.8%2C-0.7%2C-13.8%2C1.3%2C-15.9C3.2%2C-18.1%2C6.5%2C-17.5%2C10.6%2C-16.7Z%22 width%3D%22100%25%22 height%3D%22100%25%22 transform%3D%22translate(50%2050)%22 style%3D%22transition%3A%200.3s%3B%22 stroke-width%3D%220%22 stroke%3D%22url(%23sw-gradient)%22/%3E%3C/svg%3E'); */

  background-size: 60%; /* Adjust sizes as needed */
  background-repeat: no-repeat;
  background-position: right -10% center; /* Positions first on the right, second on the left */

  transition: all 0.3s;

  // without this transform, the container will be completey transparent
  transform: translateX(-0.01%);

  :hover {
    background-size: 57%; /* Adjust sizes as needed */
    transform: translateX(-1%);
  }
  z-index: 5;
`;

export const FiveMinuteSetupHeaderText = styled.div`
  font-size: 300%;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  font-weight: 600;
  text-align: center;
  letter-spacing: -3px;
  line-height: 1.1;

  max-width: 80%;

  @media (max-width: 1024px) {
    font-size: 40px;
    letter-spacing: -2px;
  }

  @media (max-width: 576px) {
    font-size: 25px;
    letter-spacing: -1px;
  }
`;

export const FiveMinuteSetupSubText = styled.p`
  letter-spacing: 2px;
  font-size: 90%;
  text-align: center;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
  max-width: 80%;
`;

export const FiveMinuteSetupMediaContainer = styled.div`
  gap: 40px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: top;
  flex-direction: row;

  width: 80%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const FiveMinuteSetupMediaItem = styled.div`
  width: 100%;
  max-height: 300px;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 576px) {
    display: flex;
  }
`;

export const FiveMinuteSetupMediaIcon = styled.div`
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const FiveMinuteSetupMediaHeaderText = styled.div`
  letter-spacing: -1.5px;
  padding-top: 2%;
  padding-bottom: 2%;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  font-size: 150%;
`;

export const FiveMinuteSetupMediaSubText = styled.div`
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
  font-size: 100%;
`;
