import React from 'react';
import {
  ContainerWithInputWidth,
  SmallCheckbox,
  StartAlignedLightDarkLargeTinyText,
} from '../../../../styles/shared-styled-components';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptTitleText,
  SpacedPromptCheckboxContainer,
} from '../../shared-training-components';

const Messaging = ({ agentSettings, onChange, loading }) => {
  console.log('agentSettings', agentSettings);
  const openingMessage = agentSettings?.phoneOpeningMessage || '';
  const baseSettings = agentSettings?.settings;
  const messagingSettings = baseSettings?.messaging || {};
  const answerWithCallerNameWhenRecognized =
    messagingSettings?.answerWithCallerNameWhenRecognized;
  const useCustomHolidayMessaging = messagingSettings?.customHolidayMessaging;

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer addMlGap>
        <PromptInput
          value={openingMessage}
          onChange={(e) => {
            onChange({
              ...agentSettings,
              phoneOpeningMessage: e.target.value,
            });
          }}
          label='Opening message'
          multiline
          numRows={4}
        />
      </PromptContainer>
      <PromptContainer>
        <SpacedPromptCheckboxContainer>
          <PromptTitleText>
            Answer with caller name when recognized
          </PromptTitleText>
          <SmallCheckbox
            checked={answerWithCallerNameWhenRecognized}
            onClick={() => {
              onChange({
                ...agentSettings,
                settings: {
                  ...baseSettings,
                  ['messaging']: {
                    ...messagingSettings,
                    answerWithCallerNameWhenRecognized:
                      answerWithCallerNameWhenRecognized ? false : true,
                  },
                },
              });
            }}
          />
        </SpacedPromptCheckboxContainer>
        <ContainerWithInputWidth useSmallWidth>
          <StartAlignedLightDarkLargeTinyText>
            "Hi Lauren!
            {openingMessage ? ` ${openingMessage.slice(0, 15)}...` : ''}"
          </StartAlignedLightDarkLargeTinyText>
        </ContainerWithInputWidth>
      </PromptContainer>
      <PromptContainer>
        <SpacedPromptCheckboxContainer>
          <PromptTitleText>
            Auto-generate custom holiday greetings
          </PromptTitleText>
          <SmallCheckbox
            checked={useCustomHolidayMessaging}
            onClick={() => {
              onChange({
                ...agentSettings,
                settings: {
                  ...baseSettings,
                  ['messaging']: {
                    ...messagingSettings,
                    customHolidayMessaging: useCustomHolidayMessaging
                      ? false
                      : true,
                  },
                },
              });
            }}
          />
        </SpacedPromptCheckboxContainer>
        <ContainerWithInputWidth useSmallWidth>
          <StartAlignedLightDarkLargeTinyText>
            "Happy Halloween!
            {openingMessage ? ` ${openingMessage.slice(0, 15)}...` : ''}"
          </StartAlignedLightDarkLargeTinyText>
        </ContainerWithInputWidth>
      </PromptContainer>
    </PromptsSection>
  );
};

export default Messaging;
