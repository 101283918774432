import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { handleSignup } from '../../../utils/auth';
import { PrimaryLoginButtonComponent } from '../LoginButton/styled';
import { SignupButtonComponent } from './styled';

const SignupButton = ({
  variant,
  onClick = () => {},
  size = 'small',
  text = 'Sign up',
}) => {
  const { loginWithRedirect } = useAuth0();
  const { pathname } = useLocation();

  const onSignup = () => {
    handleSignup({ onClick, pathname, loginWithRedirect });
  };

  return (
    <>
      {variant === 'secondary' ? (
        <SignupButtonComponent
          buttonSize={size}
          onClick={onSignup}
        >
          {text}
        </SignupButtonComponent>
      ) : (
        <PrimaryLoginButtonComponent
          buttonSize={size}
          onClick={onSignup}
        >
          {text}
        </PrimaryLoginButtonComponent>
      )}
    </>
  );
};

export default SignupButton;
