import { default as React, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import Header from '../../../../components/NavBar/Header';
import Snackbar from '../../../../components/Snackbar';
import {
  BaseCard,
  CardActionsContainer,
  CardHeaderContainer,
  CardPageSection,
  ColumnCenteredDiv,
  ColumnDivWithGap,
  ContentContainer,
  DarkEssText,
  DarkLargeText,
  LightDarkTextSpan,
  MediumGapColumnCenteredDiv,
  PageContainer,
  StartAlignedLightDarkLargeTinyText,
  Tab,
  Tabs,
  TinyPrimaryButton,
  TinySecondaryButton,
} from '../../../../styles/shared-styled-components';
import { templatesTabCookieKey } from '../../../../utils/constants';

const corporateTemplates = [
  {
    label: 'Campaign: Winter Stretch Promo',
    description: '10% off all Stretch Therapy throughout the winter',
  },
  {
    label: 'Policy: Follow-Up',
    description: 'Procedure for following up post-appointment',
  },
  {
    label: 'Policy: Deposits',
    description: 'Deposit policy for booking appointments',
  },
];

const industryTemplates = [
  {
    label: '[Salon] Campaign: 90 Day 15% Off Reactivation',
    description:
      '15% off all bookings for clients without appointments in 90 days',
  },
  {
    label: '[All Industries] Script: Massage Recommendation',
    description:
      'Series of questions to find out the best Massage to recommend',
  },
  {
    label: '[MedSpa] Campaign: Hydrafacial Promo',
    description: '15% off first Hydrafacial, including Serum + Mask',
  },
];

const corporateTemplateKey = 0;
const industryTemplateKey = 1;

const Templates = () => {
  const navigate = useNavigate();
  const { drawerOpen, drawerExpanded, cookies, setCookie } =
    useContext(BaseContext);

  const tabCookie = cookies[templatesTabCookieKey];
  const [tab, setTab] = useState(
    tabCookie != null ? parseInt(tabCookie) : corporateTemplateKey,
  );
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (tabCookie != null) {
      setTab(parseInt(tabCookie));
    }
  }, [tabCookie]);

  const onChangeTab = (tabValue) => {
    setCookie(templatesTabCookieKey, parseInt(tabValue), {});
  };

  const templatesToDisplay =
    tab === corporateTemplateKey ? corporateTemplates : industryTemplates;

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ColumnCenteredDiv>
            <DarkLargeText>Templates</DarkLargeText>
          </ColumnCenteredDiv>
          <ColumnCenteredDiv>
            <Tabs
              value={tab}
              onChange={(_, key) => onChangeTab(key)}
            >
              <Tab
                value={corporateTemplateKey}
                label='Corporate'
              />
              <Tab
                value={industryTemplateKey}
                label='Industry'
              />
            </Tabs>
          </ColumnCenteredDiv>
          <CardPageSection>
            <MediumGapColumnCenteredDiv>
              {templatesToDisplay.map((template) => {
                const { label, description } = template;
                return (
                  <BaseCard>
                    <ColumnDivWithGap>
                      <CardHeaderContainer>
                        <DarkEssText>
                          {label}
                          <LightDarkTextSpan></LightDarkTextSpan>
                        </DarkEssText>
                      </CardHeaderContainer>
                      <StartAlignedLightDarkLargeTinyText>
                        {description}
                      </StartAlignedLightDarkLargeTinyText>
                    </ColumnDivWithGap>
                    <CardActionsContainer>
                      <TinyPrimaryButton largePadding>Apply</TinyPrimaryButton>
                      <TinySecondaryButton>Get info</TinySecondaryButton>
                    </CardActionsContainer>
                  </BaseCard>
                );
              })}
            </MediumGapColumnCenteredDiv>
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Templates;
