import React, { useContext, useEffect, useState } from 'react';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import DotCarousel from '../../../components/Micro/DotCarousel';
import Paths from '../../../Paths';
import {
  BookedIcon,
  ChartIcon,
  CompleteButtonCheckmarkIcon,
  DarkGoldText,
  EmptyGapColumnCenteredDiv,
  GoldText,
  LightbulbIcon,
  PhoneIcon,
} from '../../../styles/shared-styled-components';
import {
  AgentContainer,
  AgentIconText,
  AgentInfo,
  AgentInfoSubtext,
  AgentsContainer,
  BottomBubble,
  BubbleText,
  DemoButton,
  Feature,
  FeaturesContainer,
  GradientSpan,
  HeaderSubText,
  HeaderSubTextContainer,
  HeaderText,
  HeroContainer,
  LaunchIcon,
  LightningIcon,
  SetupText,
  TextContainer,
  TopBubble,
} from '../styles/style-Homepage';

const features = [
  'EMR + Marketing integrations',
  'HIPAA compliant',
  'Multi-location support',
  'Books 500+ services',
  'Uses custom booking flows',
  'Speaks 20+ languages',
];

const Hero = ({ darkMode }) => {
  const { width } = useContext(BaseContext);
  const folds = width < 1025;
  const smallFolds = width < 500;
  const size = smallFolds ? 'xs' : folds ? 's' : null;

  const featuresFoldsToSeparateLines = width < 461;

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [highlightedAgent, setHighlightedAgent] = useState(0);
  const [paused, setPaused] = useState(false);

  const agents = [0, 1, 2];

  useEffect(() => {
    if (!paused) {
      const interval = setInterval(() => {
        setHighlightedAgent((prev) => (prev + 1) % agents.length);
      }, 5500);
      return () => clearInterval(interval);
    }
  }, [paused]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const TextComponent = (
    <TextContainer hideTopMargin={folds}>
      <HeaderText>
        <GradientSpan dark={darkMode}>
          Meet the AI front desk custom-built for Aesthetics & Wellness{' '}
        </GradientSpan>
        <LightningIcon />
        <br />
      </HeaderText>
      <HeaderSubTextContainer>
        <HeaderSubText dark={darkMode}>
          {size === 'xs' ? (
            <>
              Handles all calls + texts.
              <br></br>
              Books appointments.
              <br></br>
              Grows your business.
            </>
          ) : (
            'Handles all calls + texts. Books appointments. Grows your business.'
          )}
        </HeaderSubText>
      </HeaderSubTextContainer>
      <DemoButton href={Paths.calendlyLink}>See it in action →</DemoButton>
      <SetupText>
        <FeaturesContainer
          dark={darkMode}
          center={folds}
        >
          {features.map((feature) => (
            <Feature
              dark={darkMode}
              separateLine={featuresFoldsToSeparateLines}
            >
              {featuresFoldsToSeparateLines ? (
                <>
                  <span>{feature}</span>
                  <span>✔</span>
                </>
              ) : (
                <>✔&nbsp;&nbsp;{feature}</>
              )}
            </Feature>
          ))}
        </FeaturesContainer>
      </SetupText>
    </TextContainer>
  );

  const AgentComponent = (
    <EmptyGapColumnCenteredDiv
      relative
      bottomMargin={folds && !smallFolds ? 35 : 0}
    >
      <AgentsContainer
        style={{
          visibility: 'visible',
        }}
        size={size}
      >
        <AgentContainer
          dark={darkMode}
          position={0}
          selected={highlightedAgent === 0}
          shouldHide={highlightedAgent === 2}
          folds={!!size}
        >
          <img
            src='https://storage.googleapis.com/liveiq-assets/avatars/receptionist-cropped-compressed.png'
            alt='Ava'
            onLoad={handleImageLoad}
            loading='lazy'
          />
          {highlightedAgent === 0 && (
            <>
              <TopBubble
                receptionist
                size={size}
              >
                <PhoneIcon receptionist />
                <BubbleText receptionist>Call handled</BubbleText>
              </TopBubble>
              <BottomBubble
                receptionist
                folds={folds}
              >
                <BubbleText receptionist>Booked in EMR</BubbleText>
                <BookedIcon secondary />
              </BottomBubble>
              <AgentInfo dark={darkMode}>
                Hi, I'm&nbsp;
                <GradientSpan dark={darkMode}>Madison</GradientSpan>
              </AgentInfo>
              <AgentInfoSubtext dark={darkMode}>
                Your new&nbsp;
                {darkMode ? (
                  <GoldText>Receptionist</GoldText>
                ) : (
                  <DarkGoldText>Receptionist</DarkGoldText>
                )}
                &nbsp;&nbsp;
                <AgentIconText>☎️</AgentIconText>
              </AgentInfoSubtext>
            </>
          )}
        </AgentContainer>
        <AgentContainer
          dark={darkMode}
          position={1}
          selected={highlightedAgent === 1}
          folds={!!size}
        >
          <img
            src='https://storage.googleapis.com/liveiq-assets/avatars/marketer-cropped-compressed.png'
            alt='Ava'
            onLoad={handleImageLoad}
            loading='lazy'
          />
          {highlightedAgent === 1 && (
            <>
              <TopBubble
                marketer
                size={size}
              >
                <LaunchIcon marketer />
                <BubbleText marketer>Campaign launched</BubbleText>
              </TopBubble>
              <BottomBubble
                marketer
                size={size}
              >
                <BubbleText marketer>Lead converted</BubbleText>
                <CompleteButtonCheckmarkIcon
                  gold
                  small
                />
              </BottomBubble>
              <AgentInfo dark={darkMode}>
                Hi, I'm&nbsp;
                <GradientSpan dark={darkMode}>Ava</GradientSpan>
              </AgentInfo>
              <AgentInfoSubtext dark={darkMode}>
                Your new&nbsp;
                {darkMode ? (
                  <GoldText>Marketer</GoldText>
                ) : (
                  <DarkGoldText>Marketer</DarkGoldText>
                )}
                &nbsp;&nbsp;📢
              </AgentInfoSubtext>
            </>
          )}
        </AgentContainer>
        <AgentContainer
          dark={darkMode}
          position={2}
          selected={highlightedAgent === 2}
          shouldHide={highlightedAgent === 0}
          folds={!!size}
        >
          <img
            src='https://storage.googleapis.com/liveiq-assets/avatars/analyst-cropped.png'
            alt='Ava'
            onLoad={handleImageLoad}
            loading='lazy'
          />
          {highlightedAgent === 2 && (
            <>
              <TopBubble
                analyst
                size={width < 662 ? 'xs' : size}
              >
                <ChartIcon />
                <BubbleText analyst>Revenue up 30%</BubbleText>
              </TopBubble>
              <BottomBubble
                analyst
                size={width < 662 ? 'xs' : size}
              >
                <BubbleText analyst>New growth tip</BubbleText>
                <LightbulbIcon analyst />
              </BottomBubble>
              <AgentInfo dark={darkMode}>
                Hi, I'm&nbsp;<GradientSpan dark={darkMode}>Lucas</GradientSpan>
              </AgentInfo>
              <AgentInfoSubtext dark={darkMode}>
                Your new&nbsp;
                {darkMode ? (
                  <GoldText>Analyst</GoldText>
                ) : (
                  <DarkGoldText>Analyst</DarkGoldText>
                )}
                &nbsp;&nbsp;📈
              </AgentInfoSubtext>
            </>
          )}
        </AgentContainer>
      </AgentsContainer>
      {!size && (
        <DotCarousel
          options={agents}
          selected={highlightedAgent}
          topOffset={folds ? 28 : 130}
          paused={paused}
          onToggle={() => setPaused(!paused)}
        />
      )}
    </EmptyGapColumnCenteredDiv>
  );

  return (
    <HeroContainer size={size}>
      {folds ? (
        <>
          {AgentComponent}
          <br></br>
          {TextComponent}
        </>
      ) : (
        <>
          {TextComponent}
          {AgentComponent}
        </>
      )}
    </HeroContainer>
  );
};

export default Hero;
