import styled, { css } from 'styled-components';
import { DemoButton } from '../../../pages-website/Homepage/styles/style-Homepage';
import {
  CenterFlexStyle,
  DashboardIcon,
  ExtraSmallGapStyle,
} from '../../../styles/shared-styled-components';
import { homepageNavbarCollapseWidth } from '../../../utils/constants';
import { AppBar as BaseAppBar, Toolbar as BaseToolBar } from '../Header/styled';

export const AppBar = styled(BaseAppBar)`
  background-color: white;
  z-index: 1001;
`;

export const ToolBarLeftContainer = styled(BaseToolBar)`
  position: fixed;
  top: 2%;
  left: 5%;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  z-index: 100;
  width: fit-content;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: ${(props) =>
    props.dark ? 'rgba(50, 50, 50, 0.7)' : 'rgba(220, 220, 220, 0.7)'};

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);

  @media (max-width: 1200px) {
    width: fit-content;
    justify-content: space-between;
  }
`;

export const ToolBarRightContainer = styled(BaseToolBar)`
  position: fixed;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  z-index: 100;
  width: fit-content;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: ${(props) =>
    props.dark ? 'rgba(50, 50, 50, 0.7)' : 'rgba(220, 220, 220, 0.7)'};
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  top: 2%;
  right: 5%;
  left: auto;
  ${(props) =>
    props.collapsed
      ? css`
          padding: 10px 5px;
          padding-right: 12px;
        `
      : css`
          padding: 10px 20px;
        `}
`;

export const ToolBarLeftSection = styled.div`
  height: 100%;
  ${CenterFlexStyle}
`;

export const ToolBarMiddleSection = styled(ToolBarLeftSection)`
  display: flex;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  padding-left: 20px;
  padding-right: 20px;

  li {
    list-style-type: none;
    margin: 0;
    padding: 20px;
  }

  a {
    letter-spacing: 0.4px;
    text-decoration: none;
    transition: all 0.3s;
    color: ${(props) =>
      props.dark
        ? ({ theme }) => theme.color.lightGray
        : ({ theme }) => theme.color.charcoal};
    font-size: calc(12px + 0.2vw);
  }

  a:hover {
    color: ${({ theme }) => theme.color.primary};
  }

  @media (max-width: 1140px) {
    display: none;
  }
`;

export const ToolBarRightSection = styled(ToolBarLeftSection)``;

export const WebsiteLogo = styled.img`
  height: 42px;
  width: auto;
  cursor: pointer;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  ${ExtraSmallGapStyle}
  height: 24px;
  align-items: center;
  cursor: pointer;
`;

export const LogoIcon = styled(DashboardIcon)`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.color.primary};
`;

export const LogoText = styled.p`
  font-size: 25px;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.lightGray
      : ({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.typography.logoFont};
  margin: 0px;
`;

export const NavbarDemoButton = styled(DemoButton)`
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0.4px;
  text-decoration: none;
  font-size: calc(12px + 0.2vw);
  margin: 0 10px;
  color: ${({ theme }) => theme.color.lightGray};
  width: fit-content;
  min-width: ${(props) => (props.large ? `150px` : `100px`)};
`;

export const NavbarGoToAppButton = styled(DemoButton)`
  background-color: transparent;
  min-width: ${(props) => (props.large ? `150px` : `100px`)};
  color: white;
  letter-spacing: 0.4px;
  text-decoration: none;
  font-size: calc(12px + 0.2vw);
  border: 2px solid ${({ theme }) => theme.color.primary};
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.lightGray
      : ({ theme }) => theme.color.primary};
  :hover {
    background-color: ${(props) =>
      props.dark
        ? ({ theme }) => theme.color.primary
        : ({ theme }) => theme.color.superLightGray};
  }
`;

export const HamburgerMenu = styled.div`
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.lightGray
      : ({ theme }) => theme.color.primary};
  font-size: 20px;
  margin-bottom: 4px;
  @media (max-width: ${homepageNavbarCollapseWidth}px) {
    display: block;
  }
`;

export const MobileMenu = styled.div`
  background: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.charcoal
      : ({ theme }) => theme.color.lightGray};
  position: absolute;
  top: 80px;
  left: calc(160px + 14vw);
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  opacity: 0;
  transform: translateY(-10px); /* Move up to hide */

  ${({ isOpen }) =>
    isOpen &&
    `
      opacity: 1;
      transform: translateY(0); /* Move into place */
  `}

  transition: all 0.3s;
`;

export const MobileMenuItem = styled.a`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  margin: 10px 0;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.lightGray
      : ({ theme }) => theme.color.primary};
  margin-bottom: ${(props) => props.marginBottom && `${props.marginBottom}px`};
  text-decoration: none;

  letter-spacing: 0.4px;
  text-decoration: none;

  font-size: calc(10px + 0.4vw);

  transition: all 0.3s;
  :hover {
    color: ${(props) =>
      props.dark
        ? ({ theme }) => theme.color.primary
        : ({ theme }) => theme.color.charcoal};
  }
`;
