const spacing = {
  // page
  pageHorizontalPadding: 20,
  // header
  headerVerticalPadding: 10,
  headerHeight: 60,
  websiteHeaderHeight: 100,
  belowHeaderVerticalPadding: 20,
  // footer
  footerHeight: 100,
  footerPadding: 30,
  aboveFooterMargin: 100,
  // zindices
  topZIndex: 100,
  // gap
  extraSmallGap: 6,
  essGap: 8,
  smallGap: 10,
  smmGap: 15,
  smGap: 17,
  mediumLiteGap: 21,
  mediumGap: 25,
  mlGap: 32,
  mmlGap: 36,
  largeGap: 40,
  extraLargeGap: 50,
  ginormousGap: 80,
  // padding
  baseVerticalPadding: 20,
  baseContainerPadding: 40,
  smContainerPadding: 30,
  smallContainerPadding: 20,
  inputVerticalPadding: 16,
  // border radius
  inputBorderRadius: 8,
  baseBorderRadius: 10,
  mlBorderRadius: 20,
  largeBorderRadius: 28,
  // z-index
  headerZIndex: 1001,
};

export default spacing;
