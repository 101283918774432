import React, { useState } from 'react';
import { startKey } from '../../../utils/constants';
import PopperMenu from '../../PopperMenu';
import { MenuCalendar } from './styled';

const PopperMenuCalendar = ({
  date,
  onChange,
  open,
  anchorElement,
  onClose,
  placement = 'bottom',
}) => {
  const [fieldEditing, setFieldEditing] = useState(startKey);

  const modifiers =
    placement === 'bottom'
      ? {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        }
      : {
          name: 'offset',
          options: {
            offset: [-300, -440],
          },
        };

  return (
    <PopperMenu
      open={open}
      anchorElement={anchorElement}
      onClose={onClose}
      variant='offset'
      placement={placement}
      modifiers={modifiers}
      largeMaxHeight={true}
      removeScroll={true}
    >
      <MenuCalendar
        onChange={onChange}
        value={date}
        locale='en-US'
        firstDay={1}
      />
    </PopperMenu>
  );
};

export default PopperMenuCalendar;
