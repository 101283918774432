import gql from 'graphql-tag';

export const GET_CALENDAR_FOR_DATE = gql`
  query getCalendarForDate($date: String!) {
    getCalendarForDate(date: $date) {
      date
      staff {
        staffId
        firstName
        lastName
        vacant {
          id
          start
          end
          status
        }
        occupied {
          id
          start
          end
          status
          isStart
          isEnd
        }
        timeSlotMap
      }
    }
  }
`;

export const GET_APPOINTMENT_BY_ID = gql`
  query getAppointmentById($id: String!) {
    getAppointmentById(id: $id)
  }
`;

export const GET_CHAT_APPOINTMENTS = gql`
  query getChatAppointments(
    $chatId: String!
    $onlyBooked: Boolean!
    $name: String
  ) {
    getChatAppointments(chatId: $chatId, onlyBooked: $onlyBooked, name: $name) {
      appointments {
        status
        bookerUserId
        assignedToChat
        appointment {
          id
          start
          end
          status
          durationMinutes
          source
          client {
            firstName
            lastName
          }
          staff {
            firstName
            lastName
          }
          sessionTypeName
        }
      }
      chatCampaigns {
        id
        name
      }
      timezone
    }
  }
`;

export const GET_DEPOSIT = gql`
  query getDeposit($id: String!) {
    getDeposit(id: $id)
  }
`;
