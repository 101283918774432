import { useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import get from 'lodash/get';
import {
  GET_AGENTS,
  GET_AGENT_BY_ID,
  GET_AGENT_VERSION,
  GET_AGENT_VERSIONS,
  GET_MY_AGENTS,
} from '../queries/agent';

export const useAgentById = ({ id }) => {
  const { data, error, loading, refetch } = useQuery(GET_AGENT_BY_ID, {
    fetchPolicy: 'cache-first',
    skip: !id,
    variables: {
      id,
    },
  });

  return {
    error,
    loading,
    refetch,
    agent: get(data, 'getAgentById', null),
  };
};

export const useAgentVersion = ({ skipCondition, id }) => {
  const { data, error, loading, refetch } = useQuery(GET_AGENT_VERSION, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
    variables: {
      id,
    },
  });

  return {
    error,
    loading,
    refetch,
    version: get(data, 'getAgentVersion', null),
  };
};

export const useMyAgents = () => {
  const { data, error, loading, refetch } = useQuery(GET_MY_AGENTS, {
    fetchPolicy: 'cache-first',
  });

  // Put bots first, then alphabetical
  const sortedAgents = sortBy(get(data, 'getMyAgents', []), [
    'productKey',
    'name',
  ]);

  return {
    error,
    loading,
    refetch,
    agents: sortedAgents,
  };
};

export const useAgents = () => {
  const { data, error, loading, refetch } = useQuery(GET_AGENTS, {
    fetchPolicy: 'cache-first',
  });

  const sortedAgents = sortBy(get(data, 'getAgents', []), ['name']);

  return {
    error,
    loading,
    refetch,
    agents: sortedAgents,
  };
};

export const useAgentVersions = ({ global }) => {
  const { data, error, loading, refetch } = useQuery(GET_AGENT_VERSIONS, {
    fetchPolicy: 'cache-first',
    variables: {
      global,
    },
  });

  // Put bots first, then alphabetical
  const agentVersions = sortBy(get(data, 'getAgentVersions', []), [
    'version',
  ]).reverse();

  return {
    error,
    loading,
    refetch,
    agentVersions,
  };
};
