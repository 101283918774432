import styled from 'styled-components';
import {
  CenterFlexStyle,
  FixedWidthCenteredDiv,
  SmallGapStyle,
  TextStyle,
} from '../../../styles/shared-styled-components';

export const VariableButtonWidth = 130;

export const VariablesInputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 8px;
`;

export const VariablesInputButton = styled.div`
  ${CenterFlexStyle}
  ${SmallGapStyle}
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border: 1px dotted ${({ theme }) => theme.color.muiInput};
  border-bottom: 0px;
  padding: 8px 10px;
  padding-bottom: 18px;
  border-radius: 8px 8px 0px 0px;
  margin-bottom: -1px;
  z-index: 1;
  ${TextStyle}
  color: ${({ theme }) => theme.color.primary};
  font-size: 13px;
  z-index: 10;
  width: ${VariableButtonWidth - 9}px;
`;

export const VariablesInputLabel = styled(FixedWidthCenteredDiv).attrs({
  width: VariableButtonWidth,
})``;
