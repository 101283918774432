import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import styled, { css } from 'styled-components';
import {
  DownArrow,
  UpArrow,
} from '../../../../components/Micro/ArrowIcon/styled';
import {
  AgentIcon,
  AutoHorizontalMargins,
  AutoScrollWrapper,
  BaseDrawerContainer,
  CancelIcon,
  CardStyle,
  CatalogIcon,
  CenteredDiv,
  CenterFlexStyle,
  CollapsedDrawerWidthValue,
  ColumnCenteredDiv,
  ColumnFlexStyle,
  CompleteButtonCheckmarkIcon,
  ContentContainer,
  EmptyGapColumnCenteredDiv,
  ExclamationIcon,
  ExpandedDrawerWidthValue,
  ExtraSmallGapStyle,
  ExtraSmallIconSize,
  FullWidthColumnCenteredDiv,
  LargeTinyText,
  MediumGapStyle,
  ModulesDrawerWidth,
  MoreMenuIcon,
  PolicyIcon,
  ServiceSubmodulesDrawerWidth,
  SmallGapStyle,
  SmallIconSize,
  SMGapStyle,
  SMIconSize,
  SMMGapStyle,
  SMMIconSize,
  StartAlignedMediumDarkLargeTinyText,
  StartAlignedMediumDarkTinyText,
  SubmodulesDrawerWidth,
  TinyText,
} from '../../../../styles/shared-styled-components';

export const ModulesContentContainer = styled(ContentContainer)`
  --left-offset: ${(props) =>
    props.drawerExpanded
      ? props.submoduleDrawerExpanded
        ? props.serviceSubmoduleDrawerExpanded
          ? `${
              ExpandedDrawerWidthValue +
              ModulesDrawerWidth +
              ServiceSubmodulesDrawerWidth -
              10
            }px`
          : `${
              ExpandedDrawerWidthValue +
              ModulesDrawerWidth +
              SubmodulesDrawerWidth -
              10
            }px`
        : `${ExpandedDrawerWidthValue + SubmodulesDrawerWidth}px`
      : props.drawerOpen
      ? props.submoduleDrawerExpanded
        ? props.serviceSubmoduleDrawerExpanded
          ? `${
              CollapsedDrawerWidthValue +
              ModulesDrawerWidth +
              ServiceSubmodulesDrawerWidth
            }px`
          : `${
              CollapsedDrawerWidthValue +
              ModulesDrawerWidth +
              SubmodulesDrawerWidth
            }px`
        : `${CollapsedDrawerWidthValue + ModulesDrawerWidth}px`
      : props.submoduleDrawerExpanded
      ? props.serviceSubmoduleDrawerExpanded
        ? `${ModulesDrawerWidth + ServiceSubmodulesDrawerWidth}px`
        : `${ModulesDrawerWidth + SubmodulesDrawerWidth + 100}px`
      : `${ModulesDrawerWidth + SubmodulesDrawerWidth}px`};
  margin: unset;
  position: fixed;
  width: calc(100% - var(--left-offset));
  ${MediumGapStyle}
  left: ${(props) =>
    props.drawerExpanded
      ? props.submoduleDrawerExpanded
        ? props.serviceSubmoduleDrawerExpanded
          ? `${
              ModulesDrawerWidth +
              ExpandedDrawerWidthValue +
              ServiceSubmodulesDrawerWidth
            }`
          : `${
              ModulesDrawerWidth +
              ExpandedDrawerWidthValue +
              SubmodulesDrawerWidth
            }`
        : `${ModulesDrawerWidth + ExpandedDrawerWidthValue}`
      : props.submoduleDrawerExpanded
      ? props.serviceSubmoduleDrawerExpanded
        ? `${
            ModulesDrawerWidth +
            CollapsedDrawerWidthValue +
            ServiceSubmodulesDrawerWidth
          }`
        : `${
            ModulesDrawerWidth +
            CollapsedDrawerWidthValue +
            SubmodulesDrawerWidth
          }`
      : `${ModulesDrawerWidth + CollapsedDrawerWidthValue}`}px !important;
`;

export const ModulesDrawer = styled(BaseDrawerContainer).attrs({
  removeScroll: true,
})`
  border-right: 1px dotted ${({ theme }) => theme.color.muiInput};
  left: ${(props) =>
    props.drawerExpanded
      ? `${ExpandedDrawerWidthValue + 2}px`
      : props.drawerOpen
      ? `${CollapsedDrawerWidthValue + 2}px`
      : '0px'};
  width: ${ModulesDrawerWidth}px;
  padding: 0px;
  height: calc(
    100dvh -
      ${({ theme }) =>
        theme.spacing.headerHeight + theme.spacing.headerVerticalPadding - 50}px
  );
`;

export const DrawerLoadingIndicatorContainer = styled.div`
  height: 100%;
  padding-top: 50%;
`;

export const SubmodulesDrawer = styled(ModulesDrawer)`
  left: ${(props) =>
    props.drawerExpanded
      ? `${ExpandedDrawerWidthValue + ModulesDrawerWidth + 5}px`
      : props.drawerOpen
      ? `${CollapsedDrawerWidthValue + ModulesDrawerWidth + 5}px`
      : `${ModulesDrawerWidth + 5}px`};
  width: ${`${SubmodulesDrawerWidth}px`};
`;

export const ServiceSubmodulesDrawer = styled(ModulesDrawer)`
  left: ${(props) =>
    props.drawerExpanded
      ? `${ExpandedDrawerWidthValue + ModulesDrawerWidth + 5}px`
      : props.drawerOpen
      ? `${CollapsedDrawerWidthValue + ModulesDrawerWidth + 5}px`
      : `${ModulesDrawerWidth + 5}px`};
  width: ${`${ServiceSubmodulesDrawerWidth}px`};
`;

export const ModulesDrawerHeaderContainer = styled(CenteredDiv)`
  gap: 0px;
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.color.muiInput};
  width: ${ModulesDrawerWidth - 16}px;
  margin-bottom: 5px;
  align-items: center;
  ${SMMGapStyle}
`;

export const ModuleContainer = styled(EmptyGapColumnCenteredDiv)`
  padding: 6px;
  padding-right: 9px;
  padding-bottom: 0px;
  border-bottom: 1px dotted ${({ theme }) => theme.color.muiInput};
  margin-top: ${(props) => props.top && `-5px`};
`;

export const ModuleListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  padding: 12px 6px;
`;

export const ModuleIconsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -5px;
`;

export const ModuleNameContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${SmallGapStyle}
  margin-top: -5px;
`;

export const ModuleNameText = styled(LargeTinyText)`
  color: ${(props) =>
    props.selected
      ? props.theme.color.primary
      : props.theme.color.mediumDarkText};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const ModuleStatusIconContainer = styled.div`
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.muiInputLightDarkGray};
  ${CenterFlexStyle}
  ${(props) => (props.large ? `${SmallIconSize}` : `${ExtraSmallIconSize}`)}
`;

export const CompleteModuleIcon = styled(CompleteButtonCheckmarkIcon)`
  color: ${({ theme }) => theme.color.brightGreen};
  height: ${(props) => (props.large ? '16px' : '12px')};
  width: ${(props) => (props.large ? '16px' : '12px')};
`;

export const IncompleteModuleIcon = styled(ExclamationIcon)`
  color: ${({ theme }) => theme.color.orange};
  height: ${(props) => (props.large ? '16px' : '12px')};
  width: ${(props) => (props.large ? '16px' : '12px')};
`;

export const NotApplicableModuleIcon = styled(CancelIcon)`
  color: ${({ theme }) => theme.color.superLightError} !important;
  height: 12px;
  width: 12px;
`;

const ModuleArrowStyle = css`
  ${SMMIconSize}
  color:${({ theme }) => theme.color.muiInput};
  &:hover {
    color: ${({ theme }) => theme.color.muiInputDarkGray};
  }
  margin-top: 3px;
  cursor: pointer;
`;

export const ModuleDownArrow = styled(DownArrow)`
  ${ModuleArrowStyle}
`;

export const ModuleUpArrow = styled(UpArrow)`
  ${ModuleArrowStyle}
`;

export const CategoryDownArrow = styled(DownArrow)`
  ${SmallIconSize}
  color:inherit;
  cursor: pointer;
`;

export const CategoryUpArrow = styled(UpArrow)`
  ${SmallIconSize}
  color:inherit;
  cursor: pointer;
`;

const ModuleIconStyle = css`
  color: ${({ theme }) => theme.color.lightPrimary};
  ${SMIconSize}
`;

export const LocationModuleIcon = styled(LocationOnIcon)`
  ${ModuleIconStyle}
  height: 24px;
  width: 24px;
`;

export const CatalogModuleIcon = styled(CatalogIcon)`
  ${ModuleIconStyle}
  height: 20px;
  width: 20px;
`;

export const PolicyModuleIcon = styled(PolicyIcon)`
  ${ModuleIconStyle}
  height: 21px;
  width: 21px;
`;

export const AgentSettingsModuleIcon = styled(AgentIcon)`
  ${ModuleIconStyle}
  ${SMMIconSize}
`;

export const ModulesCompletionStatusContainer = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.color.brightGreen};
  width: ${ModulesDrawerWidth - 20}px;
  z-index: 1001;
  cursor: pointer;
`;

export const SubmodulesContainer = styled(EmptyGapColumnCenteredDiv)`
  align-items: flex-end;
  width: 100%;
  padding-bottom: 10px;
  gap: 2px;
  cursor: pointer;
`;

export const SubmoduleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${SmallGapStyle}
  margin-right: 5px;
`;

export const SubmoduleText = styled(TinyText)`
  color: ${(props) =>
    props.selected
      ? props.theme.color.primary
      : props.theme.color.lightDarkText};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const SubmoduleCardScrollContainer = styled(AutoScrollWrapper)`
  width: ${(props) =>
    props.service
      ? `${ServiceSubmodulesDrawerWidth}px`
      : `${SubmodulesDrawerWidth}px`};
  ${SMGapStyle}
  ${ColumnFlexStyle}
`;

export const SubmodulesSection = styled(FullWidthColumnCenteredDiv)`
  margin-top: 4px;
`;

export const SubmoduleSection = styled(FullWidthColumnCenteredDiv)`
  gap: 2px;
`;

export const SubmoduleCardSectionContainer = styled(FullWidthColumnCenteredDiv)`
  gap: 2px;
  align-items: flex-start;
`;

const SubmodulePaddingStyle = css`
  padding: 14px 10px;
`;

const SubmoduleWidthStyle = css`
  width: calc(100% - 25px);
`;

export const SubmoduleSectionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  margin: 5px 10px;
  margin-bottom: 0px;
  ${SubmoduleWidthStyle}
`;

export const SubmoduleNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${SmallGapStyle}
`;

export const SubmoduleNameText = styled(StartAlignedMediumDarkLargeTinyText)`
  line-height: 1.2;
`;

export const SectionMenuIcon = styled(MoreMenuIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const SubmoduleCard = styled.div.attrs({
  addHoverShadow: true,
  lightBaseBoxShadow: true,
})`
  ${CardStyle}
  ${AutoHorizontalMargins}
  ${SubmodulePaddingStyle}
  margin: 5px 10px;
  display: flex;
  justify-content: space-between;
  width: calc(80% - 5px);
  cursor: pointer;
  position: relative;
  ${ExtraSmallGapStyle}
  ${(props) =>
    props.selected
      ? css`
          border: 1px solid ${({ theme }) => theme.color.superLightPrimary};
          box-shadow: ${`${props.theme.color.lightPrimaryRgba} 0px 3px 6px, ${props.theme.color.primaryHoverRgba} 0px -1px 3px`}};
        `
      : props.complete
      ? css`
          border: 1px solid ${({ theme }) => theme.color.lightGreen};
        `
      : ``}
`;

export const SubmoduleCardText = styled(StartAlignedMediumDarkTinyText)`
  line-height: 1.1;
`;

export const AddSubmoduleCardButtonContainer = styled.div`
  ${SubmoduleWidthStyle}
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AddSubmoduleCardButton = styled.div`
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px dotted ${({ theme }) => theme.color.muiInput};
  ${ExtraSmallGapStyle}
  padding: 1px 6px;
  padding-right: 10px;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.color.lightGreen};
    border-style: solid;
  }
`;

export const AnswerSection = styled(ColumnCenteredDiv)`
  height: ${(props) => props.remainingHeight - 80}px;
`;

export const AnswerTextContainer = styled(CenteredDiv)`
  margin-top: ${(props) => props.topAligned && `-700px`};
`;

export const SoonIcon = styled(HourglassTopIcon)`
  color: ${({ theme }) => theme.color.lightBlue};
  height: 14px;
  width: 14px;
`;
