import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import styled, { css } from 'styled-components';
import {
  ColumnFlexStyle,
  ExtraLargeGapStyle,
  MediumIconSize,
  SmallGapStyle,
  SmallerTextInputWidthStyle,
} from '../../styles/shared-styled-components';

export const RangeFilterContainer = styled.div`
  display: flex;
  ${SmallerTextInputWidthStyle}
  ${(props) =>
    props.center
      ? css`
          justify-content: space-between;
          ${ExtraLargeGapStyle}
        `
      : css`
          justify-content: space-between;
        `}  
  padding: 0px 15px;
  margin-top: 5px;
  width: ${(props) => (props.useFullWidth ? '100%' : '70%')};
`;

export const FilterContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: ${(props) => (props.end ? 'flex-end' : 'flex-start')};
  flex: 1;
`;

export const FilterTextContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.end ? 'flex-end' : 'flex-start')};
  align-items: center;
  ${SmallGapStyle}
`;

export const ArrowIcon = styled(TrendingFlatIcon)`
  ${MediumIconSize}
  color: ${({ theme }) => theme.color.lightPrimary};
`;
