import { useApolloClient, useMutation } from '@apollo/client';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import { useAllEnterprises } from '../../../api/hooks/enterprise';
import {
  EDIT_BUSINESS_USER_LOCATION_VIEW,
  MODIFY_LOCATION_VALIDITY,
} from '../../../api/mutations/users';
import {
  CenteredDivWithGap,
  ColumnCenteredDiv,
  MLText,
  MediumPrimaryButton,
  MenuItem,
  Modal,
  ModalContainer,
  ModalInputsContainerScrollWrapper,
  MoreMenuIcon,
  SmallMenuItemText,
} from '../../../styles/shared-styled-components';
import {
  chatsSearchParamsCookieKey,
  greaterThanKey,
  monitoringAllActionKey,
  numUserMessagesField,
  replaceActionKey,
  singleLocationBusinessType,
  superAdminRole,
} from '../../../utils/constants';
import { getCookieExpiryObject } from '../../../utils/date';
import { baseChatSearchParams } from '../../../utils/filters';
import { removeAllLocationSpecificCookies } from '../../../utils/routing';
import { getUserLocationId, locationIdSelectedKey } from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import PopperMenu from '../../PopperMenu';
import Snackbar from '../../Snackbar';
import {
  BusinessNameText,
  CreateButtonContainer,
  EnterpriseContainer,
  EnterprisesContainer,
} from './styled';

const cookieExpiryObject = getCookieExpiryObject();

const SelectLocationModal = ({ isOpen, onClose }) => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const { user, refetchUser, setCookie, removeCookie } =
    useContext(BaseContext);

  const {
    enterprises,
    loading: enterprisesLoading,
    refetch,
  } = useAllEnterprises({
    skipCondition: !isOpen,
  });
  const [editBusinessUserLocationViewMutation] = useMutation(
    EDIT_BUSINESS_USER_LOCATION_VIEW,
  );
  const [modifyLocationValidityMutation] = useMutation(
    MODIFY_LOCATION_VALIDITY,
  );

  const [locationIdToEdit, setLocationIdToEdit] = useState();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const menuOpenBoolean = Boolean(menuAnchorEl);

  const role = user?.role;
  const isSuperAdmin = role === superAdminRole;
  const locationId = getUserLocationId(user);

  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const onSelectLocation = (locationId, isMonitoring = false) => {
    removeAllLocationSpecificCookies(removeCookie);
    handleClose();

    editBusinessUserLocationViewMutation({
      variables: {
        locationId,
        action: isMonitoring ? monitoringAllActionKey : replaceActionKey,
      },
      onCompleted: async (data) => {
        const success = data.editBusinessUserLocationView;

        if (success === true) {
          if (!isMonitoring) {
            setSnackbarMessage(`Location updated`);
          }

          await refetchUser();
          isMonitoring
            ? localStorage.setItem(
                locationIdSelectedKey,
                monitoringAllActionKey,
              )
            : localStorage.setItem(locationIdSelectedKey, locationId);

          await apolloClient.clearStore();

          if (isMonitoring) {
            let params = { ...baseChatSearchParams };
            params[numUserMessagesField] = [
              {
                key: numUserMessagesField,
                operator: greaterThanKey,
                value: 0,
              },
            ];
            setCookie(chatsSearchParamsCookieKey, params, cookieExpiryObject);
            navigate(Paths.chats);
          } else {
            navigate(Paths.callback);
          }
        } else {
          setSnackbarMessage('Error modifying location, refresh and try again');
        }
      },
    });
  };

  const onModifyValidity = (locationId, isValid) => {
    modifyLocationValidityMutation({
      variables: {
        locationId,
        isValid,
      },
      onCompleted: async (data) => {
        const success = data.modifyLocationValidity?.success;

        if (success === true) {
          await refetch();
          setSnackbarMessage(`Location updated`);
        } else {
          setSnackbarMessage('Error modifying location, refresh and try again');
        }
        setLocationIdToEdit();
        setMenuAnchorEl();
      },
    });
  };

  const isLoading = enterprisesLoading;

  return (
    isOpen &&
    !isLoading && (
      <>
        <Modal
          open={isOpen}
          onClose={handleClose}
          maxWidth={830}
        >
          <ModalContainer>
            <MLText>Select Location</MLText>
            <ModalInputsContainerScrollWrapper>
              <EnterprisesContainer>
                {isSuperAdmin && (
                  <EnterpriseContainer>
                    <BusinessNameText
                      special
                      clickable
                      onClick={() => onSelectLocation('', true)}
                    >
                      Monitor All Chats
                    </BusinessNameText>
                  </EnterpriseContainer>
                )}
                {enterprises?.map((e) => {
                  const { business, locations } = e;
                  const type = business?.type;
                  const businessName = business?.name;
                  const businessLocationId = get(locations, '[0].id');
                  const locationName = get(locations, '[0].nickname');
                  const isCurrent = businessLocationId === locationId;
                  return (
                    <EnterpriseContainer>
                      {type === singleLocationBusinessType ? (
                        <>
                          {isCurrent ? (
                            <BusinessNameText disabled>
                              {locationName} (Current)
                            </BusinessNameText>
                          ) : (
                            <BusinessNameText
                              clickable
                              onClick={() =>
                                onSelectLocation(businessLocationId)
                              }
                            >
                              {locationName}
                            </BusinessNameText>
                          )}
                          {isSuperAdmin && (
                            <MoreMenuIcon
                              onClick={(e) => {
                                setLocationIdToEdit(businessLocationId);
                                setMenuAnchorEl(e.currentTarget);
                              }}
                            />
                          )}
                        </>
                      ) : locations.length ? (
                        <ColumnCenteredDiv>
                          {locations.map((l) => {
                            const isCurrentLocation = locationId === l.id;
                            return (
                              <CenteredDivWithGap>
                                <BusinessNameText
                                  clickable={!isCurrentLocation}
                                  disabled={isCurrentLocation}
                                  onClick={() => {
                                    if (!isCurrentLocation) {
                                      onSelectLocation(l.id);
                                    }
                                  }}
                                >
                                  {businessName} - {l.nickname}
                                  {isCurrentLocation && ' (Current)'}
                                </BusinessNameText>
                                {isSuperAdmin && (
                                  <MoreMenuIcon
                                    onClick={(e) => {
                                      setLocationIdToEdit(l.id);
                                      setMenuAnchorEl(e.currentTarget);
                                    }}
                                  />
                                )}
                              </CenteredDivWithGap>
                            );
                          })}
                        </ColumnCenteredDiv>
                      ) : (
                        <>
                          {isCurrent ? (
                            <BusinessNameText disabled>
                              {businessName} (Current)
                            </BusinessNameText>
                          ) : (
                            <BusinessNameText
                              clickable
                              onClick={() =>
                                onSelectLocation(businessLocationId)
                              }
                            >
                              {businessName}
                            </BusinessNameText>
                          )}
                          {isSuperAdmin && (
                            <MoreMenuIcon
                              onClick={(e) => {
                                setLocationIdToEdit(businessLocationId);
                                setMenuAnchorEl(e.currentTarget);
                                console.log(
                                  'businessLocationId',
                                  businessLocationId,
                                );
                              }}
                            />
                          )}
                        </>
                      )}
                    </EnterpriseContainer>
                  );
                })}
              </EnterprisesContainer>
            </ModalInputsContainerScrollWrapper>
            {isSuperAdmin && (
              <CreateButtonContainer>
                <MediumPrimaryButton
                  onClick={() => {
                    navigate(Paths.createBusiness);
                  }}
                >
                  Create
                </MediumPrimaryButton>
              </CreateButtonContainer>
            )}
          </ModalContainer>
        </Modal>
        <PopperMenu
          open={menuOpenBoolean}
          anchorElement={menuAnchorEl}
          onClose={() => {
            setMenuAnchorEl();
            setLocationIdToEdit();
          }}
          variant='offset'
        >
          <MenuItem
            disableRipple
            onClick={() => {
              onModifyValidity(locationIdToEdit, false);
            }}
          >
            <SmallMenuItemText>Mark as invalid</SmallMenuItemText>
          </MenuItem>
        </PopperMenu>
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          quick
        />
      </>
    )
  );
};

export default SelectLocationModal;
