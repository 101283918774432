import React from 'react';
import {
  CenteredDiv,
  ColumnCenteredDiv,
  KeyIcon,
  MediumDarkMediumText,
  Modal,
  ModalActions,
  ModalContainer,
  ModalTitleText,
} from '../../../styles/shared-styled-components';
import LoginButton from '../../Auth/LoginButton';
import SignupButton from '../../Auth/SignupButton';

const AuthenticationPromptModal = ({ isOpen, onClose, actionText }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <CenteredDiv>
          <KeyIcon />
        </CenteredDiv>
        <ModalTitleText>Must Be Logged&nbsp;In</ModalTitleText>
        <MediumDarkMediumText>
          Sign up or log in to {actionText}.
        </MediumDarkMediumText>
        <ModalActions>
          <ColumnCenteredDiv>
            <SignupButton size='medium' />
            <LoginButton
              size='medium'
              variant={'secondary'}
            />
          </ColumnCenteredDiv>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default AuthenticationPromptModal;
