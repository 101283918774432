import './font.css';

export const interRegular = {
  fontFamily: 'SeldonFont',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
};

export default interRegular;
