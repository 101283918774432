import React, { useState } from 'react';
import {
  MenuDownArrow,
  MenuItem,
  MenuItemCheckmark,
  MenuUpArrow,
  SmallMenuItemText,
} from '../../../styles/shared-styled-components';
import PopperMenu from '../../PopperMenu';
import { FilterContainer, FilterText } from '../shared-analytics-styles';

const OptionsFilterDropdown = ({
  filtered,
  options,
  label,
  onChange,
  isMultiSelect,
  leftIconDisplay = true,
  fixedWidth,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState();

  const menuOpenBoolean = Boolean(menuAnchorEl);

  const numFiltered = filtered?.length;
  const allSelected = numFiltered === options?.length;
  const displayedLabel = isMultiSelect
    ? allSelected
      ? `All ${label}`
      : `${label} (${numFiltered})`
    : `${label || 'None selected'}`;

  return (
    <>
      <FilterContainer
        clickable={true}
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        fixedWidth={fixedWidth}
      >
        <FilterText>
          {leftIconDisplay ? (
            <>
              {menuOpenBoolean ? <MenuUpArrow /> : <MenuDownArrow />}
              {displayedLabel}
            </>
          ) : (
            <>
              {displayedLabel}
              {menuOpenBoolean ? <MenuUpArrow /> : <MenuDownArrow />}
            </>
          )}
        </FilterText>
      </FilterContainer>
      <PopperMenu
        open={menuOpenBoolean}
        anchorElement={menuAnchorEl}
        onClose={() => setMenuAnchorEl()}
        variant='offset'
      >
        {options.map((o) => {
          const { key, label } = o;
          const selected = !!filtered.find(
            (filteredValue) => filteredValue === key,
          );
          return (
            <MenuItem
              disableRipple
              onClick={() => onChange(key, !selected)}
            >
              <SmallMenuItemText>{label}</SmallMenuItemText>
              {selected && <MenuItemCheckmark />}
            </MenuItem>
          );
        })}
      </PopperMenu>
    </>
  );
};

export default OptionsFilterDropdown;
