import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Paths from '../../../Paths';
import {
  ChangesUnsavedIcon,
  CompleteIcon,
  ErrorSavingIcon,
  FullHeightCenteredDiv,
  HorizontalSectionMenuDivider,
  MenuItem,
  ModelTrainingIcon,
  ModelTrainingIconContainer,
  SMMenuItemText,
  SyncInProgressIcon,
  Tooltip,
  TooltipTitleText,
  UninitializedIcon,
} from '../../../styles/shared-styled-components';
import { handleLogin, handleSignup } from '../../../utils/auth';
import {
  appModeCookieKey,
  completeStatusKey,
  demoAppModeKey,
  inProgressStatusKey,
  incompleteStatusKey,
  liveAppModeKey,
  superAdminRole,
  uninitializedStatusKey,
  upToDateStatusKey,
} from '../../../utils/constants';
import { getCookieExpiryObject, getTimestamp } from '../../../utils/date';
import { getFormattedFirstName } from '../../../utils/name';
import {
  checkIfPathIsUserOnboarding,
  getAgentAppConnectionUrl,
} from '../../../utils/routing';
import {
  getUserLiveIQAgentId,
  getUserLocationTimezone,
} from '../../../utils/user';
import { screenWidthIsMediumMobileSizeOrSmaller } from '../../../utils/view';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import DataSyncStatusModal from '../../Modals/DataSyncStatusModal';
import PopperMenu from '../../PopperMenu';
import {
  ConnectionsIconInDropdown,
  DemoIconInDropdown,
  ProfileIconInDropdown,
  ProfileIconInHeader,
  ProfileLogoutIcon,
  ProfileMenuButton,
  SettingsIconInDropdown,
  TermsIconInDropdown,
} from './styled';

const profileReady = false;

const getEmbeddingsSyncComponent = (
  embeddingsSyncStatus,
  timezone,
  onClick,
) => {
  const syncStatus = embeddingsSyncStatus?.status;
  const syncTimestamp = embeddingsSyncStatus?.timestamp;
  if (!syncStatus) {
    return null;
  }

  if (syncStatus === uninitializedStatusKey) {
    return (
      <Tooltip
        placement='bottom'
        title={
          <TooltipTitleText>
            Agent not yet trained on your knowledge base - click to begin
            training
          </TooltipTitleText>
        }
      >
        <ModelTrainingIconContainer onClick={() => onClick()}>
          <ModelTrainingIcon />
          <UninitializedIcon />
        </ModelTrainingIconContainer>
      </Tooltip>
    );
  }

  if (syncStatus === upToDateStatusKey) {
    const timestamp = getTimestamp(syncTimestamp, timezone, false, false, true);

    return (
      <Tooltip
        placement='bottom'
        title={
          <TooltipTitleText>
            Your agent is up-to-date with all changes (last synced {timestamp})
          </TooltipTitleText>
        }
      >
        <ModelTrainingIconContainer onClick={() => onClick()}>
          <ModelTrainingIcon />
          <CompleteIcon />
        </ModelTrainingIconContainer>
      </Tooltip>
    );
  }

  if (syncStatus === incompleteStatusKey) {
    const timestamp = getTimestamp(syncTimestamp, timezone, false, false, true);

    return (
      <Tooltip
        placement='bottom'
        title={
          <TooltipTitleText>
            Agent failed to train, last synced {timestamp} - please notify
            customer support
          </TooltipTitleText>
        }
      >
        <ModelTrainingIconContainer onClick={() => onClick()}>
          <ModelTrainingIcon />
          <ErrorSavingIcon />
        </ModelTrainingIconContainer>
      </Tooltip>
    );
  }

  if (syncStatus === inProgressStatusKey) {
    const timestamp = getTimestamp(syncTimestamp, timezone, false, false, true);

    return (
      <Tooltip
        placement='bottom'
        title={
          <TooltipTitleText>
            Agent training in progress (started {timestamp}), should take under
            5 minutes - click to refresh status
          </TooltipTitleText>
        }
      >
        <ModelTrainingIconContainer onClick={() => onClick()}>
          <ModelTrainingIcon />
          <SyncInProgressIcon />
        </ModelTrainingIconContainer>
      </Tooltip>
    );
  }

  if (syncStatus === completeStatusKey) {
    const timestamp = getTimestamp(
      syncTimestamp,
      timezone,
      false,
      false,
      false,
    );

    return (
      <Tooltip
        placement='bottom'
        title={
          <TooltipTitleText>
            New changes made since agent last trained ({timestamp}) - click to
            sync
          </TooltipTitleText>
        }
      >
        <ModelTrainingIconContainer onClick={() => onClick()}>
          <ModelTrainingIcon />
          <ChangesUnsavedIcon />
        </ModelTrainingIconContainer>
      </Tooltip>
    );
  }
};

const cookieExpiryObject = getCookieExpiryObject();

const ProfileDropdown = ({ embeddingsSyncStatus, onClickEmbeddingsSync }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { loginWithRedirect } = useAuth0();
  const { inDemoMode, user, width, setCookie } = useContext(BaseContext);
  const [searchParams] = useSearchParams();

  const agentId = getUserLiveIQAgentId(user);

  const [syncModalIsOpen, setSyncModalIsOpen] = useState(false);

  const isSuperAdmin = user?.role === superAdminRole;
  const isMobileScreen = screenWidthIsMediumMobileSizeOrSmaller(width);
  const formattedIsMobileScreen = isMobileScreen || !user;
  const nameCharThreshold = formattedIsMobileScreen ? 6 : 15;

  const pathIsOnboardingPage = checkIfPathIsUserOnboarding(pathname);

  let formattedFirstName = `${getFormattedFirstName(
    user?.firstName || user?.email,
  )}`;
  formattedFirstName =
    formattedFirstName?.length > nameCharThreshold
      ? `${formattedFirstName.slice(0, nameCharThreshold)}...`
      : formattedFirstName;

  const timezone = getUserLocationTimezone(user);
  const EmbeddingsSyncComponent = getEmbeddingsSyncComponent(
    embeddingsSyncStatus,
    timezone,
    onClickEmbeddingsSync,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickProfile = () => {
    if (onProfilePage) {
      handleClose();
    } else {
      navigate(Paths.profile);
    }
  };

  const onLoginOrSignup = () => {
    const utmSource = searchParams.get('utm');

    if (utmSource) {
      localStorage.setItem('utm', utmSource);
    }
  };

  const onSignup = () => {
    handleSignup({ onClick: onLoginOrSignup, pathname, loginWithRedirect });
  };

  const onLogin = () => {
    handleLogin({ onClick: onLoginOrSignup, pathname, loginWithRedirect });
  };

  const onChangeAppMode = (newAppMode) => {
    setCookie(appModeCookieKey, newAppMode, cookieExpiryObject);
    handleClose();
    window.location.reload();
  };

  const onClickLogout = () => {
    navigate(Paths.logout);
  };

  const onProfilePage = pathname === Paths.profile;

  return (
    <>
      {EmbeddingsSyncComponent && (
        <FullHeightCenteredDiv>{EmbeddingsSyncComponent}</FullHeightCenteredDiv>
      )}
      <ProfileMenuButton
        disableElevation
        // Have to spell it in lowercase so React doesn't confuse it for a built-in prop
        disablepadding
        onClick={handleProfileClick}
        startIcon={
          <ProfileIconInHeader isMobileScreen={formattedIsMobileScreen} />
        }
        isMobileScreen={formattedIsMobileScreen}
      >
        {formattedFirstName}
      </ProfileMenuButton>
      <PopperMenu
        open={open}
        anchorElement={anchorEl}
        onClose={handleClose}
        variant='offset'
        fixedWidth={!isMobileScreen && user && 250}
      >
        {user ? (
          <>
            {!pathIsOnboardingPage && profileReady && (
              <>
                <MenuItem
                  onClick={onClickProfile}
                  disableRipple
                >
                  <ProfileIconInDropdown />
                  <SMMenuItemText>Profile</SMMenuItemText>
                </MenuItem>
                <HorizontalSectionMenuDivider />
              </>
            )}
            {agentId && (
              <>
                <MenuItem
                  onClick={() => navigate(getAgentAppConnectionUrl(agentId))}
                  disableRipple
                >
                  <ConnectionsIconInDropdown />
                  <SMMenuItemText>Connections</SMMenuItemText>
                </MenuItem>
                <HorizontalSectionMenuDivider />
              </>
            )}
            <MenuItem
              onClick={() => navigate(Paths.terms)}
              disableRipple
            >
              <TermsIconInDropdown />
              <SMMenuItemText>Terms of Use</SMMenuItemText>
            </MenuItem>
            <HorizontalSectionMenuDivider />
            {isSuperAdmin && (
              <>
                <MenuItem
                  onClick={() =>
                    onChangeAppMode(
                      inDemoMode ? liveAppModeKey : demoAppModeKey,
                    )
                  }
                  disableRipple
                >
                  <DemoIconInDropdown />
                  <SMMenuItemText>
                    {inDemoMode ? `Leave demo mode` : `Enter demo mode`}
                  </SMMenuItemText>
                </MenuItem>
                <HorizontalSectionMenuDivider />
              </>
            )}
            <MenuItem
              onClick={() => navigate(Paths.settings)}
              disableRipple
            >
              <SettingsIconInDropdown />
              <SMMenuItemText>Settings</SMMenuItemText>
            </MenuItem>
            <HorizontalSectionMenuDivider />
            <MenuItem
              onClick={onClickLogout}
              disableRipple
            >
              <ProfileLogoutIcon />
              <SMMenuItemText>Logout</SMMenuItemText>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={onSignup}
              disableRipple
            >
              <SMMenuItemText>Sign up</SMMenuItemText>
            </MenuItem>
            <MenuItem
              onClick={onLogin}
              disableRipple
            >
              <SMMenuItemText>Login</SMMenuItemText>
            </MenuItem>
          </>
        )}
      </PopperMenu>
      <DataSyncStatusModal
        isOpen={syncModalIsOpen}
        onClose={() => setSyncModalIsOpen(false)}
      />
    </>
  );
};

export default ProfileDropdown;
