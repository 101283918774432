import { sortBy } from 'lodash';
import {
  interStringSeparatorChar,
  intraStringSeparatorChar,
  liveiqSourceKey,
  requiresConsultationBookableSessionTypeStatus,
} from './constants';

export const formatStringWithLineBreaks = (str) => {
  const formatted = str.toString();

  const stringWithLineBreaks = formatted.split('&nbsp;');

  return stringWithLineBreaks;
};

export const stripAllSpacesFromString = (str) => {
  if (!str) {
    return '';
  }

  return str.replace(/\s/g, '');
};

export const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const getFileTypeFromUrl = (url) => {
  // Use a regular expression to match the file extension
  const match = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);

  // Return the file extension if a match is found, otherwise return 'unknown'
  return match ? match[1] : 'unknown';
};

export const getSortedAgentVersionNames = (names) => {
  return sortBy(names, (n) => n?.split(':'));
};

export const getCampaignTagToMatch = (campaignId) => {
  return campaignId
    ? `${liveiqSourceKey}${interStringSeparatorChar}${campaignId.slice(0, 8)}`
    : '';
};

export const getBookableStatusKey = (status, consultationId) => {
  return `${status}${
    status === requiresConsultationBookableSessionTypeStatus && consultationId
      ? `${intraStringSeparatorChar}${consultationId}`
      : ''
  }`;
};

export const getStringWithCapitalizedFirstLetter = (str) => {
  return str?.replace(str[0], str[0].toUpperCase());
};

export const formatKeyToLabel = (key) => {
  if (!key) {
    return '';
  }

  return key
    .split('_') // Split by underscores
    .map((word) => getStringWithCapitalizedFirstLetter(word)) // Capitalize each word
    .join(' '); // Join the words with spaces
};

export const parseCookieValueString = (baseValue) => {
  if (Array.isArray(baseValue)) {
    return baseValue.join(intraStringSeparatorChar);
  }
  return baseValue?.split(intraStringSeparatorChar) || [];
};

export default {
  formatStringWithLineBreaks,
  stripAllSpacesFromString,
};
