import styled from 'styled-components';
import { SMGapStyle, XIcon } from '../../../styles/shared-styled-components';
import {
  homepageMobilePixelDistance,
  homepagePixelDistance,
} from '../../../utils/constants';
import { FiveMinuteSetupSubText } from './style-FiveMinuteSetup';
import { HomepagePageContainer } from './style-Homepage';

export const PricingPageContainer = styled(HomepagePageContainer)`
  margin-top: ${homepagePixelDistance}px;

  @media (max-width: 1024px) {
    margin-top: ${homepageMobilePixelDistance};
  }
`;

export const PricingHeaderText = styled(FiveMinuteSetupSubText)`
  letter-spacing: 5px;
  margin: 0 auto;
  font-size: calc(20px + 0.4vw);
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};

  transition: all 0.3s;
  :hover {
    color: ${(props) =>
      props.dark ? ({ theme }) => theme.color.charcoal : 'black'};
  }
`;

export const TierPricingCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const TierPricingCard = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.charcoal
      : ({ theme }) => theme.color.lightGray};
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.08);

  transition: transform 0.3s ease, box-shadow 0.3s ease;

  ${SMGapStyle}

  @media (max-width: 1024px) {
    width: 270px;
    background-position: center 240px;
  }

  @media (max-width: 576px) {
    width: 240px;
    background-position: center 220px;
  }

  :hover {
    cursor: pointer;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  }
`;

// Second card with a 300px horizontal offset
export const TierPricingCard2 = styled(TierPricingCard)`
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http://www.w3.org/2000/svg%22 viewBox%3D%220%200%201440%20320%22%3E%3Cpath fill%3D%22%237161a0%22 fill-opacity%3D%220.8%22 d%3D%22M0%2C320L15%2C293.3C30%2C267%2C60%2C213%2C90%2C170.7C120%2C128%2C150%2C96%2C180%2C80C210%2C64%2C240%2C64%2C270%2C64C300%2C64%2C330%2C64%2C360%2C69.3C390%2C75%2C420%2C85%2C450%2C106.7C480%2C128%2C510%2C160%2C540%2C165.3C570%2C171%2C600%2C149%2C630%2C144C660%2C139%2C690%2C149%2C720%2C154.7C750%2C160%2C780%2C160%2C810%2C144C840%2C128%2C870%2C96%2C900%2C106.7C930%2C117%2C960%2C171%2C990%2C170.7C1020%2C171%2C1050%2C117%2C1080%2C85.3C1110%2C53%2C1140%2C43%2C1170%2C74.7C1200%2C107%2C1230%2C181%2C1260%2C202.7C1290%2C224%2C1320%2C192%2C1350%2C165.3C1380%2C139%2C1410%2C117%2C1425%2C106.7L1440%2C96L1440%2C320L1425%2C320C1410%2C320%2C1380%2C320%2C1350%2C320C1320%2C320%2C1290%2C320%2C1260%2C320C1230%2C320%2C1200%2C320%2C1170%2C320C1140%2C320%2C1110%2C320%2C1080%2C320C1050%2C320%2C1020%2C320%2C990%2C320C960%2C320%2C930%2C320%2C900%2C320C870%2C320%2C840%2C320%2C810%2C320C780%2C320%2C750%2C320%2C720%2C320C690%2C320%2C660%2C320%2C630%2C320C600%2C320%2C570%2C320%2C540%2C320C510%2C320%2C480%2C320%2C450%2C320C420%2C320%2C390%2C320%2C360%2C320C330%2C320%2C300%2C320%2C270%2C320C240%2C320%2C210%2C320%2C180%2C320C150%2C320%2C120%2C320%2C90%2C320C60%2C320%2C30%2C320%2C15%2C320L0%2C320Z%22%3E%3C/path%3E%3C/svg%3E');
  background-position: 300px left; /* Move 300px to the left */
  background-position: center 250px;
`;

// Third card with a 600px horizontal offset
export const TierPricingCard3 = styled(TierPricingCard)`
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http://www.w3.org/2000/svg%22 viewBox%3D%220%200%201440%20320%22%3E%3Cpath fill%3D%22%237161a0%22 fill-opacity%3D%220.8%22 d%3D%22M0%2C320L15%2C293.3C30%2C267%2C60%2C213%2C90%2C170.7C120%2C128%2C150%2C96%2C180%2C80C210%2C64%2C240%2C64%2C270%2C64C300%2C64%2C330%2C64%2C360%2C69.3C390%2C75%2C420%2C85%2C450%2C106.7C480%2C128%2C510%2C160%2C540%2C165.3C570%2C171%2C600%2C149%2C630%2C144C660%2C139%2C690%2C149%2C720%2C154.7C750%2C160%2C780%2C160%2C810%2C144C840%2C128%2C870%2C96%2C900%2C106.7C930%2C117%2C960%2C171%2C990%2C170.7C1020%2C171%2C1050%2C117%2C1080%2C85.3C1110%2C53%2C1140%2C43%2C1170%2C74.7C1200%2C107%2C1230%2C181%2C1260%2C202.7C1290%2C224%2C1320%2C192%2C1350%2C165.3C1380%2C139%2C1410%2C117%2C1425%2C106.7L1440%2C96L1440%2C320L1425%2C320C1410%2C320%2C1380%2C320%2C1350%2C320C1320%2C320%2C1290%2C320%2C1260%2C320C1230%2C320%2C1200%2C320%2C1170%2C320C1140%2C320%2C1110%2C320%2C1080%2C320C1050%2C320%2C1020%2C320%2C990%2C320C960%2C320%2C930%2C320%2C900%2C320C870%2C320%2C840%2C320%2C810%2C320C780%2C320%2C750%2C320%2C720%2C320C690%2C320%2C660%2C320%2C630%2C320C600%2C320%2C570%2C320%2C540%2C320C510%2C320%2C480%2C320%2C450%2C320C420%2C320%2C390%2C320%2C360%2C320C330%2C320%2C300%2C320%2C270%2C320C240%2C320%2C210%2C320%2C180%2C320C150%2C320%2C120%2C320%2C90%2C320C60%2C320%2C30%2C320%2C15%2C320L0%2C320Z%22%3E%3C/path%3E%3C/svg%3E');
  background-position: 0 left; /* Move 600px to the left */
  background-position: center 230px;
`;

export const TierPricingName = styled.span`
  font-size: ${({ theme }) => theme.typography.mediumSize};
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.gold
      : ({ theme }) => theme.color.darkGold};
  margin-bottom: 15px;
`;
export const TierPerkDescription = styled.span`
  font-size: ${({ theme }) => theme.typography.extraSmallSize};
  text-align: center;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
  margin-bottom: 15px;
`;

export const TierPriceTag = styled.div`
  display: inline-block;
  vertical-align: top;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.gold
      : ({ theme }) => theme.color.darkGold};
  font-weight: bold;
  font-weight: 600;
  letter-spacing: -0.025em;
  background: linear-gradient(
    155deg,
    ${(props) =>
      props.dark
        ? ({ theme }) => theme.color.defaultGray
        : ({ theme }) => theme.color.charcoal},
    ${({ theme }) => theme.color.primary} 50%,
    ${(props) =>
      props.dark
        ? ({ theme }) => theme.color.defaultGray
        : ({ theme }) => theme.color.charcoal}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.15;
  pointer-events: none;
`;

export const TierPriceTagTime = styled(TierPriceTag)`
  font-size: 20px;
`;

export const TierPerks = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
  li {
    list-style-type: none;
  }
`;

export const TierPerk = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 20px;
  font-size: ${({ theme }) => theme.typography.extraSmallSize};
`;

export const TierBenefitXIcon = styled(XIcon)`
  max-width: ${({ theme }) => theme.typography.extraSmallSize};
`;
