import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { GET_MINDBODY_SITE_ACTIVATION_CODE_URL } from '../../../api/queries/app-connections';
import {
  CenteredDiv,
  ColumnCenteredDiv,
  LinkTextSpan,
  MindBodyLogo,
  Modal,
  ModalActions,
  ModalContainer,
  ModalParagraphText,
  ModalTitleText,
  PrimaryButton,
} from '../../../styles/shared-styled-components';
import Input from '../../Form/Input/Input';
import LoadingIndicator from '../../LoadingIndicator';

const MindBodySiteIDModal = ({ isOpen, onClose, onSuccess }) => {
  const [
    getMindBodySiteActivationCodeUrl,
    { data, loading: getActivationCodeUrlLoading },
  ] = useLazyQuery(GET_MINDBODY_SITE_ACTIVATION_CODE_URL, {
    fetchPolicy: 'network-only',
  });

  const [siteId, setSiteId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (data) {
      const url = data.getMindBodySiteActivationCodeUrl;
      if (!url) {
        setErrorMessage('Invalid Site ID entered, try again.');
      } else {
        onSuccess(url);
      }
    }
  }, [data]);

  const onEnterSiteId = () => {
    getMindBodySiteActivationCodeUrl({
      variables: {
        siteId,
        cacheBreaker: uuidv4(),
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <CenteredDiv>
          <MindBodyLogo />
        </CenteredDiv>
        <ModalTitleText>Enter Your MindBody Site&nbsp;ID</ModalTitleText>
        <ModalParagraphText>
          Follow{' '}
          <LinkTextSpan
            href={
              'https://support.mindbodyonline.com/s/article/206398178-How-do-I-find-my-Client-ID'
            }
            target='_blank'
          >
            these instructions
          </LinkTextSpan>{' '}
          to find your Site ID in MindBody, then we'll give you an activation
          URL.
        </ModalParagraphText>
        <Input
          id='location'
          value={siteId}
          onChange={(e) => {
            setSiteId(e.target.value);
            if (errorMessage) {
              setErrorMessage('');
            }
          }}
          label='Site ID'
          isRequired={false}
          removeGap={true}
          useSmallWidth={true}
          errorMessage={!getActivationCodeUrlLoading && errorMessage}
        />
        <ModalActions>
          <ColumnCenteredDiv>
            {getActivationCodeUrlLoading ? (
              <LoadingIndicator />
            ) : (
              <PrimaryButton
                disabled={!siteId}
                onClick={onEnterSiteId}
              >
                Get activation URL
              </PrimaryButton>
            )}
          </ColumnCenteredDiv>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default MindBodySiteIDModal;
