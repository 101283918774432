import styled from 'styled-components';
import {
  CardStyle,
  ColumnCenteredDiv,
  MLText,
} from '../../../styles/shared-styled-components';

export const ContactContainer = styled(ColumnCenteredDiv)`
  ${CardStyle}
  box-shadow: none;
  border: 3px solid ${({ theme }) => theme.color.primary};
  min-height: 200px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: white;
  max-width: 1000px;
`;

export const ContactText = styled(MLText)`
  width: 80%;
  color: ${({ theme }) => theme.color.mediumDarkText};
`;
