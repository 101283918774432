import React, { useContext, useState } from 'react';
import { useAgentVersion } from '../../../../api/hooks/agent';
import {
  CenteredDiv,
  DarkGoldText,
  LightDarkEssText,
  MediumDarkExtraSmallText,
  MediumDarkSmallText,
  MediumText,
  SmallEditIcon,
  SmallEditIconContainer,
  SmallSaveIcon,
  SmallSaveIconContainer,
} from '../../../../styles/shared-styled-components';
import { textSeparatorChar } from '../../../../utils/constants';
import { formatPhoneNumber } from '../../../../utils/numbers';
import { getUserLocation } from '../../../../utils/user';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  PromptContainer,
  PromptInput,
  PromptsSection,
} from '../../shared-training-components';
import {
  AgentAvatar,
  AgentAvatarContainer,
  AgentNameContainer,
} from './styled';

const Info = ({ agent, onChange, onBlur, loading }) => {
  const { user } = useContext(BaseContext);
  const location = getUserLocation(user);
  const id = agent?.id;
  const phoneNumber = agent?.phoneNumber;
  const stagingPhoneNumber = agent?.stagingPhoneNumber;
  const { version, loading: versionLoading } = useAgentVersion({
    id,
    skipCondition: !id,
  });
  const versionTitle = `v${version?.version}`;
  const versionName = `${version?.name}`;

  const name = agent?.name;
  const [editing, setEditing] = useState(false);

  const handleBlur = () => {
    onBlur();
    setEditing(false);
  };

  if (loading || versionLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer>
        <AgentAvatarContainer>
          <AgentAvatar />
        </AgentAvatarContainer>
        <AgentNameContainer>
          {!editing ? (
            <>
              <MediumText>{name || `LiveIQ Agent`}</MediumText>
              <SmallEditIconContainer onClick={() => setEditing(true)}>
                <SmallEditIcon />
              </SmallEditIconContainer>
            </>
          ) : (
            <>
              <PromptInput
                value={name}
                onChange={(e) => onChange(e.target.value)}
                onBlur={() => handleBlur()}
                useExtraSmallWidth
              />
              <SmallSaveIconContainer onClick={() => handleBlur()}>
                <SmallSaveIcon />
              </SmallSaveIconContainer>
            </>
          )}
        </AgentNameContainer>
        <CenteredDiv>
          <MediumDarkExtraSmallText>
            Production number: {formatPhoneNumber(phoneNumber) || 'None set'}
            &nbsp;&nbsp;
            {textSeparatorChar}&nbsp;&nbsp;Staging number:&nbsp;&nbsp;
            {formatPhoneNumber(stagingPhoneNumber) || 'None set'}
          </MediumDarkExtraSmallText>
        </CenteredDiv>
      </PromptContainer>
      <PromptContainer removePadding>
        <MediumDarkSmallText>
          A valued front desk team member
        </MediumDarkSmallText>
        {version && false && (
          <LightDarkEssText>
            Running <DarkGoldText>LIQ {versionTitle}:</DarkGoldText>{' '}
            {versionName}
          </LightDarkEssText>
        )}
      </PromptContainer>
    </PromptsSection>
  );
};

export default Info;
