import styled from 'styled-components';
import { MediumPrimaryButton } from '../../../styles/shared-styled-components';

export const PauseButton = styled(MediumPrimaryButton).attrs({
  largePadding: true,
})`
  background-color: ${({ theme }) => theme.color.error};
  &:hover {
    background-color: ${({ theme }) => theme.color.lightError};
  }
`;

export const EnableButton = styled(PauseButton).attrs({
  largePadding: true,
})`
  background-color: ${({ theme }) => theme.color.brightGreen};
  &:hover {
    background-color: ${({ theme }) => theme.color.brightGreen};
  }
`;
