import { useApolloClient, useMutation } from '@apollo/client';
import React from 'react';
import { DELETE_CAMPAIGN } from '../../../api/mutations/campaign';
import {
  MLText,
  MediumDarkSMText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
} from '../../../styles/shared-styled-components';
import {
  deleteMyCampaignInCache,
  updateMyCampaignMembersInCache,
} from '../../../utils/cache';
import LoadingIndicator from '../../LoadingIndicator';

const DeleteCampaignModal = ({
  isOpen,
  onClose,
  campaignToDelete,
  onDelete,
}) => {
  const { cache } = useApolloClient();

  const [deleteCampaignMutation, { loading: deleteCampaignLoading }] =
    useMutation(DELETE_CAMPAIGN);

  const handleDelete = () => {
    const campaignId = campaignToDelete.id;

    deleteCampaignMutation({
      variables: {
        id: campaignId,
      },
      onCompleted: async (data) => {
        const success = data.deleteCampaign.success;

        if (success) {
          await updateMyCampaignMembersInCache(campaignId, {}, cache);
          await deleteMyCampaignInCache(campaignId, cache);
          onDelete();
        }
      },
    });
  };

  if (deleteCampaignLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    campaignToDelete && (
      <Modal
        open={isOpen}
        onClose={onClose}
      >
        <ModalContainer>
          <MLText>Delete Campaign</MLText>
          <MediumDarkSMText>
            Are you sure you want to delete the '{campaignToDelete.name}'
            campaign?
          </MediumDarkSMText>
          <ModalActions>
            <MediumPrimaryButton onClick={handleDelete}>
              Delete
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={onClose}>
              Cancel
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    )
  );
};

export default DeleteCampaignModal;
