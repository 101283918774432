import gql from 'graphql-tag';

export const CREATE_CAMPAIGN_SEQUENCE = gql`
  mutation createCampaignSequence(
    $campaignId: String!
    $batchSize: Int!
    $runTime: String!
  ) {
    createCampaignSequence(
      campaignId: $campaignId
      batchSize: $batchSize
      runTime: $runTime
    ) {
      success
    }
  }
`;

export const EDIT_SEQUENCE_SETTINGS = gql`
  mutation editSequenceSettings(
    $messageWhenBusinessClosed: Boolean!
    $continueIfUpcomingVisit: Boolean!
    $stopWord: String!
    $timing: JSON!
    $numberOfClientFollowUps: Int!
    $numberOfLeadFollowUps: Int!
    $maxNewChatsPerCampaignPerBatch: Int
    $maxFollowUpsPerCampaignPerBatch: Int
    $dripCadence: JSON!
  ) {
    editSequenceSettings(
      messageWhenBusinessClosed: $messageWhenBusinessClosed
      continueIfUpcomingVisit: $continueIfUpcomingVisit
      stopWord: $stopWord
      timing: $timing
      numberOfClientFollowUps: $numberOfClientFollowUps
      numberOfLeadFollowUps: $numberOfLeadFollowUps
      maxNewChatsPerCampaignPerBatch: $maxNewChatsPerCampaignPerBatch
      maxFollowUpsPerCampaignPerBatch: $maxFollowUpsPerCampaignPerBatch
      dripCadence: $dripCadence
    ) {
      success
    }
  }
`;
