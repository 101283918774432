import gql from 'graphql-tag';

export const GET_CONTACT_BY_ID = gql`
  query getContactById($id: String!) {
    getContactById(id: $id)
  }
`;

export const GET_MY_CLIENT_FILTER_TEMPLATES = gql`
  query getMyClientFilterTemplates {
    getMyClientFilterTemplates {
      id
      name
      filter
    }
  }
`;

export const GET_CLIENT_FILTER_TEMPLATE_BY_ID = gql`
  query getClientFilterTemplateById($id: String!) {
    getClientFilterTemplateById(id: $id) {
      id
      name
      filter
    }
  }
`;

export const GET_MY_CLIENTS_UPDATED_AT = gql`
  query getMyClientsUpdatedAt {
    getMyClientsUpdatedAt {
      timestamp
    }
  }
`;
