import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Auth0Provider from './Auth0Provider';

const postHogOptions = {
  api_host: 'https://us.i.posthog.com',
  session_recording: {
    maskAllInputs: false,
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={postHogOptions}
  >
    <BrowserRouter>
      <Auth0Provider>
        <App />
      </Auth0Provider>
    </BrowserRouter>
  </PostHogProvider>,
);
