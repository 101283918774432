import React, { useContext, useEffect, useState } from 'react';
import {
  AutoScrollWrapper,
  FetchMoreContainer,
  FetchMoreText,
  LoadingMoreContainer,
  MenuItem,
  MenuItemCheckmark,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
} from '../../../styles/shared-styled-components';
import {
  ascendingKey,
  descendingKey,
  lastMessageSentAtField,
  numHumanAgentMessagesField,
  numTotalMessagesField,
  numUserMessagesField,
} from '../../../utils/constants';
import { getSortParam } from '../../../utils/filters';
import { formatNumber } from '../../../utils/numbers';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../LoadingIndicator';
import PopperMenu from '../../PopperMenu';
import ClientSearchBox from '../../Search/ClientSearchBox/ClientSearchBox';
import ChatCard from '../ChatCard/ChatCard';
import {
  ChatDrawer,
  ChatDrawerHeaderContainer,
  ChatFilterIcon,
  ChatFilterIconContainer,
  ChatQueryContainer,
  ChatResultsText,
  ChatSortIcon,
  ChatSortIconContainer,
  NumChatFiltersAppliedIconContainer,
  QuerySubContainer,
  ResetFilterContainer,
  ResetFilterText,
} from './styled';

const ChatRenderer = ({
  chats,
  totalNumQueriedChats,
  chatsLoading,
  lastUpdatedAt,
  subscribeToChatUpdates,
  selectedChatId,
  onSelectChat,
  onChangeSearchFilter,
  onResetSearchFilter,
  searchFilterValue,
  sortParam,
  onChangeSortParam,
  onOpenFiltersModal,
  numFiltersApplied,
  onResetFilters,
  onScrollToBottom,
  onManuallyFetchMoreChats,
  numDisplayed,
  totalNum,
  pageNumber,
  totalNumPages,
}) => {
  const { drawerOpen, drawerExpanded } = useContext(BaseContext);

  const [sortParamMenuAnchorEl, setSortParamMenuAnchorEl] = useState(false);

  const sortParamMenuOpenBoolean = Boolean(sortParamMenuAnchorEl);

  useEffect(() => {
    subscribeToChatUpdates();
  }, [lastUpdatedAt]);

  const onUpdateSortParam = (newSortParam) => {
    onChangeSortParam(newSortParam);
    setSortParamMenuAnchorEl();
  };

  const filtersApplied = !(!numFiltersApplied && !searchFilterValue);
  const moreChatsToFetch =
    pageNumber < totalNumPages || (!chatsLoading && numDisplayed < totalNum);

  return (
    <>
      <ChatDrawer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ChatDrawerHeaderContainer>
          <ChatQueryContainer>
            <ClientSearchBox
              onChange={(e) => onChangeSearchFilter(e.target.value)}
              value={searchFilterValue}
              onReset={onResetSearchFilter}
            />
            <ChatFilterIconContainer onClick={onOpenFiltersModal}>
              <ChatFilterIcon />
              {numFiltersApplied ? (
                <NumChatFiltersAppliedIconContainer>
                  {numFiltersApplied}
                </NumChatFiltersAppliedIconContainer>
              ) : (
                <></>
              )}
            </ChatFilterIconContainer>
          </ChatQueryContainer>
          <ResetFilterContainer>
            <ResetFilterText
              disabled={!filtersApplied}
              onClick={() => {
                if (filtersApplied) {
                  onResetFilters();
                }
              }}
            >
              Reset filters
            </ResetFilterText>
          </ResetFilterContainer>
          <QuerySubContainer>
            <ChatResultsText>
              {formatNumber(totalNumQueriedChats)}{' '}
              {!filtersApplied ? 'chat' : 'result'}
              {formatNumber(totalNumQueriedChats) !== 1 && 's'}
            </ChatResultsText>
            <ChatSortIconContainer>
              <ChatSortIcon
                onClick={(e) => {
                  setSortParamMenuAnchorEl(e.currentTarget);
                }}
              />
            </ChatSortIconContainer>
          </QuerySubContainer>
          <PopperMenu
            open={sortParamMenuOpenBoolean}
            anchorElement={sortParamMenuAnchorEl}
            onClose={() => setSortParamMenuAnchorEl()}
            variant='offset'
          >
            <SmallMenuItemTitleContainer disableRipple>
              <SmallMenuItemTitleText>Select sort field</SmallMenuItemTitleText>
            </SmallMenuItemTitleContainer>
            <MenuItem
              disableRipple
              onClick={() => {
                onUpdateSortParam(
                  getSortParam(lastMessageSentAtField, descendingKey),
                );
              }}
            >
              <SmallMenuItemText>Last message (newest)</SmallMenuItemText>
              {sortParam ===
                getSortParam(lastMessageSentAtField, descendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(lastMessageSentAtField, ascendingKey),
                )
              }
            >
              <SmallMenuItemText>Last message (oldest)</SmallMenuItemText>
              {sortParam ===
                getSortParam(lastMessageSentAtField, ascendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numTotalMessagesField, descendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Total number of messages (most)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numTotalMessagesField, descendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numTotalMessagesField, ascendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Total number of messages (least)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numTotalMessagesField, ascendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numUserMessagesField, descendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Number of user messages (most)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numUserMessagesField, descendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numUserMessagesField, ascendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Number of user messages (least)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numUserMessagesField, ascendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numHumanAgentMessagesField, descendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Number of human support agent messages (most)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numHumanAgentMessagesField, descendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numHumanAgentMessagesField, ascendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Number of human support agent messages (least)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numHumanAgentMessagesField, ascendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
          </PopperMenu>
        </ChatDrawerHeaderContainer>
        <AutoScrollWrapper onScroll={onScrollToBottom}>
          {chats.map((c, idx) => {
            const id = c.id;
            const position =
              idx === 0 ? 'start' : idx === chats.length - 1 ? 'end' : 'middle';
            const selected = selectedChatId === id;
            return (
              <ChatCard
                chat={c}
                idx={idx}
                position={position}
                onClick={() => {
                  onSelectChat(id);
                }}
                selected={selected}
              />
            );
          })}
          {chatsLoading && !numFiltersApplied ? (
            <LoadingMoreContainer>
              <LoadingIndicator size={30} />
            </LoadingMoreContainer>
          ) : moreChatsToFetch ? (
            <FetchMoreContainer>
              <FetchMoreText onClick={() => onManuallyFetchMoreChats()}>
                Fetch more
              </FetchMoreText>
            </FetchMoreContainer>
          ) : (
            <></>
          )}
        </AutoScrollWrapper>
      </ChatDrawer>
    </>
  );
};

export default ChatRenderer;
