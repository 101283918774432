import { useQuery } from '@apollo/client';
import {
  GET_MY_CHAT_AGENT_VERSION_SEQUENCES,
  GET_MY_SEQUENCES,
  GET_SEQUENCE_SETTINGS,
} from '../queries/sequence';

export const useMySequences = ({ locations }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_SEQUENCES, {
    variables: {
      locations,
    },
    fetchPolicy: 'network-only',
  });

  return {
    error,
    loading,
    refetch,
    data,
  };
};

export const useMyChatAgentVersionSequences = ({ skipCondition }) => {
  const {
    data: sourceData,
    error,
    loading,
    refetch,
  } = useQuery(GET_MY_CHAT_AGENT_VERSION_SEQUENCES, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  return {
    error,
    loading,
    refetch,
    data: sourceData?.getMyChatAgentVersionSequences || {},
  };
};

export const useMySequenceSettings = () => {
  const {
    data: sourceData,
    error,
    loading,
    refetch,
  } = useQuery(GET_SEQUENCE_SETTINGS, {
    fetchPolicy: 'cache-first',
  });

  return {
    error,
    loading,
    refetch,
    data: sourceData?.getSequenceSettings || {},
  };
};
