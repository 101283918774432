import styled from 'styled-components';
import {
  CenteredDiv,
  SmallGapStyle,
} from '../../../../styles/shared-styled-components';

export const DayHoursTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -5px;
`;

export const ActiveDayToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${SmallGapStyle}
`;

export const SliderContainer = styled(CenteredDiv)`
  margin-top: 20px;
`;
