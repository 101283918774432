import React from 'react';
import colors from '../../../styles/color';
import { KnobComponent } from './styled';

const Knob = ({
  value,
  onChange,
  min = 0,
  max = 100,
  step = 20,
  size = 75,
  color = 'base',
  showValue = true,
  valueTemplate = null,
}) => {
  return (
    <KnobComponent
      value={value}
      onChange={onChange}
      valueColor={
        color === 'base'
          ? colors.lightPrimary
          : color === 'blue'
          ? colors.lightBlue
          : color === 'orange'
          ? colors.lightOrange
          : color === 'peach'
          ? colors.peach
          : color === 'turquoise'
          ? colors.turquoise
          : 'black'
      }
      strokeWidth={10}
      min={min}
      max={max}
      step={step}
      size={size}
      showValue={showValue}
      valueTemplate={valueTemplate}
    />
  );
};

export default Knob;
