import gql from 'graphql-tag';

export const CREATE_CHAT = gql`
  mutation createChat(
    $cookieId: String!
    $locationId: String!
    $scenario: ChatScenario!
  ) {
    createChat(
      cookieId: $cookieId
      locationId: $locationId
      scenario: $scenario
    ) {
      id
      messages {
        id
        sender
        content
        sentAt
      }
      campaignId
    }
  }
`;

export const CREATE_DEMO_CHAT = gql`
  mutation createDemoChat(
    $contact: JSON!
    $agent: JSON!
    $campaignId: String
    $medium: ChatMedia!
    $phoneNumber: String
    $demoName: String
  ) {
    createDemoChat(
      contact: $contact
      agent: $agent
      campaignId: $campaignId
      medium: $medium
      phoneNumber: $phoneNumber
      demoName: $demoName
    ) {
      id
      campaignId
      status
      medium
      messages {
        id
        sender
        content
        sentAt
      }
      numMessages
      lastMessageAt
      agentName
      client {
        firstName
        lastName
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage(
    $chatId: String!
    $messageId: String
    $message: String!
    $isHumanAgent: Boolean!
  ) {
    sendMessage(
      chatId: $chatId
      messageId: $messageId
      message: $message
      isHumanAgent: $isHumanAgent
    ) {
      id
      campaignId
      status
      medium
      numMessages
      lastMessageAt
      messages {
        id
        sender
        content
        sentAt
      }
    }
  }
`;

export const INTERCEPT_CHAT = gql`
  mutation interceptChat(
    $chatId: String!
    $reason: ChatInterceptionReason!
    $notes: String
  ) {
    interceptChat(chatId: $chatId, reason: $reason, notes: $notes) {
      id
      campaignId
      status
      medium
      messages {
        id
        sender
        content
        sentAt
      }
    }
  }
`;

export const END_CHAT = gql`
  mutation endChat($chatId: String!) {
    endChat(chatId: $chatId) {
      id
      campaignId
      status
      medium
      messages {
        id
        sender
        content
        sentAt
      }
    }
  }
`;

export const MARK_CHAT_AS_REQUIRING_DEPOSIT = gql`
  mutation markChatAsRequiringDeposit($chatId: String!, $campaignId: String!) {
    markChatAsRequiringDeposit(chatId: $chatId, campaignId: $campaignId) {
      success
    }
  }
`;

export const RESUME_CHAT = gql`
  mutation resumeChat($chatId: String!, $reason: String!) {
    resumeChat(chatId: $chatId, reason: $reason) {
      success
    }
  }
`;

export const RESEND_LAST_MESSAGE = gql`
  mutation resendLastMessage($chatId: String!) {
    resendLastMessage(chatId: $chatId) {
      success
    }
  }
`;

export const RESET_CHAT = gql`
  mutation resetChat($chatId: String!) {
    resetChat(chatId: $chatId)
  }
`;

export const CREATE_CHAT_FOR_CONTACT = gql`
  mutation createChatForContact($contact: JSON!, $campaignId: String!) {
    createChatForContact(contact: $contact, campaignId: $campaignId) {
      success
    }
  }
`;

export const TEST_CHAT = gql`
  mutation testChat {
    testChat
  }
`;
