import Stack from '@mui/material/Stack';
import React, { useContext } from 'react';
import spacing from '../../styles/spacing';
import { BaseContext } from '../Auth/AuthRouter/AuthRouter';
import { CircularProgress } from './styled';

const bufferToNotRenderVerticalScrollbar = 10;

const LoadingIndicator = ({ fullScreen = false, size = 50 }) => {
  const { height } = useContext(BaseContext);
  const headerHeight = spacing.headerHeight;
  const fullScreenHeight =
    height - headerHeight - bufferToNotRenderVerticalScrollbar;

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      height={fullScreen && `${fullScreenHeight}px`}
      spacing={2}
    >
      <CircularProgress size={size} />
    </Stack>
  );
};

export default LoadingIndicator;
