import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import { CREATE_ENTERPRISE } from '../../../api/mutations/enterprise';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import FormPage from '../../../components/Form/FormPage';
import Input from '../../../components/Form/Input';
import LocationSearchBox from '../../../components/Search/LocationSearchBox/LocationSearchBox';
import {
  InputContainer,
  InputTitleText,
  TextInputWidthStyle,
} from '../../../styles/shared-styled-components';
import { justCompletedOnboardingCookieKey } from '../../../utils/auth';
import { completeUserOnboardingInCache } from '../../../utils/cache';
import {
  businessTypes,
  dropdownInputType,
  franchiseBusinessType,
  integerInputType,
  locationsUsingTypes,
  multiLocationBusinessType,
  singleKey,
  singleLocationBusinessType,
} from '../../../utils/constants';
import { valueIsEmpty } from '../../../utils/data';
import {
  checkIfIsValidUrl,
  getIdOfFirstEmptyField,
} from '../../../utils/input';
import { stripAllSpacesFromString } from '../../../utils/string';
import { getUserBusiness, getUserLocation } from '../../../utils/user';
import { scrollElementIntoView } from '../../../utils/view';

const websiteId = 'website';
const websiteErrorText = 'Please enter a valid URL';

const Business = () => {
  const { cache } = useApolloClient();
  const { user, userLoading, setCookie } = useContext(BaseContext);
  const navigate = useNavigate();

  const [createEnterpriseMutation, { loading: createEnterpriseLoading }] =
    useMutation(CREATE_ENTERPRISE);

  const business = getUserBusiness(user);
  const location = getUserLocation(user);

  const [name, setName] = useState(business?.name || '');
  const [type, setType] = useState(business?.type);
  const [numLocations, setNumLocations] = useState(
    business?.numLocations || '',
  );
  const [quantityOfLocationsUsing, setQuantityOfLocationsUsing] = useState(
    business?.quantityOfLocationsUsing,
  );
  const [locationName, setLocationName] = useState(location?.name || '');
  const [website, setWebsite] = useState(business?.website || '');
  const [websiteErrorMessage, setWebsiteErrorMessage] = useState();
  const [stagingAddress, setStagingAddress] = useState('');
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [timezone, setTimezone] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [submittedDataWithErrors, setSubmittedDataWithErrors] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);

  useEffect(() => {
    if (business) {
      setName(business.name || '');
      setType(business.type);
      setNumLocations(business.numLocations || '');
      setQuantityOfLocationsUsing(business.quantityOfLocationsUsing);
      setLocationName(location?.name || '');
      setWebsite(business.website || '');
      setStagingAddress(business.hqAddress || '');
      setAddress(business.hqAddress || '');

      if (location) {
        setLat(location.lat);
        setLng(location.lng);
        setPostalCode(location.postalCode);
        setTimezone(location.timezone);
      }
    }
  }, [user]);

  const createEnterprise = () => {
    createEnterpriseMutation({
      variables: {
        name,
        type,
        numLocations: parseInt(numLocations),
        quantityOfLocationsUsing,
        locationName: type === singleLocationBusinessType ? name : locationName,
        website,
        address,
        lat: lat?.toString(),
        lng: lng?.toString(),
        timezone,
        postalCode,
      },
      onCompleted: async (data) => {
        setRedirectLoading(true);
        const result = data.createEnterprise;
        const createdEnterprise = result.enterprise;
        const updatedUserRole = result.role;

        if (createdEnterprise?.business) {
          await completeUserOnboardingInCache(
            user.email,
            createdEnterprise,
            updatedUserRole,
            cache,
          );

          const expiryDate = new Date();
          expiryDate.setDate(expiryDate.getDate() + 100);
          setCookie(justCompletedOnboardingCookieKey, 'true', {
            expires: expiryDate,
          });

          const createdAgentId = createdEnterprise?.location?.agents?.[0]?.id;

          const pathToNavigateTo = createdAgentId
            ? Paths.agentAppConnection.replace(':agentId', createdAgentId)
            : Paths.callback;
          navigate(pathToNavigateTo);
        }
      },
    });
  };

  const onClickContinue = () => {
    const formattedWebsite = stripAllSpacesFromString(website);
    const isValidUrl = checkIfIsValidUrl(formattedWebsite);

    if (!continueEnabled) {
      const idWithError = getIdOfFirstEmptyField(inputs);
      scrollElementIntoView(document, idWithError);
      setSubmittedDataWithErrors(true);

      if (website && !isValidUrl) {
        setWebsiteErrorMessage(websiteErrorText);
      }

      return;
    }

    if (!isValidUrl) {
      scrollElementIntoView(document, websiteId);
      setWebsiteErrorMessage(websiteErrorText);

      return;
    }

    createEnterprise();
  };

  const inputs = [
    <Input
      id='name'
      title="What's the name of your business?"
      value={name}
      onChange={(e) => {
        setName(e.target.value);
      }}
      label='Name'
      containsError={valueIsEmpty(name) && submittedDataWithErrors}
      useSmallGap={true}
    />,
    <Input
      id='type'
      title='What type of business is it?'
      value={type}
      onChange={(e) => {
        const previousType = type;
        const newType = e.target.value;

        setType(newType);

        if (newType === singleLocationBusinessType) {
          setNumLocations(1);
          setQuantityOfLocationsUsing(singleKey);
        } else if (previousType === singleLocationBusinessType) {
          setNumLocations();
          setQuantityOfLocationsUsing();
        }
      }}
      type={dropdownInputType}
      options={businessTypes}
      isRequired={false}
      label={'Type'}
      containsError={valueIsEmpty(type) && submittedDataWithErrors}
    />,
    <Input
      id='num-locations'
      title='How many total locations does your business own?'
      value={numLocations}
      onChange={(e) => {
        setNumLocations(e.target.value);
      }}
      label='Number of locations'
      renderCondition={
        type === franchiseBusinessType || type === multiLocationBusinessType
      }
      type={integerInputType}
      containsError={valueIsEmpty(numLocations) && submittedDataWithErrors}
    />,
    <Input
      id='locations-using'
      title='Do you plan on using LiveIQ at multiple locations, or just one?'
      value={quantityOfLocationsUsing}
      onChange={(e) => {
        const newValue = e.target.value;
        setQuantityOfLocationsUsing(newValue);
      }}
      label='Locations using LiveIQ'
      renderCondition={
        type === franchiseBusinessType || type === multiLocationBusinessType
      }
      type={dropdownInputType}
      options={locationsUsingTypes}
      containsError={
        valueIsEmpty(quantityOfLocationsUsing) && submittedDataWithErrors
      }
    />,
    <Input
      id='location-name'
      title="What's the name of the location you'll be using LiveIQ at?"
      value={locationName}
      onChange={(e) => {
        setLocationName(e.target.value);
      }}
      label='Location name'
      renderCondition={
        type !== singleLocationBusinessType &&
        quantityOfLocationsUsing === singleKey
      }
      containsError={valueIsEmpty(locationName) && submittedDataWithErrors}
    />,
    <InputContainer useSmallGap={true}>
      <InputTitleText useFullWidthLabel>
        {type === singleLocationBusinessType
          ? 'Enter the address of your business'
          : quantityOfLocationsUsing === singleKey
          ? 'Enter the address of the location that will be using LiveIQ'
          : 'Enter the address of your business HQ'}
      </InputTitleText>
      <LocationSearchBox
        customLabel='Address'
        value={stagingAddress}
        onChange={(newStagingAddress) => {
          setStagingAddress(newStagingAddress);

          if (!newStagingAddress) {
            setAddress('');
            setLat('');
            setLng('');
            setTimezone('');
            setPostalCode('');
          }
        }}
        onEnter={(newAddress, newLat, newLng, newTimezone, newPostalCode) => {
          setStagingAddress(newAddress);
          setAddress(newAddress);
          setLat(newLat);
          setLng(newLng);
          setTimezone(newTimezone);
          setPostalCode(newPostalCode);
        }}
        customPopperMenuStyle={TextInputWidthStyle}
      />
    </InputContainer>,
    <Input
      id={websiteId}
      title='Link your business website'
      value={website}
      onChange={(e) => {
        setWebsite(e.target.value);
        if (websiteErrorMessage) {
          setWebsiteErrorMessage();
        }
      }}
      label='Website'
      containsError={valueIsEmpty(website) && submittedDataWithErrors}
      useSmallGap={true}
      errorMessage={websiteErrorMessage}
    />,
  ];

  const continueEnabled =
    !valueIsEmpty(name) &&
    !valueIsEmpty(type) &&
    (type === singleLocationBusinessType ||
      (type !== singleLocationBusinessType &&
        !valueIsEmpty(quantityOfLocationsUsing) &&
        !valueIsEmpty(numLocations) &&
        (quantityOfLocationsUsing !== singleKey ||
          (quantityOfLocationsUsing === singleKey &&
            !valueIsEmpty(locationName))))) &&
    !valueIsEmpty(website) &&
    !valueIsEmpty(address) &&
    !valueIsEmpty(timezone);

  const loading = userLoading || createEnterpriseLoading || redirectLoading;

  return (
    user && (
      <FormPage
        title='Your Business'
        currentStep={2}
        totalNumSteps={2}
        isLoading={loading}
        inputs={inputs}
        continueEnabled={continueEnabled}
        onContinue={onClickContinue}
        onBack={() => navigate(Paths.personalOnboarding)}
      />
    )
  );
};

export default Business;
