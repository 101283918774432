import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Paths from '../../../Paths';
import { DarkBackgroundSecondaryButton } from '../../../styles/shared-styled-components';
import { handleLogin, returnToUrlLocalStorageKey } from '../../../utils/auth';
import { checkIfPathIsAppPage } from '../../../utils/routing';
import {
  PrimaryLoginButtonComponent,
  SecondaryLoginButtonComponent,
} from './styled';

const LoginButton = ({
  onClick = () => {},
  size = 'small',
  variant = 'primary',
}) => {
  const { loginWithRedirect } = useAuth0();

  // Make sure we return the user to an app page
  const { pathname, search } = useLocation();
  let pathToReturnTo = `${pathname}${search}`;
  const isAppPage = checkIfPathIsAppPage(pathname);
  if (!isAppPage) {
    pathToReturnTo = Paths.callback;
  }
  const existingReturnToUrl = localStorage.getItem(returnToUrlLocalStorageKey);

  const onLogin = () => {
    if (!existingReturnToUrl) {
      localStorage.setItem(returnToUrlLocalStorageKey, pathToReturnTo);
    }

    handleLogin({
      onClick,
      pathname: Paths.callback,
      pathToReturnTo,
      loginWithRedirect,
    });
  };

  return (
    <>
      {variant === 'secondary' ? (
        <SecondaryLoginButtonComponent
          buttonSize={size}
          onClick={onLogin}
        >
          Log in
        </SecondaryLoginButtonComponent>
      ) : variant === 'dark-secondary' ? (
        <DarkBackgroundSecondaryButton
          buttonSize={size}
          onClick={onLogin}
        >
          Log in
        </DarkBackgroundSecondaryButton>
      ) : (
        <PrimaryLoginButtonComponent
          buttonSize={size}
          onClick={onLogin}
        >
          Log in
        </PrimaryLoginButtonComponent>
      )}
    </>
  );
};

export default LoginButton;
