import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Paths from '../../../Paths';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  ColumnCenteredDiv,
  ContentContainer,
  LargeText,
  MediumLinkedText,
  PageContainer,
} from '../../../styles/shared-styled-components';

const Logout = () => {
  const { logout } = useAuth0();

  const [displayLogoutLink, setDisplayLogoutLink] = useState(false);

  const executeLogout = () => {
    window.localStorage.clear();
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}${Paths.homepage}`,
      },
    });
  };

  useEffect(() => {
    executeLogout();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayLogoutLink(true);
    }, 8000);

    return () => clearTimeout(timeout);
  }, []);

  if (!displayLogoutLink) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    displayLogoutLink && (
      <>
        <PageContainer>
          <ContentContainer>
            <ColumnCenteredDiv>
              <LargeText>Logging you out...</LargeText>
              <MediumLinkedText onClick={() => executeLogout()}>
                If not redirected, click here
              </MediumLinkedText>
            </ColumnCenteredDiv>
          </ContentContainer>
        </PageContainer>
      </>
    )
  );
};

export default Logout;
