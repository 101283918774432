import gql from 'graphql-tag';

export const CREATE_MESSAGE_TEMPLATE = gql`
  mutation createMessageTemplate(
    $name: String!
    $content: JSON!
    $campaignId: String!
  ) {
    createMessageTemplate(
      name: $name
      content: $content
      campaignId: $campaignId
    ) {
      success
    }
  }
`;

export const EDIT_MESSAGE_TEMPLATE = gql`
  mutation editMessageTemplate($id: String!, $name: String!, $content: JSON!) {
    editMessageTemplate(id: $id, name: $name, content: $content) {
      success
    }
  }
`;

export const DELETE_MESSAGE_TEMPLATE = gql`
  mutation deleteMessageTemplate($id: String!, $type: TemplateType!) {
    deleteMessageTemplate(id: $id, type: $type) {
      success
    }
  }
`;

export const REPLACE_MESSAGE_TEMPLATE_TYPE = gql`
  mutation replaceMessageTemplateType(
    $id: String!
    $name: String!
    $content: JSON!
  ) {
    replaceMessageTemplateType(id: $id, name: $name, content: $content) {
      success
    }
  }
`;

export const UPLOAD_FILES = gql`
  mutation uploadFiles($files: [Upload!]!) {
    uploadFiles(files: $files) {
      success
    }
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($id: String!) {
    deleteFile(id: $id) {
      success
    }
  }
`;

export const CREATE_FAQ = gql`
  mutation createFaq($question: String!, $answer: String!) {
    createFaq(question: $question, answer: $answer) {
      success
    }
  }
`;

export const EDIT_FAQ = gql`
  mutation editFaq($id: String!, $question: String!, $answer: String!) {
    editFaq(id: $id, question: $question, answer: $answer) {
      success
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation deleteFaq($id: String!) {
    deleteFaq(id: $id) {
      success
    }
  }
`;

export const CREATE_SCRIPT = gql`
  mutation createScript($campaignId: String!) {
    createScript(campaignId: $campaignId)
  }
`;

export const EDIT_SCRIPT_INFO = gql`
  mutation editScriptInfo(
    $versionId: String!
    $name: String!
    $goal: String!
    $sessionTypeIds: [String]
  ) {
    editScriptInfo(
      versionId: $versionId
      name: $name
      goal: $goal
      sessionTypeIds: $sessionTypeIds
    ) {
      success
    }
  }
`;

export const EDIT_SCRIPT_DATA = gql`
  mutation editScriptData($scriptId: String!, $nodes: JSON!, $edges: JSON!) {
    editScriptData(scriptId: $scriptId, nodes: $nodes, edges: $edges) {
      success
    }
  }
`;

export const COPY_TEMPLATE_TO_LOCATION = gql`
  mutation copyTemplateToLocation(
    $templateId: String!
    $templateType: TemplateType!
    $locationIdToCopyTo: String!
  ) {
    copyTemplateToLocation(
      templateId: $templateId
      templateType: $templateType
      locationIdToCopyTo: $locationIdToCopyTo
    ) {
      success
    }
  }
`;
