import { useApolloClient, useMutation } from '@apollo/client';
import { matchIsValidTel } from 'mui-tel-input';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useChat } from '../../../api/hooks/chat';
import { useMyLocationUsers } from '../../../api/hooks/users';
import {
  CREATE_DEMO_CHAT,
  RESET_CHAT,
  SEND_MESSAGE,
} from '../../../api/mutations/chat';
import { getTypesenseClient } from '../../../api/typesense/typesense-client';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import Input from '../../../components/Form/Input';
import LoadingIndicator from '../../../components/LoadingIndicator';
import MessageQueryManager from '../../../components/Messages/MessageQueryManager';
import Header from '../../../components/NavBar/Header';
import PopperMenu from '../../../components/PopperMenu';
import ClientSearch from '../../../components/Search/ClientSearch/ClientSearch';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  BeginChatButtonContainer,
  CardPageSection,
  CenteredDivWithExtraSmallGap,
  ChatActionButton,
  ChatActionsContainer,
  ChatBox,
  ColumnCenteredDiv,
  ContentContainer,
  CustomWidthDiv,
  EmptyGapColumnCenteredDiv,
  EssText,
  ExtraSmallPrimaryButton,
  FullSizeCenteredDiv,
  FullWidthCenteredDiv,
  InputPhoneNumberContainer,
  LightDarkEssText,
  LightDarkLargeTinyText,
  MediumDarkSmallText,
  MenuItem,
  PageContainer,
  PageTitleText,
  PrimaryChatActionButton,
  PrimaryTextSpan,
  ResetIcon,
  SmallLightDarkTextSpan,
  SmallMenuItemText,
  SmallPrimaryCopyIcon,
  Tab,
  Tabs,
  TextInput,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import { addChatToCache, removeChatFromCache } from '../../../utils/cache';
import {
  bookingSystemWebDemoChatMediumKey,
  campaignForChatDemoCookieKey,
  chatDemoContextKey,
  chatDemoCookieKey,
  chatDemoTabCookieKey,
  chatLogsCookieKey,
  clientIdForChatDemoCookieKey,
  demoNameCookieKey,
  disengagedChatStatusKey,
  leadConnectorMessagingPlatformKey,
  phoneInputType,
  smsDemoChatMediumKey,
  superAdminRole,
  textSeparatorChar,
  typesenseContactSchemaName,
  webDemoChatMediumKey,
} from '../../../utils/constants';
import { dateToTextFormat, getCookieExpiryObject } from '../../../utils/date';
import {
  getFormattedFullNameFromUser,
  getWordWithCapitalizedFirstLetter,
} from '../../../utils/name';
import { copyTextToClipboard } from '../../../utils/string';
import {
  getUserLiveIQAgents,
  getUserLocation,
  getUserTypesenseApiKey,
} from '../../../utils/user';
import {
  BookingSystemWarningContainer,
  BookingSystemWarningText,
  ChatDemoText,
  ClientSection,
  CreateChatButtonContainer,
  ResetChatButtonContainer,
} from './styled';

const cookieExpiryObject = getCookieExpiryObject();

const phoneErrorText = 'Please enter a valid phone number';

const clientSelectionTab = 0;
export const chatTab = 1;

const ChatDemo = () => {
  const { cache } = useApolloClient();

  const {
    inDemoMode,
    user,
    width,
    drawerOpen,
    drawerExpanded,
    cookies,
    setCookie,
    removeCookie,
  } = useContext(BaseContext);
  const isSuperAdmin = user?.role === superAdminRole;

  const { users: locationUsers } = useMyLocationUsers({});
  const userMap = {};
  locationUsers?.map((u) => {
    userMap[u.id] = getFormattedFullNameFromUser(u);
  });

  const location = getUserLocation(user);

  const agent = getUserLiveIQAgents(user)[0];
  const locationId = location?.id;

  const typesenseApiKey = getUserTypesenseApiKey(user);
  const typesenseClient = getTypesenseClient(typesenseApiKey);

  const contentContainerRef = useRef();
  const inputRef = useRef(null);

  // General
  const tabCookie = cookies[chatDemoTabCookieKey];
  const [tab, setTab] = useState(tabCookie != null ? parseInt(tabCookie) : 0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [remainingHeight, setRemainingHeight] = useState(0);

  // Chat
  const clientIdForChatCookie = cookies[clientIdForChatDemoCookieKey];
  const campaignForChatDemoCookie = cookies[campaignForChatDemoCookieKey];
  const demoNameCookie = cookies[demoNameCookieKey];
  const [clientIdForChatDemo, setClientIdForChatDemo] = useState(
    clientIdForChatCookie,
  );
  const [campaignForChatDemo, setCampaignForChatDemo] = useState(
    campaignForChatDemoCookie,
  );
  const [demoName, setDemoName] = useState(demoNameCookie);
  const [clientForChatDemo, setClientForChatDemo] = useState();
  const [hasFetchedClient, setHasFetchedClient] = useState(false);
  const [chatPhoneNumberMenuAnchorEl, setChatPhoneNumberMenuAnchorEl] =
    useState();
  const [chatPhoneNumber, setChatPhoneNumber] = useState();
  const [phoneErrorMessage, setPhoneErrorMessage] = useState();
  const [newMessage, setNewMessage] = useState('');
  const [resetMenuAnchorEl, setResetMenuAnchorEl] = useState();

  const chatPhoneNumberMenuOpenBoolean = Boolean(chatPhoneNumberMenuAnchorEl);
  const resetMenuOpenBoolean = Boolean(resetMenuAnchorEl);

  // Chat
  const chatId = cookies[chatDemoCookieKey];
  const {
    chat,
    loading: chatLoading,
    refetch: refetchChat,
  } = useChat({ chatId });
  const [createDemoChatMutation, { loading: createDemoChatMutationLoading }] =
    useMutation(CREATE_DEMO_CHAT);
  const [sendMessageMutation, { loading: sendMessageMutationLoading }] =
    useMutation(SEND_MESSAGE);
  const [resetChatMutation, { loading: resetChatMutationLoading }] =
    useMutation(RESET_CHAT);

  useEffect(() => {
    if (tabCookie != null) {
      setTab(parseInt(tabCookie));
    }
  }, [tabCookie]);

  useEffect(() => {
    if (clientIdForChatCookie) {
      setClientIdForChatDemo(clientIdForChatCookie);
    }
  }, [clientIdForChatCookie]);

  useEffect(() => {
    if (campaignForChatDemoCookie) {
      setCampaignForChatDemo(campaignForChatDemoCookie);
    }
  }, [campaignForChatDemoCookie]);

  useEffect(() => {
    if (demoNameCookie) {
      setDemoName(demoNameCookie);
    }
  }, [demoNameCookie]);

  useEffect(() => {
    const handleResize = () => {
      const divHeight =
        contentContainerRef?.current?.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const heightDifference = windowHeight - divHeight;
      setRemainingHeight(heightDifference);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  useEffect(() => {
    async function fetchClient() {
      let filterString = `id:=[${clientIdForChatDemo}]`;

      let searchParameters = {
        q: '*',
        per_page: 1,
        page: 1,
        filter_by: filterString,
      };

      const data = await typesenseClient
        .collections(typesenseContactSchemaName)
        .documents()
        .search(searchParameters);
      const documents = data.hits.map((hit) => hit.document);

      if (documents?.length === 1) {
        const client = {
          ...documents[0],
          campaign: {
            id: campaignForChatDemo?.id,
            name: campaignForChatDemo?.name,
          },
        };
        setClientForChatDemo(client);
      }
      setHasFetchedClient(true);
    }

    if (clientIdForChatDemo) {
      fetchClient();
    }
  }, [clientIdForChatDemo]);

  // Chat
  const onCreateChat = (medium) => {
    createDemoChatMutation({
      variables: {
        contact: clientForChatDemo,
        agent,
        campaignId: campaignForChatDemo?.id,
        medium,
        phoneNumber: chatPhoneNumber,
        demoName,
      },
      onCompleted: async (data) => {
        const createdChat = data.createDemoChat;

        setCookie(chatDemoCookieKey, createdChat.id, cookieExpiryObject);

        if (createdChat) {
          await addChatToCache(createdChat, cache);
        }
      },
    });
  };

  const beginSmsChat = () => {
    const isValidPhone = matchIsValidTel(chatPhoneNumber || '');

    if (chatPhoneNumber && !isValidPhone) {
      setPhoneErrorMessage(phoneErrorText);
      return;
    }

    setChatPhoneNumberMenuAnchorEl();
    onCreateChat(smsDemoChatMediumKey);
  };

  const onEnterMessage = () => {
    const messageToSend = newMessage;

    setNewMessage('');

    sendMessageMutation({
      variables: {
        chatId,
        messageId: uuidv4(),
        message: messageToSend,
        isHumanAgent: false,
      },
    });
  };

  const onResetChat = () => {
    removeCookie(chatDemoCookieKey);
    setResetMenuAnchorEl();
  };

  const onDeleteChat = () => {
    setNewMessage('');
    if (chatId) {
      resetChatMutation({
        variables: {
          chatId,
        },
        onCompleted: async (data) => {
          const success = data.resetChat;
          if (success) {
            await removeChatFromCache(chatId, cache);

            removeCookie(chatDemoCookieKey);

            const chatLogsCookie = cookies[chatLogsCookieKey];
            if (chatLogsCookie === chatId) {
              removeCookie(chatLogsCookieKey);
            }

            refetchChat();
          }
        },
      });
    } else {
      window.location.reload();
    }
    setResetMenuAnchorEl();
  };

  const onChangeTab = (tabValue) => {
    setCookie(chatDemoTabCookieKey, parseInt(tabValue), {});
  };

  const onSelectClientForChatDemo = (clientId, campaign) => {
    const campaignId = campaign?.id;
    const cookieCampaignId = campaignForChatDemoCookie?.id;

    if (clientIdForChatDemo !== clientId || cookieCampaignId !== campaignId) {
      setCookie(campaignForChatDemoCookieKey, campaign, cookieExpiryObject);
      setCookie(chatDemoCookieKey, null, cookieExpiryObject);
      setCookie(clientIdForChatDemoCookieKey, clientId, cookieExpiryObject);

      if (demoName != null) {
        setCookie(demoNameCookieKey, demoName, cookieExpiryObject);
      }
    }

    setCookie(chatDemoTabCookieKey, 1, cookieExpiryObject);
  };

  const onResetClientForChatDemo = () => {
    removeCookie(clientIdForChatDemoCookieKey);
    removeCookie(campaignForChatDemoCookieKey);
    setClientIdForChatDemo();
  };

  const isLoading =
    createDemoChatMutationLoading || chatLoading || resetChatMutationLoading;

  const selectedClientForChatDemo =
    clientIdForChatDemo && clientForChatDemo
      ? {
          [clientIdForChatDemo]: clientForChatDemo,
        }
      : null;

  const chatDisengaged = chat?.status === disengagedChatStatusKey;
  const chatInProgress = chat && chat?.id === chatId;
  const chatMedium = chat?.medium;

  return (
    <>
      <MetaSetter
        title={`Agent Chat Demo`}
        description={`Demo Your LiveIQ Agent`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
        >
          <ColumnCenteredDiv>
            <PageTitleText>Agent Chat Demo</PageTitleText>
          </ColumnCenteredDiv>
          <CardPageSection maxWidthUnset>
            <ColumnCenteredDiv>
              <Tabs
                value={tab}
                onChange={(_, key) => onChangeTab(key)}
              >
                <Tab
                  value={clientSelectionTab}
                  label='Contacts'
                />
                <Tab
                  value={chatTab}
                  label='Chat'
                />
              </Tabs>
            </ColumnCenteredDiv>
            {tab === clientSelectionTab ? (
              <>
                <ClientSection>
                  <EmptyGapColumnCenteredDiv>
                    <MediumDarkSmallText>
                      Select a contact to begin a demo chat
                    </MediumDarkSmallText>
                    <LightDarkLargeTinyText>
                      This will not contact them, it's just showing you how your
                      agent will interact with them
                    </LightDarkLargeTinyText>
                  </EmptyGapColumnCenteredDiv>
                  <FullWidthCenteredDiv>
                    <ClientSearch
                      locationId={locationId}
                      context={chatDemoContextKey}
                      demoName={demoName}
                      onUpdateDemoName={(updatedDemoName) => {
                        setDemoName(updatedDemoName);
                      }}
                      selectedClientForChatDemo={selectedClientForChatDemo}
                      onSelectClientForChatDemo={onSelectClientForChatDemo}
                      onResetClient={onResetClientForChatDemo}
                    />
                  </FullWidthCenteredDiv>
                </ClientSection>
              </>
            ) : tab === chatTab ? (
              <ChatBox
                remove={!chatInProgress && !isLoading}
                demo
                remainingHeight={remainingHeight}
              >
                {isLoading ? (
                  <FullSizeCenteredDiv>
                    <LoadingIndicator fullScreen />
                  </FullSizeCenteredDiv>
                ) : !chatInProgress ? (
                  <CreateChatButtonContainer>
                    {!hasFetchedClient ? (
                      <ChatDemoText>
                        Select a contact to begin a demo chat
                      </ChatDemoText>
                    ) : !clientForChatDemo ? (
                      <div style={{ width: '80%' }}>
                        <ChatDemoText>
                          Received error when fetching contact for demo, please
                          unselect, refresh, and try again
                        </ChatDemoText>
                      </div>
                    ) : (
                      <CustomWidthDiv>
                        <ChatDemoText>
                          {agent?.name} is ready to begin a demo chat with{' '}
                          <PrimaryTextSpan>
                            {getFormattedFullNameFromUser(
                              clientForChatDemo,
                              inDemoMode,
                            )}
                          </PrimaryTextSpan>
                          , as part of the{' '}
                          <PrimaryTextSpan>
                            '{campaignForChatDemoCookie?.name}'
                          </PrimaryTextSpan>{' '}
                          campaign{demoName && ` (for the '${demoName}' demo)`}
                          <br></br>
                          <br></br>
                          Here's the context we'll give {agent?.name}:<br></br>
                          <br></br>
                          <SmallLightDarkTextSpan>
                            {textSeparatorChar}{' '}
                            {clientForChatDemo.lastVisit
                              ? `${
                                  clientForChatDemo.firstName
                                    ? getWordWithCapitalizedFirstLetter(
                                        clientForChatDemo.firstName,
                                      )
                                    : 'The client'
                                }'s last visit was on ${dateToTextFormat(
                                  clientForChatDemo.lastVisit,
                                )} when they received the ${
                                  clientForChatDemo.lastVisitSessionTypeName
                                    ? `'${clientForChatDemo.lastVisitSessionTypeName}'`
                                    : '(service name unknown)'
                                } service${
                                  clientForChatDemo.lastVisitStaffName
                                    ? `, with ${
                                        inDemoMode
                                          ? 'Ashley'
                                          : clientForChatDemo.lastVisitStaffName
                                      }`
                                    : ' (staff member unknown)'
                                }`
                              : "They've never had an appointment with your business"}
                            <br></br>
                            <br></br>
                            <SmallLightDarkTextSpan>
                              {textSeparatorChar} {agent?.name}'s goal is to get
                              the client to book{' '}
                              {clientForChatDemo.lastVisit
                                ? 'another'
                                : 'their first'}{' '}
                              appointment with your business
                            </SmallLightDarkTextSpan>
                            {!clientForChatDemo.lastVisit && (
                              <>
                                <br></br>
                                <br></br>
                                <SmallLightDarkTextSpan>
                                  {textSeparatorChar} {agent?.name} should
                                  figure out what service they're interested in,
                                  then find a time in the schedule to book them
                                  in
                                </SmallLightDarkTextSpan>
                              </>
                            )}
                          </SmallLightDarkTextSpan>
                        </ChatDemoText>
                      </CustomWidthDiv>
                    )}
                    <ColumnCenteredDiv></ColumnCenteredDiv>
                  </CreateChatButtonContainer>
                ) : (
                  <MessageQueryManager
                    chat={chat}
                    context='demo'
                    userMap={userMap}
                  />
                )}
                {!isLoading && (
                  <ChatActionsContainer isDemo>
                    {chatInProgress || chatDisengaged ? (
                      <>
                        {[
                          webDemoChatMediumKey,
                          bookingSystemWebDemoChatMediumKey,
                        ].includes(chatMedium) ? (
                          <>
                            <TextInput
                              ref={inputRef}
                              value={newMessage}
                              onChange={(e) => {
                                if (chatInProgress || chatDisengaged) {
                                  setNewMessage(e.target.value);
                                }
                              }}
                              onKeyPress={(event) => {
                                if (
                                  event.key === 'Enter' &&
                                  (chatInProgress || chatDisengaged)
                                ) {
                                  onEnterMessage();
                                }
                              }}
                              removeGap
                              useSmallWidth
                              style={{ width: '80%' }}
                              removeAutoMargins={true}
                            />{' '}
                            <CenteredDivWithExtraSmallGap>
                              <ChatActionButton
                                onClick={onEnterMessage}
                                disabled={!newMessage}
                              >
                                {' '}
                                Send
                              </ChatActionButton>
                              <Tooltip
                                title={
                                  <TooltipTitleText>
                                    Copy chat ID
                                  </TooltipTitleText>
                                }
                                onClick={() => copyTextToClipboard(chatId)}
                              >
                                <SmallPrimaryCopyIcon />
                              </Tooltip>
                            </CenteredDivWithExtraSmallGap>
                          </>
                        ) : (
                          <LightDarkEssText>
                            To send messages as{' '}
                            {getWordWithCapitalizedFirstLetter(
                              clientForChatDemo?.firstName,
                            )}{' '}
                            for this demo, text from your phone
                          </LightDarkEssText>
                        )}
                      </>
                    ) : (
                      <>
                        <BeginChatButtonContainer>
                          {isSuperAdmin && (
                            <ChatActionButton
                              onClick={() =>
                                onCreateChat(bookingSystemWebDemoChatMediumKey)
                              }
                              disabled={!clientForChatDemo}
                            >
                              Begin Live Booking System Chat
                            </ChatActionButton>
                          )}
                          <PrimaryChatActionButton
                            onClick={() => onCreateChat(webDemoChatMediumKey)}
                            disabled={!clientForChatDemo}
                          >
                            Begin Demo Web Chat
                          </PrimaryChatActionButton>
                          {location?.messagingPlatform !==
                            leadConnectorMessagingPlatformKey && (
                            <ChatActionButton
                              onClick={(e) =>
                                setChatPhoneNumberMenuAnchorEl(e.currentTarget)
                              }
                              disabled={!clientForChatDemo}
                            >
                              Begin SMS Chat
                            </ChatActionButton>
                          )}
                        </BeginChatButtonContainer>
                        <PopperMenu
                          open={chatPhoneNumberMenuOpenBoolean}
                          anchorElement={chatPhoneNumberMenuAnchorEl}
                          onClose={() => {
                            setChatPhoneNumberMenuAnchorEl();
                            setChatPhoneNumber();
                          }}
                          variant='offset'
                          modifiers={{
                            name: 'offset',
                            options: {
                              offset: [-100, -230],
                            },
                          }}
                        >
                          <InputPhoneNumberContainer>
                            <EssText>
                              Input the phone number to use for the demo chat{' '}
                            </EssText>
                            <Input
                              ref={inputRef}
                              value={chatPhoneNumber}
                              onChange={(phoneNumber) => {
                                setChatPhoneNumber(phoneNumber);
                                if (phoneErrorMessage) {
                                  setPhoneErrorMessage();
                                }
                              }}
                              removeGap
                              useSmallWidth
                              removeAutoMargins={true}
                              style={{ width: '80%' }}
                              type={phoneInputType}
                              containsError={phoneErrorMessage}
                              errorMessage={phoneErrorMessage}
                            />
                            <ExtraSmallPrimaryButton
                              largePadding
                              disabled={!chatPhoneNumber}
                              onClick={beginSmsChat}
                            >
                              Begin SMS chat
                            </ExtraSmallPrimaryButton>
                          </InputPhoneNumberContainer>
                        </PopperMenu>
                      </>
                    )}
                  </ChatActionsContainer>
                )}
                {(chatInProgress || chatDisengaged || chatLoading) && (
                  <>
                    <ResetChatButtonContainer
                      onClick={(e) => setResetMenuAnchorEl(e.currentTarget)}
                    >
                      <ResetIcon />
                    </ResetChatButtonContainer>
                    <PopperMenu
                      open={resetMenuOpenBoolean}
                      anchorElement={resetMenuAnchorEl}
                      onClose={() => {
                        setResetMenuAnchorEl();
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          onResetChat();
                        }}
                      >
                        <SmallMenuItemText>Reset chat</SmallMenuItemText>
                      </MenuItem>
                      <MenuItem onClick={() => onDeleteChat()}>
                        <SmallMenuItemText>
                          Reset and delete chat
                        </SmallMenuItemText>
                      </MenuItem>
                    </PopperMenu>
                  </>
                )}
                {chatMedium === bookingSystemWebDemoChatMediumKey && (
                  <BookingSystemWarningContainer>
                    <BookingSystemWarningText>
                      Remember: this chat is directly interacting with the
                      booking system
                    </BookingSystemWarningText>
                  </BookingSystemWarningContainer>
                )}
              </ChatBox>
            ) : (
              <></>
            )}
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default ChatDemo;
