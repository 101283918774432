import { useMutation } from '@apollo/client';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { EDIT_CATEGORY_INCLUSIONS } from '../../../../api/mutations/training';
import {
  AutoScrollWrapper,
  StartAlignedMediumDarkEssText,
} from '../../../../styles/shared-styled-components';
import {
  buttonTriggeredAutoSaveDelayMs,
  serviceCategoryTypeKey,
} from '../../../../utils/constants';
import { formatKeyToLabel } from '../../../../utils/string';
import LoadingIndicator from '../../../LoadingIndicator';
import SwitchToggle from '../../../Micro/SwitchToggle/SwitchToggle';
import {
  ListInputContainer,
  ListInputSection,
  ListInputTitleContainer,
  PromptContainer,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const getAbbreviatedCategoryArray = (baseCategories) => {
  return baseCategories.map((c) => {
    return {
      id: c.id,
      name: c.name,
      included: c.included,
    };
  });
};

const salonCategories = [
  {
    id: 'kids',
    name: `Kid's Haircuts`,
    included: true,
  },
  {
    id: 'women',
    name: 'Women Haircuts',
    included: true,
  },
  {
    id: 'men',
    name: 'Men Haircuts',
    included: true,
  },
];

const salonIds = [
  'a4d8dd21-8727-4fd7-a7f9-9152e360c7db',
  'd8744b02-484b-4ad2-89fc-1f4e286842ed',
];

const Categories = ({ locationId, loading, baseCategories, refetch }) => {
  const [saveCategoryInclusionsMutation, { loading: saveLoading }] =
    useMutation(EDIT_CATEGORY_INCLUSIONS);

  const sortedCategories =
    salonIds.includes(locationId) && false
      ? salonCategories
      : sortBy(baseCategories, (c) => c.name.toLowerCase()).filter(
          (c) => c.type === serviceCategoryTypeKey,
        );

  const [categories, setCategories] = useState(sortedCategories);

  useEffect(() => {
    if (sortedCategories?.length && !categories?.length) {
      setCategories(sortedCategories);
    }
  }, [sortedCategories]);

  const onToggleCategoryInclusion = (id, included) => {
    const updatedCategories = categories.map((c) => {
      if (c.id === id) {
        return {
          ...c,
          included,
        };
      }
      return c;
    });
    setCategories(updatedCategories);
  };

  const autoSave = async () => {
    if (
      JSON.stringify(getAbbreviatedCategoryArray(sortedCategories)) !==
      JSON.stringify(getAbbreviatedCategoryArray(categories))
    ) {
      console.log('Saving categories...');
      console.log(
        'JSON.stringify(sortedCategories',
        JSON.stringify(sortedCategories),
      );
      console.log('JSON.stringify(categories)', JSON.stringify(categories));

      try {
        await saveCategoryInclusionsMutation({
          variables: { categories },
          onCompleted: async () => {
            await refetch();
          },
        });
        console.log('Categories saved successfully');
      } catch (error) {
        console.error('Error saving categories:', error);
      }
    }
  };

  // Auto-save interval effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!saveLoading) {
        autoSave();
      }
    }, buttonTriggeredAutoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [sortedCategories, categories]);

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <PromptsSection hideOverflow>
        <PromptContainer overflow>
          <ListInputTitleContainer>
            <PromptTitleText>
              Select which of these service categories your business offers
            </PromptTitleText>
          </ListInputTitleContainer>
          <ListInputSection widthPercentage={60}>
            <AutoScrollWrapper>
              {categories.map((category) => {
                const { id, name, included } = category;
                const label = formatKeyToLabel(name);
                return (
                  <ListInputContainer>
                    <StartAlignedMediumDarkEssText>
                      {label}
                    </StartAlignedMediumDarkEssText>
                    <SwitchToggle
                      value={included}
                      onChange={() => onToggleCategoryInclusion(id, !included)}
                    />
                  </ListInputContainer>
                );
              })}
            </AutoScrollWrapper>
          </ListInputSection>
        </PromptContainer>
      </PromptsSection>
    </>
  );
};

export default Categories;
