import React, { useContext } from 'react';
import {
  LightDarkSMText,
  MediumDarkEssText,
  SelectIcon,
} from '../../../../styles/shared-styled-components';
import { locationCustomPromptKey } from '../../../../utils/constants';
import { objectIsEmpty } from '../../../../utils/data';
import { checkIfUserIsSuperAdmin } from '../../../../utils/user';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import {
  EmptyDataContainer,
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const ServiceFAQ = ({ selectedFaq, serviceCorpus, onUpdate, agentName }) => {
  const { user } = useContext(BaseContext);

  const isSuperAdmin = checkIfUserIsSuperAdmin(user);

  const selectedFaqCategoryId = selectedFaq?.categoryId;
  const category = serviceCorpus[selectedFaqCategoryId];
  const categoryPrompts = category?.prompts || {};
  const promptObject = categoryPrompts[selectedFaq?.id];
  const faq = promptObject?.prompt;

  const containsValidFaq = !objectIsEmpty(selectedFaq);

  if (!containsValidFaq) {
    return (
      <EmptyDataContainer>
        <LightDarkSMText>Select an FAQ to edit</LightDarkSMText>
        <SelectIcon />
      </EmptyDataContainer>
    );
  }

  const isCustomFaq = faq?.type === locationCustomPromptKey;
  const utterance = faq?.utterance;
  const resolution = promptObject?.resolutions?.[0];
  const resolutionAnswer = resolution?.explanation || '';

  return (
    <PromptsSection>
      <PromptContainer>
        <PromptTitleText>When someone asks:</PromptTitleText>
        {isCustomFaq || isSuperAdmin ? (
          <PromptInput
            value={utterance}
            onChange={(e) => {
              onUpdate({ faq, utterance: e.target.value });
            }}
            multiline
            numRows={2}
          />
        ) : (
          <>
            <MediumDarkEssText>"{utterance}"</MediumDarkEssText>
          </>
        )}
      </PromptContainer>
      <PromptContainer>
        <PromptTitleText>{agentName} will answer:</PromptTitleText>
        <PromptInput
          value={resolutionAnswer}
          onChange={(e) => {
            onUpdate({ faq, answer: e.target.value });
          }}
          multiline
          numRows={8}
        />
      </PromptContainer>
    </PromptsSection>
  );
};

export default ServiceFAQ;
