import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { STORE_ZENOTI_API_KEY } from '../../../api/mutations/app-connections';
import {
  CenteredDiv,
  ColumnCenteredDiv,
  Modal,
  ModalActions,
  ModalContainer,
  ModalTitleText,
  PrimaryButton,
  ZenotiLogo,
} from '../../../styles/shared-styled-components';
import { singleLocationBusinessType } from '../../../utils/constants';
import { getUserBusiness, getUserLocation } from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input/Input';

const ZenotiAPIKeyModal = ({ isOpen, onClose, onSuccess }) => {
  const { user } = useContext(BaseContext);
  const business = getUserBusiness(user);
  const location = getUserLocation(user);
  const isMultiLocation = business?.type !== singleLocationBusinessType;

  const [storeZenotiApiKeyMutation] = useMutation(STORE_ZENOTI_API_KEY);

  const baseLocationNameToQuery = isMultiLocation ? location?.name : '';

  const [apiKey, setApiKey] = useState('');
  const [locationNameToQuery, setLocationNameToQuery] = useState(
    baseLocationNameToQuery,
  );

  const onSave = () => {
    storeZenotiApiKeyMutation({
      variables: {
        apiKey,
        centerName: locationNameToQuery,
      },
      onCompleted: async (data) => {
        const success = data.storeZenotiApiKey;
        await onSuccess();
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <CenteredDiv>
          <ZenotiLogo />
        </CenteredDiv>
        <ModalTitleText>Enter Your Zenoti API Key</ModalTitleText>
        <Input
          id='api-key'
          value={apiKey}
          onChange={(e) => {
            setApiKey(e.target.value);
          }}
          label='API Key'
          isRequired={false}
          removeGap={true}
          useSmallWidth={true}
        />
        {isMultiLocation && (
          <Input
            id='location-name'
            value={locationNameToQuery}
            onChange={(e) => {
              setLocationNameToQuery(e.target.value);
            }}
            label='Center name'
            isRequired={false}
            removeGap={true}
            useSmallWidth={true}
          />
        )}
        <ModalActions>
          <ColumnCenteredDiv>
            <PrimaryButton
              disabled={!apiKey || (isMultiLocation && !locationNameToQuery)}
              onClick={onSave}
            >
              Save
            </PrimaryButton>
          </ColumnCenteredDiv>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default ZenotiAPIKeyModal;
