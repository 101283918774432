import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import { INITIALIZE_APP } from '../../../api/mutations/app-connections';
import { EDIT_LOCATION_ONBOARDING } from '../../../api/mutations/enterprise';
import { GET_GHL_AUTH_URI } from '../../../api/queries/app-connections';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import FormPage from '../../../components/Form/FormPage';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { CompleteButtonCheckmarkIcon } from '../../../styles/shared-styled-components';
import {
  connectedStatusKey,
  ghlAppKey,
  hubSpotKey,
  initializedStatusKey,
  meevoKey,
  mindBodyKey,
  zenotiKey,
} from '../../../utils/constants';
import { valueIsEmpty } from '../../../utils/data';
import { getUserLocation } from '../../../utils/user';
import {
  CompleteSystemIntegrationButton,
  SystemIntegrationButton,
} from './styled';

const websiteId = 'website';
const websiteErrorText = 'Please enter a valid URL';

const Integration = () => {
  const { user, userLoading } = useContext(BaseContext);
  const navigate = useNavigate();

  const [
    editLocationOnboardingMutation,
    { loading: editLocationOnboardingLoading },
  ] = useMutation(EDIT_LOCATION_ONBOARDING);
  const [getGHLAuthUri, { data: ghlAuthUriData }] = useLazyQuery(
    GET_GHL_AUTH_URI,
    { fetchPolicy: 'network-only' },
  );
  const [initializeAppMutation, { loading: initializeAppLoading }] =
    useMutation(INITIALIZE_APP);

  const location = getUserLocation(user);
  const onboardingData = location.onboardingStatus || {};
  const bookingSystem = onboardingData.bookingSystem;
  const crmSystem = onboardingData.crmSystem;
  const bookingSystemStatus = onboardingData.bookingSystemStatus;
  const crmSystemStatus = onboardingData.crmSystemStatus;
  const bookingSystemInitialized = bookingSystemStatus === initializedStatusKey;
  const crmSystemInitialized = crmSystemStatus === initializedStatusKey;

  const [mindBodySiteIdModalIsOpen, setMindBodySiteIdModalIsOpen] =
    useState(false);
  const [mindBodyActivationCodeUrl, setMindBodyActivationCodeUrl] =
    useState('');
  const [
    mindBodyActivationCodeUrlModalIsOpen,
    setMindBodyActivationCodeUrlModalIsOpen,
  ] = useState(false);
  const [zenotiApiKeyModalIsOpen, setZenotiApiKeyModalIsOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [authModalIsOpen, setAuthModalIsOpen] = useState(false);
  const [initializeMarketingMenuAnchorEl, setInitializeMarketingMenuAnchorEl] =
    useState(null);
  const [loading, setLoading] = useState(false);

  const initializeMarketingMenuOpenBoolean = Boolean(
    initializeMarketingMenuAnchorEl,
  );

  useEffect(() => {
    if (ghlAuthUriData) {
      const uri = ghlAuthUriData.getGHLAuthUri;
      window.location.href = uri;
    }
  }, [ghlAuthUriData]);

  const onSuccessfulSiteIdInput = (activationCodeUrl) => {
    setMindBodySiteIdModalIsOpen(false);
    setMindBodyActivationCodeUrl(activationCodeUrl);
    setMindBodyActivationCodeUrlModalIsOpen(true);
  };

  const onSuccessfulMindBodyConnection = () => {
    setMindBodyActivationCodeUrlModalIsOpen(false);
    setSnackbarMessage('MindBody connection successful');
    navigate(Paths.training);
  };

  const onClickConnectZenoti = () => {
    if (!user) {
      setAuthModalIsOpen(true);
    } else {
      setZenotiApiKeyModalIsOpen(true);
    }
  };

  const onSuccessfulZenotiConnection = async () => {
    setZenotiApiKeyModalIsOpen(false);
    setSnackbarMessage('Zenoti API key upload successful');
  };

  const onClickConnectGHL = () => {
    if (!user) {
      setAuthModalIsOpen(true);
    } else {
      setLoading(true);
      getGHLAuthUri();
    }
  };

  const onInitialize = (appName, asEmr = true) => {
    initializeAppMutation({
      variables: {
        appName,
        asEmr,
      },
      onCompleted: async (data) => {
        const success = data.initializeApp?.success;

        if (success) {
          setSnackbarMessage(`Initialization started`);
        }
      },
    });
  };

  const onClickContinue = () => {
    editLocationOnboardingMutation({
      variables: {
        onboardingStatus: {
          ...onboardingData,
          crmSystem,
          bookingSystem,
        },
      },
      onCompleted: async (data) => {
        const success = data.editLocationOnboarding?.success;
        if (success) {
          navigate(Paths.integrationOnboarding);
        }
      },
    });
  };

  const isGhlBooking = bookingSystem === ghlAppKey;
  const isGhlCrm = crmSystem === ghlAppKey;

  const bookingSystemButton =
    isGhlBooking && isGhlCrm ? (
      bookingSystemInitialized ? (
        <CompleteSystemIntegrationButton>
          GHL initialized <CompleteButtonCheckmarkIcon />
        </CompleteSystemIntegrationButton>
      ) : bookingSystemStatus === connectedStatusKey ? (
        <SystemIntegrationButton
          removeBottomMargin
          onClick={(e) => setInitializeMarketingMenuAnchorEl(e.currentTarget)}
        >
          Initialize GHL
        </SystemIntegrationButton>
      ) : (
        <SystemIntegrationButton
          removeBottomMargin
          onClick={onClickConnectGHL}
        >
          Connect GHL
        </SystemIntegrationButton>
      )
    ) : bookingSystem === zenotiKey ? (
      bookingSystemInitialized ? (
        <CompleteSystemIntegrationButton>
          Zenoti initialized <CompleteButtonCheckmarkIcon />
        </CompleteSystemIntegrationButton>
      ) : bookingSystemStatus === connectedStatusKey ? (
        <SystemIntegrationButton onClick={() => onInitialize(zenotiKey)}>
          Initialize Zenoti
        </SystemIntegrationButton>
      ) : (
        <SystemIntegrationButton
          onClick={onClickConnectZenoti}
          removeBottomMargin
        >
          Connect Zenoti
        </SystemIntegrationButton>
      )
    ) : bookingSystem === mindBodyKey ? (
      bookingSystemInitialized ? (
        <CompleteSystemIntegrationButton>
          Mindbody initialized <CompleteButtonCheckmarkIcon />
        </CompleteSystemIntegrationButton>
      ) : bookingSystemStatus === connectedStatusKey ? (
        <SystemIntegrationButton onClick={() => onInitialize(mindBodyKey)}>
          Initialize Mindbody
        </SystemIntegrationButton>
      ) : (
        <SystemIntegrationButton
          removeBottomMargin
          onClick={() => setMindBodySiteIdModalIsOpen(true)}
        >
          Connect Mindbody
        </SystemIntegrationButton>
      )
    ) : bookingSystem === meevoKey ? (
      bookingSystemInitialized ? (
        <CompleteSystemIntegrationButton>
          Meevo initialized <CompleteButtonCheckmarkIcon />
        </CompleteSystemIntegrationButton>
      ) : bookingSystemStatus === connectedStatusKey ? (
        <SystemIntegrationButton>Initialize Meevo</SystemIntegrationButton>
      ) : (
        <SystemIntegrationButton removeBottomMargin>
          Connect Meevo
        </SystemIntegrationButton>
      )
    ) : (
      <></>
    );
  const crmSystemButton =
    isGhlCrm && !isGhlBooking ? (
      crmSystemInitialized ? (
        <CompleteSystemIntegrationButton>
          GHL initialized <CompleteButtonCheckmarkIcon />
        </CompleteSystemIntegrationButton>
      ) : bookingSystemStatus === connectedStatusKey ? (
        <SystemIntegrationButton
          removeBottomMargin
          onClick={(e) => setInitializeMarketingMenuAnchorEl(e.currentTarget)}
        >
          Initialize GHL
        </SystemIntegrationButton>
      ) : (
        <SystemIntegrationButton
          removeBottomMargin
          onClick={onClickConnectGHL}
        >
          Connect GHL
        </SystemIntegrationButton>
      )
    ) : crmSystem === hubSpotKey ? (
      crmSystemInitialized ? (
        <CompleteSystemIntegrationButton>
          HubSpot initialized <CompleteButtonCheckmarkIcon />
        </CompleteSystemIntegrationButton>
      ) : bookingSystemStatus === connectedStatusKey ? (
        <SystemIntegrationButton removeBottomMargin>
          Initialize HubSpot
        </SystemIntegrationButton>
      ) : (
        <SystemIntegrationButton removeBottomMargin>
          Connect HubSpot
        </SystemIntegrationButton>
      )
    ) : (
      <></>
    );

  const inputs = [bookingSystemButton, crmSystemButton];

  const continueEnabled =
    !valueIsEmpty(bookingSystem) &&
    !valueIsEmpty(crmSystem) &&
    bookingSystemInitialized &&
    crmSystemInitialized;

  if (userLoading || editLocationOnboardingLoading || loading) {
    return <LoadingIndicator />;
  }

  return (
    user && (
      <FormPage
        title='Location Setup - Integrate Your Data'
        currentStep={2}
        totalNumSteps={3}
        isLoading={loading}
        inputs={inputs}
        continueEnabled={continueEnabled}
        onContinue={onClickContinue}
        onBack={() => navigate(Paths.systemOnboarding)}
      />
    )
  );
};

export default Integration;
