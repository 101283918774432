import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import { CREATE_AGENT } from '../../../api/mutations/agent';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Header from '../../../components/NavBar/Header';
import {
  ColumnCenteredDiv,
  ContentContainer,
  DarkLargeText,
  MediumPrimaryButton,
  PageContainer,
} from '../../../styles/shared-styled-components';
import { addAgentToMyUserInCache } from '../../../utils/cache';
import { agentProductMap, liveKey } from '../../../utils/constants';
import { getUserLocation } from '../../../utils/user';
import {
  ActionSectionContainer,
  AgentContainer,
  AgentHeadShot,
  AgentHeadShotContainer,
  AgentSection,
  AgentSubtitleText,
  AgentTitleContainer,
  AgentTitleText,
  ComingSoonText,
} from './styled';

const agentProductKeys = Object.keys(agentProductMap);

const AgentSelection = () => {
  const navigate = useNavigate();
  const { cache } = useApolloClient();

  const { user, userLoading } = useContext(BaseContext);
  const location = getUserLocation(user);
  const email = user?.email;

  const [createAgentMutation, { loading: createAgentLoading }] =
    useMutation(CREATE_AGENT);

  const onCreateAgent = (productKey) => {
    const locationId = location.id;

    createAgentMutation({
      variables: {
        productKey,
        locationId,
        userId: null,
      },
      onCompleted: async (data) => {
        const agent = data.createAgent;

        await addAgentToMyUserInCache(agent, email, cache);

        const agentId = agent.id;
        navigate(Paths.agentAppConnection.replace(':agentId', agentId));
      },
    });
  };

  const isLoading = userLoading || createAgentLoading;

  if (isLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <Header />
      <PageContainer>
        <ContentContainer>
          <ColumnCenteredDiv>
            <DarkLargeText>
              Select the agent you want to hire for your business
            </DarkLargeText>
          </ColumnCenteredDiv>
          <AgentSection>
            {agentProductKeys.map((key) => {
              const product = agentProductMap[key];
              const { defaultName, avatar, status, description, color } =
                product;
              return (
                <AgentContainer>
                  <AgentHeadShotContainer>
                    <AgentHeadShot
                      src={avatar}
                      color={color}
                    />
                  </AgentHeadShotContainer>
                  <AgentTitleContainer>
                    <AgentTitleText>{defaultName}</AgentTitleText>
                    <AgentSubtitleText>The {description}</AgentSubtitleText>
                  </AgentTitleContainer>
                  <ActionSectionContainer>
                    {status === liveKey ? (
                      <MediumPrimaryButton onClick={() => onCreateAgent(key)}>
                        Create
                      </MediumPrimaryButton>
                    ) : (
                      <ComingSoonText>Coming Soon ✨</ComingSoonText>
                    )}
                  </ActionSectionContainer>
                </AgentContainer>
              );
            })}
          </AgentSection>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default AgentSelection;
