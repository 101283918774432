import styled from 'styled-components';
import {
  PauseIcon as BasePauseIcon,
  CenteredDiv,
  ExtraSmallGapStyle,
  LargeTinyIconSize,
  OutlinePlayIcon,
} from '../../../styles/shared-styled-components';

export const CarouselContainer = styled.div`
  position: absolute;
  bottom: ${(props) => props.topOffset && `-${props.topOffset}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
`;

export const DotContainer = styled(CenteredDiv)`
  ${ExtraSmallGapStyle}
`;

export const UnfilledDot = styled.div`
  border-radius: 50%;
  height: 6px;
  width: 6px;
  border: 1px solid ${({ theme }) => theme.color.lightPrimary};
`;

export const FilledDot = styled(UnfilledDot)`
  border: 1px solid ${({ theme }) => theme.color.lightPrimary};
  background-color: ${({ theme }) => theme.color.lightPrimary};
`;

export const PauseIcon = styled(BasePauseIcon)`
  ${LargeTinyIconSize}
`;

export const PlayIcon = styled(OutlinePlayIcon)`
  ${LargeTinyIconSize}
`;
