import React, { useState } from 'react';
import {
  CalendarIcon,
  FullHeightCenteredDiv,
  LightDarkEssText,
  MediumDarkEssText,
} from '../../../styles/shared-styled-components';
import { dateToTextFormat } from '../../../utils/date';
import PopperMenuCalendar from '../../Schedule/Calendar/PopperMenuCalendar';
import {
  FilterContainer,
  FilterTextContainer,
  RangeFilterContainer,
} from '../shared-filter-styles';
import { ArrowIcon } from './styled';

const DateFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  startLabel = 'Start Date',
  endLabel = 'End Date',
  center = false,
  useFullWidth = false,
  placement = 'bottom-start',
}) => {
  const [startDateMenuAnchorEl, setStartDateMenuAnchorEl] = useState();
  const [endDateMenuAnchorEl, setEndDateMenuAnchorEl] = useState();

  const startDateMenuOpenBoolean = Boolean(startDateMenuAnchorEl);
  const endDateMenuOpenBoolean = Boolean(endDateMenuAnchorEl);

  return (
    <RangeFilterContainer
      center={center}
      useFullWidth={useFullWidth}
    >
      <FilterContainer>
        <FilterTextContainer>
          <MediumDarkEssText>{startLabel}</MediumDarkEssText>
          <CalendarIcon
            selected={startDateMenuOpenBoolean}
            onClick={(e) => setStartDateMenuAnchorEl(e.currentTarget)}
          />
          <PopperMenuCalendar
            date={startDate}
            open={startDateMenuOpenBoolean}
            anchorElement={startDateMenuAnchorEl}
            onClose={() => setStartDateMenuAnchorEl()}
            onChange={(d) => {
              setStartDate(d);
              setStartDateMenuAnchorEl();
            }}
            placement={placement}
          />
        </FilterTextContainer>
        {startDate ? (
          <LightDarkEssText>{dateToTextFormat(startDate)}</LightDarkEssText>
        ) : (
          <LightDarkEssText>
            <br></br>
          </LightDarkEssText>
        )}
      </FilterContainer>
      <FullHeightCenteredDiv>
        <ArrowIcon />
      </FullHeightCenteredDiv>
      <FilterContainer end>
        <FilterTextContainer end>
          <MediumDarkEssText>{endLabel}</MediumDarkEssText>
          <CalendarIcon
            selected={endDateMenuOpenBoolean}
            onClick={(e) => {
              setEndDateMenuAnchorEl(e.currentTarget);
            }}
          />
          <PopperMenuCalendar
            date={endDate}
            open={endDateMenuOpenBoolean}
            anchorElement={endDateMenuAnchorEl}
            onClose={() => setEndDateMenuAnchorEl()}
            onChange={(d) => {
              setEndDate(d);
              setEndDateMenuAnchorEl();
            }}
            placement='bottom-start'
          />
        </FilterTextContainer>
        {endDate ? (
          <LightDarkEssText>{dateToTextFormat(endDate)}</LightDarkEssText>
        ) : (
          <LightDarkEssText>
            <br></br>
          </LightDarkEssText>
        )}
      </FilterContainer>
    </RangeFilterContainer>
  );
};

export default DateFilter;
