import { valueIsEmpty } from './data';

export const getIntFromCurrency = (currency) => {
  return parseInt(currency.toString().replaceAll(',', ''));
};

export const getFloatFromCurrency = (currency) => {
  return parseFloat(currency.toString().replaceAll(',', ''));
};

export const arrowSeparatorString = ' →  ';

const defaultNumDecimals = 2;

export const getAbbreviatedMoneyString = (
  n,
  numDecimals = defaultNumDecimals,
) => {
  if (valueIsEmpty(n)) return null;
  if (n < 1e3) return '$' + n.toFixed(numDecimals);
  if (n >= 1e3 && n < 1e6) return '$' + +(n / 1e3).toFixed(0) + 'K';
  if (n >= 1e6 && n < 1e9) return '$' + +(n / 1e6).toFixed(numDecimals) + 'M';
  if (n >= 1e9 && n < 1e12) return '$' + +(n / 1e9).toFixed(numDecimals) + 'B';
  if (n >= 1e12) return '$' + +(n / 1e12).toFixed(numDecimals) + 'T';
};

export const getAbbreviatedMoneyStringRange = (lower, upper) => {
  return `${getAbbreviatedMoneyString(
    lower,
  )}${arrowSeparatorString}${getAbbreviatedMoneyString(upper)}`;
};

export const getAnonymizedAndAbbreviatedMoneyString = (
  n,
  numDecimals = defaultNumDecimals,
) => {
  const moneyString = getAbbreviatedMoneyString(n, numDecimals);

  const unit = moneyString.slice(-1);

  return `$***${unit}`;
};

export const getFormattedMoneyString = (n, numDecimals = 2) => {
  if (n != null) {
    return `$${n.toLocaleString(undefined, {
      maximumFractionDigits: numDecimals,
    })}`;
  }
};

export const getFormattedMoneyStringWithNoCents = (n) => {
  if (n != null) {
    return `$${n.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
  }
};

export const getFormattedMoneyStringRange = (lower, upper) => {
  return `${getFormattedMoneyStringWithNoCents(
    lower,
  )}${arrowSeparatorString}${getFormattedMoneyStringWithNoCents(upper)}`;
};

export const getIntWithFallback = (value, fallback = 0) => {
  if (!valueIsEmpty(value)) {
    return parseInt(value);
  }

  return fallback;
};

export const getFormattedPercentageString = (percentage) => {
  const value = percentage * 100;
  const formattedValue = value.toFixed(1);
  return `${formattedValue}%`;
};

export const formatNumber = (n) => {
  if (valueIsEmpty(n)) {
    return '';
  }

  return n.toLocaleString();
};

export const generateRandomFloat = (min, max) => {
  return Math.random() * (max - min) + min;
};

export const generateRandomFloats = (min, max, n) => {
  const floats = [];

  for (let i = 0; i < n; i++) {
    const randomFloat = generateRandomFloat(min, max);
    floats.push(randomFloat);
  }

  return floats;
};

export const formatPhoneNumber = (phoneNumberString, inText = false) => {
  // Clean the input string, removing any non-digit characters
  const cleaned = ('' + phoneNumberString?.replace('+1', '')).replace(
    /\D/g,
    '',
  );

  // Check if the cleaned number length matches a typical phone number length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // If matched, format the number as (XXX) XXX-XXXX
    return !inText
      ? '(' + match[1] + ') ' + match[2] + '-' + match[3]
      : `${match[1]}-${match[2]}-${match[3]}`;
  }

  // If not matched, return the original string
  return phoneNumberString;
};

export const safeParseInt = (value) => {
  if (value == null) {
    return null;
  }

  const formattedValue = value?.toString()?.replaceAll(',', '');

  return parseInt(formattedValue);
};

export const safeParseFloat = (value) => {
  if (value == null) {
    return null;
  }

  const formattedValue = value?.toString()?.replaceAll(',', '');

  return parseFloat(formattedValue);
};

export default {
  getIntFromCurrency,
  getAbbreviatedMoneyStringRange,
  getIntWithFallback,
  getFormattedPercentageString,
  formatNumber,
};
