import React, { useEffect, useState } from 'react';
import {
  AdornmentContainer,
  DeleteButtonContainer,
  DollarIcon,
  Dropdown,
  DropdownLabel,
  EndAlignedFlexDiv,
  Form,
  InputContainer,
  InputSubtitleText,
  InputTitleText,
  MediumDarkExtraSmallText,
  MenuItem,
  PercentIcon,
  PhoneInput,
  ResetIcon,
  SmallPrimaryCopyIcon,
  SpaceBetweenFlexDiv,
  TextInput,
  Tooltip,
  TooltipSubtitleText,
} from '../../../styles/shared-styled-components';
import {
  dollarInputType,
  dropdownInputType,
  numericInputTypes,
  percentInputType,
  phoneInputType,
  textInputType,
} from '../../../utils/constants';
import { sanitizeInput } from '../../../utils/data';
import SwitchToggle from '../../Micro/SwitchToggle/SwitchToggle';
import NumberFieldFormat from '../../NumberFieldFormat';
import CustomVariablesMenu from '../CustomVariablesMenu';

export const sanitizeIntegerInput = (e, handleOnValid, handleOnInvalid) => {
  const isValidInput = sanitizeInput(e, 'integer');
  if (isValidInput) {
    handleOnValid();
  } else {
    handleOnInvalid();
  }
};

const getHeightPerRow = (numRows) => {
  if (numRows < 4) {
    return 45;
  } else if (numRows === 4) {
    return 42;
  } else if (numRows < 7) {
    return 41;
  } else if (numRows < 9) {
    return 40;
  } else {
    return 38;
  }
};

export const Input = ({
  id,
  title,
  subtitle = '',
  value,
  onChange,
  label,
  options = [],
  renderCondition = true,
  containsError = false,
  errorMessage = '',
  type = textInputType,
  isRequired = false,
  CustomInputComponent = null,
  renderInputOnly = false,
  useSmallWidth = false,
  useFullWidth = false,
  useExtraSmallWidth = false,
  fixedHeight = null,
  fixedWidth = null,
  centerInputLabel = false,
  useSmallGap = false,
  useExtraSmallGap = false,
  removeGap = false,
  useSmallText = false,
  useExtraSmallText = false,
  onKeyPress = () => {},
  displayErroMessages = true,
  removeAutoMargins = false,
  multiline = false,
  numRows = 4,
  variables = [],
  showVariableTypes = false,
  showAutoGenerateToggle = false,
  autoGenerate = false,
  onChangeAutoGenerate = () => {},
  inputRef,
  addCopyButton = false,
  onBlur = () => {},
  onCopy = () => {},
  includeDeleteButton = false,
  onDelete = () => {},
}) => {
  const [errorMessageText, setErrorMessageText] = useState(errorMessage);
  const [
    displayFlashingIntegerInputErrorMessage,
    setDisplayFlashingIntegerInputErrorMessage,
  ] = useState(false);

  const removeFlashingIntegerInputErrorMessage = () => {
    setErrorMessageText('');
    setDisplayFlashingIntegerInputErrorMessage(false);
  };

  useEffect(() => {
    setErrorMessageText(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (displayFlashingIntegerInputErrorMessage) {
      setErrorMessageText('Numbers only!');
      setTimeout(removeFlashingIntegerInputErrorMessage, 2000);
    } else {
      setErrorMessageText('');
    }
  }, [displayFlashingIntegerInputErrorMessage]);

  const isNumericType = numericInputTypes.includes(type);

  const startAdornment =
    type === percentInputType ? (
      <AdornmentContainer>
        <PercentIcon />
      </AdornmentContainer>
    ) : type === dollarInputType ? (
      <AdornmentContainer>
        <DollarIcon />
      </AdornmentContainer>
    ) : null;

  const endAdornment = addCopyButton ? (
    <Tooltip title={<TooltipSubtitleText>Copy</TooltipSubtitleText>}>
      <AdornmentContainer onClick={onCopy}>
        <SmallPrimaryCopyIcon />
      </AdornmentContainer>
    </Tooltip>
  ) : null;

  const sanitizeInputFunction = isNumericType
    ? (e) => {
        sanitizeIntegerInput(
          e,
          () =>
            displayErroMessages &&
            setDisplayFlashingIntegerInputErrorMessage(false),
          () =>
            displayErroMessages &&
            setDisplayFlashingIntegerInputErrorMessage(true),
        );
      }
    : () => {};

  // If adding variables, force label to shrink
  const inputLabelProps = variables?.length
    ? {
        shrink: true,
      }
    : {};

  const InputField = CustomInputComponent ? (
    CustomInputComponent
  ) : type === dropdownInputType ? (
    <Form
      containsError={containsError}
      useSmallWidth={useSmallWidth}
      useFullWidth={useFullWidth}
      useExtraSmallWidth={useExtraSmallWidth}
      fixedHeight={fixedHeight}
      fixedWidth={fixedWidth}
    >
      <DropdownLabel>{label}</DropdownLabel>
      <Dropdown
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        containsError={containsError}
        useSmallText={useSmallText}
        useExtraSmallText={useExtraSmallText}
      >
        {options.map((option) => (
          <MenuItem
            value={option.key}
            key={option.key}
          >
            {option.label}
          </MenuItem>
        ))}
      </Dropdown>
    </Form>
  ) : type === phoneInputType ? (
    <PhoneInput
      value={value}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      containsError={containsError}
      helperText={errorMessageText}
      useSmallWidth={useSmallWidth}
      inputRef={inputRef}
    />
  ) : multiline ? (
    <TextInput
      value={autoGenerate ? 'AI will auto-generate this message' : value}
      disabled={!!autoGenerate}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      onKeyDown={sanitizeInputFunction}
      containsError={containsError}
      helperText={errorMessageText ? errorMessageText : ''}
      useSmallWidth={useSmallWidth}
      useFullWidth={useFullWidth}
      useSmallText={useSmallText}
      useExtraSmallText={useExtraSmallText}
      removeAutoMargins={false}
      fixedHeight={numRows * getHeightPerRow(numRows)}
      multiline
      rows={numRows}
      expanded
      inputRef={inputRef}
      InputLabelProps={inputLabelProps}
      fixedWidth={fixedWidth}
    />
  ) : (
    <TextInput
      type={isNumericType && 'tel'}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      onKeyDown={sanitizeInputFunction}
      InputProps={{
        startAdornment,
        endAdornment,
        inputComponent: isNumericType && NumberFieldFormat,
      }}
      containsError={containsError}
      helperText={errorMessageText ? errorMessageText : ''}
      useSmallWidth={useSmallWidth}
      useExtraSmallWidth={useExtraSmallWidth}
      useFullWidth={useFullWidth}
      useSmallText={useSmallText}
      useExtraSmallText={useExtraSmallText}
      removeAutoMargins={removeAutoMargins}
      onKeyPress={onKeyPress}
      inputRef={inputRef}
      InputLabelProps={inputLabelProps}
      fixedWidth={fixedWidth}
      fixedHeight={fixedHeight}
    />
  );

  const onAddVariable = (variable) => {
    const cursorPosition = inputRef.current.selectionStart || 0;
    let valueToUse = value || '';
    const newValue =
      valueToUse.slice(0, cursorPosition) +
      `{{${variable}}}` +
      valueToUse.slice(cursorPosition);
    onChange({
      target: { value: newValue },
    });
  };

  return (
    renderCondition && (
      <>
        {renderInputOnly ? (
          InputField
        ) : (
          <InputContainer
            id={id}
            useFullWidth={useFullWidth}
            useSmallGap={useSmallGap}
            useExtraSmallGap={useExtraSmallGap}
            removeGap={removeGap}
          >
            {variables.length || showAutoGenerateToggle ? (
              <SpaceBetweenFlexDiv>
                {variables.length ? (
                  <CustomVariablesMenu
                    variables={variables}
                    onAddVariable={onAddVariable}
                    showTypeToggle={showVariableTypes}
                    disabled={autoGenerate}
                  />
                ) : (
                  <></>
                )}
                {showAutoGenerateToggle ? (
                  <EndAlignedFlexDiv gap={5}>
                    <MediumDarkExtraSmallText>
                      AI will auto-generate
                    </MediumDarkExtraSmallText>
                    <SwitchToggle value={autoGenerate} />
                  </EndAlignedFlexDiv>
                ) : (
                  <></>
                )}
              </SpaceBetweenFlexDiv>
            ) : (
              <></>
            )}
            <InputTitleText
              useFullWidthLabel
              centered={centerInputLabel}
            >
              {title}
              {isRequired && (
                <>
                  &nbsp;
                  <sup>*</sup>
                </>
              )}
              {subtitle && <InputSubtitleText>{subtitle}</InputSubtitleText>}
            </InputTitleText>
            {InputField}
            {includeDeleteButton && (
              <DeleteButtonContainer onClick={() => onDelete()}>
                <ResetIcon small />
              </DeleteButtonContainer>
            )}
          </InputContainer>
        )}
      </>
    )
  );
};

export default Input;
