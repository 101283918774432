import { useMutation } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import React, { useContext, useState } from 'react';
import { useMultiLocationUsers } from '../../../api/hooks/users';
import {
  ASSIGN_USERS_TO_LOCATION,
  CREATE_LOCATION_USER,
} from '../../../api/mutations/users';
import {
  CenteredDiv,
  ColumnCenteredDiv,
  DarkMLText,
  EmptyGapColumnCenteredDiv,
  LightDarkEssText,
  MediumDarkEssText,
  MediumPrimaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SMCheckbox,
  SpacedDiv,
  Tab,
  Tabs,
} from '../../../styles/shared-styled-components';
import {
  dropdownInputType,
  phoneInputType,
  userRoleOptions,
} from '../../../utils/constants';
import { getFormattedFullNameFromUser } from '../../../utils/name';
import { getUserLocationId } from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input';
import SplitInput from '../../Form/SplitInput/SplitInput';
import LoadingIndicator from '../../LoadingIndicator';

const CreateUserModal = ({ isOpen, onClose, onSave }) => {
  const { user } = useContext(BaseContext);
  const locationId = getUserLocationId(user);

  const {
    users: multiLocationUsersToAssign,
    loading: multiLocationUsersToAssignLoading,
    refetch: refetchMultiLocationUsers,
  } = useMultiLocationUsers({ locationId });
  const [createUserMutation, { loading: createUserLoading }] =
    useMutation(CREATE_LOCATION_USER);
  const [
    assignUsersToLocationMutation,
    { loading: assignUsersToLocationLoading },
  ] = useMutation(ASSIGN_USERS_TO_LOCATION);

  const sortedMultiLocationUsers = sortBy(multiLocationUsersToAssign, (u) => {
    const split = u?.email?.split('@');
    const tld = split?.[1] || '';
    return tld;
  });

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [role, setRole] = useState();
  const [userIdsToAssign, setUserIdsToAssign] = useState([]);
  const [tab, setTab] = useState(0);

  const onChangeAssignment = (userId, include) => {
    let updatedUserIds = [...userIdsToAssign];

    if (include) {
      updatedUserIds = Array.from(new Set([...updatedUserIds, userId]));
    } else {
      updatedUserIds = updatedUserIds.filter((id) => id !== userId);
    }

    setUserIdsToAssign(updatedUserIds);
  };

  const handleClose = () => {
    setUserIdsToAssign([]);
    onClose();
  };

  const onClickSave = () => {
    if (tab === 0) {
      createUserMutation({
        variables: {
          firstName,
          lastName,
          email,
          phone,
          role,
        },
        onCompleted: async (data) => {
          await refetchMultiLocationUsers();
          onSave(true);
          setUserIdsToAssign([]);
          handleClose();
        },
      });
    } else if (tab === 1) {
      assignUsersToLocationMutation({
        variables: {
          userIds: userIdsToAssign,
        },
        onCompleted: async (data) => {
          onSave(true);
          setUserIdsToAssign([]);
          handleClose();
        },
      });
    }
  };

  if (createUserLoading) {
    return <LoadingIndicator />;
  }

  const saveEnabled =
    tab === 0
      ? !!firstName && !!lastName && !!email && !!phone && !!role
      : tab === 1
      ? userIdsToAssign.length > 0
      : false;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer useSmallGap={true}>
        <DarkMLText>Create New User</DarkMLText>
        <ModalInputsContainer addTopMargin>
          <CenteredDiv>
            <Tabs
              value={tab}
              onChange={(_, key) => setTab(key)}
              addBottomMargin
            >
              <Tab
                value={0}
                label='Create new'
              />
              <Tab
                value={1}
                label='Assign existing'
              />
            </Tabs>
          </CenteredDiv>
          {tab === 0 ? (
            <>
              <EmptyGapColumnCenteredDiv>
                <SplitInput
                  id={'name'}
                  splitInputs={[
                    {
                      id: 'first',
                      value: firstName,
                      onChange: (e) => setFirstName(e.target.value),
                      label: 'First name',
                    },
                    {
                      id: 'last',
                      value: lastName,
                      onChange: (e) => setLastName(e.target.value),
                      label: 'Last name',
                    },
                  ]}
                  useExtraSmallGap={true}
                />
              </EmptyGapColumnCenteredDiv>
              <Input
                id={'role'}
                value={role}
                label={'Role'}
                type={dropdownInputType}
                options={userRoleOptions}
                onChange={(e) => setRole(e.target.value)}
              />
              <Input
                id={'email'}
                value={email}
                label={'Email'}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                id={'phone'}
                value={phone}
                label={'Phone'}
                onChange={(value) => setPhone(value)}
                type={phoneInputType}
              />
            </>
          ) : tab === 1 ? (
            <>
              <ModalInputsContainerScrollWrapper>
                <ColumnCenteredDiv>
                  {sortedMultiLocationUsers.map((u) => {
                    const user = u.user;
                    const alreadyIncluded = u.assignedToLocation;
                    const id = user.id;
                    const included = userIdsToAssign.includes(id);
                    return (
                      <SpacedDiv width={'55%'}>
                        <MediumDarkEssText>
                          {getFormattedFullNameFromUser(user)}
                        </MediumDarkEssText>
                        {alreadyIncluded ? (
                          <LightDarkEssText>(Assigned)</LightDarkEssText>
                        ) : (
                          <SMCheckbox
                            checked={included}
                            onClick={() => onChangeAssignment(id, !included)}
                          />
                        )}
                      </SpacedDiv>
                    );
                  })}
                </ColumnCenteredDiv>
              </ModalInputsContainerScrollWrapper>
            </>
          ) : (
            <></>
          )}
        </ModalInputsContainer>
        <ModalActions addTopMargin={true}>
          {tab === 0 ? (
            <MediumPrimaryButton
              onClick={onClickSave}
              disabled={!saveEnabled}
            >
              Create
            </MediumPrimaryButton>
          ) : tab === 1 ? (
            <MediumPrimaryButton
              onClick={onClickSave}
              disabled={!saveEnabled}
            >
              Assign users
            </MediumPrimaryButton>
          ) : (
            <></>
          )}
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default CreateUserModal;
