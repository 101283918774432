import React, { useState } from 'react';
import Paths from '../../../Paths';
import {
  FAQAnswer,
  FAQContainer,
  FAQHeaderSubText,
  FAQHeaderText,
  FAQQuestion,
  FAQQuestionContainer,
  FAQQuestionsContainer,
  FAQTextContainer,
} from '../styles/style-FAQ';
import { DemoButton } from '../styles/style-Homepage';

const faqData = [
  {
    question: 'Can I train the AI agents for my business?',
    answer: `We've designed all front desk agents to be completely trainable for whatever your business needs -- just like you'd train a new employee! Deposit policies, booking flows, membership packages, you name it; you'll be amazed at the amount of customization you're allowed.`,
  },
  {
    question: 'What kind of privacy measures are taken with my business data?',
    answer:
      `Our team previously built technology for large banks & pharmaceutical companies, so we understand the importance of consumer privacy. ` +
      `We take your data protection very seriously, and implement all the same security measures as the EMR/CRM tools you know and trust. ` +
      `We're fully HIPAA compliant, and deploy all the cutting-edge security techniques across our stack.`,
  },
  {
    question: 'Can the AI receptionist conduct phone conversations?',
    answer: `Yes, our AI receptionist can handle end-to-end phone calls in multiple languages, answering questions, booking appointments, and escalating to humans as needed!`,
  },
  {
    question:
      'Can I track conversations my AI agents are having, and interject when needed?',
    answer: `You're able to monitor all conversations end-to-end, and intercept or pause conversations whenever you want.`,
  },
  {
    question: 'How long does the setup take?',
    answer: `Integrating your systems is automatic and takes only a few minutes. Onboarding the AI agents to your business is extremely important, and does require ~1 hour of your time. But the good news is, unlike us humans, the AI agents will never forget your instructions!`,
  },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id='faq'>
      <FAQContainer>
        <FAQTextContainer>
          <FAQHeaderText>FAQ</FAQHeaderText>
          <FAQHeaderSubText>
            <p>
              Not sure if the AI front desk is right for you? Let's find a
              solution for your business
            </p>
            <p>
              <DemoButton href={Paths.calendlyLink}>Let's talk</DemoButton>
            </p>
          </FAQHeaderSubText>
        </FAQTextContainer>
        <FAQQuestionsContainer>
          {faqData.map((item, index) => (
            <FAQQuestionContainer
              key={index}
              onClick={() => toggleQuestion(index)}
              expanded={activeIndex === index}
            >
              <FAQQuestion>
                {item.question}
                <span>{activeIndex === index ? '-' : '+'}</span>
              </FAQQuestion>
              <FAQAnswer expanded={activeIndex === index}>
                {item.answer}
              </FAQAnswer>
            </FAQQuestionContainer>
          ))}
        </FAQQuestionsContainer>
      </FAQContainer>
    </div>
  );
};

export default FAQ;
