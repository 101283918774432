import React, { useContext, useEffect, useRef, useState } from 'react';
import Input from '../../../../components/Form/Input';
import {
  AdaptablePrimaryButton,
  AdaptableSecondaryButton,
  SmallPrimaryEditIcon,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkEssText,
  StartAlignedMediumDarkLargeTinyText,
} from '../../../../styles/shared-styled-components';
import {
  bookingScriptNodeType,
  conditionalScriptNodeType,
  customMessageTemplateVariables,
  dropdownInputType,
  dynamicVariableType,
  nodeToEditIdCookieKey,
  nodeTypeLabelMap,
  nodeTypes,
} from '../../../../utils/constants';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import { VariablesInputLabel } from '../../../Form/CustomVariablesMenu/styled';
import SelectOfferingModal from '../../../Modals/SelectOfferingModal/SelectOfferingModal';
import {
  ActionButtonsContainer,
  CloseDrawerIcon,
  CloseDrawerIconContainer,
  CloseDrawerIconSection,
  DrawerContainer,
  DrawerContentContainer,
  InputContainer,
  NameContainer,
  NameText,
  SubfieldContainer,
  SubfieldsSection,
} from './styled';

const NodeDrawer = ({ isOpen, node, onUpdate, nodes, offerings }) => {
  const { removeCookie } = useContext(BaseContext);
  const contentInputRef = useRef();

  const handleClose = () => {
    removeCookie(nodeToEditIdCookieKey);
  };

  const data = node?.data;
  const id = data?.id;

  console.log('Node data', data);

  const [type, setType] = useState(data?.type || '');
  const [bookingOffering, setBookingOffering] = useState(data?.bookingOffering);
  const [selectOfferingModalOpen, setSelectOfferingModalOpen] = useState(false);
  const [title, setTitle] = useState(data?.label || '');
  const [condition, setCondition] = useState(data?.condition || '');
  const [expectedResponse, setExpectedResponse] = useState(
    data?.expectedResponse || '',
  );
  const [content, setContent] = useState(data?.content || '');

  useEffect(() => {
    if (data) {
      setType(data.type || '');
      setBookingOffering(data.bookingOffering);
      setTitle(data.label || '');
      setCondition(data.condition || '');
      setExpectedResponse(data.expectedResponse || '');
      setContent(data.content || '');
    }
  }, [data]);

  const onUpdateBookingOffering = (offering) => {
    setBookingOffering(offering);
  };

  const otherNodes = [];
  const numNodes = nodes.length;
  const containsOtherNodes = numNodes > 1;
  if (containsOtherNodes) {
    nodes.map((n) => {
      if (n.id !== id) {
        otherNodes.push({
          name: `'${n.data.label}' Response`,
          value: `'${n.data.label}' Response (ID=${n.id.slice(0, 5)})`,
          type: dynamicVariableType,
        });
      }
    });
  }
  const variables = [...customMessageTemplateVariables, ...otherNodes];

  return (
    isOpen &&
    data && (
      <>
        <DrawerContainer>
          <DrawerContentContainer>
            <CloseDrawerIconSection>
              <CloseDrawerIconContainer onClick={handleClose}>
                <CloseDrawerIcon />
              </CloseDrawerIconContainer>
            </CloseDrawerIconSection>
            <NameContainer>
              <NameText>Edit {nodeTypeLabelMap[type]} Node</NameText>
            </NameContainer>
            <SubfieldsSection>
              <SubfieldContainer>
                <InputContainer>
                  <Input
                    label='Type'
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    useFullWidth
                    removeGap
                    removeAutoMargins
                    useSmallText
                    type={dropdownInputType}
                    options={nodeTypes}
                  />
                </InputContainer>
              </SubfieldContainer>
              <SubfieldContainer>
                <InputContainer>
                  <Input
                    label='Title'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    useFullWidth
                    removeGap
                    removeAutoMargins
                    useSmallText
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        onUpdate({
                          id,
                          label: title,
                        });
                      }
                    }}
                  />
                </InputContainer>
              </SubfieldContainer>
              {type === bookingScriptNodeType && (
                <SubfieldContainer>
                  <InputContainer>
                    <StartAlignedFlexDiv addGap>
                      <StartAlignedMediumDarkEssText>
                        Offering to book
                      </StartAlignedMediumDarkEssText>
                      <SmallPrimaryEditIcon
                        onClick={() => setSelectOfferingModalOpen(true)}
                      />
                    </StartAlignedFlexDiv>
                    {bookingOffering && (
                      <StartAlignedMediumDarkLargeTinyText>
                        {bookingOffering?.displayName}
                      </StartAlignedMediumDarkLargeTinyText>
                    )}
                  </InputContainer>
                </SubfieldContainer>
              )}
              {type === conditionalScriptNodeType && (
                <SubfieldContainer>
                  <InputContainer>
                    <Input
                      label='Condition'
                      value={condition}
                      onChange={(e) => setCondition(e.target.value)}
                      useFullWidth
                      removeGap
                      removeAutoMargins
                      useSmallText
                      multiline
                      numRows={3}
                    />
                  </InputContainer>
                </SubfieldContainer>
              )}
              <SubfieldContainer>
                <InputContainer>
                  <Input
                    label='Expected response (optional)'
                    value={expectedResponse}
                    onChange={(e) => setExpectedResponse(e.target.value)}
                    useFullWidth
                    removeGap
                    removeAutoMargins
                    useSmallText
                    multiline
                    numRows={3}
                  />
                </InputContainer>
              </SubfieldContainer>
              <SubfieldContainer>
                <InputContainer>
                  <Input
                    id='content'
                    value={content}
                    onChange={(e) => {
                      setContent(e.target.value);
                    }}
                    label={<VariablesInputLabel>Content</VariablesInputLabel>}
                    multiline
                    numRows={7}
                    variables={variables}
                    showVariableTypes={containsOtherNodes}
                    inputRef={contentInputRef}
                    useFullWidth
                    removeGap
                    removeAutoMargins
                    useSmallText
                  />
                </InputContainer>
              </SubfieldContainer>
            </SubfieldsSection>
          </DrawerContentContainer>
          <ActionButtonsContainer>
            <AdaptablePrimaryButton
              removeMargins
              onClick={() =>
                onUpdate(
                  {
                    id,
                    label: title,
                    content,
                    condition,
                    type,
                    bookingOffering,
                    expectedResponse,
                  },
                  true,
                )
              }
              enabled
            >
              Save
            </AdaptablePrimaryButton>
            <AdaptableSecondaryButton removeMargins>
              Revert
            </AdaptableSecondaryButton>
          </ActionButtonsContainer>
        </DrawerContainer>
        <SelectOfferingModal
          isOpen={selectOfferingModalOpen}
          onClose={() => setSelectOfferingModalOpen(false)}
          offerings={offerings}
          onSave={onUpdateBookingOffering}
          inputSelected={bookingOffering}
        />
      </>
    )
  );
};

export default NodeDrawer;
