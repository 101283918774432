import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useChatAppointments } from '../../../api/hooks/calendar';
import { SAVE_CHAT_APPOINTMENT_ASSIGNMENTS } from '../../../api/mutations/calendar';
import { MARK_CHAT_AS_REQUIRING_DEPOSIT } from '../../../api/mutations/chat';
import {
  BrightGreenText,
  CenteredDivWithGap,
  ColumnCenteredDiv,
  Dropdown,
  DropdownLabel,
  DynamicPercentageWidthDiv,
  FlexibleMediumPrimaryButton,
  FlexibleMediumSecondaryButton,
  Form,
  InputButton,
  LargeTinyText,
  LightDarkEssText,
  MLText,
  MediumDarkSmallText,
  MediumPrimaryButton,
  MenuItem,
  Modal,
  ModalActions,
  ModalContainer,
  ScrollWrapper,
  SmallCheckbox,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
  StartAlignedBrightGreenLargeTinyText,
  StartAlignedLightDarkLargeTinyText,
  StartAlignedMediumDarkLargeTinyText,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import {
  appNameLabelMap,
  appointmentStatusLabelMap,
  assistantKey,
  humanAgentKey,
} from '../../../utils/constants';
import {
  getClientTimezone,
  getTimezoneStringFromUTCDatetime,
} from '../../../utils/date';
import { getFormattedFullNameFromUser } from '../../../utils/name';
import { getUserLocationTimezone } from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';
import PopperMenu from '../../PopperMenu';
import {
  AppointmentItem,
  AppointmentItemContainer,
  BottomAppointmentItemContainer,
  TimestampDisclaimerIcon,
  TopAppointmentItemContainer,
  TopLeftSideAppointmentItemContainer,
  TopRightSideAppointmentItemContainer,
} from './styled';

const getBookerOptions = (agentName, userMap) => {
  const userMapKeys = Object.keys(userMap);

  const options = [
    {
      key: assistantKey,
      label: agentName,
    },
    ...userMapKeys.map((k) => {
      return {
        key: k,
        label: userMap[k],
      };
    }),
  ];

  return options;
};

const EditChatAppointmentsModal = ({
  isOpen,
  onClose,
  chatId,
  clientName,
  userMap,
  agentName,
  onSave,
}) => {
  const { user, inMonitoringMode } = useContext(BaseContext);
  const timezone = inMonitoringMode
    ? getClientTimezone()
    : getUserLocationTimezone(user);

  const [nameToSearchFor, setNameToSearchFor] = useState();
  const [campaignIdMenuAnchorEl, setCampaignIdMenuAnchorEl] = useState();
  const campaignIdMenuOpen = Boolean(campaignIdMenuAnchorEl);

  const { data, loading, refetch } = useChatAppointments({
    chatId,
    onlyBooked: true,
    name: nameToSearchFor,
  });
  const baseAppointmentMap = {};
  const chatAppointments = data?.appointments || [];
  const chatCampaigns = data?.chatCampaigns || [];
  const numChatCampaigns = chatCampaigns.length;
  const assumedCampaign = numChatCampaigns === 1 ? chatCampaigns[0] : null;
  chatAppointments?.map((a) => (baseAppointmentMap[a?.appointment?.id] = a));

  const [saveChatAppointmentAssignmentsMutation, { loading: saveLoading }] =
    useMutation(SAVE_CHAT_APPOINTMENT_ASSIGNMENTS);
  const [markChatAsRequiringDepositMutation, { loading: depositLoading }] =
    useMutation(MARK_CHAT_AS_REQUIRING_DEPOSIT);

  const [formattedAppointments, setFormattedAppointments] = useState([]);

  useEffect(() => {
    if (chatId) {
      refetch();
    }
  }, [chatId, isOpen, nameToSearchFor]);

  useEffect(() => {
    if (isOpen) {
      const queriedChatAppointments = data?.appointments || [];
      if (queriedChatAppointments?.length) {
        const updatedFormattedAppointments = queriedChatAppointments?.map(
          (a) => {
            const { assignedToChat, bookerUserId, appointment } = a;
            return {
              chatId,
              assignedToChat,
              bookerUserId,
              appointment,
            };
          },
        );
        setFormattedAppointments(updatedFormattedAppointments);
      } else {
        setFormattedAppointments([]);
      }
    }
  }, [data, isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = (campaignId) => {
    const appointmentsToInput = formattedAppointments?.map((a) => {
      const baseAppointment = baseAppointmentMap[a.appointment.id];

      return {
        chatId: a.chatId,
        currentlyAssigned: baseAppointment?.assignedToChat || false,
        updatedAssigned: a.assignedToChat,
        bookerType:
          a.bookerUserId === assistantKey ? assistantKey : humanAgentKey,
        bookerUserId: a.bookerUserId,
        appointmentId: a.appointment.id,
      };
    });

    saveChatAppointmentAssignmentsMutation({
      variables: {
        appointments: appointmentsToInput,
        appliedPromo: {},
        campaignId,
      },
      onCompleted: async (data) => {
        const success = data.saveChatAppointmentAssignments;
        if (success) {
          onSave();
        }
        handleClose();
      },
    });
  };

  const handleRequiresDeposit = (campaignId) => {
    markChatAsRequiringDepositMutation({
      variables: {
        chatId,
        campaignId,
      },
      onCompleted: async (data) => {
        const success = data.markChatAsRequiringDeposit;
        if (success) {
          onSave();
        }
        handleClose();
      },
    });
  };

  const hasAppointments = formattedAppointments?.length > 0;

  const bookerOptions = getBookerOptions(agentName, userMap);

  if (saveLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    formattedAppointments && (
      <Modal
        open={isOpen}
        onClose={handleClose}
        maxWidth={600}
      >
        <ModalContainer>
          <MLText>Assign Chat Appointments</MLText>
          <ColumnCenteredDiv topMargin={10}>
            <MediumDarkSmallText>
              {hasAppointments ? (
                <>{clientName}'s appointments since chat</>
              ) : (
                <>{clientName} hasn't booked any appointments since this chat</>
              )}{' '}
            </MediumDarkSmallText>
            <ScrollWrapper
              maxHeight={350}
              removeBottomPadding
              fullWidth
            >
              <AppointmentItemContainer>
                {hasAppointments ? (
                  <>
                    {formattedAppointments?.map((a, idx) => {
                      const { appointment, assignedToChat, bookerUserId } = a;

                      const appointmentId = a?.appointment?.id;

                      const bookerKey = !bookerUserId
                        ? assistantKey
                        : bookerUserId;

                      const timestamp = getTimezoneStringFromUTCDatetime(
                        appointment?.start,
                        timezone,
                        true,
                      );

                      return (
                        <AppointmentItem
                          isBottom={idx === formattedAppointments?.length - 1}
                        >
                          <TopAppointmentItemContainer>
                            <TopLeftSideAppointmentItemContainer>
                              <StartAlignedMediumDarkLargeTinyText>
                                {appointment?.sessionTypeName ||
                                  'Unknown service'}{' '}
                                with{' '}
                                {getFormattedFullNameFromUser(
                                  appointment?.staff,
                                ) || 'unknown staff'}{' '}
                                <BrightGreenText>
                                  ({appNameLabelMap[appointment.source]} -{' '}
                                  {appointmentStatusLabelMap[
                                    appointment?.status
                                  ] || 'Unknown'}
                                  )
                                </BrightGreenText>
                                <br></br>
                                {timestamp}
                                {inMonitoringMode && (
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>
                                        Note: Since you're in monitoring mode,
                                        this appointment time is displayed in
                                        your local computer's timezone, not the
                                        clients'
                                      </TooltipTitleText>
                                    }
                                    placement='right'
                                  >
                                    <TimestampDisclaimerIcon />
                                  </Tooltip>
                                )}
                              </StartAlignedMediumDarkLargeTinyText>
                              {assignedToChat ? (
                                <StartAlignedBrightGreenLargeTinyText>
                                  Assigned to chat
                                </StartAlignedBrightGreenLargeTinyText>
                              ) : (
                                <StartAlignedLightDarkLargeTinyText>
                                  Not assigned to chat
                                </StartAlignedLightDarkLargeTinyText>
                              )}
                            </TopLeftSideAppointmentItemContainer>
                            <TopRightSideAppointmentItemContainer>
                              <SmallCheckbox
                                checked={assignedToChat}
                                onClick={() => {
                                  const updatedFormattedAppointments =
                                    formattedAppointments?.map((a) => {
                                      if (
                                        appointmentId === a?.appointment?.id
                                      ) {
                                        return {
                                          ...a,
                                          assignedToChat: !assignedToChat,
                                        };
                                      } else {
                                        return a;
                                      }
                                    });
                                  setFormattedAppointments(
                                    updatedFormattedAppointments,
                                  );
                                }}
                              />
                            </TopRightSideAppointmentItemContainer>
                          </TopAppointmentItemContainer>
                          {assignedToChat && (
                            <BottomAppointmentItemContainer>
                              <Form
                                useFullWidth
                                useMediumText
                                fixedHeight={55}
                              >
                                <DropdownLabel>Booker</DropdownLabel>
                                <Dropdown
                                  value={bookerKey}
                                  label={'Booker'}
                                  options={bookerOptions}
                                  onChange={(e) => {
                                    const bookerId = e.target.value;

                                    const updatedFormattedAppointments =
                                      formattedAppointments?.map((a) => {
                                        if (
                                          appointmentId === a?.appointment?.id
                                        ) {
                                          return {
                                            ...a,
                                            bookerUserId: bookerId,
                                          };
                                        } else {
                                          return a;
                                        }
                                      });
                                    setFormattedAppointments(
                                      updatedFormattedAppointments,
                                    );
                                  }}
                                >
                                  {bookerOptions.map((option) => (
                                    <MenuItem
                                      value={option.key}
                                      key={option.key}
                                    >
                                      <SmallMenuItemText>
                                        {option.label}
                                      </SmallMenuItemText>
                                    </MenuItem>
                                  ))}
                                </Dropdown>
                              </Form>
                            </BottomAppointmentItemContainer>
                          )}
                        </AppointmentItem>
                      );
                    })}
                  </>
                ) : (
                  <ColumnCenteredDiv>
                    <LightDarkEssText>
                      Booked for someone else? Search by their name:
                    </LightDarkEssText>
                    <DynamicPercentageWidthDiv width={90}>
                      <CenteredDivWithGap>
                        <Input
                          id='external-client-id'
                          value={nameToSearchFor}
                          onChange={(e) => {
                            setNameToSearchFor(e.target.value);
                          }}
                          label='Client booking first name'
                          useFullWidth
                          removeGap
                        />
                        <InputButton
                          largePadding
                          onClick={() => refetch()}
                        >
                          Search
                        </InputButton>
                      </CenteredDivWithGap>
                    </DynamicPercentageWidthDiv>
                  </ColumnCenteredDiv>
                )}
              </AppointmentItemContainer>
            </ScrollWrapper>
          </ColumnCenteredDiv>
          <ModalActions>
            {hasAppointments ? (
              <ColumnCenteredDiv>
                <MediumPrimaryButton
                  largePadding
                  onClick={(e) => {
                    if (numChatCampaigns > 1) {
                      setCampaignIdMenuAnchorEl(e.currentTarget);
                    } else {
                      handleSave(assumedCampaign.id);
                    }
                  }}
                >
                  Save chat assignments
                </MediumPrimaryButton>
                {numChatCampaigns === 1 && (
                  <LargeTinyText>
                    Will be attributed to the '{assumedCampaign?.name}' campaign
                  </LargeTinyText>
                )}
              </ColumnCenteredDiv>
            ) : (
              <ColumnCenteredDiv>
                <FlexibleMediumPrimaryButton
                  largePadding
                  onClick={(e) => {
                    if (numChatCampaigns > 1) {
                      setCampaignIdMenuAnchorEl(e.currentTarget);
                    } else {
                      handleRequiresDeposit(assumedCampaign.id);
                    }
                  }}
                  fixedMinWidth={250}
                >
                  Requires deposit
                </FlexibleMediumPrimaryButton>
                <FlexibleMediumSecondaryButton
                  onClick={handleClose}
                  largePadding
                  fixedMinWidth={250}
                >
                  Close
                </FlexibleMediumSecondaryButton>
              </ColumnCenteredDiv>
            )}
          </ModalActions>
          <PopperMenu
            open={campaignIdMenuOpen}
            anchorElement={campaignIdMenuAnchorEl}
            onClose={() => {
              setCampaignIdMenuAnchorEl();
            }}
            variant='offset'
          >
            <SmallMenuItemTitleContainer disableRipple>
              <SmallMenuItemTitleText>
                Select campaign to assign booking to:
              </SmallMenuItemTitleText>
            </SmallMenuItemTitleContainer>
            {chatCampaigns.map((c) => (
              <MenuItem
                onClick={() => {
                  if (hasAppointments) {
                    handleSave(c.id);
                  } else {
                    handleRequiresDeposit(c.id);
                  }
                }}
              >
                <SmallMenuItemText>{c.name}</SmallMenuItemText>
              </MenuItem>
            ))}
          </PopperMenu>
        </ModalContainer>
      </Modal>
    )
  );
};

export default EditChatAppointmentsModal;
