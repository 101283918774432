import React, { useState } from 'react';
import {
  FullWidthCenteredDivWithGap,
  TinyText,
  WhiteTinyText,
} from '../../../styles/shared-styled-components';
import { endKey, startKey } from '../../../utils/constants';
import PopperMenu from '../../PopperMenu';
import {
  MenuCalendar,
  RangeField,
  RangeFieldContainer,
  ResetFilterIcon,
  ResetFilterIconContainer,
} from './styled';

const RangePopperMenuCalendar = ({
  start,
  end,
  onChangeStart,
  onChangeEnd,
  onReset,
  open,
  anchorElement,
  onClose,
  placement = 'bottom',
}) => {
  const [fieldEditing, setFieldEditing] = useState(startKey);

  const modifiers =
    placement === 'bottom'
      ? {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        }
      : {
          name: 'offset',
          options: {
            offset: [-300, -440],
          },
        };

  const onChange = (updatedValue) => {
    if (fieldEditing === startKey) {
      onChangeStart(updatedValue);
      setFieldEditing(endKey);
    } else {
      onChangeEnd(updatedValue);
    }
  };

  const handleClose = () => {
    setFieldEditing(startKey);
    onClose();
  };

  const handleReset = () => {
    handleClose();
    onReset();
  };

  return (
    <PopperMenu
      open={open}
      anchorElement={anchorElement}
      onClose={handleClose}
      variant='offset'
      placement={placement}
      modifiers={modifiers}
      largeMaxHeight={true}
      removeScroll={true}
      largePaperHeight
    >
      <FullWidthCenteredDivWithGap relativePosition>
        <RangeFieldContainer position={startKey}>
          <RangeField
            selected={fieldEditing === startKey}
            onClick={() => setFieldEditing(startKey)}
            position={startKey}
          >
            {fieldEditing === startKey ? (
              <WhiteTinyText>Start Filter</WhiteTinyText>
            ) : (
              <TinyText>Start Filter</TinyText>
            )}
          </RangeField>
          <RangeField
            selected={fieldEditing === endKey}
            onClick={() => setFieldEditing(endKey)}
            position={endKey}
          >
            {fieldEditing === endKey ? (
              <WhiteTinyText>End Filter</WhiteTinyText>
            ) : (
              <TinyText>End Filter</TinyText>
            )}
          </RangeField>
        </RangeFieldContainer>
        <ResetFilterIconContainer onClick={handleReset}>
          <ResetFilterIcon />
        </ResetFilterIconContainer>
      </FullWidthCenteredDivWithGap>
      <MenuCalendar
        onChange={onChange}
        value={fieldEditing === startKey ? start : end}
        locale='en-US'
        firstDay={1}
      />
    </PopperMenu>
  );
};

export default RangePopperMenuCalendar;
