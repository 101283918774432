import React, { useContext, useRef, useState } from 'react';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Header from '../../../components/NavBar/Header';
import ClientSearch from '../../../components/Search/ClientSearch/ClientSearch';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  ContentContainer,
  PageContainer,
  PageTitleText,
  TinyGapColumnCenteredDiv,
} from '../../../styles/shared-styled-components';
import { masterContactsContextKey } from '../../../utils/constants';
import { getUserLocationId } from '../../../utils/user';

const Clients = () => {
  const { user, drawerOpen, drawerExpanded, cookies, removeCookie } =
    useContext(BaseContext);

  const contentContainerRef = useRef();

  const locationId = getUserLocationId(user);

  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <MetaSetter
        title={`Contacts`}
        description={`LiveIQ Contacts`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
        >
          <TinyGapColumnCenteredDiv>
            <PageTitleText>Contacts</PageTitleText>
          </TinyGapColumnCenteredDiv>
          {locationId && (
            <ClientSearch
              locationId={locationId}
              context={masterContactsContextKey}
            />
          )}
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Clients;
