import React, { useState } from 'react';
import {
  CenteredDiv,
  MediumDarkMLText,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
} from '../../../styles/shared-styled-components';
import { VideoFrame } from './styled';

const AgentVideoMessageModal = ({ isOpen, onClose, agentName }) => {
  const [reason, setReason] = useState();
  const [notes, setNotes] = useState();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <MediumDarkMLText>A Video Message from {agentName} ✨</MediumDarkMLText>
        <CenteredDiv>
          <VideoFrame>
            <source
              src='https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/Ava%20intro%20message%202.mp4'
              type='video/mp4'
            />
          </VideoFrame>
        </CenteredDiv>
        <ModalActions>
          <MediumSecondaryButton onClick={onClose}>Close</MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default AgentVideoMessageModal;
