import { get, sortBy } from 'lodash';
import {
  appointmentBookedStatus,
  appointmentDepositRequiredStatus,
  appointmentMonitoringStatus,
} from './constants';

export const sortChatMessages = (chat) => {
  if (!chat) {
    return null;
  }

  const sortedMessages = sortBy(
    chat?.messages,
    (m) => new Date(get(m, 'sentAt')),
  );
  return sortedMessages;
};

export const getChatAppointmentStatus = (appointmentStatuses) => {
  if (!appointmentStatuses?.length) {
    return {
      appointmentStatus: null,
      count: 0,
    };
  }

  if (appointmentStatuses.includes(appointmentBookedStatus)) {
    return {
      appointmentStatus: appointmentBookedStatus,
      count: appointmentStatuses.filter((s) => s === appointmentBookedStatus)
        .length,
    };
  } else if (appointmentStatuses.includes(appointmentDepositRequiredStatus)) {
    return {
      appointmentStatus: appointmentDepositRequiredStatus,
      count: 1,
    };
  } else if (appointmentStatuses.includes(appointmentMonitoringStatus)) {
    return {
      appointmentStatus: appointmentMonitoringStatus,
      count: 1,
    };
  }

  return {
    appointmentStatus: null,
    count: 0,
  };
};

export default {
  sortChatMessages,
};
