import React from 'react';
import {
  EmptyGapColumnCenteredDiv,
  InputTitleContainer,
  StartAlignedMediumDarkEssText,
} from '../../../styles/shared-styled-components';
import { intFieldType } from '../../../utils/constants';
import SplitInput from '../../Form/SplitInput/SplitInput';

const RangeFilter = ({
  label,
  lowerBound,
  setLowerBound,
  upperBound,
  setUpperBound,
}) => {
  return (
    <EmptyGapColumnCenteredDiv>
      <InputTitleContainer>
        <StartAlignedMediumDarkEssText>{label}</StartAlignedMediumDarkEssText>
      </InputTitleContainer>
      <SplitInput
        id={'discount'}
        splitInputs={[
          {
            id: 'greater',
            value: lowerBound,
            onChange: (e) => setLowerBound(e.target.value),
            label: 'Greater than',
            type: intFieldType,
          },
          {
            id: 'less',
            value: upperBound,
            onChange: (e) => setUpperBound(e.target.value),
            label: 'Less than',
            type: intFieldType,
          },
        ]}
        useExtraSmallGap={true}
      />
    </EmptyGapColumnCenteredDiv>
  );
};

export default RangeFilter;
